import {
  VStack
} from "@chakra-ui/react";

import FieldGroup from "components/FieldGroup";
import { Aside } from "components/Page";
import { AccountSelect, CategorySelect, PayeeSelect } from "components/Select";

const ScheduledTransactionsAside = ({ setFilters }) => {
  const _setFilters = (filter, items) => {
    window.analytics.track("Scheduled Transactions Filtered", {
      filter
    });

    setFilters(filter, items);
  }

  return (
    <Aside>
      <FieldGroup title = "Filters" pt = "6">
        <VStack spacing = "2">
          <PayeeSelect 
            isMulti = { true }
            onChange = { items => _setFilters("payee", items.map(item => item.value)) }
            shouldLoadData = { true }
          />

          <AccountSelect 
            isMulti = { true }
            onChange = { items => _setFilters("account", items.map(item => item.value)) }
            shouldLoadData = { true }
          />

          <CategorySelect 
            isMulti = { true }
            onChange = { items => _setFilters("category", items.map(item => item.value)) }
            shouldLoadData = { true }
            includeNoCategory = { true }
          />
        </VStack>
      </FieldGroup>
    </Aside>
  )
};

export default ScheduledTransactionsAside;
import gql from "graphql-tag";

const GET_ALL_CATEGORIES_IN_SELECT_FORMAT = gql`
  query GetAllCategoriesInSelectFormat($budget_id: uuid!) {
    categories(where: {is_system: { _eq: false }, budget_id: { _eq: $budget_id }}) {
      value: id
      label: name
    }
  }
`;

export {
  GET_ALL_CATEGORIES_IN_SELECT_FORMAT
}
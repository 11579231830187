import {
  Button,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { useState } from "react";
import * as Sentry from "@sentry/react";

import PasswordField from "components/PasswordField";
import { auth } from 'utils/nhost';
import parseAuthError from 'utils/parseAuthError';

const LoginForm = () => {
  const [ email, setEmail ] = useState("");
  const [ error, setError ] = useState({});
  const [ isLoading, toggleLoading ] = useState(false);
  const [ password, setPassword ] = useState("");

  const onSubmit = e => {
    Sentry.setUser({ email });
    toggleLoading(true);
    setError({});
    e.preventDefault();

    auth.login({ email, password })
    .then(resp => {
      const { user } = resp;
      Sentry.setUser({ email, id: user.id });

      window.analytics.identify(user.id);
      window.analytics.track("User Logged In");
    })
    .catch(err => {
      toggleLoading(false);
      const parsedError = parseAuthError(err);
      setError(parsedError);

      window.analytics.track("Error Triggered", {
        error: parsedError.code
      });
    })
  }

  const isFormValid = email.length > 0 && password.length > 0;

  return (
    <Box 
      bg = {{ base: "transparent", sm: mode("white", "gray.800") }}
      rounded = "base" 
      shadow = {{ base: "none", sm: mode("light.xl", "dark.xl") }}
      width = {{ base: "full", sm: "md" }}
      py = {{ base: "2", sm: "6"}}
      px = {{ base: "4", sm: "6" }}
    >
      <form onSubmit = { onSubmit }>
        <Stack spacing = "4" py = "4">
          <FormControl id = "email" isInvalid = { error.field === "email" }>
            <FormLabel>Email address</FormLabel>
            <Input 
              autoComplete = "email"
              autoFocus = { true }
              id = "email"
              onChange = { e => setEmail(e.target.value) }
              rounded = "sm"
              type = "email"
              value = { email }
            />
            <FormErrorMessage>{ error.message }</FormErrorMessage>
          </FormControl>
          <FormControl id = "password">
            <PasswordField 
              displayForgotPasswordLink = { true }
              isInvalid = { error.field === "current-password" }
              errorMessage = { error.message }
              autoComplete = "current-password"
              id = "password"
              label = "Password"
              name = "current-password"
              onChange = { e => setPassword(e.target.value) }
              value = { password }
            />
          </FormControl>
          <Button
            colorScheme = "brand"
            loadingText = "Logging In..."
            fontSize = "md"
            isDisabled = { !isFormValid }
            isLoading = { isLoading }
            type = "submit"
            width = "full"
          >
            Log In
          </Button>
        </Stack>
        <Text>Don't have an account? <Link color = "brand.500" href = "/signup">Sign up</Link></Text>
      </form>
    </Box>
  )
};

export default LoginForm;

import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { NhostAuthProvider } from '@nhost/react-auth';
import { NhostApolloProvider } from "@nhost/react-apollo";
import { InMemoryCache } from '@apollo/client';
import "./index.css";

import { auth } from "utils/nhost";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <NhostAuthProvider auth = { auth }>
    <NhostApolloProvider
      gqlEndpoint = { process.env.REACT_APP_GQL_ENDPOINT }
      auth = { auth }
      cache = { new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              budget_months: {
                merge: ( existing = [], incoming = [] ) => {
                  return incoming
                }
              },
              categories: {
                merge: ( existing = [], incoming = [] ) => {
                  return incoming
                }
              },
              category_groups: {
                merge: ( existing = [], incoming = [] ) => {
                  return incoming
                }
              },
              investment_holdings: {
                merge: ( existing = [], incoming = [] ) => {
                  return incoming
                }
              },
              scheduled_transactions: {
                merge: ( existing = [], incoming = [] ) => {
                  return incoming
                }
              },
              transactions: {
                merge: ( existing = [], incoming = [] ) => {
                  return incoming
                }
              }
            }
          }
        }
      }) }
    >
      <ColorModeScript />
      <App />
    </NhostApolloProvider> 
  </NhostAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

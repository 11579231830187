import { memo, useRef } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  useBreakpointValue,
  VStack
} from "@chakra-ui/react";

import Budget from "./Budget";
import CategoryActions from "./CategoryActions";
import CategoryName from "./CategoryName";
import CategoryNotes from "./CategoryNotes";
import CategoryGroup from "./CategoryGroup";
import IsIncomeToggle from "./IsIncomeToggle";
import IsRolloverToggle from "./IsRolloverToggle";
import UpcomingTransactions from "./UpcomingTransactions";
import FieldGroup from "components/FieldGroup";

const shouldNotReRender = (prev, next) => {
  return next.isLoading;
}

const CategoryDrawer = ({ isOpen, onClose, category = {}, currentMonth }) => {
  const size = useBreakpointValue({ base: "full", md: "sm" });
  const closeButtonRef = useRef(null);

  return (
    <Drawer
      onClose = { onClose }
      isOpen = { isOpen }
      placement = "right"
      size = { size }
      blockScrollOnMount = { false }
      trapFocus = { false }
      initialFocusRef = { closeButtonRef }
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Category</DrawerHeader>
          <DrawerBody ref = { closeButtonRef }>
            { category && <VStack spacing = "8">
              <FieldGroup pl = "0" title = "Category Details">
                <CategoryName category = { category } />
                <CategoryGroup category = { category } />
                <CategoryNotes category = { category } />
              </FieldGroup>
              <FieldGroup title = "Category Settings">
                <IsIncomeToggle category = { category } />
                <IsRolloverToggle category = { category } />
              </FieldGroup>
              <Budget category = { category } currentMonth = { currentMonth } />
              <UpcomingTransactions category = { category } currentMonth = { currentMonth }/>
              <CategoryActions category = { category } onClose = { onClose } />
            </VStack> }
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
};

export default memo(CategoryDrawer, shouldNotReRender);
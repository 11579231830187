import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  Divider,
  HStack,
  Switch,
  Text,
  useBoolean,
  useDisclosure
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { FiTrash } from 'react-icons/fi';

import { GET_CATEGORIES, GET_CATEGORY_GROUPS } from "../gql";
import ShortcutTooltip from "components/ShortcutTooltip";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const DELETE_CATEGORY_GROUP = gql`
  mutation DeleteCategoryGroup($id: uuid!) {
    delete_category_groups_by_pk(id: $id) {
      id
    }
  }
`;

const DELETE_CATEGORIES = gql`
  mutation DeleteCategories($category_ids: [uuid!]!) {
    delete_categories(where: {id: {_in: $category_ids}}) {
      returning {
        id
      }
    }
  }
`;

const DeleteCategoryGroup = ({ categoryGroup, categories, callback }) => {
  const budgetId = getDefaultBudget();
  const [ deleteCategories, { toggle: toggleDeleteCategories } ] = useBoolean(false);
  const renderToast = useToast();
  const cancelRef = useRef();
  const [ deleteCategoryGroup ] = useMutation(DELETE_CATEGORY_GROUP, {
    update: (cache, { data: { delete_category_groups_by_pk: { id }}}) => {
      const categoryGroupsData = cache.readQuery({ query: GET_CATEGORY_GROUPS, variables: { budget_id: budgetId } });
      cache.writeQuery({
        query: GET_CATEGORY_GROUPS,
        variables: { budget_id: budgetId },
        data: {
          category_groups: categoryGroupsData.category_groups.filter(categoryGroup => categoryGroup.id !== id )
        }
      });

      const categoriesData = cache.readQuery({ query: GET_CATEGORIES, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_CATEGORIES,
        variables: { budget_id: budgetId },
        data: {
          categories: categoriesData.categories.map(category => {
            if ( category.category_group_id === id ) {
              return { ...category, category_group_id: null }
            } else {
              return category
            }
          })
        }
      })
    }
  });
  const [ deleteCategoriesMutation ] = useMutation(DELETE_CATEGORIES, {
    update: (cache, { data: { delete_categories: { returning }}}) => {
      const deletedCategoryIds = returning.map(r => r.id);
      const data = cache.readQuery({ query: GET_CATEGORIES, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_CATEGORIES,
        variables: { budget_id: budgetId },
        data: {
          categories: data.categories.filter(category => !deletedCategoryIds.includes(category.id))
        }
      })
    }
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCategoryGroupDelete = async () => {
    callback();

    if ( deleteCategories ) {
      await deleteCategoriesMutation({
        variables: {
          category_ids: categories.map(category => category.id)
        }
      });
    }

    await deleteCategoryGroup({
      variables: {
        id: categoryGroup.id
      }
    });

    window.analytics.track("Category Group Deleted", {
      delete_categories: deleteCategories
    });
    renderToast("Category Group Deleted", deleteCategories ? `${categories.length} ${ categories.length === 1 ? "category" : "categories" } have also been deleted.` : null);
  }

  const totalCategoryTransactions = categories.reduce((total, category) => total + category.transactions_aggregate.aggregate.count, 0);
  return (
    <>
      <ShortcutTooltip label = "Delete Category Group">
        <Button
          size = "sm"
          variant = "icon"
          onClick = { onOpen }
        ><FiTrash /></Button>
      </ShortcutTooltip>

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Delete Category Group</AlertDialogHeader>
          <AlertDialogBody>
            { categories.length > 0 ? (
              <>
                <FormControl display = "flex" alignItems = "center">
                  <Switch
                    id = "delete-categories"
                    colorScheme = "primary"
                    mr = "2"
                    isChecked = { deleteCategories }
                    onChange = { toggleDeleteCategories }
                  />
                  <FormLabel htmlFor = "delete-categories" mb = "0">Delete { categories.length } { categories.length === 1 ? "category" : "categories" }?</FormLabel>
                </FormControl>
                <Text display = { deleteCategories ? "block" : "none"} mt = "1">
                { totalCategoryTransactions > 0 ? `${totalCategoryTransactions} transaction${totalCategoryTransactions > 1 ? 's' : '' } will be marked as uncategorized.` : "There are no transactions associated with this category group."}
                </Text>
              </>
            ) : (
              <Text>Are you sure?</Text>
            )}
          </AlertDialogBody>
          <AlertDialogFooter justifyContent = "space-between">
            <Button size = "sm" ref = { cancelRef } onClick = { onClose }>Cancel</Button>
            <Button size = "sm" colorScheme = "red" onClick = { onCategoryGroupDelete }>Delete Category Group</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const CategoryGroupActions = ({ categoryGroup, categories, onClose }) => {
  return (
    <Box width = "full">
      <Divider my = "2" />
      <HStack justifyContent = "flex-end">
        <DeleteCategoryGroup categoryGroup = { categoryGroup } categories = { categories } callback = { onClose } />
      </HStack>
    </Box>
  )
};

export default CategoryGroupActions;
import {
  Th,
  Tr
} from "@chakra-ui/react";

const ThProps = {
  scope: "col",
  px: "4",
  fontSize: { base: "sm", md: "unset"}
}

const HeaderRow = ({ headerGroup }) => {
  const renderHeader = headerName => headerGroup.headers.find(column => column.Header === headerName).render('Header');
  const getHeaderProps = headerName => headerGroup.headers.find(column => column.Header === headerName).getHeaderProps();

  return (
    <Tr { ...headerGroup.getHeaderGroupProps() }>
      <Th
        { ...getHeaderProps('Date') }
        { ...ThProps }
        width = {{ base: "20", sm: "28" }}
        px = {{ base: "2", sm: "4" }}
      >{ renderHeader('Date') }</Th>
      <Th
        { ...getHeaderProps('Activity Type') }
        { ...ThProps }
        display = {{ base: "none", md: "table-cell" }}
      >{ renderHeader('Activity Type') }</Th>
      <Th
        { ...getHeaderProps('Symbol') }
        { ...ThProps }
        display = {{ base: "none", md: "table-cell" }}
      >{ renderHeader('Symbol') }</Th>
      <Th
        { ...getHeaderProps('Account') }
        { ...ThProps }
        display = {{ base: "none", md: "table-cell" }}
      >{ renderHeader('Account') }</Th>

      <Th
        { ...ThProps }
        display = {{ base: "table-cell", md: "none" }}
      >Details</Th>

      <Th
        { ...getHeaderProps('Amount') }
        { ...ThProps }
        width = {{ base: "24", sm: "36" }}
        px = {{ base: "2", sm: "4" }}
        textAlign = "right"
      >{ renderHeader('Amount') }</Th>
    </Tr>
  )
};

export default HeaderRow;
import { VscDebugRestart } from "react-icons/vsc";
import { BiArrowBack, BiBarChart } from "react-icons/bi";
import { FaBalanceScale } from "react-icons/fa";

const planActions = [
  {
    id: "start-fresh",
    name: "Start Fresh",
    keywords: "reset start fresh",
    icon: VscDebugRestart,
    command: "start_fresh",
    section: "Plan"
  },
  {
    id: "plan-last-month-budget",
    name: "Quick Budget - Last Month Budget",
    keywords: "quick budget last month plan",
    icon: BiArrowBack,
    command: "plan_quick_budget_last_month",
    section: "Plan",
    shortcut: ['⌥', 'L'],
    is_quick_budget_function: true
  },
  {
    id: "plan-average-spending-budget",
    name: "Quick Budget - Average Spending",
    keywords: "quick budget average spending plan",
    icon: BiBarChart,
    command: "plan_quick_budget_average_spending",
    section: "Plan",
    shortcut: ['⌥', 'A'],
    is_quick_budget_function: true
  },
  {
    id: "plan-true-up-budget",
    name: "Quick Budget - True Up",
    keywords: "quick budget true up plan",
    icon: FaBalanceScale,
    command: "plan_quick_budget_true_up",
    section: "Plan",
    shortcut: ['⌥', 'T'],
    is_quick_budget_function: true
  }
];

const categoryActions = [
  {
    id: "category-last-month-budget",
    name: "Category Quick Budget - Last Month Budget",
    keywords: "quick budget last month category",
    icon: BiArrowBack,
    command: "category_quick_budget_last_month",
    section: "Category",
    shortcut: ['⌥', 'shift', 'L'],
    is_quick_budget_function: true
  },
  {
    id: "category-average-spending-budget",
    name: "Category Quick Budget - Average Spending",
    keywords: "quick budget average spending category",
    icon: BiBarChart,
    command: "category_quick_budget_average_spending",
    section: "Category",
    shortcut: ['⌥', 'shift', 'A'],
    is_quick_budget_function: true
  },
  {
    id: "category-true-up-budget",
    name: "Category Quick Budget - True Up",
    keywords: "quick budget true up category",
    icon: FaBalanceScale,
    command: "category_quick_budget_true_up",
    section: "Category",
    shortcut: ['⌥', 'shift', 'T'],
    is_quick_budget_function: true
  }
]

export {
  planActions,
  categoryActions
}
import { useEffect } from 'react';
const Mousetrap = require('mousetrap');

const useHotKeys = (key, func, dependencies = [], condition = true, action = null ) => {
  useEffect(() => {
    if ( condition ) {
      if ( action ) {
        Mousetrap.bind(key, e => {
          !e.repeat && func(e)
        }, action);
  
        return () => {
          Mousetrap.unbind(key, action);
        }
      } else {
        Mousetrap.bind(key, e => {
          !e.repeat && func(e)
        });
  
        return () => {
          Mousetrap.unbind(key);
        }
      }
    };
      
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [ key, func, condition, ...dependencies ])
};

export default useHotKeys;
import {
  VStack
} from "@chakra-ui/react";

import CategoryGroupActions from "./CategoryGroupActions";
import CategoryGroupName from "./CategoryGroupName";
import CategoryList from "./CategoryList";

const CategoryGroup = ({ categoryGroup, categories, onClose, setTargetCategory }) => (
  <VStack spacing = "8">
    <CategoryGroupName categoryGroup = { categoryGroup } />
    <CategoryList categoryGroup = { categoryGroup } categories = { categories } setTargetCategory = { categoryId => { onClose(); setTargetCategory(categoryId) } } />
    <CategoryGroupActions categoryGroup = { categoryGroup } categories = { categories } onClose = { onClose } />
  </VStack>
);

export default CategoryGroup;
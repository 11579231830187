import {
  Td,
  Tr
} from "@chakra-ui/react";

const TdProps = {
  px: { base: "2", md: "4"},
  py: { base: "1", md: "2" },
  whiteSpace: "nowrap",
  isTruncated: true
}

const BodyRow = ({ row, onRowClick = () => null }) => {
  const renderCell = headerName => row.cells.find(cell => cell.column.Header === headerName).render('Cell');
  const getCellProps = headerName => row.cells.find(cell => cell.column.Header === headerName).getCellProps();

  return (
    <Tr
      { ...row.getRowProps() }
      onClick = { () => onRowClick(row.original.id) }
      id = { row.original.id }
      tabIndex = { row.index }
      onMouseEnter = { () => document.getElementById(row.original.id).focus()}
    >
      <Td
        { ...getCellProps('Name') }
        { ...TdProps }
      >{ renderCell("Name") }</Td>
      <Td
        { ...getCellProps('Quantity') }
        { ...TdProps }
        display = {{ base: "none", sm: "table-cell"}}
      >{ renderCell("Quantity") }</Td>
      <Td
        { ...getCellProps('Value') }
        { ...TdProps }
      >{ renderCell("Value") }</Td>
      <Td
        { ...getCellProps('Return') }
        { ...TdProps }
      >{ renderCell("Return") }</Td>
      <Td
        { ...getCellProps('Weight') }
        { ...TdProps }
        display = {{ base: "none", sm: "table-cell"}}
      >{ renderCell("Weight") }</Td>
    </Tr>
  )
};

export default BodyRow;
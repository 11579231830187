import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { useState } from "react";

import { auth } from 'utils/nhost';
import parseAuthError from 'utils/parseAuthError';

const RequestForm = ({ callback }) => {
  const [ email, setEmail ] = useState("");
  const [ error, setError ] = useState({});
  const [ isLoading, toggleLoading ] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    toggleLoading(true);

    auth.requestPasswordChange(email)
    .then(() => {
      window.analytics.track("Password Reset Requested");
      callback();
    })
    .catch(err => {
      toggleLoading(false);
      const parsedError = parseAuthError(err);
      setError(parsedError);

      window.analytics.track("Error Triggered", {
        error: parsedError.code
      });
    })
  };

  return (
    <Box 
      bg = {{ base: "transparent", sm: mode("white", "gray.800") }}
      rounded = "sm" 
      shadow = {{ base: "none", sm: "xl" }}
      width = {{ base: "full", sm: "md" }}
      py = {{ base: "2", sm: "6"}}
      px = {{ base: "4", sm: "6" }}
    >
      <form onSubmit = { onSubmit }>
        <Stack spacing = "4" py = "4">
          <FormControl id = "email" isInvalid = { error.field === "email" }>
            <FormLabel>Email address</FormLabel>
            <Input
              autoComplete = "email"
              autoFocus = { true }
              id = "email"
              onChange = { e => setEmail(e.target.value) }
              type = "email"
              value = { email }
              rounded = "sm"
            />
            <FormErrorMessage>{ error.message }</FormErrorMessage>
          </FormControl>
          <Button
            colorScheme = "primary"
            loadingText = "Sending reset email..."
            fontSize = "md"
            isDisabled = { email.length === 0 }
            isLoading = { isLoading }
            type = "submit"
            width = "full"
          >Reset Password</Button>
        </Stack>
      </form>
      <Link color = "primary.500" href = "/login">Log in</Link>
    </Box>
  )
};

export default RequestForm;
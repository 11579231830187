import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useRadioGroup
} from "@chakra-ui/react";
import { GiBroom } from "react-icons/gi";
import { VscDebugRestart } from "react-icons/vsc";
import { useMutation } from "@apollo/client";

import { GET_BUDGET_MONTHS, GET_CATEGORIES, GET_CATEGORY_GROUPS, HARD_RESET_PLAN, SOFT_RESET_PLAN } from "../gql";
import RadioButton from "components/RadioButton";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const StartFresh = ({ onClose, isOpen }) => {
  const budgetId = getDefaultBudget();
  const renderToast = useToast();
  const [ resetMode, setResetMode ] = useState("soft");
  const [ softResetPlanMutation, { loading: loadingSoftReset }] = useMutation(SOFT_RESET_PLAN, {
    update: cache => {
      cache.writeQuery({
        query: GET_BUDGET_MONTHS,
        variables: { budget_id: budgetId },
        data: {
          budget_months: []
        }
      })
    }
  });
  const [ hardResetPlanMutation, { loading: loadingHardReset }] = useMutation(HARD_RESET_PLAN, {
    update: cache => {
      cache.writeQuery({
        query: GET_CATEGORIES,
        variables: { budget_id: budgetId },
        data: {
          categories: []
        }
      });

      cache.writeQuery({
        query: GET_BUDGET_MONTHS,
        variables: { budget_id: budgetId },
        data: {
          budget_months: []
        }
      });

      cache.writeQuery({
        query: GET_CATEGORY_GROUPS,
        variables: { budget_id: budgetId },
        data: {
          category_groups: []
        }
      });
    }
  })

  useEffect(() => {
    setResetMode("soft");
  }, [ isOpen ]);

  const { getRadioProps, getRootProps } = useRadioGroup({ onChange: setResetMode, defaultValue: resetMode, value: resetMode });

  const onClick = async () => {
    if ( resetMode === "soft" ) {
      await softResetPlanMutation({
        variables: { 
          budget_id: budgetId
        }
      }).then(() => {
        renderToast("Clean Up Complete" );
        window.analytics.track("Plan Reset Completed", {
          mode: "soft"
        });
      });
    } else if (resetMode === "hard" ) {
      await hardResetPlanMutation({
        variables: { 
          budget_id: budgetId
        }
      }).then(() => {
        renderToast("Ready to Start Fresh");
        window.analytics.track("Plan Reset Completed", {
          mode: "hard"
        });
      });
    }

    onClose(false);
  }

  return (
    <Modal isOpen = { isOpen } onClose = { onClose } size = "xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Start Fresh</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack
            justiy = "center"
            direction = {{ base: "column", md: "row" }}
            spacing = "4"
            { ...getRootProps() }
            alignItems = {{ base: "unset", md: "stretch"}}
          >
            <RadioButton
              icon = { <GiBroom /> }
              label = "Clean Up"
              { ...getRadioProps({
                value: "soft"
              })}
              description = "Keep all of your categories and category groups and reset all budgets to 0."
            />

            <RadioButton
              icon = { <VscDebugRestart /> }
              label = "Start Fresh"
              { ...getRadioProps({
                value: "hard"
              })}
              description = "Delete all categories. All transactions will be marked as uncategorized."
            />
          </Stack>
        </ModalBody>
        <ModalFooter mt = "2">
          <Button
            width = {{ base: "full", md: "unset" }}
            colorScheme = "primary"
            size = "sm"
            rounded = "sm"
            onClick = { onClick }
            isLoading = { loadingSoftReset || loadingHardReset }
          >
            { resetMode === "soft" ? "Clean Up Plan" : "Start a Fresh Plan" }
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default StartFresh;
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

import Accordion from "./Accordion";
import Button from "./Button";
import CloseButton from "./CloseButton";
import Container from "./Container";
import Drawer from "./Drawer";
import Editable from "./Editable";
import Heading from "./Heading";
import Input from "./Input";
import Kbd from "./Kbd";
import Link from "./Link";
import Modal from "./Modal";
import NumberInput from "./NumberInput";
import PopoutSelect from "./PopoutSelect";
import SelectDropdown from "./SelectDropdown";
import Switch from "./Switch";
import Table from "./Table";
import Text from "./Text";
import Textarea from "./Textarea";
import Tooltip from "./Tooltip";


const components = {
  Accordion,
  Button,
  CloseButton,
  Container,
  Drawer,
  Editable,
  Heading,
  Input,
  Kbd,
  Link,
  Modal,
  NumberInput,
  PopoutSelect,
  SelectDropdown,
  Steps,
  Switch,
  Table,
  Text,
  Textarea,
  Tooltip
};

export default components;
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { incomeCategories, categoryGroups } from "./budgetDefaults";
import { CREATE_BUDGET, CREATE_CATEGORIES, CREATE_CATEGORY_GROUPS, ADD_DEFAULT_BUDGET} from "./gql";
import PasswordField from "components/PasswordField";
import { CurrencySelect } from "components/Select";
import { auth } from 'utils/nhost';
import parseAuthError from 'utils/parseAuthError';

const SignupForm = () => {
  const [ firstName, setFirstName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ error, setError ] = useState({});
  const [ isLoading, toggleLoading ] = useState(false);
  const [ password, setPassword ] = useState("");
  const [ currency, setCurrency ] = useState(null);

  const [ addDefaultBudget ] = useMutation(ADD_DEFAULT_BUDGET);
  const [ createBudget ] = useMutation(CREATE_BUDGET);
  const [ createCategories ] = useMutation(CREATE_CATEGORIES);
  const [ createCategoryGroups ] = useMutation(CREATE_CATEGORY_GROUPS);

  const onSubmit = e => {
    Sentry.setUser({ email });
    toggleLoading(true);
    setError({});
    e.preventDefault();

    auth.register({
      email,
      password,
      options: { userData: { display_name: firstName, timezone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/New_York' } },
    })
    .then(async resp => {
      const { user } = resp;
      Sentry.setUser({ email, id: user.id });

      window.analytics.alias(user.id);
      window.analytics.identify(user.id);
      window.analytics.track("User Signed Up");

      // Create Budget
      const createBudgetResponse = await createBudget({
        variables: {
          budget: {
            default_currency: currency.value,
            budget_roles: {
              data: {
                user_id: auth.getClaim("x-hasura-user-id"),
                role: "creator"
              }
            }
          }
        }
      });

      const budgetId = createBudgetResponse.data.insert_budgets_one.id;

      await createCategories({
        variables: {
          categories: incomeCategories.map(category => ({
            name: category,
            is_income: true,
            budget_id: budgetId
          }))
        }
      });

      await createCategoryGroups({
        variables: {
          category_groups: categoryGroups.map(group => ({
            name: group.name,
            categories: {
              data: group.categories.map(category => ({ name: category, budget_id: budgetId }))
            },
            budget_id: budgetId
          }))
        }
      })

      await addDefaultBudget({
        variables: {
          user_id: auth.getClaim("x-hasura-user-id"),
          budget_id: budgetId
        }
      })
      .then(() => {
        window.location.assign("/")
      })
    })
    .catch(err => {
      toggleLoading(false);
      const parsedError = parseAuthError(err);
      setError(parsedError);

      window.analytics.track("Error Triggered", {
        error: parsedError.code
      });
    })
  }

  const isFormValid = email.length > 0 && password.length >= 8 && firstName.length > 0 && currency;

  return (
    <Box 
      bg = {{ base: "transparent", sm: mode("white", "gray.800") }}
      rounded = "sm" 
      shadow = {{ base: "none", sm: "xl" }}
      width = {{ base: "full", sm: "md" }}
      py = {{ base: "2", sm: "6"}}
      px = {{ base: "4", sm: "6" }}
    >
      <form onSubmit = { onSubmit }>
        <Stack spacing = "4" py = "4">
          <FormControl id = "firstName">
            <FormLabel>First name</FormLabel>
            <Input 
              autoComplete = "given-name"
              autoFocus = { true }
              id = "firstName"
              onChange = { e => setFirstName(e.target.value) }
              rounded = "sm"
              type = "text"
              value = { firstName }
            />
            <FormErrorMessage>{ error.message }</FormErrorMessage>
          </FormControl>
          <FormControl id = "email" isInvalid = { error.field === "email" }>
            <FormLabel>Email address</FormLabel>
            <Input 
              autoComplete = "email"
              id = "email"
              onChange = { e => setEmail(e.target.value) }
              rounded = "sm"
              type = "email"
              value = { email }
            />
            <FormErrorMessage>{ error.message }</FormErrorMessage>
          </FormControl>
          <FormControl id = "password">
            <PasswordField 
              isInvalid = { error.field === "new-password" }
              errorMessage = { error.message }
              autoComplete = "new-password"
              id = "password"
              label = "Password"
              name = "new-password"
              onChange = { e => setPassword(e.target.value) }
              value = { password }
              helpText = "Password must be at least 8 characters"
            />
          </FormControl>

          <FormControl id = "currency">
            <FormLabel>Budget Currency</FormLabel>
            <CurrencySelect 
              shouldLoadIPCurrency = { true }
              value = { currency }
              onChange = { setCurrency }
            />
          </FormControl>
          <Box>
            <Button
              colorScheme = "brand"
              loadingText = "Signing up..."
              fontSize = "md"
              isDisabled = { !isFormValid }
              isLoading = { isLoading }
              type = "submit"
              width = "full"
            >
              Sign up
            </Button>
            <Text textAlign = "center" variant = "helper">No credit card required during free trial. Cancel at anytime.</Text>
          </Box>
        </Stack>
        <Text>Already have an account? <Link color = "brand.500" href = "/login">Log in</Link></Text>
      </form>
    </Box>
  )
};

export default SignupForm;

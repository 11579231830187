import PopoutSelect from "./PopoutSelect";
import { accountTypesAndSubtypes } from "utils/options";

const AccountTypeSelect = ({ onChange, accountType, accountSubtype, isDisabled, buttonLabel = "Select Account Type"}) => {
  const accountTypeOptions = accountTypesAndSubtypes.map(section => ({ 
    ...section, 
    options: section.subtypes.map(subtype => ({ 
      ...subtype, 
      value: section.value + "-" + subtype.value, 
      type: section.value, 
      subtype: subtype.value 
  })) })) || []; // paypal is listed in two sections
  const value = accountTypeOptions.find(section => section.value === accountType)?.options.find(option => option.subtype === accountSubtype);

  return (
    <PopoutSelect
      value = { value }
      options = { accountTypeOptions }
      onChange = { onChange }
      buttonLabel = { buttonLabel }
      tooltipText = { buttonLabel }
      isDisabled = { isDisabled }
    />
  )
};

export default AccountTypeSelect;
const Accordion = {
  parts: ['button'],
  baseStyle: {
    button: {
      _focus: {
        boxShadow: "none"
      }
    }
  },
};

export default Accordion;
import gql from "graphql-tag";

const GET_ALL_PAYEES_IN_SELECT_FORMAT = gql`
  query GetAllPayeesInSelectFormat($budget_id: uuid!) {
    payees(where: { budget_id: { _eq: $budget_id }}) {
      value: id
      label: name
    }
  }
`;

export {
  GET_ALL_PAYEES_IN_SELECT_FORMAT
}
import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  HStack,
  useDisclosure
} from "@chakra-ui/react";
import { FiTrash } from 'react-icons/fi';
import { FaCheckCircle } from "react-icons/fa";
import { useMutation } from "@apollo/client";

import { 
  DELETE_SCHEDULED_TRANSACTION,
  GET_SCHEDULED_TRANSACTIONS,
  UPDATE_NEXT_DATE
} from "../gql";
import ShortcutTooltip from "components/ShortcutTooltip";
import useToast from "hooks/useToast";
import { getNextDate } from "utils/calculations";
import getDefaultBudget from "utils/getDefaultBudget";

const moment = require("moment-timezone");

const DeleteScheduledTransaction = ({ scheduledTransactionId, deleteScheduledTransaction, callback }) => {
  const renderToast = useToast();
  const cancelRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onDelete = async () => {
    await deleteScheduledTransaction({
      variables: {
        scheduled_transaction_id: scheduledTransactionId
      }
    });

    renderToast("Scheduled Transaction Deleted");
    window.analytics.track("Scheduled Transaction Deleted");
    callback();
  };

  return (
    <>
      <ShortcutTooltip label = "Delete scheduled transaction">
        <Button
          size = "sm"
          variant = "icon"
          onClick = { onOpen }
        ><FiTrash /></Button>
      </ShortcutTooltip>

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Delete Scheduled Transaction</AlertDialogHeader>
          <AlertDialogBody>
          Are you sure?
          </AlertDialogBody>
          <AlertDialogFooter justifyContent = "space-between">
            <Button size = "sm" ref = { cancelRef } onClick = { onClose }>Cancel</Button>
            <Button size = "sm" colorScheme = "red" onClick = { onDelete }>Delete Scheduled Transaction</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
};

const ScheduledTransactionActions = ({ scheduledTransaction, onClose }) => {
  const budgetId = getDefaultBudget()
  const renderToast = useToast();
  const [ updateNextDate ] = useMutation(UPDATE_NEXT_DATE);
  const [ deleteScheduledTransaction ] = useMutation(DELETE_SCHEDULED_TRANSACTION, {
    update: (cache, { data: { delete_scheduled_transactions_by_pk: { id } }}) => {
      const data = cache.readQuery({ query: GET_SCHEDULED_TRANSACTIONS, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_SCHEDULED_TRANSACTIONS,
        variables: { budget_id: budgetId },
        data: {
          scheduled_transactions: data.scheduled_transactions.filter(transaction => transaction.id !== id )
        }
      })
    }
  });

  const onMarkPaid = async () => {
    const startDate = scheduledTransaction.start_date;
    const endDate = scheduledTransaction.end_after_date;
    const frequency = scheduledTransaction.frequency;
    const afterDate = moment(scheduledTransaction.next_date).toDate();

    const nextDate = getNextDate({ startDate, endDate, frequency, afterDate, include: false });

    if ( nextDate ) {
      await updateNextDate({
        variables: {
          scheduled_transaction_id: scheduledTransaction.id,
          next_date: nextDate
        }
      });
    } else {
      await deleteScheduledTransaction({
        variables: {
          scheduled_transaction_id: scheduledTransaction.id
        }
      });
      onClose();
    }

    renderToast("Scheduled Transaction Marked as Paid");
    window.analytics.track("Scheduled Transaction Marked Paid");
  }

  return (
    <Box width = "full">
      <Divider mb = "2"/>
        <HStack width = "full" justifyContent = "flex-end"> 
          <ShortcutTooltip label = "Mark scheduled transaction as paid">
            <Button
              size = "sm"
              variant = "icon"
              onClick = { onMarkPaid }
            >
              <FaCheckCircle />
            </Button>
          </ShortcutTooltip>
          
            <DeleteScheduledTransaction deleteScheduledTransaction = { deleteScheduledTransaction } scheduledTransactionId = { scheduledTransaction.id} callback = { onClose } />
        </HStack>
    </Box>
  )
};

export default ScheduledTransactionActions;
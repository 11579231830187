import { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure
} from "@chakra-ui/react";
import { HiPlus } from 'react-icons/hi';
import { useMutation } from "@apollo/client";

import { GET_CATEGORY_GROUPS, INSERT_CATEGORY_GROUP } from "./gql";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const AddCategoryGroup = ({ setTargetCategoryGroup }) => {
  const budgetId = getDefaultBudget();
  const renderToast = useToast();
  const [ categoryGroupName, setCategoryGroupName ] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [ createCategoryGroup, { loading } ] = useMutation(INSERT_CATEGORY_GROUP, {
    update: (cache, { data: { insert_category_groups_one }}) => {
      const data = cache.readQuery({ query: GET_CATEGORY_GROUPS, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_CATEGORY_GROUPS,
        variables: { budget_id: budgetId },
        data: {
          category_groups: [ ...(data?.category_groups || []), insert_category_groups_one ]
        }
      })
    }
  });

  useEffect(() => {
    setCategoryGroupName("");
  }, [ isOpen ]);
  
  const onSubmit = async e => {
    e.preventDefault();

    const resp = await createCategoryGroup({
      variables: {
        category_group: {
          name: categoryGroupName,
          budget_id: budgetId
        }
      }
    });

    const categoryGroupId = resp.data.insert_category_groups_one.id;
    onClose();
    setTargetCategoryGroup(categoryGroupId); 
    renderToast("Category Group Created");
    window.analytics.track("Category Group Created");
  }

  return (
    <>
      <Button 
        variant = "ghost" 
        colorScheme = "gray" 
        leftIcon = { <HiPlus /> } 
        fontSize = "sm" 
        onClick = { onOpen }
        rounded = "sm"
      >Add Category Group</Button>

      <Modal isOpen = { isOpen } onClose = { onClose }>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Category Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit = { onSubmit }>
              <HStack pb = "2">
                <Input
                  value = { categoryGroupName }
                  onChange = { e => setCategoryGroupName(e.target.value) }
                  autoFocus = { true }
                  placeholder = "Category Group Name"
                  variant = "flushed"
                />
                <Button
                  width = "120px"
                  size = "sm"
                  isLoading = { loading }
                  loadingText = "Saving..."
                  type = "submit"
                  isDisabled = { categoryGroupName.length === 0 }
                  colorScheme = "brand"
                >Save</Button>
              </HStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
};

export default AddCategoryGroup;
import { useEffect, useState } from "react";
import {
  Container,
  Fade,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue as mode,
  useToast
} from "@chakra-ui/react";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";

import CategorizeTransactions from "./CategorizeTransactions";
import { GET_QUICK_REVIEW_DETAILS } from "./gql";
import ScheduledTransactions from "./ScheduledTransactions";
import SpendingPower from "./SpendingPower";
import StartScreen from "./StartScreen";
import FullToast from "components/FullToast";
import { ALL_TRANSACTION_FIELDS } from "fragments";
import getDefaultBudget from "utils/getDefaultBudget";

const GET_UNCATEGORIZED_TRANSACTIONS = gql`
  ${ALL_TRANSACTION_FIELDS}
  query GetUncategorizedTransactions($budget_id: uuid!) {
    transactions(where: {is_deleted: { _eq: false }, category_id: {_is_null: true}, account: {budget_id: {_eq: $budget_id}, is_hidden: {_eq: false}}}) {
      ...AllTransactionFields
    }
  }
`;

const GET_OVERDUE_SCHEDULED_TRANSACTIONS = gql`
  subscription GetOverdueScheduledTransactions($budget_id: uuid!, $today: String!) {
    scheduled_transactions(where: {next_date: {_lte: $today}, account: {budget_id: {_eq: $budget_id }}}) {
      id
      account {
        id
        name
      }
      amount
      currency
      category {
        id
        name
      }
      end_after_date
      frequency
      needs_category
      next_date
      start_date
      notes
      payee {
        id
        name
      }
      transfer_account {
        id
        name
      }
      __typename
    }
  }
`;

const GET_BUDGET = gql`
  query GetBudget($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      __typename
      id
      default_currency
      quick_review_last_completed_at
      quick_review_streak
    }
  }
`;

const UPDATE_BUDGET = gql`
  mutation UpdateBudget($budget_id: uuid!, $date: timestamptz!, $streak: numeric!) {
    update_budgets_by_pk(
      pk_columns: { id: $budget_id },
      _set: { quick_review_last_completed_at: $date, quick_review_streak: $streak }
    ) {
      id
      quick_review_last_completed_at
      quick_review_streak
    }
  }
`;

const GET_BUDGETED_EXPENSES = gql`
  query GetBudgetedExpenses($budget_id: uuid!, $current_month: String!) {
    budgeted_expenses: budget_months_aggregate(where: { amount: { _gt: 0 }, category: {budget_id: {_eq: $budget_id}, is_system: {_eq: false}, is_income: {_eq: false}}, year_month: {_eq: $current_month}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

const GET_ACTUAL_EXPENSES = gql`
  query GetActualExpenses($budget_id: uuid!, $current_month: String!) {
    actual_expenses: transactions_by_category_month_aggregate(where: {year_month: {_eq: $current_month}, category: {budget_id: {_eq: $budget_id}, is_income: {_eq: false}, is_system: {_eq: false}}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

const GET_UPCOMING_SCHEDULED_TRANSACTIONS = gql`
  query GetUpcomingScheduledTransactions($budget_id: uuid!, $start_of_month: String!, $end_of_month: String! ) {
    scheduled_transactions(where: {_and: {next_date: {_gte: $start_of_month, _lte: $end_of_month}, account: {is_hidden: {_eq: false}, budget_id: {_eq: $budget_id}}}}) {
      amount
      frequency
      next_date
      end_after_date
      start_date
      needs_category
      category_id
      category {
        is_income
      }
    }
  }
`;

const QuickReview = ({ onClose, isOpen }) => {
  const toast = useToast();
  const budgetId = getDefaultBudget();
  const currentMonth = moment().format("YYYY-MM");
  const [ uncategorizedTransactions, setUncategorizedTransactions ] = useState(null);
  const [ activeScreen, setActiveScreen ] = useState("start");
  const [ updateBudget ] = useMutation(UPDATE_BUDGET);
  const { data: quickReviewDetails, refetch: refetchQuickReviewDetails } = useQuery(GET_QUICK_REVIEW_DETAILS, {
    variables: {
      budget_id: budgetId,
      today: moment().format("YYYY-MM-DD")
    },
    skip: !isOpen,
    fetchPolicy: "no-cache"
  });

  const screens = [
    { index: 0, screen: 'start', show: true },
    { index: 1, screen: 'categorizeTransactions', show: quickReviewDetails?.uncategorized_transactions.aggregate.count > 0 },
    { index: 2, screen: 'scheduledTransactions', show: quickReviewDetails?.overdue_scheduled_transactions.aggregate.count > 0 },
    { index: 3, screen: 'spendingPower', show: true }
  ]

  const { data: uncategorizedTransactionsData } = useQuery(GET_UNCATEGORIZED_TRANSACTIONS, {
    variables: {
      budget_id: budgetId
    },
    skip: !isOpen
  });

  const { data: overdueScheduledTransactionsData } = useSubscription(GET_OVERDUE_SCHEDULED_TRANSACTIONS, {
    variables: {
      budget_id: budgetId,
      today: moment().format("YYYY-MM-DD")
    },
    skip: !isOpen
  });

  const { data: budgetedExpensesData } = useQuery(GET_BUDGETED_EXPENSES, {
    variables: {
      budget_id: budgetId,
      current_month: currentMonth
    },
    skip: !isOpen
  });

  const { data: actualExpensesData } = useQuery(GET_ACTUAL_EXPENSES, {
    variables: {
      budget_id: budgetId,
      current_month: currentMonth
    },
    skip: !isOpen
  });

  const { data: budgetData } = useQuery(GET_BUDGET, {
    variables: {
      budget_id: budgetId
    },
    skip: !isOpen
  });

  const { data: scheduledTransactionsData } = useQuery(GET_UPCOMING_SCHEDULED_TRANSACTIONS, {
    variables: {
      budget_id: budgetId,
      start_of_month: moment().startOf('month').format("YYYY-MM-DD"),
      end_of_month: moment().endOf('month').format("YYYY-MM-DD")
    },
    skip: !isOpen
  });

  const overdueScheduledTransactions = overdueScheduledTransactionsData?.scheduled_transactions;
  const upcomingScheduledTransactions = scheduledTransactionsData?.scheduled_transactions || [];

  useEffect(() => {
    if ( uncategorizedTransactionsData ) {
      setUncategorizedTransactions(prev => prev || uncategorizedTransactionsData.transactions.filter(transaction => transaction.needs_category))
    }
  }, [ uncategorizedTransactionsData ]);

  useEffect(() => {
    setActiveScreen("start");
    if ( isOpen ) {
      refetchQuickReviewDetails();
    }
  // eslint-disable-next-line
  }, [ isOpen ]);

  const goToScreen = direction => {
    const screensToShow = screens.filter(screen => screen.show);
    const currentScreen = screensToShow.find(screen => screen.screen === activeScreen);
    const nextScreen = screensToShow.find(screen => screen.index > (currentScreen?.index || 0));
    const previousScreen = screensToShow.reverse().find(screen => screen.index < (currentScreen?.index || 3));
    setActiveScreen(direction === "previous" ? previousScreen.screen : nextScreen.screen);
  }

  const updateTransaction = newTransaction => {
    setUncategorizedTransactions(prev => prev.map(transaction => transaction.id === newTransaction.id ? newTransaction : transaction ));
  }

  const onComplete = () => {
    let streak = null;
    const currentStreak = budgetData.budget.quick_review_streak;
    const lastCompletedAt = budgetData.budget.quick_review_last_completed_at;

    if ( lastCompletedAt && moment(lastCompletedAt).isSame(moment(), 'day') ) {
      streak = currentStreak
    } else if ( lastCompletedAt && moment(lastCompletedAt).isSame(moment().subtract(1, 'day'), 'day') ) {
      streak = currentStreak + 1
    } else {
      streak = 1;
    };

    updateBudget({
      variables: {
        budget_id: budgetId,
        streak,
        date: moment().toDate()
      }
    })
    .then(() => {
      window.analytics.track("Quick Review Completed", {
        streak
      });

      toast({
        position: "top",
        render: ({ onClose }) => (
          <FullToast
            onClose = { onClose }
            title = "Quick Review Complete"
            message = { "Current streak: " + streak }
          />
        ),
        isClosable: true,
        duration: 9999
      })
      onClose();
    });
  }
  
  const isLoaded = uncategorizedTransactions && overdueScheduledTransactions;

  return (
    <Modal isOpen = { isOpen } onClose = { onClose }>
      <ModalContent 
        height = "100%" 
        m = "0" 
        maxW = "100%" 
        width = "100%" 
        bg = {{ base: mode("white", "gray.800"), md: mode("white", "gray.900") }}
        overflow = "scroll"
        rounded = "none"
      >
        <ModalHeader>Quick Review</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW = "container.sm" py = {{ base: 2, md: 6, lg: 12 }}>
          <Fade in = { isLoaded }>
              { activeScreen === "start" ? (
              <StartScreen 
                  uncategorizedTransactionsCount = { uncategorizedTransactions?.length }
                  overdueScheduledTransactionsCount = { overdueScheduledTransactions?.length }
                  goToScreen = { goToScreen }
              /> 
              ): null }

              { activeScreen === "categorizeTransactions" ? (
              <CategorizeTransactions
                  transactions = { uncategorizedTransactions }
                  goToScreen = { goToScreen }
                  updateTransaction = { updateTransaction }
              />
              ) : null }

              { activeScreen === "scheduledTransactions" ? (
              <ScheduledTransactions
                  scheduledTransactions = { overdueScheduledTransactions }
                  goToScreen = { goToScreen }
              />
              ) : null }

              { activeScreen === "spendingPower" ? (
              <SpendingPower
                  goToScreen = { goToScreen }
                  onComplete = { onComplete }
                  scheduledTransactions = { upcomingScheduledTransactions }
                  totalActualExpenses = { actualExpensesData?.actual_expenses.aggregate.sum.amount || 0 }
                  totalBudgetedExpenses = { budgetedExpensesData?.budgeted_expenses.aggregate.sum.amount || 0 }
                  currency = { budgetData?.budget.default_currency }
              />
              ) : null }
          </Fade>
        </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

export default QuickReview;
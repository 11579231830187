import { theme } from "@chakra-ui/react";

const Button = {
  baseStyle: {
    _focus: {
      boxShadow: "none"
    },
    rounded: "sm"
  },
  variants: {
    icon: props => ({
      ...theme.components.Button.variants.ghost(props),
      rounded: "full",
      _focus: {
        boxShadow: "none"
      },
      p: "1",
      textAlign: "center"
    }),
    selectButton: props => ({
      width: "full",
      rounded: "sm",
      border: "none",
      boxShadow: "none",
      bg: props.colorMode === "light" ? "gray.100" : "gray.600",
      _hover: {
        bg: props.colorMode === "light" ? "gray.200" : "gray.500",
        cursor: "pointer"
      },
      display: "inline-flex",
      fontSize: "sm",
      px: "2",
      py: "1",
      verticalAlign: "top",
      height: "full",
      lineHeight: "1.2",
      justifyContent: "flex-start",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      minHeight: "1.7rem",
      alignItems: "center",
      color: props.colorMode === "light" ? "gray.900" : "whiteAlpha.900",
      fontWeight: "normal",
    }),
    disabledSelectButton: props => ({
      width: "full",
      rounded: "sm",
      border: "none",
      boxShadow: "none",
      bg: "transparent",
      display: "inline-flex",
      fontSize: "sm",
      px: "0",
      py: "2",
      verticalAlign: "top",
      height: "full",
      lineHeight: "1.2",
      justifyContent: "flex-start",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      minHeight: "1.7rem",
      alignItems: "center",
      color: props.colorMode === "light" ? "inherit" : "whiteAlpha.900",
      fontWeight: "normal",
      cursor: "default"
    })
  }
};

export default Button;
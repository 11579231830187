import gql from "graphql-tag";

const UPDATE_CATEGORY_NOTES = gql`
  mutation UpdateCategoryNotes($category_id: uuid!, $notes: String!) {
    update_categories_by_pk(
      pk_columns: { id: $category_id },
      _set: { notes: $notes }
    ) {
      id
      notes
    }
  }
`;

export {
  UPDATE_CATEGORY_NOTES
}
import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { CategorySelect } from "components/Select";
import useToast from "hooks/useToast";

const INSERT_CATEGORY = gql`
  mutation InsertCategory($category_name: citext!) {
    insert_categories(objects: [{ name: $category_name }]) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateTransactionCategory($scheduled_transaction_id: uuid!, $category_id: uuid!) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { category_id: $category_id }
    ) {
      id
    }
  }
`;

const Category = ({ scheduledTransaction }) => {
  const renderToast = useToast();
  const [ createCategory ] = useMutation(INSERT_CATEGORY);
  const [ updateCategory ] = useMutation(UPDATE_CATEGORY);

  const onCategoryChange = async item => {
    if ( item.value !== scheduledTransaction.category?.value ) {
      let categoryId;

      if ( item.__isNew__ ) {
        const resp = await createCategory({
          variables: {
            category_name: item.label
          }
        });

        categoryId = resp.data.insert_categories.returning[0].id;
      } else {
        categoryId = item.value;
      }

      await updateCategory({
        variables: {
          category_id: categoryId,
          scheduled_transaction_id: scheduledTransaction.id
        }
      });

      renderToast("Category Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "category"
      })
    }
  };

  const isTransferCategory = !scheduledTransaction.needs_category && scheduledTransaction.transfer_account;

  return (
    <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Category</FormLabel>
      </Box>
      <CategorySelect
        value = { isTransferCategory ? ({ label: "Transfer", value: "transfer" }) : scheduledTransaction.category }
        onChange = { onCategoryChange }
        shouldLoadData = { true }
        isCreatable = { true }
        isDisabled = { isTransferCategory }
        
      />
    </Grid>
  )
};

export default Category;
import { useEffect, useState } from "react";
import { AiOutlineFilter } from "react-icons/ai";
import { useLocation } from 'react-router-dom';
import {
  Box
} from "@chakra-ui/react";

import AddTransaction from "./AddTransaction";
import TransactionsAside from "./TransactionsAside";
import TransactionsDrawer from "./TransactionDrawer";
import TransactionsTable from "./TransactionsTable";
import UploadTransactions from "./UploadTransactions";
import { Main, Header, Page } from "components/Page";

const Transactions = () => {
  const [ filters, setFilters ] = useState([]);
  const [ defaultFilters, setDefaultFilters ] = useState([]);
  const _setFilters = (id, value) => {
    setFilters(prev => prev.filter(filter => filter.id !== id).concat({ id, value}));
  };

  const querySearch = useLocation().search;

  useEffect(() => {
    if ( querySearch ) {
      const urlQuery = new URLSearchParams(querySearch);
      const queryParams = Object.fromEntries(urlQuery);    
    
      const { category, payee, account } = queryParams;
      const _filters = [
        { id: "category", value: category?.split(",") || [] },
        { id: "payee", value: payee?.split(",") || [] },
        { id: "account", value: account?.split(",") || [] },
      ];

      setFilters(_filters);
      setDefaultFilters(_filters);
    }
  }, [ querySearch ]);

  const [ focusTransaction, setFocusTransaction ] = useState(null);

  useEffect(() => {
    window.analytics.page("Transactions");
  }, []);

  const _setFocusTransaction = transactionId => {
    window.analytics.track("Transaction Drawer Opened");
    setFocusTransaction(transactionId);
  }

  return (
    <Page>
      <TransactionsAside defaultFilters = { defaultFilters } setFilters = { _setFilters } />
      <Main 
        asideButtonLabel = "Filter Transactions"
        asideShortcutKey = "f"
        AsideButtonIcon = { AiOutlineFilter }
        hasAside = { true }
      >
        <Header title = "Transactions">
          <Box display = "flex">
            <UploadTransactions />
            <AddTransaction />
          </Box>
        </Header>
        <TransactionsTable
          setFocusTransaction = { _setFocusTransaction }
          disableHotKeys = { !!focusTransaction }
          filters = { filters }
        />
        <TransactionsDrawer
          isOpen = { !!focusTransaction }
          onClose = { () => setFocusTransaction(null) }
          transactionId = { focusTransaction }
        />
      </Main>
    </Page>
  )
};

export default Transactions;
import { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Text,
  useColorModeValue as mode,
  useDisclosure
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { AiOutlineBank, AiOutlineDisconnect, AiOutlineWarning } from "react-icons/ai";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import * as Sentry from "@sentry/react";

import AccountsList from "./AccountsList";
import useToast from "hooks/useToast";
import Plaid from "components/Plaid";
import ShortcutTooltip from "components/ShortcutTooltip";
import API from "utils/api";

const DELETE_PLAID_ITEM = gql`
  mutation DeletePlaidItem($id: String! ) {
    delete_plaid_items(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const REMOVE_PLAID_ITEM_ERROR = gql`
  mutation RemovePlaidItemError($item_id: String!, $today: timestamptz) {
    update_plaid_items_by_pk(
      pk_columns: { id: $item_id },
      _set: { error: null, synced_at: $today }
    ) {
      id
    }
  }
`;

const DisconnectInstitution = ({ institution }) => {
  const [ deletePlaidItem ] = useMutation(DELETE_PLAID_ITEM);
  const [ isLoading, toggleIsLoading ] = useState(false);
  const cancelRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onDisconnectItem = async () => {
    toggleIsLoading(true);
    const api = new API();

    await api.revokePlaidAccessToken(institution.access_token);
    window.analytics.track("Institution Deleted", {
      provider: "plaid"
    });

    await deletePlaidItem({ variables: { id: institution.id }})
    .then(() => toggleIsLoading(false));
  }

  return (
    <>
      <ShortcutTooltip label = "Disconnect institution">
        <Button size = "sm" variant = "icon" onClick = { onOpen }><AiOutlineDisconnect /></Button>
      </ShortcutTooltip>

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
        returnFocusOnClose = { false }
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Disconnect Bank</AlertDialogHeader>
          <AlertDialogBody>Are you sure you want to disconnect this bank? You will still be able to access your current accounts and transactions. However, no new data will be synced with OmniMoney. This action cannot be undone.</AlertDialogBody>
          <AlertDialogFooter>
            <Button variant = "ghost" ref = { cancelRef } onClick = { onClose }>Cancel</Button>
            <Button 
              variant = "ghost" 
              colorScheme = "red" 
              onClick = { onDisconnectItem } 
              ml = { 3 }
              isLoading = { isLoading }
              loadingText = "Deleting..."
            >Disconnect</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
};

const ReconnectInstitution = ({ institution }) => {
  const [ removePlaidItemError ] = useMutation(REMOVE_PLAID_ITEM_ERROR)
  const renderToast = useToast();
  const [ linkToken, setLinkToken ] = useState(null);
  const [ isLoading, toggleIsLoading ] = useState(false);
  const api = new API();

  const oldSyncedAt = institution.synced_at;

  const onSuccess = async () => {
    await removePlaidItemError({
      variables: {
        item_id: institution.id,
        today: new Date().toISOString()
      }
    });

    await Promise.all(institution.accounts.map(account => api.syncPlaidHistory(account.id, moment.max(moment(oldSyncedAt), moment(account.sync_start_date)).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))))

    window.analytics.track("Institution Reconnected", {
      provider: "plaid"
    })
    setLinkToken(null);
    renderToast("Bank account reconnected", "Any new or missed transactions will be availble shortly.")
  }

  const onExit = (err, metadata) => {
    if ( err ) {
      Sentry.captureException(err);
      console.log(err)
    }
  };

  const fetchLinkToken = async () => {
    toggleIsLoading(true);
    const response = await api.getPlaidLinkToken(institution.access_token);
    setLinkToken(response.link_token);
    toggleIsLoading(false);
  }

  return (
    <>
      <ShortcutTooltip label = "There's an error with this institution. Click here to fix the connection.">
        <Button isLoading = { isLoading } color = "red.500" size = "sm" variant = "icon" onClick = { fetchLinkToken }><AiOutlineWarning /></Button>
      </ShortcutTooltip>
      { linkToken ? <Plaid linkToken = { linkToken } onSuccess = { onSuccess } onExit = { onExit } /> : null }
    </>
  )
}

const Institution = ({ institution, setTargetAccount }) => {
  return (
    <Box 
      width = "full" 
      py = "4"
      rounded = "sm"
      shadow = { mode("light.md", "dark.md") }
      bg = { mode("white", "gray.700")}
    >
      <HStack px = "4" justifyContent = "space-between" alignItems = "flex-end" mb = "2">
        <Box>
          <Flex align = "center" mb = "2">
            <Avatar shadow = { mode("light.md", "dark.md") } size = "sm" mr = "2" src = { institution.plaid_institution?.logo ? "data:image/png;base64," + institution.plaid_institution?.logo : null } icon = { <AiOutlineBank fontSize = "1.25rem"/> } />
            <Heading fontWeight = "normal" fontSize = "xl" as = "h3">
              { institution.plaid_institution?.name || institution.name }
            </Heading>
          </Flex>
          { institution.access_token ? (
            <Text variant = "helper">
              { institution.synced_at ? `Last updated on ${ moment(institution.synced_at).format("LLL") }` : "Waiting for first sync"}
            </Text>
          ) : null }
        </Box>
        <HStack>
          { ['ITEM_LOGIN_REQUIRED'].includes(institution.error) ? <ReconnectInstitution institution = { institution } /> : null }
          { institution.access_token ? <DisconnectInstitution institution = { institution } /> : null }
        </HStack>
      </HStack>
      <Divider />

      <AccountsList 
        accounts = { institution.accounts || [] } 
        setTargetAccount = { setTargetAccount }
      />
    </Box>
  );
};

export default Institution;
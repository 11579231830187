import gql from "graphql-tag";

const GET_QUICK_REVIEW_STATS = gql`
  query GetQuickReviewStats($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      quick_review_last_completed_at
      quick_review_streak
    }
  }
`;

const GET_UNCAT_TRANSACTIONS = gql`
  query GetUncatTransactions($budget_id: uuid!) {
    transactions(where: {is_deleted: { _eq: false }, category_id: {_is_null: true}, account: {budget_id: {_eq: $budget_id}, is_hidden: {_eq: false}}}) {
      id
      needs_category
    }
  }
`;

export {
  GET_QUICK_REVIEW_STATS,
  GET_UNCAT_TRANSACTIONS
}
import { useEffect } from "react";

import {
  Avatar,
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Text,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";

import About from "./About";
import News from "./News";
import PriceHistory from "./PriceHistory";

const Symbol = ({ goBackToNews, symbol }) => {
  useEffect(() => {
    window.analytics.page("Symbol");
  }, []);

  return (
    <Box>
      <Container maxW = "container.xl">
        <Box mb = "2">
          <Button colorScheme = "tertiary" size = "sm" variant = "link" onClick = { goBackToNews }>Back to News</Button>
        </Box>
        <Box>
          <HStack spacing = { 2 }>
            <Avatar bg = "gray.200" shadow = { mode("light.md", "dark.md") } size = "xl" src = { symbol.logo.url } name = { symbol.company.companyName || symbol.symbol } />
            <Box>
              <Heading as = "h2">{ symbol.company.companyName }</Heading>
              <Text opacity = "0.7" fontWeight = "semibold">{ symbol.company.symbol }</Text>
            </Box>
          </HStack>
        </Box>
        <VStack mt = "4" width = "full" spacing = "4" alignItems = "flex-start">
          <About companyData = { symbol.company } statsData = { symbol.stats } />
          <PriceHistory symbol = { symbol.company.symbol } />
          { symbol.news?.length > 0 ? <News news = { symbol.news } /> : null }
        </VStack>
        
      </Container>
    </Box>
  )
};

export default Symbol;
import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  useDisclosure,
  HStack
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { FiTrash } from 'react-icons/fi';

import { GET_CATEGORIES } from "../gql";
import ShortcutTooltip from "components/ShortcutTooltip";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: uuid!) {
    delete_categories_by_pk(id: $id) {
      id
    }
  }
`;

const DeleteCategory = ({ category, callback }) => {
  const budgetId = getDefaultBudget();
  const renderToast = useToast();
  const cancelRef = useRef();
  const [ deleteCategory ] = useMutation(DELETE_CATEGORY, {
    update: (cache, { data: { delete_categories_by_pk: { id }}}) => {
      const data = cache.readQuery({ query: GET_CATEGORIES, variables: { budget_id: budgetId } });
      cache.writeQuery({
        query: GET_CATEGORIES,
        variables: { budget_id: budgetId },
        data: {
          categories: data.categories.filter(category => category.id !== id )
        }
      })
    }
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCategoryDelete = async () => {
    callback();
    await deleteCategory({
      variables: {
        id: category.id
      }
    });

    window.analytics.track("Category Deleted");
    renderToast("Category Deleted");
  }

  const totalTransactions = category.transactions_aggregate.aggregate.count;

  return (
    <>
      <ShortcutTooltip label = "Delete Category">
        <Button
          size = "sm"
          variant = "icon"
          onClick = { onOpen }
        ><FiTrash /></Button>
      </ShortcutTooltip>

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Delete Category</AlertDialogHeader>
          <AlertDialogBody>
            Are you sure? { totalTransactions > 0 ? `${totalTransactions} transaction${totalTransactions > 1 ? 's' : '' } will be marked as uncategorized.` : "There are no transactions associated with this category."}
          </AlertDialogBody>
          <AlertDialogFooter justifyContent = "space-between">
            <Button size = "sm" ref = { cancelRef } onClick = { onClose }>Cancel</Button>
            <Button size = "sm" colorScheme = "red" onClick = { onCategoryDelete }>Delete Category</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const CategoryActions = ({ category , onClose }) => {
  return (
    <Box width = "full">
      <Divider my = "2" />
      <HStack justifyContent = "flex-end">
        <DeleteCategory category = { category } callback = { onClose } />
      </HStack>
    </Box>
  )
};

export default CategoryActions;
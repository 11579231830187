import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useTheme
} from "@chakra-ui/react";
import { CSVReader } from "react-papaparse";
import { useQuery } from "@apollo/client";

import { AccountSelect } from "components/Select";
import { GET_ALL_CATEGORIES_IN_SELECT_FORMAT } from "gql/categories";
import { GET_ALL_PAYEES_IN_SELECT_FORMAT } from "gql/payees";
import { currencyOptions } from "utils/options";

const Upload = ({ account, setAccount, isOpen, setUploadData, budgetId }) => {
  const [ error, setError ] = useState(null);
  const theme = useTheme();
  const { data: categoriesData } = useQuery(GET_ALL_CATEGORIES_IN_SELECT_FORMAT, {
    skip: !isOpen,
    variables: { budget_id: budgetId }
  });
  const { data: payeesData } = useQuery(GET_ALL_PAYEES_IN_SELECT_FORMAT, {
    skip: !isOpen,
    variables: { budget_id: budgetId }
  });

  const categoryOptions = categoriesData?.categories;
  const payeeOptions = payeesData?.payees;

  useEffect(() => {
    setError(null);
  }, [ isOpen, setAccount ]);

  const onFileUpload = uploadDataArray => {
    // Column check
    const requiredColumns = ['date', 'payee', 'amount', 'currency'];
    const fileColumns = uploadDataArray[0].meta.fields;
    const missingColumn = requiredColumns.find(column => !fileColumns.includes(column));
    if ( missingColumn ) {
      setError(`Missing "${missingColumn}" column in csv`);
      return null;
    }

    const uploadData = uploadDataArray.map(arr => arr.data);
    
    const columnWithMissingData = requiredColumns.find(column => !!uploadData.find(row => !row[column]));
    if ( columnWithMissingData ) {
      const missingRowCount = uploadData.filter(row => !row[columnWithMissingData]).length
      setError(`Missing ${columnWithMissingData} data for ${missingRowCount} row${missingRowCount === 1 ? '': 's'}.`);
      return null;
    }

    const currenciesInUpload = uploadData.map(row => row.currency.trim().toLowerCase());
    const validCurrencies = currencyOptions.map(option => option.value.toLowerCase());
    const invalidCurrency = currenciesInUpload.find(currency => !validCurrencies.includes(currency))
    if ( invalidCurrency ) {
      setError(`Invalid currency ${invalidCurrency}.`);
      return null;
    }

    setError(null);
    const formattedData = uploadData.map((row, index) => {
      const payee = payeeOptions.find(p => p.label.toLowerCase() === row.payee.trim().toLowerCase()) || {
        label: row.payee.trim(),
        value: null,
        __isNew__: true
      };

      const category = categoryOptions.find(p => p.label.toLowerCase() === row.category?.trim().toLowerCase()) || {
        label: row.category?.trim(),
        value: null,
        __isNew__: !!row.category?.trim()
      };

      const currency = currencyOptions.find(option => option.value.toLowerCase() === row.currency.trim().toLowerCase());

      return {
        index,
        date: new Date(row.date.trim()),
        payee,
        amount: parseFloat(row.amount.trim()),
        currency,
        category: row.category ? category : undefined,
        notes: row.notes || undefined
      }
    });
      
    setUploadData(formattedData);
  }

  const onFileRemove = () => {
    setError(null);
    setUploadData(null)
  }

  const hasHeader = true;
  return (
    <Box mt = "4">
      <Box>
        <Text>Upload a csv file with the following header columns:</Text>
        <Text>date, payee, amount, currency (e.g. USD, EUR, etc.), category*, notes*</Text>
        <Text variant = "helper">*Optional</Text>
      </Box>
      <Divider my = "4" />
      <Box experimental_spaceY = "2">
        <Box display = {{ base: "block", md: "flex" }} justifyContent = "space-between">
          <FormLabel width = {{ base: "full", md: "50%"}}>Account</FormLabel>
          <AccountSelect
            value = { account }
            onChange = { setAccount }
            shouldLoadData = { isOpen }
            filterFunction = { account => account.type !== "investment" }
          />
        </Box>

        <Box justifyContent = "space-between">
          <FormLabel>CSV File</FormLabel>
          <CSVReader
            onDrop = { onFileUpload }
            onError={ null }
            style = {{ dropArea: {
              borderColor: theme.colors.brand[400],
              borderRadius: 'var(--chakra-radii-sm)',
            },
            dropAreaActive: {
              borderColor: theme.colors.brand[600],
            },
            dropFile: {
              width: '100%',
              background: 'transparent',
              zIndex: 0,
              height: '100px'
            },
            fileSizeInfo: {
              display: 'none'
            },
            fileNameInfo: {
              borderRadius: 3,
              fontSize: 16,
              lineHeight: 1,
              background: "transparent"
            },
            removeButton: {
              color: theme.colors.gray[500],
              width: 'var(--close-button-size)',
              height: 'var(--close-button-size)',
              borderRadius: 'var(--chakra-radii-full)',
              fontSize: '12px'
            },
            progressBar: {
              backgroundColor: theme.colors.brand[400],
            } }}
            config={{
              header: hasHeader
            }}
            addRemoveButton
            onRemoveFile = { onFileRemove }
            skipEmptyLines = "greedy"
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        </Box>
        <FormControl isInvalid = { !!error }>
          <FormErrorMessage>{ error }</FormErrorMessage>
        </FormControl>
      </Box>
    </Box>
  )
};

export default Upload;
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import {
  Box,
  FormLabel
} from "@chakra-ui/react";

import { CurrencySelect } from "components/Select";
import useToast from "hooks/useToast";
import { currencyOptions } from "utils/options";

const UPDATE_CURRENCY = gql`
  mutation UpdateAccountBalance($id: uuid!, $currency: String!) {
    update_accounts(_set: { currency: $currency }, where: { id: { _eq: $id }}) {
      returning {
        id
      }
    }
  }
`;

const AccountCurrency = ({ account }) => {
  const renderToast = useToast();
  const [ updateCurrency ] = useMutation(UPDATE_CURRENCY);

  const onCurrencyChange = data => {
    const { value: newCurrency } = data;

    if ( newCurrency !== account.currency ) {
      updateCurrency({
        variables: {
          id: account.id,
          currency: newCurrency
        }
      });

      renderToast("Currency updated");
      window.analytics.track("Account Updated", {
        field: 'currency'
      })
    }
  }

  return account.plaid_item ? null : (
    <>
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Currency</FormLabel>
      </Box>
      <CurrencySelect
        value = { currencyOptions.find(option => option.value === account.currency) }
        onChange = { onCurrencyChange }
        shouldLoadDefaultCurrency = { false }
        shouldLoadIPCurrency = { false }
      />
    </>
  )
};

export default AccountCurrency;
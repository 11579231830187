import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '@nhost/react-auth';
import * as Sentry from "@sentry/react";
import { useLocation } from 'react-router-dom';

import { auth } from "utils/nhost";

const getRedirect = querySearch => {
  if ( querySearch ) {
    const urlQuery = new URLSearchParams(querySearch);
    const queryParams = Object.fromEntries(urlQuery); 
    const { redirect_, ...rest } = queryParams;
    const otherParams = Object.entries(rest)
      .map(param => `&${param[0]}=${param[1]}`)
      .reduce((all, curr) => all.concat(curr), '');
    return redirect_ + otherParams;
  } else {
    return null
  }
}

const AuthGate = ({ shouldBeSignedIn, ignoreAuth, component: Component, redirect, ...rest }) => {
  const { signedIn } = useAuth();
  const querySearch = useLocation().search;
  const onAuthRedirect = getRedirect(querySearch) || "/";

  return (
    <Route { ...rest } render = { props => {
      if ( signedIn === null ) {
        return null;
      }

      if ( ignoreAuth ) {
        return <Component { ...props } />
      }

      if ( !signedIn && shouldBeSignedIn ) {
        const { pathname, search } = window.location;
        const totalPath = pathname + search;
        return <Redirect to = { totalPath !== "/" ? `/login?redirect_=${totalPath}` : "/login" } />
      }
  
      if ( signedIn && !shouldBeSignedIn ) {
        return <Redirect to = { onAuthRedirect } />
      }

      if ( redirect ) {
        return <Redirect to = { redirect } />
      }

      // identify the user if entered app on an auth page
      if ( signedIn && shouldBeSignedIn && !document.referrer.startsWith(window.location.origin) ) {
        Sentry.setUser({ email: auth.user().email, id: auth.user().id });
        window.analytics.identify(auth.user().id);
      }

      return <Component { ...props } />
    }} />
  );
}

export default AuthGate;
import gql from "graphql-tag";

export default gql`
  fragment AllHoldingFields on investment_holdings {
    __typename
    account {
      id
      name
    }
    cost_basis
    currency
    quantity
    security {
      id
      symbol
    }
  }
`;
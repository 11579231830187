import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import DateField from "components/DateField";
import useToast from "hooks/useToast";

const moment = require("moment-timezone");

const UPDATE_DATE = gql`
  mutation UpdateDate($transaction_id: uuid!, $authorized_at: String! ) {
    transaction: update_transactions_by_pk(
      pk_columns: { id: $transaction_id },
      _set: { authorized_at: $authorized_at }
    ) {
      id
      authorized_at
      __typename
    }
  }
`;

const _Date = ({ transaction, onUpdate }) => {
  const renderToast = useToast();
  const [ updateDate ] = useMutation(UPDATE_DATE);

  const onDateChange = async newDate => {
    const updateDateResponse = await updateDate({
      variables: {
        transaction_id: transaction.id,
        authorized_at: moment(newDate).format("YYYY-MM-DD")
      }
    });

    onUpdate({ ...transaction, ...updateDateResponse.data.transaction });

    renderToast("Date Updated");
    window.analytics.track("Transaction Updated", {
      field: "authorized_at"
    })
  }

  return (
    <Grid width = "full" templateColumns = "40% 60%">
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Date</FormLabel>
      </Box>
      <Box>
        <DateField
          selected = { moment(transaction.authorized_at).toDate() }
          filterDate = { date => date <= new Date() }
          onChange = { onDateChange }
        />
      </Box>
    </Grid>
  )
};

export default _Date;
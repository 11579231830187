import {
  FormControl,
  FormLabel,
  Switch
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import useToast from "hooks/useToast";

const UPDATE_IS_INCOME = gql`
  mutation ToggleIsIncome($id: uuid!, $is_income: Boolean!) {
    update_categories_by_pk(
      pk_columns: { id: $id },
      _set: { is_income: $is_income, category_group_id: null, is_rollover: false }
    ) {
      id
      is_income
    }
  }
`;

const IsIncomeToggle = ({ category }) => {
  const renderToast = useToast();
  const [ updateIsIncome ] = useMutation(UPDATE_IS_INCOME);

  const toggleIsIncome = async newValue => {
    await updateIsIncome({
      variables: {
        id: category.id,
        is_income: newValue
      }
    })

    renderToast("Category Updated");
    window.analytics.track("Category Updated", {
      field: "is_income"
    });
  }

  return (
    <FormControl mt = "2" display = "flex" alignItems = "center">
      <Switch
        id = "is-income"
        defaultChecked = { category.is_income }
        onChange = { e => toggleIsIncome(e.target.checked) }
        colorScheme = "brand"
      />
      <FormLabel mb = "0" fontWeight = "normal" ml = "2" htmlFor = "is-income">Treat transactions as income?</FormLabel>
    </FormControl>
  )
};

export default IsIncomeToggle;
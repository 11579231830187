import {
  Box,
  Button,
  Divider,
  Heading,
  SimpleGrid,
  Tag,
  Text,
  VStack,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { useState } from 'react';

const getMarketcapLetter = digitsCount => {
  if ( digitsCount <= 4 ) {
    return "K"
  } else if ( digitsCount <= 7 ) {
    return "M"
  } else if ( digitsCount <= 11 ) {
    return "B"
  } else if ( digitsCount <= 14 ) {
    return "T"
  }
};

const getMarketcapCategory = marketcap => {
  if ( marketcap < 50000000 ) {
    return "Nano-cap"
  } else if ( marketcap < 300000000 ) {
    return "Micro-cap"
  } else if ( marketcap < 2000000000 ) {
    return "Small-cap"
  } else if ( marketcap < 10000000000 ) {
    return "Mid-cap"
  } else if ( marketcap < 200000000000 ) {
    return "Large-cap"
  } else {
    return "Mega-cap"
  }
}

const formatMarketcap = marketcap => {
  const marketcapString = String(marketcap);
  const digitsCount = marketcapString.length;
  const decimalCount = digitsCount % 3 === 0 ? 0 : 3 - (digitsCount % 3);
  return (marketcap / parseInt(("1" + "0".repeat(digitsCount - 1))) ).toFixed(decimalCount) + getMarketcapLetter(digitsCount) + " " + getMarketcapCategory(marketcap)
}

const About = ({ companyData, statsData }) => {
  const { CEO, city, description, employees, state, tags } = companyData;
  const { marketcap, peRatio, dividendYield } = statsData;
  const [ showFullDescription, toggleFullDescription ] = useState(false);

  const miniDescription = description?.split(".").slice(0, 3).join(".") + ".";

  return (
    <VStack spacing = "3" alignItems = "flex-start" width = "full">
      <Heading fontSize = "xl" as = "h3">About</Heading>
      <Divider />
      <Wrap>
        { tags?.map((tag, idx) => <WrapItem key = { idx }><Tag shadow = "sm" size = "sm">{ tag }</Tag></WrapItem>)}
      </Wrap>
      <Text display = { description ? "block" : "none" } fontSize = "sm">{ showFullDescription ? description : miniDescription } <Button _hover = {{ textDecoration: "none" }} variant = "link" color = "primary.500" onClick = { () => toggleFullDescription(prev => !prev) }>{ showFullDescription ? "Read Less" : "Read More" }</Button></Text>
      <SimpleGrid fontSize = "xs" spacing = "4" columns = { 3 } width = "full">
        <Box>
          <Text fontWeight = "semibold">CEO</Text>
          <Text>{ CEO }</Text> 
        </Box>
        <Box>
          <Text fontWeight = "semibold">Employees</Text>
          <Text>{ employees?.toLocaleString() }</Text> 
        </Box>
        <Box>
          <Text fontWeight = "semibold">Headquarters</Text>
          <Text>{ city }, { state }</Text> 
        </Box>
        <Box>
          <Text fontWeight = "semibold">Market Cap</Text>
          <Text>{ formatMarketcap(marketcap || 0) }</Text> 
        </Box>
        <Box>
          <Text fontWeight = "semibold">P/E Ratio</Text>
          <Text>{ Math.round((peRatio || 0) * 100) / 100 }</Text> 
        </Box>
        <Box>
          <Text fontWeight = "semibold">Dividend Yield</Text>
          <Text>{ Math.round((dividendYield || 0) * 10000) / 100 }</Text> 
        </Box>
      </SimpleGrid>
    </VStack>
  )
}

export default About;
import { Image, useColorModeValue } from '@chakra-ui/react';

import logoLarge from "images/logos/logo-large.svg";
import logoSmallDark from "images/logos/logo-small-dark.svg";
import logoSmallLight from "images/logos/logo-small-light.svg";

const Logo = ({ size, ...props }) => {
  const src = {
    sm: useColorModeValue(logoSmallLight, logoSmallDark),
    lg: logoLarge
  }

  return (
    <Image alt = "OmniMoney Logo" src = { src[size] } { ...props } />
  )
};

export default Logo;
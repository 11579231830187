const PopoutSelect = {
  baseStyle: props => ({
    container: {
      width: "full",
      rounded: "sm",
      boxShadow: props.colorMode === "light" ? "light.md" : "dark.lg",
      borderBottomLeftRadius: "none",
      borderBottomRightRadius: "none",
      borderBottom: "1px",
      borderColor: "red"
    },
    control: {
      minHeight: "unset",
      border: "none",
      boxShadow: "none",
      bg: props.colorMode === "light" ? "white" : "gray.700",
      rounded: "sm"
    },
    input: {
      display: "inline-flex",
      px: "2",
      rounded: "md",
      verticalAlign: "top",
      height: "full",
      lineHeight: "1.2",
      justifyContent: "flex-start",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      minHeight: "1.7rem",
      alignItems: "center",
      color: props.colorMode === "light" ? "inherit" : "whiteAlpha.900"
    },
    menu: {
      bg: props.colorMode === "light" ? "white" : "gray.700",
      boxShadow: props.colorMode === "light" ? "light.md" : "dark.lg",
      rounded: "sm",
      my: "0",
      borderTopLeftRadius: "none",
      borderTopRightRadius: "none",
      borderTop: "1px",
      borderColor: "gray.200"
    },
    menuList: {
      width: "full"
    },
    option: {
      borderLeft: "2px",
      bg: "transparent",
      borderColor: "transparent",
      _active: {
        bg: props.colorMode === "light" ? "gray.100" : "gray.500"
      },
      fontSize: "sm"
    },
    placeholder: {
      opacity: "0.8"
    },
    focusedOption: {
      bg: props.colorMode === "light" ? "gray.100" : "gray.500",
      borderColor: "brand.400"
    },
    selectedOption: {
      bg: "brand.400"
    },
    singleValue: {
      px: "2",
      color: props.colorMode === "light" ? "inherit" : "whiteAlpha.900"
    },
    valueContainer: {
      px: "0",
      rounded: "sm"
    }
  })
};

export default PopoutSelect;
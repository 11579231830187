const Editable = {
  parts: ["preview", "input"],
  baseStyle: props => ({
    input: {
      rounded: "none",
      _focus: {
        boxShadow: "none",
        borderBottom: "1px",
        borderColor: "brand.400"
      }
    },
    preview: {
      height: "31px",
      py: "3px",
      rounded: "none",
      width: "full",
      borderBottom: "1px",
      borderColor: props.colorMode === "dark" ? "whiteAlpha.300" : "gray.200",
      _hover: {
        borderColor: props.colorMode === "dark" ? "white" : "gray.400",
      }
    }
  })
};

export default Editable;
import PopoutSelect from "./PopoutSelect";
import { frequencyOptions } from "utils/options";

const FrequencySelect = ({ value, onChange, buttonLabel = "Select Frequency", tooltipText = "Select Frequency" }) => {
  const props = {
    onChange, 
    value,
    options: frequencyOptions
  };

  return (
    <PopoutSelect
      tooltipText = { tooltipText }
      buttonLabel = { buttonLabel }
      { ...props }
    />
  )
};

export default FrequencySelect;


import {
  List,
  ListItem,
  Text
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";

import FieldGroup from "components/FieldGroup";
import { getDatesBetween } from "utils/calculations";
import { formatCurrency } from "utils/format";

const GET_CATEGORY_SCHEDULED_TRANSACTIONS = gql`
  query GetCategoryScheduledTransactions($category_id: uuid!) {
    scheduled_transactions(where: { category_id: { _eq: $category_id }}) {
      id
      amount
      end_after_date
      frequency
      next_date
      start_date
      payee {
        name
      }
      transfer_account {
        name
      }
      currency
    }
  }
`;

const UpcomingTransactions = ({ category, currentMonth }) => {
  const { data } = useQuery(GET_CATEGORY_SCHEDULED_TRANSACTIONS, { variables: { category_id: category.id }});
  const afterDate = moment(currentMonth).startOf('month').format("YYYY-MM-DD");
  const beforeDate = moment(currentMonth).endOf('month').format("YYYY-MM-DD");

  const scheduledTransactions = data?.scheduled_transactions || [];
  const upcomingTransactions = scheduledTransactions.map(transaction => {
    const upcomingDates = getDatesBetween({
      startDate: transaction.next_date,
      endDate: transaction.end_after_date,
      frequency: transaction.frequency,
      afterDate,
      beforeDate
    });

    return { ...transaction, upcomingDates }
  })
  .filter(transaction => transaction.upcomingDates.length > 0 )
  .map(transaction => transaction.upcomingDates.map(date => ({
    id: transaction.id + date,
    date: date,
    amount: transaction.amount,
    currency: transaction.currency,
    payee: transaction.payee?.name || `Transfer ${ transaction.amount > 0 ? "from" : "to"}: ${transaction.transfer_account?.name}`
  })))
  .reduce((all, transaction) => all.concat(transaction), [])
  .sort((t1, t2) => t1.date > t2.date ? 1 : -1 )

  return (
    <FieldGroup title = { data && upcomingTransactions.length === 0 ? "No Upcoming Transactions" : "Upcoming Transactions"}>
      <List>
        { upcomingTransactions.map(transaction => (
          <ListItem display = "flex" key = { transaction.id }>
            <Text width = "30%">{ moment.utc(transaction.date).format("MMM D") }</Text>
            <Text width = "40%" isTruncated>{ transaction.payee }</Text>
            <Text textAlign = "right" width = "30%">{ formatCurrency(transaction.amount, transaction.currency) }</Text>
          </ListItem>
        ))}
      </List>
    </FieldGroup>
  );
}

export default UpcomingTransactions;
import { 
  AiOutlinePieChart,
  AiOutlineStock
} from "react-icons/ai";
import { BiWallet, BiSun } from 'react-icons/bi';
import { BsArrowRight, BsCodeSlash } from 'react-icons/bs';
import { FiExternalLink } from "react-icons/fi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiLockPasswordLine } from "react-icons/ri";

const actions = [
  // Quick Actions Section
  {
    id: "new-transaction", 
    name: "New Transaction", 
    shortcut: ['T'], 
    keywords: "new transaction", 
    icon: AiOutlinePlusCircle, 
    command: "new_transaction",
    section: "Quick Actions",
    authRequired: true
  },
  
  {
    id: "quick-review", 
    name: "Quick Review", 
    shortcut: ['R'], 
    keywords: "quick review", 
    icon: HiOutlineClipboardCheck, 
    command: "quick_review",
    section: "Quick Actions",
    authRequired: true
  },

  // Navigation Section
  //// Budget
  {
    id: "budget",
    name: "Budget",
    icon: BiWallet,
    children: [ "accounts", "plan", "scheduled-transactions", "budget-transactions" ],
    section: "Navigation",
    authRequired: true
  },
  { 
    id: "accounts", 
    name: "Accounts", 
    shortcut: ['B', 'A'], 
    shortcutSeparator: "then",
    keywords: "accounts banks", 
    icon: BsArrowRight, 
    perform: () => window.location.assign("/budget/accounts"),
    parent: "budget",
    authRequired: true
  },
  { 
    id: "accounts", 
    name: "Accounts", 
    shortcut: ['B', 'A'], 
    shortcutSeparator: "then",
    keywords: "accounts banks", 
    icon: BsArrowRight, 
    perform: () => window.location.assign("/budget/accounts"),
    parent: "budget",
    authRequired: true
  },
  {
    id: "plan",
    name: "Plan",
    shortcut: ['B', "P"],
    shortcutSeparator: "then",
    keywords: "budget plan category categories",
    icon: BsArrowRight,
    perform: () => window.location.assign("/budget/plan"),
    parent: "budget",
    authRequired: true
  },
  {
    id: "scheduled-transactions", 
    name: "Scheduled Transactions", 
    shortcut: ['B', 'S'], 
    shortcutSeparator: "then",
    keywords: "scheduled transactions", 
    icon: BsArrowRight, 
    perform: () => window.location.assign("/budget/scheduled-transactions"),
    parent: "budget",
    authRequired: true
  },
  {
    id: "budget-transactions", 
    name: "Transactions", 
    shortcut: ['B', 'T'], 
    shortcutSeparator: "then",
    keywords: "transactions", 
    icon: BsArrowRight, 
    perform: () => window.location.assign("/budget/transactions"),
    parent: "budget",
    authRequired: true
  },
  //// Insights 
  {
    id: "insights",
    name: "Insights",
    icon: AiOutlinePieChart,
    children: [ "trends" ],
    section: "Navigation",
    authRequired: true
  },
  {
    id: "trends", 
    name: "Trends", 
    shortcut: ['I', 'T'], 
    shortcutSeparator: "then",
    keywords: "charts trends analysis", 
    icon: BsArrowRight, 
    perform: () => window.location.assign("/insights/trends"),
    parent: "insights",
    authRequired: true
  },

  //// Invest
  {
    id: "invest",
    name: "Invest",
    icon: AiOutlineStock,
    children: [ "activity", "portfolio", "research" ],
    section: "Navigation",
    authRequired: true
  },
  {
    id: "activity", 
    name: "Activity", 
    shortcut: ['I', 'A'], 
    shortcutSeparator: "then",
    keywords: "transactions activity trades", 
    icon: BsArrowRight, 
    perform: () => window.location.assign("/invest/activity"),
    parent: "invest",
    authRequired: true
  },
  {
    id: "portfolio", 
    name: "Portfolio", 
    shortcut: ['I', 'P'], 
    shortcutSeparator: "then",
    keywords: "portfolio holdings", 
    icon: BsArrowRight, 
    perform: () => window.location.assign("/invest/portfolio"),
    parent: "invest",
    authRequired: true
  },
  {
    id: "research", 
    name: "Research", 
    shortcut: ['I', 'R'], 
    shortcutSeparator: "then",
    keywords: "research news", 
    icon: BsArrowRight, 
    perform: () => window.location.assign("/invest/research"),
    parent: "invest",
    authRequired: true
  },

  // Settings Section
  {
    id: "api", 
    name: "API",  
    keywords: "api code", 
    icon: BsCodeSlash, 
    command: "api",
    section: "Settings",
    authRequired: true
  },
  {
    id: "change-password",
    name: "Change Password",
    keywords: "change password settings",
    icon: RiLockPasswordLine,
    command: "change_password",
    section: "Settings",
    authRequired: true
  },
  {
    id: "change-theme",
    name: "Change Theme",
    keywords: "change theme settings",
    icon: BiSun,
    command: "change_theme",
    section: "Settings",
    authRequired: true
  },
  {
    id: "notifications", 
    name: "Notifications",  
    keywords: "notifications email message", 
    icon: IoMdNotificationsOutline, 
    command: "notifications",
    section: "Settings",
    authRequired: true
  },

  // Feedback Section
  {
    id: "changelog", 
    name: "Changelog", 
    keywords: "changelog changes updates", 
    icon: FiExternalLink, 
    perform: () => window.open("https://omnimoney.canny.io/changelog", "_blank"),
    section: "Feedback"
  },
  {
    id: "feature-requests", 
    name: "Feature Requests", 
    keywords: "feature requests", 
    icon: FiExternalLink, 
    perform: () => window.open("https://omnimoney.canny.io/feature-requests", "_blank"),
    section: "Feedback"
  },
  {
    id: "report-issue", 
    name: "Report Issue", 
    keywords: "report issue bug", 
    icon: FiExternalLink, 
    perform: () => window.open("https://omnimoney.canny.io/bugs", "_blank"),
    section: "Feedback"
  },
  {
    id: "roadmap", 
    name: "Roadmap", 
    keywords: "roadmap", 
    icon: FiExternalLink, 
    perform: () => window.open("https://omnimoney.canny.io/", "_blank"),
    section: "Feedback"
  }
];

export default actions;
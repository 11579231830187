import {
  Box,
  Divider,
  Text,
  Tooltip,
  useColorModeValue as mode
} from "@chakra-ui/react";

import { formatCurrency } from "utils/format";

const BarSegment = ({ color, percentage, ...props }) => (
  <Box
    bg = { color }
    width = { `${percentage * 100}%` }
    height = "full"
    { ...props  }
  />
);

const ColorCircle = ({ bg, ...props }) => (
  <Box
    w = "12px"
    h = "12px"
    bg = { bg }
    shadow = { mode("light.sm", "dark.sm") }
    borderRadius = "full"
    { ...props }
  />
)

const SpendingProgressBar = ({ budgeted, actual, scheduled, isIncome, ...props }) => {
  const spendingPower = isIncome ? budgeted - actual - scheduled : budgeted + actual + scheduled;
  const _actual = Math.max(isIncome ? actual : -actual, 0);
  const _scheduled = Math.max(isIncome ? scheduled : -scheduled, 0);
  const denominator = Math.max(budgeted, _actual + _scheduled, 0);
 
  const actualPercentage = denominator === 0 ? 0 : _actual / denominator;
  const scheduledPercentage = denominator === 0 ? 0 : _scheduled / denominator;
  const spendingPowerPercentage = denominator === 0 ? 0 : spendingPower / denominator;

  const actualColor = (spendingPower >= 0 || isIncome ) ? "green" : "red";

  return (
    <Tooltip
      label = {
        <Box py = "2">
          <Box display = "flex" justifyContent = "space-between">
            <Box display = "flex" alignItems = "center">
              <ColorCircle mr = "1" bg = "transparent" visibility = "hidden"/>
              <Text mr = "4">Budgeted: </Text>
            </Box>
            <Text>{ formatCurrency(budgeted) }</Text>
          </Box>

          <Box display = "flex" justifyContent = "space-between">
            <Box display = "flex" alignItems = "center">
              <ColorCircle mr = "1" bg = { mode(`${actualColor}.500`, `${actualColor}.700`) } />
              <Text mr = "4">Actual: </Text>
            </Box>
            <Text>{ formatCurrency(actual) }</Text>
          </Box>

          <Box display = "flex" justifyContent = "space-between">
            <Box display = "flex" alignItems = "center">
              <ColorCircle mr = "1" bg = { mode(`yellow.500`, `yellow.700`) } />
              <Text mr = "4">Scheduled: </Text>
            </Box>
            <Text>{ formatCurrency(scheduled) }</Text>
          </Box>

          <Divider my = "1" />

          <Box display = "flex" justifyContent = "space-between" fontWeight = "bold">
            <Box display = "flex" alignItems = "center">
              <ColorCircle mr = "1" bg = { mode(`gray.100`, `gray.600`) } />
              <Text mr = "4">Available: </Text>
            </Box>
            <Text>{ formatCurrency(spendingPower) }</Text>
          </Box>
        </Box>
      }
    >
      <Box
        width = "full"
        shadow = { mode("light.sm", "dark.sm") }
        display = "flex"
        height = "0.75rem"
        rounded = "sm"
        { ...props }
      >
        <BarSegment
          color = { mode(`${actualColor}.500`, `${actualColor}.700`) }
          percentage = { actualPercentage }
          roundedLeft = { actualPercentage > 0 ? "sm" : "none" }
          roundedRight = { actualPercentage === 1 ? "sm" : "none" }
        />

        <BarSegment
          color = { mode(`yellow.500`, `yellow.700`) }
          percentage = { scheduledPercentage }
          roundedLeft = { actualPercentage === 0 && scheduledPercentage > 0 ? "sm" : "none" }
          roundedRight = { scheduledPercentage === 1 ? "sm" : "none" }
        />
        <BarSegment
          color = { mode(`gray.100`, `gray.600`) }
          percentage = { Math.max(spendingPowerPercentage, 0) }
          roundedLeft = { actualPercentage === 0 && scheduledPercentage === 0 && spendingPowerPercentage > 0 ? "sm" : "none" }
          roundedRight = { spendingPowerPercentage === 1 ? "sm" : "none" }
        />

      <BarSegment
          color = "transparent"
          percentage = { denominator === 0 ? 1 : 0 }
          roundedLeft = "sm"
          roundedRight = "sm"
        />
    </Box>
    </Tooltip>
  )
};

export default SpendingProgressBar;
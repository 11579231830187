import {
  Box,
  VStack
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { usePagination, useSortBy, useTable, useFilters } from "react-table";
import {
  useRegisterActions
} from "kbar";
import { AiOutlineDownload } from "react-icons/ai";
import { ExportToCsv } from 'export-to-csv';
import moment from "moment-timezone";

import PaginationComponent from "./PaginationComponent";
import TableComponent from "./TableComponent";
import LoadingSpinner from "components/LoadingSpinner";

const Table = ({ isDownloadable, isLoading, columns, data, HeaderRow, BodyRow, NoDataComponent = () => null, sortBy = [], filters, object, displayPagination = true, disableHotKeys = false, allowHorizontalOverflow = false, ...props }) => {
  const memoedFilters = useMemo(() => filters || [], [ filters ]);

  const {
    canNextPage,
    canPreviousPage,
    headerGroups,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    setAllFilters,
    state: { pageIndex },
    rows
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy
      },
      autoResetPage: false,
      autoResetFilters: false
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setAllFilters(memoedFilters);
  }, [ memoedFilters, setAllFilters ]);

  useEffect(() => {
    if ( pageIndex > pageCount - 1 ) {
      gotoPage(Math.min(pageCount - 1, pageIndex ));
    }
  }, [ gotoPage, pageCount, pageIndex ]);

  const onDownload = () => {
    const downloadData = rows.map(row => {
      const transaction = row.original;
      const formattedTransaction = {
        'date': moment(transaction.authorized_at).format("YYYY-MM-DD"),
        'payee': transaction.payee.name,
        'amount': transaction.amount,
        'currency': transaction.currency,
        'category': transaction.category.name || "",
        'is_pending': transaction.is_pending,
        'notes': transaction.notes || ""
      }
      return formattedTransaction;
    })
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      filename: object.toLowerCase(),
      headers: ["Date", "Payee", "Amount", "Currency", "Category", "Is Pending", "Notes"]
    };

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(downloadData);
  }

  useRegisterActions( isDownloadable && !isLoading ? [{
    id: "download",
    name: `Download ${object}`,
    keywords: "download",
    icon: AiOutlineDownload,
    section: "Transactions",
    perform: onDownload
  }] : [], [ isDownloadable, onDownload, isLoading ]);

  if ( isLoading ) {
    return <LoadingSpinner />
  };

  if ( data.length === 0 ) {
    return <NoDataComponent />
  };

  return (
    <VStack spacing = "4" my = "4">
      <Box overflowX = { allowHorizontalOverflow ? "auto" : "unset" }>
        <TableComponent
          getTableBodyProps = { getTableBodyProps }
          getTableProps = { getTableProps }
          headerGroups = { headerGroups }
          BodyRow = { BodyRow }
          HeaderRow = { HeaderRow }
          page = { page }
          prepareRow = { prepareRow }
          disableHotKeys = { disableHotKeys }
          { ...props }
        />
      </Box>
      { displayPagination ? (
        <PaginationComponent
          canNextPage = { canNextPage }
          canPreviousPage = { canPreviousPage }
          gotoPage = { gotoPage }
          nextPage = { nextPage }
          pageCount = { pageCount }
          previousPage = { previousPage }
        />
      ) : null }
    </VStack>
  );
};

export default Table;
import {
  Button,
  Heading,
  List,
  ListIcon,
  ListItem,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";

import ShortcutTooltip from "components/ShortcutTooltip";
import useHotKeys from "hooks/useHotKeys";

const UncategorizedTransactionsMessage = ({ uncategorizedTransactionsCount }) => (
  <ListItem>
    <ListIcon as = { uncategorizedTransactionsCount > 0 ? AiOutlineCheckCircle : AiFillCheckCircle } color = { uncategorizedTransactionsCount > 0 ? "unset" : "green.500" } />
    { uncategorizedTransactionsCount === 0 ? "All of your transactions are categorized" : `Categorize ${uncategorizedTransactionsCount} transaction${uncategorizedTransactionsCount > 1 ? "s" : ""}` }
  </ListItem>
);

const ScheduledTransactionsMessage = ({ overdueScheduledTransactionsCount }) => (
  <ListItem>
    <ListIcon as = { overdueScheduledTransactionsCount > 0 ? AiOutlineCheckCircle : AiFillCheckCircle } color = { overdueScheduledTransactionsCount > 0 ? "unset" : "green.500" } />
    { overdueScheduledTransactionsCount === 0 ? "There are no due or overdue scheduled transactions" : `Mark ${overdueScheduledTransactionsCount} scheduled transaction${overdueScheduledTransactionsCount > 1 ? "s" : ""} as paid`}
  </ListItem>
);

const StartScreen = ({ uncategorizedTransactionsCount, overdueScheduledTransactionsCount, goToScreen }) => {
  useHotKeys('enter', () => goToScreen("next"));

  return (
    <VStack 
      shadow = {{ base: "none", md: mode("light.xl", "dark.xl")}} 
      px = {{ base: "4", md: "8" }} py = "8"
      bg = { mode("white", "gray.800") }
    >
      <Heading fontSize = "2xl" textAlign = "center">
        Get a clear vision of your finances in only a few minutes.
      </Heading>
      <List fontSize = "xl" py = "8" spacing = "2">
        <UncategorizedTransactionsMessage uncategorizedTransactionsCount = { uncategorizedTransactionsCount } />
        <ScheduledTransactionsMessage overdueScheduledTransactionsCount = { overdueScheduledTransactionsCount } />
        <ListItem>
          <ListIcon as = { AiOutlineCheckCircle } />
          Review spending power
        </ListItem>
      </List>
      <ShortcutTooltip 
        keys = {['Enter']} 
        label = "Start Quick Review"
        width = "full"
      >
        <Button
          colorScheme = "primary"
          onClick = { () => goToScreen("next") }
          width = "full"
        >Start Quick Review</Button>
      </ShortcutTooltip>
    </VStack>
  )
};

export default StartScreen;
import {
  Box,
  Text,
  useColorModeValue as mode,
  useId,
  useRadio,
  VStack
} from "@chakra-ui/react";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

const CheckboxIcon = ({ checked }) => (
  <Box fontSize="3xl" color={ checked ? 'primary.500' : mode('gray.700', 'whiteAlpha.800')}>
    { checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
  </Box>
);

const RadioButton = ({ label, icon, description, ...props }) => {
  const radioButtonProps = {
    borderRadius: "sm",
    cursor: "pointer",
    transition: "all 0.2s",
    bg: mode("white", "gray.800")
  };

  const { getCheckboxProps, getInputProps, getLabelProps, state } = useRadio({ ...props });
  const id = useId();

  return (
    <label
      style = {{ width: "100%" }}
      { ...getLabelProps() }
    >
      <input { ...getInputProps() } aria-labelledby = { id } />
      <Box { ...getCheckboxProps() } id = { id } { ...radioButtonProps }>
        <VStack 
          bg = { mode("white", "gray.700") } 
          shadow = { mode("light.lg", "dark.lg") } 
          spacing = "6" 
          p = "4" 
          borderRadius = "sm"
          justifyContent = "space-between"
        >
          <VStack
            textAlign = "center"
            color = { state.isChecked ? "primary.500" : mode("gray.700", "whiteAlpha.800") }
          >
              <Box
                aria-hidden
                fontSize = "4xl"
              >
                { icon }
              </Box>
              <Text fontWeight = "medium" fontSize = "xl">{ label }</Text>
              <Text 
                display = { description ? "flex" : "none" }
                color = { mode("gray.900", "whiteAlpha.900") }
              >{ description }</Text>
          </VStack>
          <CheckboxIcon checked = { state.isChecked } />
        </VStack>
      </Box>
    </label>
  )
};

export default RadioButton;

import { forwardRef } from "react";
import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateField.css";

const moment = require("moment-timezone");

const InputComponent = forwardRef(({ value, onClick, disabled, onKeyDown, ...props }, ref) => {
  return (
    <Input
      value = { value }
      onClick = { onClick }
      isDisabled = { disabled }
      ref = { ref }
      onKeyDown = { onKeyDown }
      width = "full"
      rounded = "sm"
      border = "none"
      boxShadow = "none"
      bg = { mode("gray.100", "gray.600")}
      _hover = {{
        bg: mode("gray.200", "gray.500"),
        cursor: "pointer"
      }}
      display = "inline-flex"
      fontSize = "sm"
      px = "2"
      py = "1"
      verticalAlign = "top"
      height = "full"
      lineHeight = "1.2"
      justifyContent = "flex-start"
      outline = "2px solid transparent"
      outlineOffset = "2px"
      alignItems = "center"
      color = { mode("gray.900", "whiteAlpha.900") }
      fontWeight = "normal"
      _focus = {{
        bg: mode("gray.200", "gray.500"),
        cursor: "pointer"
      }}
      shadow = { disabled ? "none" : "base" }
      { ...props }
    />
  );
});

const HeaderComponent = ({ date, decreaseMonth, increaseMonth, decreaseYear, increaseYear, showMonthYearPicker, prevYearButtonDisabled, prevMonthButtonDisabled, nextYearButtonDisabled, nextMonthButtonDisabled }) => (
  <Box>
    <HStack justifyContent = "space-between" px = "2">
      <Button
        isDisabled = { showMonthYearPicker ? prevYearButtonDisabled : prevMonthButtonDisabled } 
        onClick = { showMonthYearPicker ? decreaseYear : decreaseMonth } 
        size = "sm" 
        variant = "icon"
      >{"<"}</Button>
      <Text color = "inherit" fontSize = "sm">{ moment(date).format(showMonthYearPicker ? "YYYY" : "MMM YYYY") }</Text>
      <Button 
        isDisabled = { showMonthYearPicker ? nextYearButtonDisabled : nextMonthButtonDisabled }
        onClick = { showMonthYearPicker ? increaseYear : increaseMonth } 
        size = "sm" 
        variant = "icon"
      >{">"}</Button>
    </HStack>
  </Box>
);

const DateField = ({ selected, innerRef, value, filterDate, ...props }) => {
  const classMode = mode("light", "dark");

  return (
    <DatePicker
      selected = { selected || value }
      customInput = { <InputComponent /> }
      renderCustomHeader = { headerProps => HeaderComponent({ ...props, ...headerProps}) }
      showPopperArrow = { false }
      popperClassName = { classMode }
      dayClassName = { () => classMode }
      disabledKeyboardNavigation // Disabling because enter key closes drawer / modal
      ref = { innerRef }
      filterDate = { filterDate }
      { ...props }
    />
  )
};

export default DateField;
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import PopoutSelect from "./PopoutSelect";
import MultiSelectDropdown from "./MultiSelectDropdown";
import getDefaultBudget from "utils/getDefaultBudget";

const GET_ACCOUNTS = gql`
  query GetAccounts($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      manual_accounts: accounts(order_by: {name: asc}, where: {is_hidden: {_eq: false}, plaid_item_id: {_is_null: true}}) {
        id
        value: id
        label: name
        type
        current_balance
        currency
      }
      plaid_items(order_by: {plaid_institution: {name: asc}}) {
        id
        value: id
        plaid_institution {
          name
        }
        options: accounts(order_by: {name: asc}, where: {_and: {is_hidden: {_eq: false}}}) {
          id
          value: id
          label: name
          type
          current_balance
          currency
        }
      }
    }
  }
`;

const AccountSelect = ({ selectAll, isCreatable, filterFunction = () => true, onChange, value, isMulti, isDisabled, buttonLabel = "Select Account", shouldLoadData = false, tooltipText = "Select Account" }) => {
  const budgetId = getDefaultBudget();
  const { refetch } = useQuery(GET_ACCOUNTS, {
    variables: {
      budget_id: budgetId
    },
    skip: true
  });

  const loadOptions = async () => {
    // Using this hack because useLazyQuery isn't async
    if ( shouldLoadData ) {
      const { data } = await refetch();
      const manualAccounts = (data?.budget.manual_accounts || []).filter(filterFunction);
      const plaidItems = (data?.budget.plaid_items || []).map(plaidItem => ({ ...plaidItem, options: plaidItem.options.filter(filterFunction)}));
      const accounts = plaidItems
      .map(plaidItem => ({ ...plaidItem, label: plaidItem.plaid_institution.name }))
      .concat(manualAccounts.length > 0 ? { label: "Manual Accounts", value: "manual", options: manualAccounts } : []);
      
      return accounts;
    }

    return [];
  }

  const props = {
    isCreatable,
    onChange,
    value,
    loadOptions,
    isDisabled
  }

  return isMulti ? (
    <MultiSelectDropdown
      label = {{ singular: "Account", plural: "Accounts"}}
      isMulti = { true }
      selectAll = { selectAll }
      { ...props }
    />
  ) : (
    <PopoutSelect
      shortcutKey = "/" 
      tooltipText = { tooltipText}
      buttonLabel = { buttonLabel }
      isMulti = { isMulti }
      { ...props } 
    />
  );
};

export default AccountSelect;
import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { CategorySelect } from "components/Select";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const INSERT_CATEGORY = gql`
  mutation InsertCategory($category_name: citext!, $budget_id: uuid!) {
    insert_categories(objects: [{ name: $category_name, budget_id: $budget_id }]) {
      returning {
        id
        name
        __typename
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateTransactionCategory($transaction_id: uuid!, $category_id: uuid!) {
    transaction: update_transactions_by_pk(
      pk_columns: { id: $transaction_id },
      _set: { category_id: $category_id }
    ) {
      id
      category {
        id
        name
        __typename
      }
      __typename
    }
  }
`;

const Category = ({ transaction, onUpdate }) => {
  const renderToast = useToast();
  const [ createCategory ] = useMutation(INSERT_CATEGORY);
  const [ updateCategory ] = useMutation(UPDATE_CATEGORY);
  const budgetId = getDefaultBudget();

  const onCategoryChange = async item => {
    if ( item.value !== transaction.category?.value ) {
      let categoryId;

      if ( item.__isNew__ ) {
        const resp = await createCategory({
          variables: {
            category_name: item.label,
            budget_id: budgetId
          }
        });

        categoryId = resp.data.insert_categories.returning[0].id;
      } else {
        categoryId = item.value;
      }

      const updateCategoryResponse = await updateCategory({
        variables: {
          category_id: categoryId,
          transaction_id: transaction.id
        }
      });

      onUpdate({ ...transaction, ...updateCategoryResponse.data.transaction });

      renderToast("Category Updated");
      window.analytics.track("Transaction Updated", {
        field: "category"
      })
    }
  };

  const isTransferCategory = !transaction.needs_category && transaction.transfer_account;
  const getValue = () => {
    if ( isTransferCategory ) {
      return { label: "Transfer", value: "transfer" }
    } else {
      const { category } = transaction;
      if ( category ) {
        return {...category, label: category.name, value: category.id }
      } else {
        return null
      }
    }
  }

  return (
    <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Category</FormLabel>
      </Box>
      <CategorySelect
        value = { getValue() }
        onChange = { onCategoryChange }
        shouldLoadData = { true }
        isCreatable = { true }
        isDisabled = { isTransferCategory }
        
      />
    </Grid>
  )
};

export default Category;
import { forwardRef } from "react";
import {
  Box,
  Icon,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

import HotKeyCombination from "components/HotKeyCombination";

const KBarResultItem = forwardRef(
  ({ item, isActive }, ref) => {
    return (
      <Box 
        ref = { ref }
        tabIndex = "0"
        height = "46px"
        width = "full"
        backgroundColor = { isActive ? mode("gray.100", "gray.700") : "transparent" }
      >
        <Box
          display = "flex"
          overflow = "hidden"
          flexGrow = "1"
          height = "full"
          alignItems = "center"
          padding = "0 20px"
          borderLeft = "2px solid"
          borderColor = { isActive ? "brand.400" : "transparent" }
        >
          <Box
            mr = "16px"
            width = "16px"
            display = "flex"
            alignItems = "center"
          >
            { item.icon ? <Icon as = { item.icon } color = { isActive ? mode("gray.900", "whiteAlpha.900") : mode("gray.500", "whiteAlpha.600") } /> : null }
          </Box>
          <Box
            flexGrow = "1"
            display = "inline-flex"
            alignItems = "center"
            overflow = "hidden"
            fontSize = {{ base: "xs", sm: "sm"}}
            textOverflow = "ellipsis"
            justifyContent = "space-between"
          >
            <Text
              mr = "8px"
              flexGrow = "1"
              overflow = "hidden"
            >  
              { item.name }
            </Text>

            <Box display = "flex" >
              <HotKeyCombination keys = { item.shortcut || [] } separator = { item.shortcutSeparator } />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
);

export default KBarResultItem;
import {
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import Mousetrap from "mousetrap";
import moment from "moment-timezone";

import { GET_QUICK_REVIEW_STATS } from "./gql";
import StatCard from "components/StatCard";
import getDefaultBudget from "utils/getDefaultBudget";

const QuickReviewStreak = () => {
  const budgetId = getDefaultBudget();
  const { data, loading } = useQuery(GET_QUICK_REVIEW_STATS, {
    variables: {
      budget_id: budgetId
    }
  });

  const budget = data?.budget;
  const lastCompletedAtMoment = budget?.quick_review_last_completed_at ? moment(budget.quick_review_last_completed_at) : null;
  const doneToday = moment().isSame(lastCompletedAtMoment, 'day');
  const doneYesterday = moment().subtract(1, 'day').isSame(lastCompletedAtMoment, 'day');
  const isStreakBroken = !(doneToday || doneYesterday);

  return (
    <StatCard
      label = "Quick Review Streak"
      onClick = { () => Mousetrap.trigger("r", 'click') }
      isLoading = { loading && !data }
      header = { doneToday ? "Done for today!" : "Complete today's quick review"}
    >
      <Text 
        as = "span"
        color = { mode('gray.800', 'white') }
        fontSize = "3xl"
        fontWeight = "semibold"
        lineHeight = "1"
      >
        { isStreakBroken? 0 : budget?.quick_review_streak }
      </Text>
    </StatCard>
  )
};

export default QuickReviewStreak;
import {
  Box,
  Heading,
  ScaleFade,
  VStack,
  useDisclosure,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { useEffect } from "react";

import LoginForm from "./LoginForm";
import Logo from "components/Logo";

const Login = () => {
  const { isOpen: isDisplayed, onOpen: onDisplay } = useDisclosure();

  useEffect(() => {
    onDisplay();
  }, [ onDisplay ]);

  useEffect(() => {
    window.analytics.page("Log In");
  }, []);

  return (
    <Box
      bg = {{ base: mode("white", "gray.800"), sm: mode("gray.100", "gray.900") }}
      minH = "100vh"
    >
      <VStack pt = {{ base: "20", lg: "32" }} spacing = "4">
        <Heading>Log in to OmniMoney</Heading>
        <ScaleFade initialScale = { 0.5 } in = { isDisplayed }><Logo size = "sm" height = "32"/></ScaleFade>
        <LoginForm />
      </VStack>
    </Box>
  )
};

export default Login;
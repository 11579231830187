const SelectField = {
  baseStyle: props => ({
    container: {
      width: "full",
      rounded: "sm"
    },
    control: {
      minHeight: "unset",
      border: "none",
      boxShadow: "none",
      bg: props.colorMode === "light" ? "gray.100" : "gray.700",
      _hover: {
        bg: props.colorMode === "light" ? "gray.200" : "gray.500",
        cursor: "pointer",
      },
      rounded: "sm"
    },
    input: {
      display: "inline-flex",
      px: "2",
      rounded: "md",
      verticalAlign: "top",
      height: "full",
      lineHeight: "1.2",
      justifyContent: "flex-start",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      minHeight: "1.7rem",
      alignItems: "center",
      color: props.colorMode === "light" ? "inherit" : "whiteAlpha.900"
    },
    menu: {
      bg: props.colorMode === "light" ? "white" : "gray.700",
      boxShadow: props.colorMode === "light" ? "light.md" : "dark.lg",
      rounded: "sm"
    },
    option: {
      borderLeft: "2px",
      bg: "transparent",
      borderColor: "transparent",
      _active: {
        bg: props.colorMode === "light" ? "gray.100" : "gray.500"
      },
      fontSize: "sm"
    },
    placeholder: {
      opacity: "0.8"
    },
    focusedOption: {
      bg: props.colorMode === "light" ? "gray.150" : "gray.500",
      borderColor: "brand.400"
    },
    selectedOption: {
      bg: "brand.400"
    },
    singleValue: {
      px: "2",
      color: props.colorMode === "light" ? "inherit" : "whiteAlpha.900"
    },
    valueContainer: {
      px: "0",
      rounded: "sm"
    }
  })
};

export default SelectField;
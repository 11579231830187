import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  Box,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import moment from "moment-timezone";

import BodyRow from "./BodyRow";
import HeaderRow from "./HeaderRow";
import { GET_INVESTMENT_TRANSACTIONS } from "../gql";
import NullState from "components/NullState";
import Table from "components/Table";
import pieChartIcon from "images/icons/pie-chart.svg";
import { formatCurrency } from "utils/format";
import getDefaultBudget from "utils/getDefaultBudget";

const ActivityTable = ({ filters }) => {
  const budgetId = getDefaultBudget();
  const { data: investmentTransactionsData } = useQuery(GET_INVESTMENT_TRANSACTIONS, { variables: { budget_id: budgetId }});

  const columns = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      id: 'date',
      Cell: props => <span>{ moment(props.value).format("MMM D") }</span>
    },
    {
      Header: "Type",
      accessor: "type",
      id: "type",
      filter: (rows, columnIds, filterValue) => {
        if ( filterValue.length === 0 ) {
          return rows
        } else {
          return rows.filter(row => filterValue.includes(row.original.type))
        }
      }
    },
    {
      Header: 'Activity Type',
      accessor: "subtype",
      id: "subtype",
      Cell: props => <Text textTransform = "capitalize">{ props.value }</Text>
    },
    {
      Header: 'Symbol',
      accessor: "security",
      id: "symbol",
      Cell: props => <span>{ props.value?.symbol }</span>
    },
    {
      Header: 'Account',
      accessor: "account",
      id: "account",
      Cell: props => <span>{ props.value.name }</span>,
      filter: (rows, columnIds, filterValue) => {
        if ( filterValue.length === 0 ) {
          return rows
        } else {
          return rows.filter(row => filterValue.includes(row.original.account.id))
        }
      }
    },
    {
      Header: "Amount",
      accessor: "amount",
      id: "amount",
      Cell: props => {
        const { value } = props;
        const { currency, subtype, type } = props.row.original;
        const textColor = ['deposit', 'dividend'].includes(subtype)
          ? mode("green.300", "green.200")
          : ['withdrawal'].includes(subtype)
            ? mode("red.300", "red.200")
            : "inherit";
          
        const showSign = ['cash', 'deposit', 'withdrawal'].includes(type);

        return (
          <Text color = { textColor }>{ formatCurrency(value, currency, { signDisplay: showSign ? 'exceptZero' : "never" }) }</Text>
        )
      }
    }
  ], []);

  const data = useMemo(() => investmentTransactionsData?.investment_transactions || [], [ investmentTransactionsData ]);
  const sortBy = useMemo(() => [
    { id: 'date', desc: true },
    { id: 'id', desc: true }
  ], []);

  return (
    <Box>
      <Table
        variant = "styledFocus"
        columns = { columns }
        data = { data }
        sortBy = { sortBy }
        isLoading = { !investmentTransactionsData }
        filters = { filters }
        BodyRow = { ({ row }) => <BodyRow row = { row } /> }
        HeaderRow = { HeaderRow }
        NoDataComponent = { () => (
          <NullState
            icon = { pieChartIcon }
            message = "Get started by adding your first investment transaction."
            mt = "8"
            py = "4"
          />
        )}
      />
    </Box>
  )
};

export default ActivityTable;
import { 
  Box, 
  Flex, 
  Fade,
  Text, 
  useColorModeValue as mode 
} from '@chakra-ui/react';

const StatCard = ({ header, label, children, onClick, isLoading, ...props }) => (
  <Flex
    direction="column"
    align="center"
    p="6"
    bg={ mode('white', 'gray.700') }
    rounded = "base"
    shadow = { mode("light.md", "dark.md") }
    color={ mode('gray.500', 'gray.400') }
    textAlign="center"
    onClick = { onClick }
    cursor = { !!onClick ? "pointer" : "default" }
    { ...props }
  >
    <Box width = "full">
      <Text visibility = { header ? "visible" : "hidden" } mb = "2" fontSize="xs" fontWeight="bold" textTransform="uppercase" letterSpacing="wide" opacity = "0.7">
        { header || "." }
      </Text>

      <Fade 
        in = { !isLoading }
      >
        { children }
      </Fade>
      <Text mt = "2" fontSize="xs" fontWeight="bold" textTransform="uppercase" letterSpacing="wide">
        { label }
      </Text>
    </Box>
  </Flex>
);

export default StatCard;
import { useToast as useToastChakra } from "@chakra-ui/react";

import Toast from "components/Toast";

const useToast = () => {
  const toast = useToastChakra();

  const renderToast = (title, message, duration = 7000) => {
    toast({ 
      position: "bottom-right",
      render: ({ onClose }) => (
        <Toast
          onClose = { onClose }
          message = { message }
          title = { title }
        />
      ),
      duration,
      isClosable: true
    })
  }

 return renderToast;

}

export default useToast;
import gql from "graphql-tag";

export default gql`
  fragment AllTransactionFields on transactions {
    __typename
    id
    created_at
    updated_at
    account {
      __typename
      id
      name
      type
      current_balance
      currency
    }
    amount
    authorized_at
    needs_category
    posted_at
    is_deleted
    currency
    is_pending
    notes
    payee {
      __typename
      id
      name
    }
    category {
      __typename
      id
      name
    }
    plaid_id
    amount_converted
    transfer_account {
      __typename
      id
      name
      type
    }
  }
`;
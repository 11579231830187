import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { PayeeSelect } from "components/Select";
import useToast from "hooks/useToast";
import { needsCategory } from "utils/calculations";
import getDefaultBudget from "utils/getDefaultBudget";

const INSERT_PAYEE = gql`
  mutation($payee_name: citext!, $budget_id: uuid!) {
    insert_payees(objects: [{ name: $payee_name, budget_id: $budget_id }]) {
      returning {
        id
        __typename
        name
      }
    }
  }
`;

const UPDATE_PAYEE = gql`
  mutation UpdatePayee($transaction_id: uuid!, $payee_id: uuid, $transfer_account_id: uuid, $category_id: uuid) {
    transaction: update_transactions_by_pk(
      pk_columns: { id: $transaction_id },
      _set: {
        payee_id: $payee_id,
        transfer_account_id: $transfer_account_id
        category_id: $category_id
      }
    ) {
      id
      payee {
        id
        name
        __typename
      }
      transfer_account {
        id
        name
        type
        __typename
      }
      category {
        id
        name
        __typename
      }
    }
  }
`;

const Payee = ({ transaction, onUpdate }) => {
  const budgetId = getDefaultBudget();
  const renderToast = useToast();
  const [ createPayee ] = useMutation(INSERT_PAYEE);
  const [ updatePayee ] = useMutation(UPDATE_PAYEE);

  const onPayeeChange = async item => {
    if ( item.value !== transaction.payee?.value && item.value !== transaction.transfer_account?.id ) {
      let payeeId = null;
      let transferAccountId = null;

      if ( item.__isNew__ ) {
        const resp = await createPayee({
          variables: {
            payee_name: item.label,
            budget_id: budgetId
          }
        });

        payeeId = resp.data.insert_payees.returning[0].id;
      } else {
        payeeId = item.__typename === "payees" ? item.value : null;
        transferAccountId = item.__typename === "accounts" ? item.value : null;
      }

      const transactionNeedsCategory = needsCategory(transaction.account.type, item.__typename === "accounts" ? item.type : null)

      const updatePayeeResponse = await updatePayee({
        variables: {
          payee_id: payeeId,
          transfer_account_id: transferAccountId,
          transaction_id: transaction.id,
          category_id: transactionNeedsCategory ? transaction.category?.id : null
        }
      });

      onUpdate({ ...transaction, ...updatePayeeResponse.data.transaction });

      renderToast("Payee Updated");
      window.analytics.track("Transaction Updated", {
        field: "payee"
      })
    }
  };

  const getValue = () => {
    const { payee, transfer_account } = transaction;
    if ( payee ) {
      return { ...payee, label: payee.name, value: payee.id }
    } else if ( transfer_account ) {
      return { ...transfer_account, label: transfer_account.name, id: transfer_account.id }
    } else {
      return null
    }
  }

  return (
    <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Payee</FormLabel>
      </Box>
      <PayeeSelect
        value = { getValue() }
        onChange = { onPayeeChange }
        shouldLoadData = { true }
        isCreatable = { true }
        transferDirection = { transaction.amount >= 0 ? "incoming" : "outgoing" }
      />
    </Grid>
  )
};

export default Payee;
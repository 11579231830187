import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { AccountSelect } from "components/Select";
import useToast from "hooks/useToast";

const UPDATE_ACCOUNT = gql`
mutation UpdateNotes($scheduled_transaction_id: uuid!, $account_id: uuid!) {
  update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { account_id: $account_id }
    ) {
      id
    }
  }
`;

const Account = ({ scheduledTransaction }) => {
  const renderToast = useToast();
  const [ updateAccount ] = useMutation(UPDATE_ACCOUNT);

  const onAccountChange = async item => {
    if ( item.value !== scheduledTransaction.account?.value ) {
      const { value: accountId } = item;

      await updateAccount({
        variables: {
          account_id: accountId,
          scheduled_transaction_id: scheduledTransaction.id
        }
      });

      renderToast("Account Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "account"
      });
    }
  };

  return (
    <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Account</FormLabel>
      </Box>
      <AccountSelect
        value = { scheduledTransaction.account }
        onChange = { onAccountChange }
        shouldLoadData = { true }
        buttonLabel = "Select Account"
      />
    </Grid>
  )
};

export default Account;
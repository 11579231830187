import { useBoolean, useBreakpointValue } from '@chakra-ui/react'
import * as React from 'react'

const useMobileAsideState = (breakpointDisplay = "lg") => {
  const [isOpen, actions] = useBoolean();
  const isMobile = useBreakpointValue({
    base: true,
    [breakpointDisplay]: false,
  })
  React.useEffect(() => {
    if (isMobile === false) {
      actions.off()
    }
  }, [isMobile, actions])
  return {
    isMobile,
    isOpen,
    ...actions,
  }
};

export default useMobileAsideState;
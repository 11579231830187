import {
  VStack
} from "@chakra-ui/react";

import Account from "./Account";
import Amount from "./Amount";
import Category from "./Category";
import Notes from "./Notes";
import Payee from "./Payee";
import DateSection from "./Date";
import TransactionActions from "./TransactionActions";

const Transaction = ({ transaction, onClose, onUpdate, onDeleteCallback }) => {
  return (
    <VStack spacing = "8" width = "full">
      <Payee transaction = { transaction } onUpdate = { onUpdate }/>
      <Amount transaction = { transaction } onUpdate = { onUpdate }/>
      <Category transaction = { transaction } onUpdate = { onUpdate }/>
      <Account transaction = { transaction } onUpdate = { onUpdate } />
      <DateSection transaction = { transaction } onUpdate = { onUpdate } />
      <Notes transaction = { transaction } onUpdate = { onUpdate } />
      <TransactionActions transaction = { transaction } onClose = { onClose } onUpdate = { onUpdate } onDeleteCallback = { onDeleteCallback } />
    </VStack>
  )
};

export default Transaction;
import { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Box, Text, useTheme } from "@chakra-ui/react";
import DoughnutLabel from "chartjs-plugin-doughnutlabel-rebourne";
import { useColorModeValue as mode } from "@chakra-ui/react";

import { formatCurrency, formatPercentage } from "utils/format";

const HoldingsPieChart = ({ holdings }) => {
  const [ targetSymbol, setTargetSymbol ] = useState(null);

  const sortedHoldings = holdings.sort((h1, h2) => h1.value > h2.value ? -1 : 1);
  let topHoldings = [];
  let otherHoldings = [];
  let topSymbolsWeight = 0;
  const topSymbolsWeightLimit = 0.90;
  sortedHoldings.forEach(holding => {
    if ( topSymbolsWeight < topSymbolsWeightLimit && topHoldings.length <= 10  ) {
      topHoldings = topHoldings.concat(holding);
      topSymbolsWeight += holding.weight;
    } else {
      otherHoldings = otherHoldings.concat(holding);
    }
  });
  const otherHolding = {
    symbol: "Other",
    name: "Other",
    value: otherHoldings.reduce((total, holding) => total + holding.value, 0),
    weight: otherHoldings.reduce((total, holding) => total + holding.weight, 0)
  };

  const allHoldings = topHoldings.concat(otherHolding);

  const labels = allHoldings.map(holding => holding.symbol);
  const data = allHoldings.map(holding => holding.value);

  const theme = useTheme();

  const totalValue = allHoldings.reduce((total, holding) => total + holding.value, 0);
  const targetHolding = allHoldings.find(holding => holding.symbol === targetSymbol);
  
  const colors = ['primary', 'tertiary', 'yellow', 'secondary', 'teal', 'red', 'pink', 'orange', 'green', 'cyan'];
  const backgroundColor = topHoldings.map((holding, index) => {
    const color = colors[index % colors.length];
    return theme.colors[color][300];
  }).concat(otherHoldings.concat.length > 0 ? "gray" : null );

  return (
    <Box>
      <Text visibility = { targetHolding ? "visible" : "hidden" } fontWeight = "medium">{ targetHolding?.name || "Placeholder"}</Text>
      <Doughnut 
        plugins = {[ DoughnutLabel ]}
        options = {{
          cutout: 90,
          plugins: {
            doughnutlabel: {
              paddingPercentage: 5,
              labels: [
                { 
                  text: formatCurrency(targetHolding?.value ?? totalValue),
                  font: {
                    size: '24',
                    family: 'Arial, Helvetica, sans-serif',
                    weight: 'bold',
                  },
                  color: mode(theme.colors.gray[800], theme.colors.whiteAlpha[800]),
                }, { 
                  text: formatPercentage(targetHolding?.weight || 0),
                  font: {
                    size: '18',
                    family: 'Arial, Helvetica, sans-serif',
                    weight: 'bold',
                  },
                  color: mode(theme.colors.gray[800], theme.colors.whiteAlpha[800]),
                  display: !!targetHolding
                }
              ]
            },
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          layout: {
            padding: {
              bottom: 10,
              top: 10,
              left: 10,
              right: 10
            }
          },
          onHover: (e, elements, chart) => {
            if ( e.type === "mousemove" ) {
              const newTargetSymbol = allHoldings[elements[0]?.index]?.symbol;
              if ( newTargetSymbol !== targetSymbol ) {
                setTargetSymbol(newTargetSymbol)
              }
            } else if (e.type === "mouseout") {
              setTargetSymbol(null)
            } else {
              console.log(e.type)
            }
          },
        }}
        data = {{
          labels,
          datasets: [{
            label: "Holdings",
            data,
            hoverOffset: 5,
            backgroundColor
          }]
        }}
      />
    </Box>
  )
};

export default HoldingsPieChart;
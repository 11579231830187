import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import components from "./components";
import shadows from "./shadows";
import styles from "./styles";

const getTheme = colorTheme => {
  const config = {
    initialColorMode: colorTheme || "system",
    useSystemColorMode: colorTheme === "system"
  };

  return extendTheme({ colors, components, config, styles, shadows });
}

export default getTheme;



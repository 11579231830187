import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  HStack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { FiTrash } from 'react-icons/fi';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import ShortcutTooltip from "components/ShortcutTooltip";
import useToast from "hooks/useToast";

const TOGGLE_IS_HIDDEN = gql`
  mutation ToggleIsHidden($id: uuid!, $is_hidden: Boolean!) {
    update_accounts(_set: { is_hidden: $is_hidden }, where: { id: { _eq: $id }}) {
      returning {
        id
      }
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($id: uuid!) {
    delete_accounts(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const HideAccount = ({ accountId, isHidden }) => {
  const renderToast = useToast();
  const cancelRef = useRef();
  const [ toggleIsHidden ] = useMutation(TOGGLE_IS_HIDDEN);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onIsHiddenToggle = async () => {
    await toggleIsHidden({
      variables: {
        id: accountId,
        is_hidden: !isHidden
      }
    });

    window.analytics.track("Account Updated", {
      field: 'is_hidden'
    })
    onClose();
    renderToast(`Account ${ isHidden ? "displayed" : "hidden" }`);
  }

  return (
    <>
      <ShortcutTooltip label = { isHidden ? "Display account" : "Hide account" }>
        <Button
          size = "sm"
          variant = "icon"
          onClick = { isHidden ? onIsHiddenToggle : onOpen }
        >
          { isHidden ? <HiEye /> : <HiEyeOff /> }
        </Button>
      </ShortcutTooltip>

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Hide Account</AlertDialogHeader>
          <AlertDialogBody>
          Hiding this account means that its balances and transactions will no longer be included in any calculations or displayed within OmniMoney. You can unhide this account at any time. 
          </AlertDialogBody>
          <AlertDialogFooter justifyContent = "space-between">
            <Button size = "sm" ref = { cancelRef } onClick = { onClose }>Cancel</Button>
            <Button size = "sm" colorScheme = "brand" onClick = { onIsHiddenToggle }>Hide Account</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const DeleteAccount = ({ accountId, callback }) => {
  const renderToast = useToast();
  const cancelRef = useRef();
  const [ deleteAccount ] = useMutation(DELETE_ACCOUNT);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onAccountDelete = async () => {
    await deleteAccount({
      variables: {
        id: accountId
      }
    });

    window.analytics.track("Account Deleted");
    renderToast("Account deleted")
    callback();
  }

  return (
    <>
      <ShortcutTooltip label = "Delete account">
        <Button
          size = "sm"
          variant = "icon"
          onClick = { onOpen }
        ><FiTrash /></Button>
      </ShortcutTooltip>

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Delete Account</AlertDialogHeader>
          <AlertDialogBody>
            Any transactions connected to this account will be deleted as well.
          </AlertDialogBody>
          <AlertDialogFooter justifyContent = "space-between">
            <Button size = "sm" ref = { cancelRef } onClick = { onClose }>Cancel</Button>
            <Button size = "sm" colorScheme = "red" onClick = { onAccountDelete }>Delete Account</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const AccountActions = ({ account, onClose }) => { 

  return(
    <Box width = "full">
      { account.is_hidden ? <Text mb = "1" textAlign = "right" fontSize = "sm" variant = "helper">This account and its transactions are hidden.</Text> : null }
      <Divider mb = "2"/>
      <HStack width = "full" justifyContent = "flex-end">
        <HideAccount accountId = { account.id } isHidden = { account.is_hidden } />
        { account.plaid_item ? null : <DeleteAccount accountId = { account.id } callback = { onClose } /> }
      </HStack>
    </Box>
  )
};

export default AccountActions;
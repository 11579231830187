import {
  Box
} from "@chakra-ui/react";

import NavFooter from "./NavFooter";
import NavItems from "./NavItems";

const Navigation = () => (
  <Box 
    position = "fixed"
    left = "0"
    top = "0"
    bottom = "0"
    width = "var(--nav-width)"
    display = "var(--display-nav)"
    flexDirection = "column"
    height = "full"
    flex = "1"
  >
    <Box
      mt = "2"
      py = "2"
      flex = "1 1 0%"  
      overflow = "auto"
      as = "nav"
      px = "2"
    >
      <NavItems />
    </Box>
    <Box 
      as = "footer"
      display = "flex"
      alignItems = "center"
      width = "full"
      px = "6"
      py = "4"
    >
      <NavFooter />
    </Box>
  </Box>
);

export default Navigation;
import {
  Box,
  HStack,
  List,
  ListItem,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

import { parseAccountType, formatCurrency } from "utils/format";

const AccountsList = ({ accounts, setTargetAccount }) => (
  <List className = "accounts">
    { accounts.map((account, idx) => {
      const balance = account.current_balance ?? account.available_balance;

      return (
        <ListItem
          id = { account.id }
          key = { idx }
          tabIndex = { idx }
          onClick = { () => setTargetAccount(account.id) }
          display = "flex"
          alignItems = "center"
          justifyContent = "space-between"
          width = "full"
          py = "2"
          px = "2"
          rounded = "none"
          borderLeft = "2px"
          borderColor = "transparent"
          _focus = {{
            borderColor: "brandAlpha.400",
            bg: mode("gray.100", "gray.600"),
            outline: "none"
          }}
          cursor = "pointer"
          onMouseEnter = { () => document.getElementById(account.id).focus() }
          opacity = { account.is_hidden ? 0.5 : 1 }
        >
          <Box width = "full" display = {{ base: "block", sm: "flex"}} justifyContent = "space-between">
            <Text width = {{ base: "full", sm: "40%" }} fontSize = "md" isTruncated>{ account.name }</Text>
            <HStack width = {{ base: "full", sm: "60%" }} justifyContent = "space-between">
              <Text fontSize = {{ base: "sm", sm: "md" }} opacity = {{ base: 0.7, sm: 1}}>{ parseAccountType(account.type, account.subtype) }</Text>
              <Text fontSize = {{ base: "sm", sm: "md" }} opacity = {{ base: 0.7, sm: 1}}>{ formatCurrency(['credit', 'loan'].includes(account.type) && balance !== 0 ? -balance : balance, account.currency) }</Text>
            </HStack>
          </Box>
        </ListItem>
      )
    })}
  </List>
);

export default AccountsList;
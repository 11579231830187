import {
  Flex, 
  Input,
  Button,
  useClipboard
} from '@chakra-ui/react';

const CopyField = ({ copyText, ...props }) => {
  const { hasCopied, onCopy } = useClipboard(copyText);

  return (
    <Flex { ...props }>
      <Input rounded = "sm" value = { copyText } isReadOnly onClick = { onCopy }/>
      <Button 
        colorScheme = "brand" 
        variant = "outline" 
        onClick = { onCopy } 
        ml = {2} 
        _focus={{
            boxShadow: "none",
        }}
      >
        { hasCopied ? "Copied" : "Copy" }
      </Button>
    </Flex>
  )
};

export default CopyField;
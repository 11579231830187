import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { HiPlus } from 'react-icons/hi';
import { useMutation } from "@apollo/client";

import { ADD_HOLDING, GET_HOLDINGS } from "./gql";
import { AccountSelect, CurrencySelect } from "components/Select";
import ShortcutTooltip from "components/ShortcutTooltip";
import SymbolSearchInput from "components/SymbolSearchInput";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const AddHolding = () => {
  const budgetId = getDefaultBudget();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ addHolding, { loading } ] = useMutation(ADD_HOLDING, {
    update: (cache, { data: { insert_investment_holdings_one } }) => {
      const data = cache.readQuery({ query: GET_HOLDINGS, variables: { budget_id: budgetId } });
      cache.writeQuery({ 
        query: GET_HOLDINGS, 
        variables: { budget_id: budgetId },
        data: {
          investment_holdings: [ ...data.investment_holdings, insert_investment_holdings_one ]
        }
      });
    }
  });
  const renderToast = useToast();

  const [ account, setAccount ] = useState(null);
  const [ costBasis, setCostBasis ] = useState(null);
  const [ currency, setCurrency ] = useState(null);
  const [ quantity, setQuantity ] = useState(null);
  const [ security, setSecurity ] = useState(null);


  const clearValues = () => {
    setAccount(null);
    setCostBasis(null);
    setCurrency(null);
    setQuantity(null);
    setSecurity(null);
  };

  useEffect(() => {
    clearValues();
  }, [ isOpen ]);

  const onSubmit = e => {
    e.preventDefault();

    let securityObject;

    const iexSecurityTypeMapping = {
      "et": "etf",
      "cs": "equity"
    }

    const securityType = iexSecurityTypeMapping[security.type] || "other"

    if ( security.iexId ) {
      securityObject = {
        data: {
          symbol: security.symbol,
          iex_id: security.iexId,
          type: securityType
        },
        on_conflict: {
          constraint: 'securities_symbol_key',
          update_columns: ['iex_id']
        }
      }
    } else {
      securityObject = {
        data: {
          symbol: security.symbol,
          type: securityType
        },
        on_conflict: {
          constraint: 'securities_symbol_key',
          update_columns: []
        }
      }
    }

    addHolding({
      variables: {
        investment_holding: {
          account_id: account.value,
          cost_basis: parseFloat(costBasis),
          currency: currency.value,
          quantity: parseFloat(quantity),
          security: securityObject
        }
      }
    });

    renderToast("Holding Saved");
    onClose();
  }

  const isValid = account && costBasis && quantity && security;

  return (
    <>
      <Button
        variant = "ghost"
        colorScheme = "gray"
        leftIcon = { <HiPlus /> }
        fontSize = "sm"
        onClick = { onOpen }
        rounded = "sm"
        display = {{ base: "none", md: "inline-flex" }}
      >Add Holding</Button>

      <ShortcutTooltip label = "Add Holding">
        <Button
          variant = "icon"
          onClick = { onOpen }
          display = {{ base: "inline-flex", md: "none"}}
        ><HiPlus /></Button>
      </ShortcutTooltip>

      <Modal
        isOpen = { isOpen }
        onClose = { onClose }
        size = "lg"
        returnFocusOnClose = { false }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Holding</ModalHeader>
          <ModalCloseButton />
          <form onSubmit = { onSubmit }>
            <ModalBody>
              <Grid templateColumns = "40% 60%" rowGap = "2">
                <FormLabel display = "flex" alignItems = "center" mb = "0">Account</FormLabel>
                <AccountSelect
                  value = { account }
                  onChange = { setAccount }
                  shouldLoadData = { isOpen }
                  filterFunction = { account => account.type === "investment" }
                />

                <FormLabel display = "flex" alignItems = "center" mb = "0">Symbol</FormLabel>
                <SymbolSearchInput 
                  value = { security }
                  onChange = { setSecurity }
                />

                <GridItem colSpan={ 2 }>
                  <Box visibility = { security ? "visible" : "hidden"}>
                    <Grid templateColumns = "40% 60%" rowGap = "1" fontSize = "sm" mt = "2">
                      <Text>Exchange</Text>
                      <Text>{ security?.exchangeName }</Text>

                      <Text>Region</Text>
                      <Text>{ security?.region }</Text>
                    </Grid>
                  </Box>
                </GridItem>

                <FormLabel display = "flex" alignItems = "center" mb = "0">Quantity</FormLabel>
                <NumberInput
                  placeholder = "0"
                  variant = "flushed"
                  precision = { 5 }
                  defaultValue = { 0 }
                  clampValueOnBlur = { true }
                  min = { 0 }
                  value = { quantity || "" }
                  onChange = { (valAsString, valAsNumber) => setQuantity(valAsString) }
                >
                  <NumberInputField />
                </NumberInput>

                <FormLabel display = "flex" alignItems = "center" mb = "0">Cost Basis</FormLabel>
                <NumberInput
                  placeholder = "0"
                  variant = "flushed"
                  precision = { 2 }
                  defaultValue = { 0 }
                  clampValueOnBlur = { true }
                  min = { 0 }
                  value = { costBasis || "" }
                  onChange = { (valAsString, valAsNumber) => setCostBasis(valAsString) }
                >
                  <NumberInputField />
                </NumberInput>

                <FormLabel display = "flex" alignItems = "center" mb = "0">Currency</FormLabel>
                <CurrencySelect
                  value = { currency }
                  onChange = { setCurrency }
                  shouldLoadDefaultCurrency = { isOpen }
                />
              </Grid>
            </ModalBody>
            <ModalFooter display = "flex" justifyContent = "end">
              <Button
                colorScheme = "brand"
                type = "submit"
                isLoading = { loading }
                loadingText = "Saving..."
                isDisabled = { !isValid }
                size = "sm"
                onClick = { onSubmit }
              >Create</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
};

export default AddHolding;
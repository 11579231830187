const Switch = {
  baseStyle: {
    track: {
      _focus: {
        boxShadow: "none",
        outline: "none"
      }
    }
  }
};

export default Switch;
import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { AccountSelect } from "components/Select";
import useToast from "hooks/useToast";

const UPDATE_ACCOUNT = gql`
mutation UpdateAccount($transaction_id: uuid!, $account_id: uuid!) {
  transaction: update_transactions_by_pk(
      pk_columns: { id: $transaction_id },
      _set: { account_id: $account_id }
    ) {
      id
      account {
        id
        name
        type
      }
      __typename
    }
  }
`;

const Account = ({ transaction, onUpdate }) => {
  const renderToast = useToast();
  const [ updateAccount ] = useMutation(UPDATE_ACCOUNT);

  const onAccountChange = async item => {
    if ( item.value !== transaction.account?.value ) {
      const { value: accountId } = item;

      const updateAccountResponse = await updateAccount({
        variables: {
          account_id: accountId,
          transaction_id: transaction.id
        }
      });

      onUpdate({ ...transaction, ...updateAccountResponse.data.transaction })

      renderToast("Account Updated");
      window.analytics.track("Transaction Updated", {
        field: "account"
      });
    }
  };

  return (
    <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Account</FormLabel>
      </Box>
      <AccountSelect
        value = {{ ...transaction.account, value: transaction.account.id, label: transaction.account.name }}
        onChange = { onAccountChange }
        shouldLoadData = { true }
        buttonLabel = "Select Account"
        isDisabled = { transaction.plaid_id }
      />
    </Grid>
  )
};

export default Account;
import {
  Box,
  Td,
  Tr
} from "@chakra-ui/react";

const TdProps = {
  px: { md: "4" },
  py: { base: "1", md: "2" },
  whiteSpace: "nowrap",
  isTruncated: true
}

const BodyRow = ({ row, onRowClick = () => null }) => {
  const renderCell = headerName => row.cells.find(cell => cell.column.Header === headerName).render('Cell');
  const getCellProps = headerName => row.cells.find(cell => cell.column.Header === headerName).getCellProps();

  return (
    <Tr
      { ...row.getRowProps() }
      onClick = { () => onRowClick(row.original.id) }
      id = { row.original.id }
      tabIndex = { row.index }
      onMouseEnter = { () => document.getElementById(row.original.id).focus() }
    >
      <Td
        { ...getCellProps('Date') }
        { ...TdProps }
        px = { { base: "2", sm: "4" } }
        fontSize = {{ base: "sm", md: "unset" }}
      >{ renderCell("Date") }</Td>
      <Td
        { ...getCellProps('Activity Type') }
        { ...TdProps }
        display = {{ base: "none", md: "table-cell" }} 
      >{ renderCell("Activity Type") }</Td>

      <Td
        { ...getCellProps('Symbol') }
        { ...TdProps }
        display = {{ base: "none", md: "table-cell" }} 
      >{ renderCell('Symbol') }</Td>

      <Td
        { ...getCellProps('Account') }
        { ...TdProps }
        display = {{ base: "none", md: "table-cell" }} 
      >{ renderCell('Account') }</Td>

      <Td
        { ...TdProps }
        display = {{ base: "table-cell", md: "none" }}
        fontSize = "sm"
      >
        <Box isTruncated fontWeight = "medium">{ renderCell('Symbol') }</Box>
        <Box isTruncated fontWeight = "light">{ renderCell('Activity Type') }</Box>
        <Box isTruncated fontWeight = "light">{ renderCell('Account') }</Box>
      </Td>

      <Td
        { ...getCellProps('Amount') }
        { ...TdProps }
        px = {{ base: "2", sm: "4" }}
        fontSize = {{ base: "sm", md: "unset" }}
        textAlign = "right"
      >{ renderCell('Amount') }</Td>
    </Tr>
  )
};

export default BodyRow;
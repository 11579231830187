import {
  Button,
  ButtonGroup,
  Flex,
  useEditableControls
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";

import ShortcutTooltip from "./ShortcutTooltip";

const EditableControls = () => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent = "center" size = "sm">
      <ShortcutTooltip label = "Save" keys = {["enter"]}>
        <Button
          size = "sm"
          variant = "icon"
          { ...getSubmitButtonProps() }
        ><CheckIcon />
        </Button>
      </ShortcutTooltip>

      <ShortcutTooltip label = "Cancel" keys = {["esc"]}>
        <Button
          size = "sm"
          variant = "icon"
          { ...getCancelButtonProps() }
        ><CloseIcon />
        </Button>
      </ShortcutTooltip>
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <Button
        size = "sm"
        variant = "icon"
        { ...getEditButtonProps() }
      ><EditIcon />
      </Button>
    </Flex>
  );
};

export default EditableControls;
import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { BiUpload } from "react-icons/bi";
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";

import { GET_TRANSACTIONS, INSERT_TRANSACTIONS } from "gql/transactions";
import Review from "./Review";
import Confirmation from "./Confirmation";
import Upload from "./Upload";
import { HeaderButton } from "components/Page";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const UploadTransactions = () => {
  const renderToast = useToast();
  const budgetId = getDefaultBudget();
  const [ account, setAccount ] = useState(null);
  const [ uploadData, setUploadData ] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const [ insertTransactionsMutation, { loading: isInsertingTransactions } ] = useMutation(INSERT_TRANSACTIONS, {
    update: (cache, { data: { insert_transactions: { returning: newTransactions } }}) => {
      const data = cache.readQuery({ query: GET_TRANSACTIONS, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_TRANSACTIONS,
        variables: { budget_id: budgetId },
        data: {
          transactions: [ ...data?.transactions || [], ...newTransactions ]
        }
      })
    }
  });

  useEffect(() => {
    reset();
    //setAccount(null);
    setUploadData(null);
    // eslint-disable-next-line
  }, [ isOpen ]);

  const onReviewComplete = () => {
    // Upload transactions

    const transactionObjects = uploadData.map(row => {
      const transaction = {
        account_id: account.value,
        amount: row.amount,
        authorized_at: moment(row.date).format("YYYY-MM-DD"),
        currency: row.currency.value,
        notes: row.notes,
        amount_converted: row.amount
      };

      if ( row.payee.__typename === "accounts" ) {
        transaction.transfer_account_id = row.payee.value
      } else {
        transaction.payee = {
          data: {
            name: row.payee.label,
            budget_id: budgetId
          },
          on_conflict: {
            constraint: 'payees_budget_id_name_key',
            update_columns: [ 'budget_id' ]
          }
        }
      }

      if ( row.category ) {
        transaction.category = {
          data: {
            name: row.category.label,
            budget_id: budgetId
          },
          on_conflict: {
            constraint: 'categories_budget_id_name_key',
            update_columns: [ 'budget_id' ]
          }
        }
      }

      return transaction
    });

    insertTransactionsMutation({
      variables: {
        transactions: transactionObjects
      }
    })
    .then(() => {
      const transactionsCount = uploadData.length;
      const earliestDate = uploadData.reduce((minDate, row) => moment(minDate).isBefore(moment(row.date)) ? minDate : row.date, uploadData[0].date);
      const latestDate = uploadData.reduce((maxDate, row) => moment(maxDate).isAfter(moment(row.date)) ? maxDate : row.date, uploadData[0].date);
      const dateSpan = moment(latestDate).diff(moment(earliestDate), 'days');
      window.analytics.track("Transactions Uploaded", {
        transactions_count: transactionsCount,
        date_span: dateSpan
      });
      renderToast("Transactions Created");
      nextStep();
    })
  }

  const onComplete = () => {
    onClose();
  }

  const transactionsCount = uploadData?.length;
  const transactionsTotal = uploadData?.reduce((total, row) => total + row.amount, 0);

  const steps = [
    {
      label: "Upload",
      content: Upload,
      props: { account, setAccount, isOpen, setUploadData, budgetId },
      nextButtonText: "Upload Transactions",
      isValid: account && uploadData,
      onClick: nextStep,
      isBackDisabled: true

    }, {
      label: "Review",
      content: Review,
      props: { account, setAccount, isOpen, uploadData, setUploadData },
      nextButtonText: "Upload Transactions",
      isValid: true,
      onClick: onReviewComplete
    }, {
      label: "Complete",
      content: Confirmation,
      props: { transactionsCount, transactionsTotal, account, isOpen },
      nextButtonText: "Done",
      isValid: true,
      onClick: onComplete,
      isBackDisabled: true
    }, 
  ]
  return (
    <>
      <HeaderButton
        buttonLabel = "Upload Transactions"
        LeftIcon = { BiUpload }
        onClick = { onOpen }
      />

      <Modal
        isOpen = { isOpen }
        onClose = { onClose }
        size = "2xl"
        variant = { activeStep === 1 ? "fullScreen" : "unset"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Transactions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Steps activeStep={activeStep} colorScheme = "primary" responsive = { false }>
              {steps.map(({ label, content, props }) => (
                <Step label={label} key={label}>
                  { content(props) }
                </Step>
              ))}
            </Steps>
          </ModalBody>
          <ModalFooter display = "flex" justifyContent = "space-between">
            <Button
              colorScheme = "gray"
              size = "sm"
              onClick = { prevStep }
              visibility = { steps[activeStep].isBackDisabled ? "hidden" : "visible"}
            >
              Back
            </Button>

            <Button
              colorScheme = "primary"
              size = "sm"
              onClick = { steps[activeStep].onClick }
              isDisabled = { !steps[activeStep].isValid }
              isLoading = { isInsertingTransactions }
            >
              { steps[activeStep].nextButtonText }
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
};

export default UploadTransactions;
import { useEffect, useState } from "react";
import iex from "utils/iex";

import { PopoutSelect } from "components/Select";

const iexSearchMSLimit = iex.isSandboxMode ? 100 : 10;

const SymbolSearchInput = ({ value, onChange }) => {
  const [ searchText, setSearchText ] = useState("");
  const [ queryResults, setQueryResults ] = useState([]);
  const [ isLoading, toggleIsLoading ] = useState(false);
  const [ queryTimer, setQueryTimer ] = useState(null);

  const getQueryResults = searchText => {
    iex.search(searchText)
      .then(resp => {
        if ( queryTimer ) {
          clearTimeout(queryTimer);
          setQueryTimer(null);
        }

        setQueryResults(resp);
        toggleIsLoading(false);
      })
      .catch(err => {
        if ( err.response.status === 429 ) {
          const timer = setTimeout(() => getQueryResults(searchText), iexSearchMSLimit);
          setQueryTimer(timer);
        } else {
          console.log(err)
        }
      })
  }

  useEffect(() => {
    if ( searchText.length <= 2 ) {
      toggleIsLoading(false);
      setQueryResults([]);
    } else {
      getQueryResults(searchText);
    }
    // eslint-disable-next-line
  }, [ searchText ]);

  return (
    <PopoutSelect
      tooltipText = "Search for a symbol"
      buttonLabel = "Search for a symbol"
      options = { queryResults.map(result => ({ ...result, label: result.symbol + ": " + result.securityName, value: result.symbol })) }
      value = { value }
      isLoading = { isLoading }
      noOptionsMessage = { () => searchText.length > 2 ? "No symbols found" : "Enter the company's name or ticker" }
      onInputChange = { newSearchText => setSearchText(newSearchText)}
      filterOption = { () => true }
      onChange = { onChange }
      isClearable = { true }
    />
  )
};

export default SymbolSearchInput;
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Box,
  Container
} from "@chakra-ui/react";

import AddHolding from "./AddHolding";
import { GET_HOLDINGS } from "./gql";
import HoldingsPieChart from "./HoldingsPieChart";
import HoldingsTable from "./HoldingsTable";
import UploadHoldings from "./UploadHoldings";
import { Main, Page, Header} from "components/Page";
import getDefaultBudget from "utils/getDefaultBudget";
import iex from "utils/iex";

const transformHoldings = (holdings, iexData) => {
  if ( holdings && iexData ) {
    const symbols = Array.from(new Set(holdings.map(holding => holding.security.symbol)));

    const combinedHoldings = symbols.map(symbol => {
      const symbolHoldings = holdings.filter(holding => holding.security.symbol === symbol);
      const symbolData = symbolHoldings[0].security;
      
      return {
        id: 'symbol-' + symbolData.id,
        symbol: symbolData.symbol,
        logo: iexData[symbolData.symbol]?.logo.url,
        name: iexData[symbolData.symbol]?.company.companyName,
        quantity: symbolHoldings.reduce((total, holding) => holding.quantity + total, 0),
        costBasis: symbolHoldings.reduce((total, holding) => holding.cost_basis + total, 0)
      }
    });

    const holdingsWithValue = combinedHoldings.map(holding => ({
      ...holding,
      value: holding.quantity * (iexData[holding.symbol]?.price || 0 ) // Chart data is sorted desc
    }));

    const totalValue = holdingsWithValue.reduce((total, holding) => total + holding.value, 0);

    return holdingsWithValue.map(holding => ({
      ...holding,
      weight: holding.value / totalValue,
      return: (holding.value / holding.costBasis) - 1
    }));
  };
}

const Portfolio = () => {
  const [ iexData, setIexData ] = useState(null);
  const budgetId = getDefaultBudget();
  const { data: holdingsData } = useQuery(GET_HOLDINGS, {
    variables: {
      budget_id: budgetId
    }
  });

  useEffect(() => {
    window.analytics.page("Portfolio");
  }, []);

  useEffect(() => {
    if ( holdingsData ) {
      // Only grab iex data for new symbols
      const currentSymbols = Object.keys(iexData || {});

      const symbols = Array.from(new Set(holdingsData?.investment_holdings.map(holding => holding.security.symbol).filter(symbol => !currentSymbols.includes(symbol))));
      if ( symbols.length > 0 ) {
        iex.batch({ symbols, types: ['logo', 'price', 'company']})
        .then(resp => {
          setIexData(prev => ({ ...(prev || {}), ...resp }));
        })
      }
    }
  }, [ iexData, holdingsData ]);

  const holdings = transformHoldings(holdingsData?.investment_holdings, iexData);

  return (
    <Page>
      <Main>
        <Container maxW = "container.xl">
          <Header title = "Portfolio">
            <Box display = "flex">
              <UploadHoldings />
              <AddHolding />
            </Box>
          </Header>

          <Box display = {{ base: "block", lg: "flex"}} experimental_spaceX = {{ base: "0", lg: "8" }}>
            <Box display = "flex" justifyContent = "center">
              <Box py = {{ base: "2", md: "10"}} maxW = "96">
                <HoldingsPieChart
                  holdings = { holdings || [] }
                />
              </Box>
            </Box>
            <HoldingsTable 
              holdings = { holdings }
            />
          </Box>
        </Container>
      </Main>
    </Page>
  )
};

export default Portfolio;
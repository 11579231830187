import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Textarea,
  useBoolean
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";

import { INCREMENT_ACCOUNT_BALANCE } from "gql/accounts";
import { GET_TRANSACTIONS, INSERT_TRANSACTION } from "gql/transactions";
import DateField from "components/DateField";
import { AccountSelect, CategorySelect, CurrencySelect, PayeeSelect } from "components/Select";
import Toggle from "components/Toggle";
import LargeCurrencyInput from "components/LargeCurrencyInput";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";
import { needsCategory } from "utils/calculations";

const moment = require("moment-timezone");

const CreateTransaction = ({ onClose, isOpen }) => {
  const [ createAnother, { toggle: toggleCreateAnother } ] = useBoolean(false);
  const [ updateAccountBalance, { toggle: toggleUpdateAccountBalance } ] = useBoolean(true);
  const budgetId = getDefaultBudget();
  const renderToast = useToast();

  const [ date, setDate ] = useState(new Date());
  const [ amount, setAmount ] = useState(0);
  const [ notes, setNotes ] = useState("");
  const [ account, setAccount ] = useState(null);
  const [ category, setCategory ] = useState(null);
  const [ currency, setCurrency ] = useState(null);
  const [ payee, setPayee ] = useState(null);

  const [ insertTransaction ] = useMutation(INSERT_TRANSACTION, {
    update: (cache, { data: { insert_transactions_one } }) => {
      const data = cache.readQuery({ query: GET_TRANSACTIONS, variables: { budget_id: budgetId } });
      cache.writeQuery({ 
        query: GET_TRANSACTIONS, 
        variables: { budget_id: budgetId },
        data: {
          transactions: [ ...(data?.transactions || []), insert_transactions_one ]
        }
      });
    }
  });
  const [ incrementAccountBalanceMutation ] = useMutation(INCREMENT_ACCOUNT_BALANCE);

  const clearValues = () => {
    setPayee(null);
    setAccount(null);
    setCurrency(null);
    setCategory(null);
    setNotes("");
    setAmount(0);
    setDate(new Date());
  };

  useEffect(() => {
    clearValues();
  }, [ isOpen ]);

  const onSubmit = async e => {
    e.preventDefault();

    const transaction = {
      account_id: account.value,
      amount,
      authorized_at: moment(date).format("YYYY-MM-DD"),
      currency: currency.value,
      notes,
      amount_converted: amount,
    };

    if ( payee.__typename === "accounts" ) {
      transaction.transfer_account_id = payee.value;
    } else {
      transaction.payee = {
        data: {
          name: payee.label,
          budget_id: budgetId
        },
        on_conflict: {
          constraint: 'payees_budget_id_name_key',
          update_columns: [ 'budget_id' ]
        }
      }
    }

    if ( !disableCategory ) {
      transaction.category = {
        data: {
          name: category.label,
          budget_id: budgetId
        },
        on_conflict: {
          constraint: 'categories_budget_id_name_key',
          update_columns: [ 'budget_id' ]
        }
      }
    }

    await insertTransaction({
      variables: {
        transaction
      }
    });

    if ( updateAccountBalance ) {
      await incrementAccountBalanceMutation({
        variables: {
          account_id: account.value,
          amount: ['credit', 'loan'].includes(account.type) ? -amount : amount 
        }
      })
    }

    renderToast("Transaction Created");
    window.analytics.track("Transaction Created", {
      update_account_balance: updateAccountBalance
    });
    
    if ( createAnother ) {
      clearValues();
    } else {
      onClose();
    }
  }


  const disableCategory = account && !needsCategory(account?.type, payee?.type);
  const isTransferCategory = disableCategory && payee?.__typename === "accounts";

  const isValid = account && payee && ( disableCategory || category ) && currency;

  return (
    <Modal
      isOpen = { isOpen }
      onClose = { onClose }
      size = "3xl"
      returnFocusOnClose = { false }
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Transaction</ModalHeader>
        <ModalCloseButton />
        <form onSubmit = { onSubmit }>
          <ModalBody>
            <Box alignItems = "center">
              <CurrencySelect
                value = { currency }
                onChange = { setCurrency }
                shouldLoadDefaultCurrency = { isOpen }
                isFullWidth = { false }
              />
            </Box>
            <LargeCurrencyInput
              value = { amount }
              onChange = { setAmount }
              currency = { currency?.value }
              placeholder = "0"
            />
            <SimpleGrid mt = "3" columns = {{ base: 1, md: 2, lg: 4 }} py = "4" spacing = "2">
              
              <Box>
                <DateField
                  value = { date }
                  onChange = { setDate }
                  filterDate = { date => date <= new Date() } 
                />
              </Box>

              <AccountSelect
                value = { account }
                onChange = { setAccount }
                shouldLoadData = { isOpen }
                buttonLabel = "Account"
              />

              <PayeeSelect
                value = { payee }
                onChange = { setPayee }
                isCreatable = { true }
                shouldLoadData = { isOpen }
                buttonLabel = "Payee"
                transferDirection = { amount >= 0 ? "incoming" : "outgoing" }
              />
              <CategorySelect
                value = { isTransferCategory ? ({ label: "Transfer", value: "transfer" }) : category }
                onChange = { setCategory }
                isCreatable = { true }
                shouldLoadData = { isOpen }
                buttonLabel = "Category"
                isDisabled = { disableCategory }
              />
            </SimpleGrid>
            <Textarea
              value = { notes }
              onChange = { e => setNotes(e.target.value) }
              placeholder = "Transaction notes"
              rows = "1"
              resize = "none"
              size = "sm"
              variant = "flushed"
            />
          </ModalBody>
          <ModalFooter>
            <Stack direction = {{ base: "column", sm: "row" }} spacing = "2" justifyContent = "space-between" width = "full">
              <Stack direction = {{ base: "column", md: "row" }} spacing = "1" flexGrow = "1">
                <Toggle
                  label = "Create another?"
                  isChecked = { createAnother }
                  onToggle = { toggleCreateAnother }
                />
                <Toggle
                  label = "Update account balance?"
                  isChecked = { updateAccountBalance }
                  onToggle = { toggleUpdateAccountBalance }
                />
              </Stack>
              <Button 
                width = {{ base: "full", sm: "unset" }}
                colorScheme = "brand"
                type = "submit"
                isLoading = { false }
                loadingText = "Creating Transaction"
                isDisabled = { !isValid }
                size = "sm"
                onClick = { onSubmit }
              >Create</Button>
            </Stack>
        </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
};

export default CreateTransaction;
import { Kbd, Text } from '@chakra-ui/react';

const _ = require('lodash');

const HotKeyCombination = ({ keys, separator = "+" }) =>  {
  return _.flatMap(keys, (value, index, array) =>
    array.length -1 !== index // check for the last item
    ? [ <Kbd key = { index }>{ value }</Kbd> , <Text key = { index + "+"} fontSize = "xs" mx = "1">{ separator }</Text>]
    : <Kbd key = { index }>{ value }</Kbd>
  )
};

export default HotKeyCombination;
import {
  Grid,
  VStack,
} from "@chakra-ui/react";

import AccountActions from "./AccountActions";
import AccountAvailableBalance from "./AccountAvailableBalance";
import AccountCurrentBalance from "./AccountCurrentBalance";
import AccountCurrency from "./AccountCurrency";
import AccountDetails from "./AccountDetails";
import AccountLimit from "./AccountLimit";
import SyncData from "./SyncData";
import FieldGroup from "components/FieldGroup";

const Account = ({ account, onClose }) => {
  return (
    <VStack spacing = "8">
      <AccountDetails account = { account } />

      <FieldGroup title = "Balance">
        <Grid templateColumns = "40% 60%" rowGap = "2">
          <AccountCurrentBalance account = { account } />
          <AccountAvailableBalance account = { account } />
          <AccountLimit account = { account } />
          <AccountCurrency account = { account }/>
        </Grid>
      </FieldGroup>

      { account.plaid_item?.initial_update_completed_at ? (
        <SyncData account = { account } />
      ) : null }
      
      <AccountActions account = { account } onClose = { onClose } />
    </VStack>
  )
};

export default Account;
import { useEffect, useState } from "react";
import {
  Box,
  Heading,
  VStack,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import ChangePasswordForm from "./ChangePasswordForm";
import ChangeSuccess from "./ChangeSuccess";
import RequestSuccess from "./RequestSuccess";
import RequestForm from "./RequestForm";

const ResetPassword = () => {
  const [ verificationCode, setCode ] = useState(null);
  const [ screen, setScreen ] = useState(null);
  const querySearch = useLocation().search;

  useEffect(() => {
    window.analytics.page("Password Reset");

    if ( querySearch ) {
      const urlQuery = new URLSearchParams(querySearch);
      const queryParams = Object.fromEntries(urlQuery);
      const { code } = queryParams;
      setCode(code);
      setScreen("change-password-form")
    } else {
      setScreen("reset-form")
    }
    
  }, [ querySearch ]);

  return (
    <Box
      bg = {{ base: mode("white", "gray.800"), sm: mode("gray.100", "gray.900") }}
      minH = "100vh"
    >
      <VStack pt = {{ base: "12", lg: "16" }} spacing = "4">
        <Heading>Reset Password</Heading>
        { screen === "reset-form" ? <RequestForm callback = { () => setScreen("request-success") } /> : null }
        { screen === "request-success" ? <RequestSuccess /> : null }
        
        { screen === "change-password-form" ? <ChangePasswordForm verificationCode = { verificationCode } callback = { () => setScreen("change-sucess") } /> : null }
        { screen === "change-sucess" ? <ChangeSuccess /> : null }
      </VStack>
    </Box>
  )
};

export default ResetPassword;
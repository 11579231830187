import { accountTypesAndSubtypes } from "utils/options";

const formatCurrency = ( amount, currency, options = { signDisplay: "auto" }) => new Intl.NumberFormat(undefined, {
  style: currency ? 'currency' : 'decimal',
  currency,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  ...options
}).format(amount === 0 ? 0 : amount);

const formatPercentage = ( number, decimalPlaces ) => number.toLocaleString(undefined,{style: 'percent', minimumFractionDigits: decimalPlaces });

const parseAccountType = (type, subtype) => accountTypesAndSubtypes.find(_type => _type.value === type)?.subtypes.find(_subtype => _subtype.value === subtype)?.label;

export {
  formatCurrency,
  formatPercentage,
  parseAccountType
}
import MultiSelectDropdown from "./MultiSelectDropdown";
import PopoutSelect from "./PopoutSelect";
import { activityTypeOptions } from "utils/options";

const ActivityTypeSelect = ({ value, onChange, isMulti, buttonLabel = "Select Type", tooltipText = "Select Type" })=> {
  const props = {
    onChange,
    value,
    options: activityTypeOptions
  }

  return isMulti ? (
    <MultiSelectDropdown
      label = {{ singular: "Activity Type", plural: "Activity Types" }}
      isMulti = { true }
      { ...props }
    />
  ) : (
    <PopoutSelect
      tooltipText = { tooltipText }
      buttonLabel = { buttonLabel }
      { ...props }
    />
  )
};

export default ActivityTypeSelect;
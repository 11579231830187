import { useKBar as _useKBar, VisualState } from "kbar";

const useKBar = () => {
  const { query } = _useKBar();

  const open = () => query.setVisualState(() => VisualState.animatingIn);
  const close = () => query.setVisualState(VisualState.animatingOut);

  return { open, close };
};

export default useKBar;
import gql from "graphql-tag";

import { ALL_ACCOUNT_FIELDS } from "./fragments";

const INCREMENT_ACCOUNT_BALANCE = gql`
  ${ALL_ACCOUNT_FIELDS}
  mutation IncrementAccountBalance($account_id: uuid!, $amount: numeric!) {
    update_accounts_by_pk(
      pk_columns: { id: $account_id },
      _inc: { current_balance :$amount }
    ) {
      ...AllAccountFields
    }
  }
`;

export {
  INCREMENT_ACCOUNT_BALANCE
};
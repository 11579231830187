import moment from "moment-timezone";
import { RRule } from 'rrule';

const frequencyMapping = {
  weekly: {
    freq: RRule.WEEKLY,
    interval: 1
  },
  biweekly: {
    freq: RRule.WEEKLY,
    interval: 2
  },
  "1st&15th": {
    freq: RRule.MONTHLY,
    interval: 1,
    bymonthday: [1, 15]
  },
  monthly: {
    freq: RRule.MONTHLY,
    interval: 1
  },
  endOfMonth: {
    freq: RRule.MONTHLY,
    interval: 1,
    bymonthday: [-1]
  },
  "3Months": {
    freq: RRule.MONTHLY,
    interval: 3
  },
  yearly: {
    freq: RRule.YEARLY,
    interval: 1
  }
}

const needsCategory = ( accountType, transferAccountType ) => {
  return ['depository', 'credit'].includes(accountType) && ( !transferAccountType || ['investment', 'loan'].includes(transferAccountType) )
}

const getInterval = ({ startDate, endDate, frequency }) => {  
  return new RRule({
    ...frequencyMapping[frequency],
    dtstart: startDate,
    until: endDate
  });
};

const getDatesBetween = ({ startDate, endDate, frequency, afterDate, beforeDate }) => {
  const _afterDate = moment.utc(afterDate).toDate();
  const _beforeDate = moment.utc(beforeDate).toDate();
  const _startDate = moment.utc(startDate).toDate();
  const _endDate = endDate ? moment.utc(endDate).toDate() : null;

  if ( frequency === "once" ) {
    if ( moment(_startDate).isBetween(moment(_afterDate), moment(_beforeDate), 'day', "[]") ) {
      return [ startDate ]
    } else {
      return []
    }
  } else {
    const interval = getInterval({ 
      startDate: _startDate, 
      endDate: _endDate,
      frequency
    });

    return interval.between(_afterDate, _beforeDate, true)
  }
}

const getNextDate = ({ startDate, endDate, afterDateMoment = moment().startOf('day'), frequency, include = false }) => {
  const afterDate = afterDateMoment.endOf('day').toDate();
  const today = moment().startOf('day');
  const startDateMoment = moment(startDate);
  const endDateMoment = endDate ? moment(endDate) : null;

  if ( frequency === "once" ) {
    if ( startDateMoment.isBefore(today) || !include ) {
      return null
    } else {
      return startDate
    }
  } else if ( startDate && frequency) {
    const interval = getInterval({ startDate: startDateMoment.toDate(), endDate: endDateMoment?.toDate(), frequency });
    const nextDate = interval.after(afterDate, include);
    console.log(afterDate, nextDate)
    return nextDate ? moment(nextDate).format("YYYY-MM-DD") : null
  }
}

export {
  getDatesBetween,
  getNextDate,
  needsCategory
}
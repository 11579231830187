import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  VStack
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";

import { INCREMENT_ACCOUNT_BALANCE } from "gql/accounts";
import useToast from "hooks/useToast";
import { formatCurrency } from "utils/format";

const Confirmation = ({ transactionsCount, transactionsTotal, account, isOpen }) => {
  const renderToast = useToast();
  const [ isAccountBalanceUpdated, setIsAccountBalanceUpdated ] = useState(false);
  const [ incrementAccountBalanceMutation, { loading: isUpdatingAccountBalance } ] = useMutation(INCREMENT_ACCOUNT_BALANCE);
  const incrementAmount = ['credit', 'loan'].includes(account?.type) ? -transactionsTotal : transactionsTotal;
  const newAccountBalance = account?.current_balance + incrementAmount;

  useEffect(() => {
    setIsAccountBalanceUpdated(false);
  }, [ isOpen ]);

  const onUpdateAccountBalance = () => {
    incrementAccountBalanceMutation({
      variables: {
        account_id: account?.value,
        amount: incrementAmount
      }
    })
    .then(() => {
      setIsAccountBalanceUpdated(true);
      renderToast("Current balance updated");
      window.analytics.track("Account Updated", {
        field: "current_balance"
      });
    })
  };

  return (
    <Box mt = "4">
      <VStack alignItems = {{ base: "center", md: "flex-start"}} textAlign = "center">
        <Text mb = "4">{transactionsCount} transaction{transactionsCount === 1 ? '' : 's'} have been successfully uploaded.</Text>
        <Text>Would you like to update the { account?.name } balance from { formatCurrency(account?.current_balance, account?.currency) } to { formatCurrency(newAccountBalance, account?.currency) }?</Text>
        <Button 
          onClick = { onUpdateAccountBalance } 
          colorScheme = "tertiary"
          size = "sm"
          width = {{ base: "full", sm: "unset" }}
          isDisabled = { isAccountBalanceUpdated }
          isLoading = { isUpdatingAccountBalance }
        >{ isAccountBalanceUpdated ? "Account Balance Updated" : "Update Account Balance" }</Button>
      </VStack>
    </Box>
  )
};

export default Confirmation;
import { Box, Button } from '@chakra-ui/react';
import { BsReverseLayoutSidebarReverse, BsLayoutSidebarInsetReverse } from "react-icons/bs";

const AsideButton = ({ onClick, isOpen, closeOther, OpenIcon = BsLayoutSidebarInsetReverse, CloseIcon = BsReverseLayoutSidebarReverse, breakpoint, type, ...props }) => (
  <Button
    display = {{ base: 'flex', [breakpoint]: 'none' }}
    fontSize = "lg"
    onClick = { () => { if (!isOpen) { closeOther() }; onClick(); } }
    variant = "icon"
    transition = "all 0.2s"
    { ...props }
  >
    <Box srOnly>{ isOpen ? `Close ${type}` : `Open ${type}` }</Box>
    { isOpen ? <OpenIcon /> : <CloseIcon /> }
  </Button>
);

export default AsideButton;
const Kbd = {
  baseStyle: props => ({
    padding: "4px 3px 4px 4px",
    minWidth: "20px",
    borderRadius:"3px",
    fontSize: "11px",
    textTransform: "capitalize",
    lineHeight: "110%",
    textAlign: "center",
    backgroundColor: props.colorMode === "light" ? "gray.200" : "whiteAlpha.300",
    color: props.colorMode === "light" ? "gray.900" : "whiteAlpha.700",
    borderBottomWidth: "0",
    borderWidth: "0"
  })
};

export default Kbd;
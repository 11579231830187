const incomeCategories = ["Paycheck", "Bank Interest" ];
const categoryGroups = [
  { name: "Bills", categories: ["Rent", "Phone Bill", "Utilities", "Subscriptions"] },
  { name: "Lifestyle", categories: ["Groceries", "Eating Out", "Shopping", "Personal Care"] },
  { name: "Goals", categories: ["Emergency Fund", "Summer Vacation"] }
];

export {
  incomeCategories,
  categoryGroups
}
import {
  Box,
  SimpleGrid,
  Text
} from "@chakra-ui/react";
import { memo, useRef } from "react";

import CategoryGroup from "./CategoryGroup";

const shouldNotReRender = (prev, next) => {
  return next.isLoading;
}

const CategoryGroupList = ({ currentMonth, categoryGroups, currency, setTargetCategory, setTargetCategoryGroup, categories }) => {
  const categoryGroupProps = {
    currentMonth,
    setTargetCategory,
    setTargetCategoryGroup,
    currency
  };
  const categoryGroupListRef = useRef(null);

  const noGroupCategories = categories.filter(category => !category.is_income && !category.category_group_id);

  return (
    <Box width = "full" ref = { categoryGroupListRef }>
      <SimpleGrid 
        columns = {{ base: 3, sm: 4 }} 
        fontSize = {{ base: "sm", sm: "unset"}}
        py = "1"
        px = {{ base: 4, sm: 8 }}
        mb = "2"
        fontWeight = "semibold"
        opacity = "0.5"
      >
        <Text>Category</Text>
        <Text pr = "1" textAlign = "right">Budgeted</Text>
        <Text display = {{ base: "none", sm: "grid"}}  textAlign = "right">Activity</Text>
        <Text textAlign = "right">Remaining</Text>
      </SimpleGrid>

      <CategoryGroup
       { ...categoryGroupProps }
        categoryGroup = {{ name: "Income", is_income: true }}
        setTargetCategoryGroup = { null }
        categories = { categories.filter(category => category.is_income) }
        categoryGroupListRef = { categoryGroupListRef }
      />

      { categoryGroups?.map(categoryGroup => (
        <CategoryGroup
          { ...categoryGroupProps }
          categoryGroup = { categoryGroup }
          key = { categoryGroup.id }
          categories = { categories.filter(category => category.category_group_id === categoryGroup.id)}
          categoryGroupListRef = { categoryGroupListRef }
        />
      ))}

      { noGroupCategories?.length > 0 ? (
        <CategoryGroup
          { ...categoryGroupProps }
          categoryGroup = {{ name: "No Group", categories: noGroupCategories }}
          setTargetCategoryGroup = { null }
          categories = { noGroupCategories }
          categoryGroupListRef = { categoryGroupListRef }
        />
      ) : null }
    </Box>
  )
};

export default memo(CategoryGroupList, shouldNotReRender);
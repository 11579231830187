const Drawer = {
  baseStyle: props => ({
    closeButton: {
      borderRadius: "full",
    },
    overlay: {
      bg: props.colorMode === "dark" ? "transparent" : "grayAlpha.100",
      zIndex: 0
    },
    header: {
      fontWeight: "light"
    }
  })
};

export default Drawer;
import { useState } from "react";
import {
  Stack
} from "@chakra-ui/react";
import { AiFillBank } from 'react-icons/ai';
import { FaPiggyBank } from "react-icons/fa";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import * as Sentry from "@sentry/react";

import IconButton from "components/IconButton";
import Plaid from "components/Plaid";
import API from "utils/api";
import getDefaultBudget from "utils/getDefaultBudget";

const INSERT_PLAID_ITEM = gql`
  mutation InsertPlaidItem($plaid_item: plaid_items_insert_input!) {
    insert_plaid_items_one(
      object: $plaid_item
    ) {
      id
      plaid_institution {
        id
        name
        logo
      }
    }
  }
`;

const ADD_PLAID_INSTITUTION_LOGO = gql`
  mutation AddPlaidInstitutionLogo($plaid_institution_id: String!, $logo: String) {
    update_plaid_institutions_by_pk(
      pk_columns: { id: $plaid_institution_id },
      _set: { logo: $logo }
    ) {
      id
      name
      logo
    }
  }
`;

const PlaidAccountButton = ({ budgetId, createAccountCallback }) => {
  const [ isLoading, toggleIsLoading ] = useState(false);
  const [ linkToken, setLinkToken ] = useState(null);
  const [ insertPlaidItem ] = useMutation(INSERT_PLAID_ITEM);
  const [ addPlaidInstitutionLogo ] = useMutation(ADD_PLAID_INSTITUTION_LOGO);
  const api = new API();

  const fetchLinkToken = async () => {
    toggleIsLoading(true);

    const response = await api.getPlaidLinkToken();
    setLinkToken(response.link_token);
    window.analytics.track("Add Institution Portal Opened", {
      provider: "plaid"
    });
  }

  const onSuccess = async (token, metadata) => {
    toggleIsLoading(false);
    const response = await api.getPlaidAccessToken(token);
    const { institution } = metadata;

    const plaidItem = {
      id: response.item_id,
      access_token: response.access_token,
      plaid_institution: {
        data: {
          id: institution.institution_id,
          name: institution.name
        },
        on_conflict: {
          constraint: "plaid_institutions_pkey",
          update_columns: [ "name" ]
        }
      },
      budget_id: budgetId || getDefaultBudget()
    }

    const newItemResponse = await insertPlaidItem({
      variables: {
        plaid_item: plaidItem
      }
    });

    const newItem = newItemResponse.data.insert_plaid_items_one;
    const newItemInstitution = newItem.plaid_institution;

    if ( !newItemInstitution.logo ) {
      // Fetch from API
      const data = await api.getPlaidInstitution(institution.institution_id);

      // Update
      await addPlaidInstitutionLogo({
        variables: {
          plaid_institution_id: institution.institution_id,
          logo: data.logo
        }
      })
    }

    window.analytics.track("Institution Connected", {
      provider: "plaid",
      institution: institution.name
    });

    setLinkToken(null);
    createAccountCallback();
  }

  const onExit = (err, metadata) => {
    setLinkToken(null);
    if ( err ) {
      Sentry.captureException(err);
      console.log(err)
    }

    window.analytics.track("Add Institution Portal Closed", {
      provider: "plaid",
      has_error: !!err
    })
  }

  return (
    <>
      <IconButton
        label = "Linked Bank Account"
        Icon = { AiFillBank }
        description = "Automatically import your account balances and transactions. This feature is currently available for US and Canadian banks only."
        onClick = { fetchLinkToken }
        isLoading = { isLoading }
      />
      
      { linkToken ? <Plaid linkToken = { linkToken } onExit = { onExit } onSuccess = { onSuccess } /> : null }
    </>
  )
}

const SelectAccountType = ({ budgetId, onManualAccountClick, createAccountCallback }) => {
  return (
    <Stack direction = "column" spacing = "4">
      <PlaidAccountButton createAccountCallback = { createAccountCallback } budgetId = { budgetId }/>
      <IconButton
        label = "Manual Account"
        Icon = { FaPiggyBank }
        description = "Unable to link your account? Add a manual account that you can update at any time."
        onClick = { onManualAccountClick }
      />
    </Stack>
  )
};

export default SelectAccountType;
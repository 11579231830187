import { Button, Heading, HStack, useColorModeValue as mode } from '@chakra-ui/react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import moment from "moment-timezone";

import ShortcutTooltip from "components/ShortcutTooltip";
import useHotKeys from "hooks/useHotKeys";

const PlanHeader = ({ currentMonth, changeMonth }) => {
  useHotKeys('left', () => changeMonth(-1));
  useHotKeys('right', () => changeMonth(1));

  const buttonProps = {
    size: "lg",
    variant: "icon",
    shadow: mode("light.xs", "dark.xs")
  }

  return (
    <HStack spacing = "4" justifyContent = "center" mb = "4">
      <ShortcutTooltip label = "Previous month" keys = {['left']}>
        <Button
          { ...buttonProps }
          aria-label="Previous month" 
          onClick = { () => changeMonth(-1)}
        ><AiOutlineArrowLeft /></Button>
      </ShortcutTooltip>
      <Heading as="h4" size="md" fontWeight = "light">{ moment(currentMonth).format("MMMM YYYY") }</Heading>
      <ShortcutTooltip label = "Next month" keys = {['right']}>
        <Button
          { ...buttonProps }
          aria-label="Next month" 
          onClick = { () => changeMonth(1)}
        ><AiOutlineArrowRight /></Button>
      </ShortcutTooltip>
    </HStack>
  );
}

export default PlanHeader;
import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import DateField from "components/DateField";
import { FrequencySelect } from "components/Select";
import useToast from "hooks/useToast";
import { getNextDate } from "utils/calculations";
import { frequencyOptions } from "utils/options";

const moment = require("moment-timezone");

const UPDATE_START_DATE = gql`
  mutation updateStartDate($scheduled_transaction_id: uuid!, $start_date: String!, $next_date: String! ) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { start_date: $start_date, next_date: $next_date }
    ) {
      id
    }
  }
`;

const UPDATE_END_AFTER_DATE = gql`
  mutation UpdateEndAfterDate($scheduled_transaction_id: uuid!, $end_after_date: String ) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { end_after_date: $end_after_date }
    ) {
      id
    }
  }
`;

const UPDATE_FREQUENCY = gql`
  mutation UpdateFrequency($scheduled_transaction_id: uuid!, $frequency: String!, $next_date: String! ) {
    update_scheduled_transactions_by_pk (
      pk_columns: { id: $scheduled_transaction_id },
      _set: { frequency: $frequency, next_date: $next_date }
    ) {
      id
    }
  }
`;

const _Date = ({ scheduledTransaction }) => {
  const renderToast = useToast();
  const [ updateStartDate ] = useMutation(UPDATE_START_DATE);
  const [ updateEndAfterDate ] = useMutation(UPDATE_END_AFTER_DATE);
  const [ updateFrequency ] = useMutation(UPDATE_FREQUENCY);

  const onStartDateChange = async newDate => {
    const startDate = moment(newDate).format("YYYY-MM-DD");
    if ( startDate !== scheduledTransaction.start_date ) {
      const nextDate = getNextDate({ startDate, endDate: null, frequency: scheduledTransaction.frequency, include: true });

      await updateStartDate({
        variables: {
          scheduled_transaction_id: scheduledTransaction.id,
          start_date: moment(newDate).format("YYYY-MM-DD"),
          next_date: nextDate
        }
      });

      renderToast("Start Date Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "start_date"
      })
    }
  };

  const onFrequencyChange = async item => {
    if ( item.value !== scheduledTransaction.frequency ) {
      const { value: frequency } = item;
      const nextDate = getNextDate({ startDate: scheduledTransaction.start_date, endDate: null, frequency, include: true });

      await updateFrequency({
        variables: {
          scheduled_transaction_id: scheduledTransaction.id,
          frequency,
          next_date: nextDate
        }
      });

      renderToast("Frequency Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "frequency"
      })
    }
  };

  const onEndAfterDateChange = async newDate => {
    const endDate = newDate ? moment(newDate).format("YYYY-MM-DD") : null;

    if ( endDate !== scheduledTransaction.end_after_date ) {
      await updateEndAfterDate({
        variables: {
          scheduled_transaction_id: scheduledTransaction.id,
          end_after_date: endDate
        }
      })
  
      renderToast("End Date Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "end_after_date"
      })
    }
  }

  return (
    <Box width = "full">
      <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
        <Box display = "flex" alignItems = "center">
          <FormLabel mb = "0">Start Date</FormLabel>
        </Box>
        <DateField
          selected = { moment(scheduledTransaction.start_date).toDate() }
          filterDate = { date => scheduledTransaction.end_after_date ? date < moment(scheduledTransaction.end_after_date).toDate() : true }
          onChange = { onStartDateChange }
        />

        <Box display = "flex" alignItems = "center">
          <FormLabel mb = "0">End After</FormLabel>
        </Box>
        <DateField
          selected = { scheduledTransaction.end_after_date ? moment(scheduledTransaction.end_after_date).toDate() : null }
          filterDate = { date => date > moment(scheduledTransaction.next_date).toDate() && date > new Date() }
          isClearable = { true }
          onChange = { onEndAfterDateChange }
        />

        <Box display = "flex" alignItems = "center">
          <FormLabel mb = "0">Frequency</FormLabel>
        </Box>
        <FrequencySelect
          value = { frequencyOptions.find(option => option.value === scheduledTransaction.frequency ) }
          onChange = { onFrequencyChange }
          buttonLabel = "Select Frequency"
        />
      </Grid>
    </Box>
  )
};

export default _Date;
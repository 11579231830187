import {
  Box,
  Heading,
  HStack,
  List,
  ListItem,
} from "@chakra-ui/react";

import AddCategory from "../AddCategory";

const CategoryList = ({ categoryGroup, categories, setTargetCategory }) => (
  <Box width = "full">
    <HStack justifyContent = "space-between">
      <Heading as = "h3" fontSize = "md">Categories</Heading>
      <AddCategory categoryGroupId = { categoryGroup.id } isIncome = { false }/>
    </HStack>
    <List width = "full" my = "4">
      { categories.map(category => (
        <ListItem
          key = { category.id }
          _hover = {{ opacity: 1 }}
          opacity = "0.8"
          onClick = { () => setTargetCategory(category.id) }
          as = "button"
          width = "full"
          textAlign = "left"
          _focus = {{ boxShadow: "none", outline: "none" }}
          mb = "1"
        >{ category.name }</ListItem>
      ))}
    </List>
  </Box>
)

export default CategoryList;
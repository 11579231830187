import {
  Box,
  chakra,
  Text,
  VStack,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'

const IconButton = ({ label, Icon, description, onClick, isLoading, isDisabled = false }) => {
  const ButtonBox = chakra('button', {
    baseStyle: {
      borderWidth: '2px',
      px: '2',
      pt: '5',
      pb: '3',
      borderRadius: 'sm',
      transition: 'all 0.2s',
      _focus: { shadow: 'outline', boxShadow: "none", outline: "none" },
      _hover: { bg: mode("gray.200", "gray.800") },
      _disabled: { opacity: ".7" }
    },
  });
  
  return (
    <ButtonBox width = "full" onClick = { onClick } disabled = { isLoading || isDisabled }>
      <VStack spacing="4">
        <VStack textAlign="center">
          <Box aria-hidden fontSize="4xl" mb="3" textColor = "brand.500">
            <Icon />
          </Box>
          <Text fontWeight="extrabold" fontSize="xl">
            { isLoading ? "Loading..." : label }
          </Text>
          <Text fontSize="sm">{description}</Text>
        </VStack>
      </VStack>
    </ButtonBox>
  )
};

export default IconButton;
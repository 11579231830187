import * as Sentry from "@sentry/react";

const parseAuthError = error => {
    Sentry.setContext("Nhost Error", error?.response || error);
    try {
        const { data, config } = error?.response || { data: null, config: null };
        if ( data.message === '"ticket" must be a valid GUID' && config?.url === "/change-password/change") {
            return ({
                field: "new-password", message: "This link is invalid. Please request a new one.", code: "Invalid auth link"
            })
        } else if ( data.message === "Account does not exist." ) {
            return ({ field: "email", message: "Account does not exist.", code: "Account does not exist" })
        } else if ( data.message === "Username and password do not match" || data.message === "Incorrect current password." ) {
            return ({ field: "current-password", message: "Incorrect password", code: "Incorrect password" })
        } else if ( data.message === '"email" must be a valid email' ) {
            return ({ field: "email", message: "Invalid email", code: "Invalid email" })
        } else if ( data.message === "Account already exists." ) {
            return ({ field: "email", message: "Account already exists", code: "Account already exists"})
        } else {
            const errorObj = new Error("Unhandled Nhost error")
            Sentry.captureException(errorObj)
            return ({})
        }
    } catch (err) {
        Sentry.captureException(err);
        return {}
    }
};

export default parseAuthError;
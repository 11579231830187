import {
  FormControl,
  FormLabel,
  Textarea
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";

import { UPDATE_CATEGORY_NOTES } from "./gql";
import useToast from "hooks/useToast";

const CategoryNotes = ({ category }) => {
  const renderToast = useToast();
  const [ updateCategoryNotesMutation ] = useMutation(UPDATE_CATEGORY_NOTES);

  const onCategoryNotesChange = newNotes => {
    if ( newNotes !== category.notes || "" ) {
      updateCategoryNotesMutation({
        variables: {
          category_id: category.id,
          notes: newNotes
        }
      })
      .then(() => {
        renderToast("Notes Updated");
        window.analytics.track("Category Updated", {
          field: "notes"
        });
      })
    }
  }

  return (
    <FormControl>
      <FormLabel>Notes</FormLabel>
      <Textarea
        placeholder = "Category notes..."
        defaultValue = { category.notes || "" }
        onBlur = { e => onCategoryNotesChange(e.target.value) }
        rows = "3"
        borderRadius = "sm"
      />
    </FormControl>
  )
};

export default CategoryNotes;
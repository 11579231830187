import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import PopoutSelect from "./PopoutSelect";
import MultiSelectDropdown from "./MultiSelectDropdown";
import getDefaultBudget from "utils/getDefaultBudget";

const GET_CATEGORIES = gql`
  query GetCategories($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      category_groups(order_by: {name: asc}) {
        id
        value: id
        label: name
        options: categories(order_by: {name: asc}, where: {is_system: {_eq: false}}) {
          id
          value: id
          label: name
          is_income
        }
      }
      no_group: categories(order_by: {name: asc}, where: {is_system: {_eq: false}, is_income: { _eq: false }, category_group_id: {_is_null: true}}) {
        id
        value: id
        label: name
        is_income
      }
      income_categories: categories(where: { is_income: { _eq: true }}) {
        id
        value: id
        label: name
        is_income
      }
    }
  }
`;

const CategorySelect = ({ selectAll, isCreatable, onChange, value, isMulti, includeNoCategory = false, buttonLabel = "Select Category", shouldLoadData = false, tooltipText = "Select Category", defaultValues = [], isDisabled, filterFunction = () => true }) => {
  const budgetId = getDefaultBudget();
  const { refetch } = useQuery(GET_CATEGORIES, {
    variables: {
      budget_id: budgetId
    },
    skip: true
  });

  const loadOptions = async () => {
    if ( shouldLoadData ) {
      const { data } = await refetch();
      const categoryGroups = data?.budget.category_groups || [];
      const noGroupCategories = data?.budget.no_group || [];
      const incomeCategories = data?.budget.income_categories || [];
      const categories = ( includeNoCategory ? [{ label: "No Category", value: "none", id: 'none' }] : [] )
        .concat(incomeCategories.length > 0 ? { label: "Income", value: "income", options: incomeCategories.filter(filterFunction) } : [])
        .concat(categoryGroups.map(categoryGroup => ({ ...categoryGroup, options: categoryGroup.options.filter(filterFunction)})))
        .concat(noGroupCategories.length > 0 ? { label: "No Group", value: "noGroup", options: noGroupCategories.filter(filterFunction) } : []);

        return categories;
    }

    return [];
  }

  const props = {
    isCreatable,
    onChange,
    value,
    isDisabled,
    loadOptions,
    includeNoCategory
  }

  return isMulti ? (
    <MultiSelectDropdown
      label = {{ singular: "Category", plural: "Categories"}}
      isMulti = { true }
      selectAll = { selectAll }
      defaultValues = { defaultValues }
      { ...props }
    />
  ) : (
    <PopoutSelect
      shortcutKey = "#"
      tooltipText = { tooltipText }
      buttonLabel = { buttonLabel }
      { ...props }
    />
  );
};

export default CategorySelect;
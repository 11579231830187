import {
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  useBreakpointValue
} from "@chakra-ui/react";
import { useSubscription } from "@apollo/client";
import gql from "graphql-tag";

import Account from "./Account";
import LoadingSpinner from "components/LoadingSpinner";

const GET_ACCOUNT = gql`
  subscription GetAccount($account_id: uuid!) {
    account: accounts_by_pk(id: $account_id) {
      id
      name
      current_balance
      available_balance
      limit
      currency
      subtype
      type
      is_hidden
      sync_start_date
      plaid_item {
        id
        plaid_institution {
          id
          name
        }
        initial_update_completed_at
        historical_update_completed_at
        created_at
        error
      }
    }
  }
`;

const AccountDrawer = ({ isOpen, onClose, accountId }) => {
  const size = useBreakpointValue({ base: "full", md: "sm" });
  const { data: accountData } = useSubscription(GET_ACCOUNT, {
    variables: { account_id: accountId },
    skip: !accountId,
    fetchPolicy: "no-cache"
  });

  const account = accountData?.account;
  const isLoaded = account && account.id === accountId;

  const _onClose = () => {
    window.analytics.track("Account Drawer Closed");
    onClose();
  }

  return (
    <Drawer
      onClose = { _onClose }
      isOpen = { isOpen }
      placement = "right"
      size = { size }
    >
      <DrawerOverlay>
        <DrawerContent>
        <DrawerCloseButton />
          <DrawerHeader>Account</DrawerHeader>
          <DrawerBody>
            { isLoaded ? ( // Handles when previous account is stored in cashe
              <Account
                account = { account }
                onClose = { onClose }
              />
            ) : (
              <LoadingSpinner />
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
};

export default AccountDrawer;
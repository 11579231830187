import {
  Box,
  Button,
  FormLabel,
  Input,
  SimpleGrid,
  Text
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { BiArrowBack, BiBarChart } from "react-icons/bi";
import { FaBalanceScale } from "react-icons/fa";
import mousetrap from "mousetrap";

import { UPSERT_BUDGET_MONTH, GET_BUDGET_MONTHS } from "../gql";
import CurrencyInputField from "components/CurrencyInputField";
import FieldGroup from "components/FieldGroup";
import ShortcutTooltip from "components/ShortcutTooltip";
import useToast from "hooks/useToast";
import { formatCurrency } from "utils/format";

const Budget = ({ category, currentMonth }) => {
  const renderToast = useToast();
  const [ upsertBudgetMonth ] = useMutation(UPSERT_BUDGET_MONTH, {
    update: (cache, { data: { insert_budget_months_one }}) => {
      const data = cache.readQuery({ query: GET_BUDGET_MONTHS, variables: { budget_id: category.budget_id }});
      cache.writeQuery({
        query: GET_BUDGET_MONTHS,
        variables: { budget_id: category.budget_id },
        data: {
          budget_months: [ ...data.budget_months.filter(budgetMonth => budgetMonth.category_id !== category.id && budgetMonth.year_month !== currentMonth), insert_budget_months_one]
        }
      })
    }
  });

  const currentMonthBudget = category.currentMonth.budget;
  const currentMonthActivity = category.currentMonth.activity;
  const totalScheduledTransactions = category.currentMonth.scheduledTransactions;
  const rollover = category.is_rollover ? category.previousRollover.rollover : 0;
  const remaining = category.currentMonth.remaining; 
  const { lastMonthBudget, averageActivity } = category;

  const onSubmit = async (newValue, method) => {
    const newValueFloat = Math.round(newValue * 100) / 100;
    if ( newValueFloat !== currentMonthBudget ) {
      await upsertBudgetMonth({
        variables: {
          budget_month: {
            category_id: category.id,
            year_month: currentMonth,
            amount: newValueFloat || 0,
            budget_id: category.budget_id
          }
        }
      });
  
      renderToast("Budget Updated");
      window.analytics.track("Category Budget Updated", {
        method
      });
    }
  };

  const lastMonthBudgetDiff = parseFloat((lastMonthBudget - currentMonthBudget).toFixed(2));
  const trailingAverageDiff = parseFloat((averageActivity - currentMonthBudget).toFixed(2));
  const trueUpAmount = currentMonthBudget - remaining + ( category.is_income ? totalScheduledTransactions : -totalScheduledTransactions);
  const trueUpDiff = parseFloat((trueUpAmount - currentMonthBudget).toFixed(2));

  return (
    <Box width = "full">
      <SimpleGrid columns = {{ base: 1, sm: 2 }} mb = "4">
        <FormLabel mb = "0">Budgeted</FormLabel>
        <CurrencyInputField
          value = { currentMonthBudget }
          onSubmit = { newValue => onSubmit(newValue, "manual")}
          textAlign = "left"
          variant = "flushed"
          mb = "2"
          showButtons = { true }
        />

        <FormLabel mb = "0">Activity</FormLabel>
        <Input
          variant = "flushed"
          isReadOnly = { true }
          textAlign = "left"
          height = "unset" 
          value = { formatCurrency(currentMonthActivity) }
          mb = "2"
        />

        { category.is_rollover ? (
          <>
            <FormLabel mb = "0">Rollover</FormLabel>
            <Input
              variant = "flushed"
              isReadOnly = { true }
              textAlign = "left"
              height = "unset" 
              value = { formatCurrency(rollover) }
              mb = "2"
            />
          </>
        ) : null }

        <FormLabel mb = "0">Remaining</FormLabel>
        <Input
          variant = "flushed"
          isReadOnly = { true }
          textAlign = "left"
          height = "unset" 
          value = { formatCurrency(remaining) }
          mb = "2"
        />

      </SimpleGrid>

      <FieldGroup title = "Quick Budget">
        <ShortcutTooltip label = "Last Month Budget" keys = {['⌥', 'shift', 'L']}>
          <Button 
            isDisabled = { lastMonthBudgetDiff === 0 }
            onClick = { () => mousetrap.trigger("option+shift+l", 'click') }
            fontSize = "sm"
            width = "full"
          >
            <Box width = "15%"><BiArrowBack /></Box>
            <Text width = "50%">Last Month Budget: </Text>
            <Text width = "35%" textAlign = "right">{ formatCurrency(lastMonthBudget) }</Text>
          </Button>
        </ShortcutTooltip>

        <ShortcutTooltip label = { `Average ${category.is_income ? "Income" : "Spending"}` } keys = {['⌥', 'shift', 'A']}>
          <Button
            isDisabled = { trailingAverageDiff === 0 }
            onClick = { () => mousetrap.trigger("option+shift+a", 'click') }
            fontSize = "sm"
            width = "full"
          >
            <Box width = "15%" ><BiBarChart /></Box>
            <Text width = "50%">Average { category.is_income ? "Income" : "Spending" }</Text>
            <Text width = "35%" textAlign = "right">{ formatCurrency(averageActivity) }</Text>
          </Button>
        </ShortcutTooltip>

        <ShortcutTooltip label = "True Up" keys = {['⌥', 'shift', 'T']}>
          <Button
            isDisabled = { trueUpDiff === 0 }
            onClick = { () => mousetrap.trigger("option+shift+t", 'click') }
            fontSize = "sm"
            width = "full"
          >
            <Box width = "15%" ><FaBalanceScale /></Box>
            <Text width = "50%">True Up</Text>
            <Text width = "35%" textAlign = "right">{ formatCurrency(trueUpAmount) }</Text>
          </Button>
        </ShortcutTooltip>
      </FieldGroup>
    </Box>
  )
};

export default Budget;
import { useRef } from "react";
import {
  Table,
  Tbody,
  Thead,
  useColorModeValue as mode
} from "@chakra-ui/react";

import useHotKeys from "hooks/useHotKeys";

const TableComponent = ({ BodyRow, getTableProps, getTableBodyProps, headerGroups, HeaderRow, page, prepareRow, disableHotKeys, ...props }) => {
  const tbodyRef = useRef();

  useHotKeys('down', () => {
    if ( !disableHotKeys ) {
      const currentRow = tbodyRef.current?.querySelector('tr:focus');
      if ( currentRow ) {
        currentRow.nextElementSibling?.focus();
      } else {
        tbodyRef.current?.firstChild.focus();
      }
    }
  }, [ disableHotKeys, tbodyRef ]);

  useHotKeys('up', () => {
    if ( !disableHotKeys ) {
      const currentRow = tbodyRef.current?.querySelector('tr:focus');
      if ( currentRow ) {
        currentRow.previousElementSibling?.focus();
      } else {
        tbodyRef.current?.firstChild.focus();
      }
    }
  }, [ disableHotKeys, tbodyRef ]);

  useHotKeys('enter', e => {
    if ( !disableHotKeys ) {
      const currentRow = tbodyRef.current?.querySelector('tr:focus');
      currentRow.click();
  
      e.preventDefault();
      e.stopPropagation();
    }
  }, [ disableHotKeys, tbodyRef ]);

  return (
    <Table
      { ...getTableProps() }
      shadow = { mode("light.sm", "dark.sm") }
      style = {{ tableLayout: "fixed" }}
      { ...props }
    > 
      <Thead>
        { headerGroups.map((headerGroup, idx) => (
          <HeaderRow headerGroup = { headerGroup } key = { idx } />
        ))}
      </Thead>
      <Tbody { ...getTableBodyProps() } pb = "8" ref = { tbodyRef }>
        { page.map(row => {
          prepareRow(row);
          return (
            <BodyRow row = { row } key = { row.id } />
          )
        })}
      </Tbody>
    </Table>
  )
};

export default TableComponent;
import { useState, useEffect } from "react";
import {
  Editable,
  EditableInput,
  EditablePreview,
  useColorModeValue as mode
} from "@chakra-ui/react";

const LargeCurrencyInput = ({ currency, defaultValue, isDisabled, onChange, onSubmit, value, ...props }) => {
  const [ _value, setValue ] = useState(value || 0);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const _onChange = newValue => {
    const isValid = /^-?\d*(\.\d{0,2})?$/.test(newValue);
    if ( isValid ) {
      setValue(newValue)

      if ( onChange && !isNaN(newValue) ) {
        const newValueFloat = parseFloat(newValue || 0);
        onChange(newValueFloat);
      }
    }
  } 

  const _onSubmit = newValue => {
    const newValueFloat = parseFloat(newValue);
    if ( onSubmit ) {
      onSubmit(newValueFloat);
    }
  }

  return (
    <Editable
      value = { _value }
      defaultValue = { defaultValue ? defaultValue : value }
      onChange = { _onChange }
      isDisabled = { isDisabled }
      onSubmit = { _onSubmit }
      width = "full"
      height = "unset" 
      fontSize = "5xl"
      textAlign = "center"
      className = "mousetrap"
      variant = "unstyled"
      color = { (isNaN(parseFloat(value)) || parseFloat(value) === 0) ? "inherit" : (parseFloat(value) < 0 ? mode("red.300", "red.200") : mode("green.300", "green.200")) }
      { ...props }
    >
      <EditablePreview height = "unset" borderBottom = "none" />
      <EditableInput _focus = {{ borderBottom: "none" }} />
    </Editable>
  )
}

export default LargeCurrencyInput;
const Table = {
  parts: ['th', 'tr'],
  baseStyle: {
    th: {
      fontWeight: "semibold",
      opacity: "0.5",
      textAlign: "left",
      letterSpacing: "wider",
      py: "2",
      fontSize: {
        base: "sm",
        md: "unset"
      }
    }
  },
  variants: {
    styledFocus: props => ({
      tr: {
        borderLeft: {
          base: "2px",
          lg: "4px"
        },
        bg: "transparent",
        borderColor: {
          base: "transparent",
          lg: "transparent"
        },
        cursor: "pointer",
        _focus: {
          borderColor: "brand.400",
          bg: props.colorMode === "light" ? "gray.100" : "gray.900",
          outline: "none"
        },
        _hover: {
          borderColor: "brand.400",
          bg: props.colorMode === "light" ? "gray.100" : "gray.900",
          outline: "none"
        }
      }
    })
  }
};

export default Table;
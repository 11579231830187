import { Fragment } from "react";
import {
  Box,
  FormLabel,
  Grid,
  NumberInput,
  NumberInputField,
  Text
} from "@chakra-ui/react";

import { AccountSelect, CurrencySelect } from "components/Select";
import SymbolSearchInput from "components/SymbolSearchInput";

const headerStyles = {
  fontFamily: "heading",
  textTransform: "uppercase",
  fontSize: { base: "sm", md: "unset" },
  fontWeight: "semibold",
  opacity: "0.5",
  py: "3",
  textAlign: "right",
  display: "flex",
  alignItems: "end",
  justifyContent: "end"
}

const Edit = ({ uploadData, account, setAccount, currency, setCurrency, isOpen, updateHolding }) => {
  return (
    <Box mt = "4">
      <Grid templateColumns = "40% 60%" rowGap = "1" columnGap = "2">
        <FormLabel display = "flex" alignItems = "center" mb = "0">Account</FormLabel>
        <AccountSelect
          value = { account }
          onChange = { setAccount }
          shouldLoadData = { isOpen }
          filterFunction = { account => account.type === "investment" }
        />

        <FormLabel display = {{ base: "none", "lg": "flex"}} alignItems = "center" mb = "0">Cost Basis Currency</FormLabel>
        <FormLabel display = {{ base: "flex", "lg": "none"}} alignItems = "center" mb = "0">Currency</FormLabel>
        <CurrencySelect
          value = { currency }
          onChange = { setCurrency }
          shouldLoadDefaultCurrency = { isOpen }
        />
      </Grid>
      
      <FormLabel mt = "4">Upload Data</FormLabel>
      <Grid templateColumns = {{ base: "45% 25% 25%", md: "50% 25% 25%" }} rowGap = "1" columnGap = "2">
        <Text { ...headerStyles } textAlign = "left" justifyContent = "start">Symbol</Text>
        <Text { ...headerStyles }>Quantity</Text>
        <Text { ...headerStyles }>Cost Basis</Text>

        { uploadData?.map((data, index) => {
          return (
            <Fragment key = { index }>
              <SymbolSearchInput 
                value = { data.symbol }
                onChange = { newValue => updateHolding('symbol', index, newValue) }
              />
              <NumberInput
                placeholder = "0"
                variant = "flushed"
                precision = { 5 }
                defaultValue = { data.quantity }
                clampValueOnBlur = { true }
                min = { 0 }
                onBlur = { e => updateHolding('quantity', index, parseFloat(e.target.value)) }
                size = {{ base: "sm", md: "md" }}
              >
                <NumberInputField textAlign = "right"/>
              </NumberInput>

              <NumberInput
                placeholder = "0"
                variant = "flushed"
                precision = { 2 }
                defaultValue = { data.costBasis }
                clampValueOnBlur = { true }
                min = { 0 }
                onBlur = { e => updateHolding('costBasis', index, parseFloat(e.target.value)) }
                size = {{ base: "sm", md: "md" }}
              >
                <NumberInputField textAlign = "right"/>
              </NumberInput>
            </Fragment>
          )
        })}
      </Grid>
    </Box>
  )
};

export default Edit;
import gql from "graphql-tag";

const GET_DISTINCT_HOLDINGS = gql`
  query GetDistinctHoldings($budget_id: uuid!) {
    investment_holdings(where: {account: {is_hidden: {_eq: false}, budget_id: {_eq: $budget_id}}}, distinct_on: security_id) {
      security {
        symbol
      }
    }
  }
`;

export {
  GET_DISTINCT_HOLDINGS
}
import {
  Box
} from "@chakra-ui/react";

const Aside = ({ children, ...props }) => (
  <Box
    as = "aside"
    display = "var(--display-aside)"
    flex = "1"
    width = "var(--aside-width)"
    right = "0"
    py = "5"
    px = "3"
    position = "fixed"
    { ...props }
  >
    { children }
  </Box>
);

export default Aside;
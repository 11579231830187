import {
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  useBreakpointValue
} from "@chakra-ui/react";
import { useSubscription } from "@apollo/client";
import gql from "graphql-tag";

import ScheduleTransaction from "./ScheduledTransaction";
import LoadingSpinner from "components/LoadingSpinner";

const GET_SCHEDULED_TRANSACTION = gql`
  subscription GetScheduledTransaction($scheduled_transaction_id: uuid!) {
    scheduled_transaction: scheduled_transactions_by_pk(id: $scheduled_transaction_id) {
      id
      frequency
      account {
        id
        name
        type
        label: name
        value: id
      }
      amount
      next_date
      start_date
      end_after_date
      category {
        id
        name
        label: name
        value: id
      }
      currency
      notes
      payee {
        id
        name
        value: id
        label: name
      }
      transfer_account {
        id
        name
        value: id
        label: name
        type
      }
      needs_category
    }
  }
`;

const ScheduledTransactionDrawer = ({ isOpen, onClose, scheduledTransactionId }) => {
  const size = useBreakpointValue({ base: "full", md: "sm" });
  const { data: transactionData } = useSubscription(GET_SCHEDULED_TRANSACTION, {
    variables: { scheduled_transaction_id: scheduledTransactionId },
    skip: !scheduledTransactionId,
    fetchPolicy: "no-cache"
  });

  const scheduledTransaction = transactionData?.scheduled_transaction;
  const isLoaded = scheduledTransaction && scheduledTransaction.id === scheduledTransactionId;

  const _onClose = () => {
    window.analytics.track("Scheduled Transaction Drawer Closed");
    onClose();
  }

  return (
    <Drawer
      onClose = { _onClose }
      isOpen = { isOpen }
      placement = "right"
      size = { size }
    >
      <DrawerOverlay>
        <DrawerContent>
        <DrawerCloseButton />
          <DrawerHeader>Scheduled Transaction</DrawerHeader>
          <DrawerBody>
            { isLoaded ? ( 
              <ScheduleTransaction
                scheduledTransaction = { scheduledTransaction }
                onClose = { onClose }
              />
            ) : (
              <LoadingSpinner />
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
)
};

export default ScheduledTransactionDrawer;
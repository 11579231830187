import gql from "graphql-tag";

import { ALL_SCHEDULED_TRANSACTION_FIELDS } from "fragments";

const ADD_SCHEDULED_TRANSACTION = gql`
  ${ALL_SCHEDULED_TRANSACTION_FIELDS} 
  mutation AddScheduledTransaction($scheduled_transaction: scheduled_transactions_insert_input!) {
    insert_scheduled_transactions_one(object: $scheduled_transaction) {
      ...AllScheduledTransactionFields
    }
  }
`;

const DELETE_SCHEDULED_TRANSACTION = gql`
  mutation DeleteScheduledTransaction($scheduled_transaction_id: uuid!) {
    delete_scheduled_transactions_by_pk (
      id: $scheduled_transaction_id
    ) {
      id
    }
  }
`;

const GET_SCHEDULED_TRANSACTIONS = gql`
  ${ALL_SCHEDULED_TRANSACTION_FIELDS}
  query GetAllScheduledTransactions($budget_id: uuid!) {
    scheduled_transactions(where: { account: { is_hidden: { _eq: false }, budget_id: { _eq: $budget_id }}}, order_by: { next_date: asc }) {
      ...AllScheduledTransactionFields
    }
  }
`;

const UPDATE_NEXT_DATE = gql`
  mutation UpdateNextDate($scheduled_transaction_id: uuid!, $next_date: String!) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { next_date: $next_date }
    ) {
      id
    }
  }
`;

export {
  ADD_SCHEDULED_TRANSACTION,
  DELETE_SCHEDULED_TRANSACTION,
  GET_SCHEDULED_TRANSACTIONS,
  UPDATE_NEXT_DATE
}
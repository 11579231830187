import gql from "graphql-tag";

import { ALL_INVESTMENT_TRANSACTIONS_FIELDS } from "fragments";

const GET_INVESTMENT_TRANSACTIONS = gql`
  ${ ALL_INVESTMENT_TRANSACTIONS_FIELDS }
  query GetInvestmentTransactions($budget_id: uuid!) {
    investment_transactions(where: { account: { is_hidden: { _eq: false}, budget_id: { _eq: $budget_id }}}, order_by: { date: desc, id: desc }) {
      ...AllInvestmentTransactionFields
    }
  }
`;

export {
  GET_INVESTMENT_TRANSACTIONS
}
import {
  Box,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  ListItem,
  Text,
  UnorderedList,
  useTheme
} from "@chakra-ui/react";
import { CSVReader } from "react-papaparse";

import { AccountSelect } from "components/Select";

const Upload = ({ account, setAccount, isOpen, error, onFileUpload, onFileRemove }) => {
  const theme = useTheme();
  // const [ hasHeader, setHasHeader ] = useState(true);
  const hasHeader = true;
  return (
    <Box mt = "4">
      <Box>
        <Text>Upload a csv file with the following header columns:</Text>
        <UnorderedList>
          <ListItem>symbol</ListItem>
          <ListItem>quantity</ListItem>
          <ListItem>cost basis</ListItem>
        </UnorderedList>
      </Box>
      <Divider my = "4" />
      <Box experimental_spaceY = "2">
        <Box display = {{ base: "block", md: "flex" }} justifyContent = "space-between">
          <FormLabel width = {{ base: "full", md: "50%"}}>Investment Account</FormLabel>
          <AccountSelect
            value = { account }
            onChange = { setAccount }
            shouldLoadData = { isOpen }
            filterFunction = { account => account.type === "investment" }
          />
        </Box>

        <Box justifyContent = "space-between">
          <FormLabel>CSV File</FormLabel>
          <CSVReader
            onDrop = { onFileUpload }
            onError={ null }
            style = {{ dropArea: {
              borderColor: theme.colors.brand[400],
              borderRadius: 'var(--chakra-radii-sm)',
            },
            dropAreaActive: {
              borderColor: theme.colors.brand[600],
            },
            dropFile: {
              width: '100%',
              background: 'transparent',
              zIndex: 0,
              height: '100px'
            },
            fileSizeInfo: {
              display: 'none'
            },
            fileNameInfo: {
              borderRadius: 3,
              fontSize: 16,
              lineHeight: 1,
              background: "transparent"
            },
            removeButton: {
              color: theme.colors.gray[500],
              width: 'var(--close-button-size)',
              height: 'var(--close-button-size)',
              borderRadius: 'var(--chakra-radii-full)',
              fontSize: '12px'
            },
            progressBar: {
              backgroundColor: theme.colors.brand[400],
            } }}
            config={{
              header: hasHeader
            }}
            addRemoveButton
            onRemoveFile = { onFileRemove }
            skipEmptyLines = "greedy"
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
          {/* <Checkbox 
            mt = "2"
            isChecked = { hasHeader }
            onChange = { () => setHasHeader(prev => !prev) }
            colorScheme = "primary"
            _focus = {{
              boxShadow: "none"
            }}
          >My CSV has a header row</Checkbox> */}
        </Box>
        <FormControl isInvalid = { error.screen === "upload" }>
          <FormErrorMessage>{ error.message }</FormErrorMessage>
        </FormControl>
      </Box>
    </Box>
  )
};

export default Upload;
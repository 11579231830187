import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import moment from "moment-timezone";

import SpendingProgressBar from "components/SpendingProgressBar";
import { formatCurrency } from "utils/format";
import getDefaultBudget from "utils/getDefaultBudget";
import { getDatesBetween } from "utils/calculations";

const GET_CURRENT_MONTH_BUDGET = gql`
  query GetCurrentMonthBudget($budget_id: uuid!, $year_month: String!, $start_of_month: String!, $end_of_month: String!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      category_groups {
        categories {
          id
          is_income
          name
          budget_months(where: {year_month: {_eq: $year_month}}) {
          amount
          }
          transactions: transactions_by_month_aggregate(where: {year_month: {_eq: $year_month}}) {
            aggregate {
              sum {
                amount
              }
            }
          }
          scheduled_transactions(where: {_and: {next_date: {_gte: $start_of_month, _lte: $end_of_month}, account: {is_hidden: {_eq: false}}}}) {
            amount
            frequency
            next_date
            end_after_date
            start_date
          }
        }
        id
        name
      }
      categories(where: { category_group_id: { _is_null: true }}) {
        id
        is_income
        name
        budget_months(where: {year_month: {_eq: $year_month}}) {
          amount
        }
        transactions: transactions_by_month_aggregate(where: {year_month: {_eq: $year_month}}) {
          aggregate {
            sum {
              amount
            }
          }
        }
        scheduled_transactions(where: {_and: {next_date: {_gte: $start_of_month, _lte: $end_of_month}, account: {is_hidden: {_eq: false}}}}) {
          amount
          frequency
          next_date
          end_after_date
          start_date
        }
      }
    }
  }
`;

const afterDate = moment().startOf('month').format("YYYY-MM-DD");
const beforeDate = moment().endOf('month').format("YYYY-MM-DD");

const calculateTotalUpcomingTransactions = scheduledTransaction => {
  const { next_date, end_after_date, frequency, amount } = scheduledTransaction;
  const upcomingDates = getDatesBetween({
    startDate: next_date, 
    endDate: end_after_date, 
    frequency: frequency, 
    afterDate,
    beforeDate
  });

  return upcomingDates.length * amount
}

const CategoryProgressBar = ({  budgetedAmount, actualAmount, scheduledAmount, isIncome }) => (
  <SpendingProgressBar 
    budgeted = { budgetedAmount }
    actual = { actualAmount }
    scheduled = { scheduledAmount }
    isIncome = { isIncome }
  />
);

const CategoryProgress = ({ category }) => {
  const budgetedAmount = category.budget_months[0]?.amount || 0;
  const actualAmount = (category.transactions.aggregate.sum.amount || 0);
  const scheduledAmount = category.scheduled_transactions.reduce((total, st) => total + calculateTotalUpcomingTransactions(st), 0);

  return <ProgressTemplate label = { category.name } budgetedAmount = { budgetedAmount } actualAmount = { actualAmount } scheduledAmount = { scheduledAmount } isIncome = { category.is_income} />
}

const ProgressTemplate = ({ label, isHeader = false, budgetedAmount, actualAmount, scheduledAmount, isIncome }) => {
  const remaining = budgetedAmount + ( isIncome ? -actualAmount : actualAmount);
  const headerTextProps = isHeader ? { fontWeight: "bold", opacity: "0.8" } : {};
  const boxProps = isHeader ? { shadow: mode("light.md", "dark.md"), py: "4", border: "1px", borderColor: mode("gray.200", "gray.700") } : {};
  return (
    <Grid { ...boxProps } px = "2" templateColumns = {{ base: "1fr 1fr", md: "15% 1fr 20%" }} gridAutoFlow = {{ base: "dense", md: "unset" }} alignItems = "center" my = {{ base: "4", md: "2"}}>
      <GridItem><Text { ...headerTextProps }>{ label }</Text></GridItem>
      <GridItem gridColumn = {{ base: "1/3", md: "unset"}}>
        <CategoryProgressBar budgetedAmount = { budgetedAmount } actualAmount = { actualAmount } scheduledAmount = { scheduledAmount } isIncome = { isIncome }/>
      </GridItem>
      <GridItem><Text textAlign = "right">{ formatCurrency(remaining)}</Text></GridItem>
    </Grid>
  )
}

const Budget = () => {
  const budgetId = getDefaultBudget();
  const yearMonth = moment().format("YYYY-MM");
  const { data } = useQuery(GET_CURRENT_MONTH_BUDGET, {
    variables: {
      budget_id: budgetId,
      year_month: yearMonth,
      start_of_month: moment().startOf('month').format("YYYY-MM-DD"),
      end_of_month: moment().endOf('month').format("YYYY-MM-DD")
    }
  });
  const budget = data?.budget;

  const incomeCategories = budget?.categories.filter(category => category.is_income) || [];
  const totalIncomeBudget = incomeCategories.reduce((total, category) => total + (category.budget_months[0]?.amount || 0), 0);
  const totalActualIncome = incomeCategories.reduce((total, category) => total + ( category.transactions.aggregate.sum.amount || 0), 0);
  const totalIncomeScheduled = incomeCategories.reduce((total, category) => total + category?.scheduled_transactions.reduce((_total, st) => calculateTotalUpcomingTransactions(st) + _total, 0), 0);

  const expenseCategories = (budget?.categories.filter(category => !category.is_income) || [])
    .concat(budget?.category_groups.reduce((all, categoryGroup) => all.concat(categoryGroup.categories), []));

  const totalExpenseBudget = expenseCategories.reduce((total, category) => total + (category?.budget_months[0]?.amount || 0), 0);
  const totalActualExpenses = expenseCategories.reduce((total, category) => total + ( category?.transactions.aggregate.sum.amount || 0), 0);
  const totalExpensesScheduled = expenseCategories.reduce((total, category) => total + category?.scheduled_transactions.reduce((_total, st) => calculateTotalUpcomingTransactions(st) + _total, 0), 0);

  return (
    <Box>
      <Grid 
        py = "2" 
        my = "2" 
        rowGap = "2" 
        columnGap = "4"
        fontSize = "sm"
        gridTemplateColumns = "repeat(auto-fill, 200px)"
        columns = {{ base: 1, sm: 2, lg: 4 }}
      >
          <HStack spacing = "1">
            <Box width = "30px" height = "15px" backgroundColor = { mode("green.500", "green.700")} shadow = { mode("light.sm", "dark.sm") }></Box>
            <Text whiteSpace = "nowrap">Spent (Under Budget)</Text>
          </HStack>

          <HStack spacing = "1">
            <Box width = "30px" height = "15px" backgroundColor = { mode("yellow.500", "yellow.700")} shadow = { mode("light.sm", "dark.sm") }></Box>
            <Text whiteSpace = "nowrap">Upcoming Transactions</Text>
          </HStack>

          <HStack spacing = "1">
            <Box width = "30px" height = "15px" backgroundColor = { mode("red.500", "red.700")} shadow = { mode("light.sm", "dark.sm") }></Box>
            <Text whiteSpace = "nowrap">Spent (Over Budget)</Text>
          </HStack>

          <HStack spacing = "1">
            <Box width = "30px" height = "15px" backgroundColor = { mode("gray.100", "gray.600")} shadow = { mode("light.sm", "dark.sm") }></Box>
            <Text whiteSpace = "nowrap">Available</Text>
          </HStack>
      </Grid>

      <Grid px = "2" templateColumns = {{ base: "1fr 1fr", md: "15% 1fr 20%" }} gridAutoFlow = {{ base: "dense", md: "unset" }} alignItems = "center" my = {{ base: "4", md: "2"}}>
        <GridItem></GridItem>
        <GridItem gridColumn = {{ base: "1/3", md: "unset"}}></GridItem>
        <GridItem><Text textAlign = "right">Available</Text></GridItem>
      </Grid>


      <Box>
        <ProgressTemplate label = "Income" isHeader = { true } actualAmount = { totalActualIncome } budgetedAmount = { totalIncomeBudget } scheduledAmount = { totalIncomeScheduled } isIncome = { true } />
        <Box py = "4">
          { incomeCategories.map((category, index) => (
            <CategoryProgress category = { category } key = { index } />
          ))}
        </Box>
      </Box>

      <Box mt = "4">
      <ProgressTemplate label = "Expenses" isHeader = { true } actualAmount = { totalActualExpenses } budgetedAmount = { totalExpenseBudget } scheduledAmount = { totalExpensesScheduled } isIncome = { false } />
        { budget?.category_groups.map((group, index) => (
          <Box key = { index } py = "4" my = "2">
            <Text mb = "1" fontWeight = "medium">{ group.name }</Text>
            { group.categories.map((category, index) => (
              <CategoryProgress category = { category } key = { index } />
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  )
};

export default Budget;
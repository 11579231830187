import { useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  FormLabel,
  Grid,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import CurrencyInputField from "components/CurrencyInputField";
import { CurrencySelect } from "components/Select";
import { ALL_ACCOUNT_FIELDS } from "fragments";
import useToast from "hooks/useToast";
import { formatCurrency } from "utils/format";

const UPDATE_AMOUNT = gql`
  mutation UpdateAmount($transaction_id: uuid!, $amount: numeric!) {
    transaction: update_transactions_by_pk(
      pk_columns: { id: $transaction_id },
      _set: { amount: $amount, amount_converted: $amount } 
    ) {
      id
      amount
      __typename
    }
  }
`;

const UPDATE_CURRENCY = gql`
  mutation UpdateCurrency($transaction_id: uuid!, $currency: String!) {
    transaction: update_transactions_by_pk(
      pk_columns: { id: $transaction_id },
      _set: { currency: $currency } 
    ) {
      id
      currency
      __typename
    }
  }
`;

const INCREMENT_ACCOUNT_BALANCE = gql`
  ${ALL_ACCOUNT_FIELDS}
  mutation IncrementAccountBalance($account_id: uuid!, $amount: numeric!) {
    update_accounts_by_pk(
      pk_columns: { id: $account_id },
      _inc: { current_balance :$amount }
    ) {
      ...AllAccountFields
    }
  }
`;

const Amount = ({ transaction, onUpdate }) => {
  const { currency } = transaction;
  const renderToast = useToast();
  const [ updateAmount ] = useMutation(UPDATE_AMOUNT);
  const [ updateCurrency ] = useMutation(UPDATE_CURRENCY);
  const [ transactionAmountDiff, setTransactionAmountDiff ] = useState(0);
  const { isOpen: isAlertOpen, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
  const cancelRef = useRef();
  const [ incrementAccountBalanceMutation ] = useMutation(INCREMENT_ACCOUNT_BALANCE);

  const onUpdateAccountBalance = () => {
    incrementAccountBalanceMutation({
      variables: {
        account_id: transaction.account.id,
        amount: transactionAmountDiff
      }
    })
    .then(() => {
      onCloseAlert();
      renderToast("Account Balance Updated");
    })
  }

  const onAmountChange = async newValue => {
    const newAmountFloat = parseFloat(newValue);

    if ( newAmountFloat !== transaction.amount ) {
      setTransactionAmountDiff(newAmountFloat - transaction.amount);
      const updateAmountResponse = await updateAmount({
        variables: {
          amount: newAmountFloat,
          transaction_id: transaction.id
        }
      });

      onUpdate({ ...transaction, ...updateAmountResponse.data.transaction });

      renderToast("Amount Updated");
      window.analytics.track("Transaction Updated", {
        field: "amount"
      });

      if ( !transaction.plaid_id ) {
        onOpenAlert();
      }
    }
  };

  const onCurrencyChange = async item => {
    const { value: currency } = item;

    if ( currency !== transaction.currency ) {
      const updateCurrencyResponse = await updateCurrency({
        variables: {
          currency,
          transaction_id: transaction.id
        }
      });

      onUpdate({ ...transaction, ...updateCurrencyResponse.data.transaction });

      renderToast("Currency Updated");
      window.analytics.track("Transaction Updated", {
        field: "currency"
      })
    }
  }

  return (
    <>
      <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
        <Box display = "flex" alignItems = "center">
          <FormLabel>Amount</FormLabel>
        </Box>
        <CurrencyInputField
          value = { transaction.amount }
          currency = { currency }
          variant = "flushed"
          onSubmit = { onAmountChange }
          isDisabled = { !!transaction.plaid_id }
          showButtons = { !transaction.plaid_id }
        />

        { transaction.plaid_id ? null : (
          <>
            <Box display = "flex" alignItems = "center">
              <FormLabel>Currency</FormLabel>
            </Box>
            <CurrencySelect
              value = {{ label: transaction.currency, value: transaction.currency }}
              onChange = { onCurrencyChange }
            />
          </>
        )}
      </Grid>
      <AlertDialog
        isOpen = { isAlertOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onCloseAlert }
        size = "lg"
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Update Account Balance?</AlertDialogHeader>

          <AlertDialogBody>
            <Text>Your {transaction.account.name} account balance will change from { formatCurrency(transaction.account.current_balance, transaction.account.currency) } to { formatCurrency(transaction.account.current_balance + transactionAmountDiff, transaction.account.currency) }.</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Stack 
              direction = "column" 
              spacing = "2"
              width = "full"
            >
              <Button
                onClick = { onUpdateAccountBalance }
                width = {{ base: "full", sm: "unset" }}
                colorScheme = "primary"
              >Update Account Balance</Button>

              <Button
                ref = { cancelRef }
                onClick = { onCloseAlert }
                width = {{ base: "full", sm: "unset" }}
              >Don't Update Account Balance</Button>
            </Stack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
};

export default Amount;
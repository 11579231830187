import { useState, useEffect } from "react";
import {
  Editable,
  EditableInput,
  EditablePreview
} from "@chakra-ui/react";

import EditableControls from "./EditableControls";
import { formatCurrency } from "utils/format";

const CurrencyInputField = ({ currency, defaultValue, isDisabled, value, onChange, onSubmit, showButtons = false, textAlign = "left",...props }) => {
  const [ _value, setValue ] = useState(value || 0);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const _onChange = newValue => {
    const isValid = /^-?\d*(\.\d{0,2})?$/.test(newValue);
    if ( isValid ) {
      setValue(newValue)

      if ( onChange && !isNaN(newValue) ) {
        const newValueFloat = parseFloat(newValue || 0);
        onChange(newValueFloat);
      }
    }
  }

  const _onSubmit = newValue => {
    const newValueFloat = parseFloat(newValue || 0);
    setValue(newValueFloat);
    if ( onSubmit ) {
      onSubmit(newValueFloat);
    }
  }

  return (
    <Editable
      value = { isDisabled ? formatCurrency(_value, currency) : _value }
      defaultValue = { defaultValue ? defaultValue : value }
      onChange = { _onChange }
      isDisabled = { isDisabled }
      onSubmit = { _onSubmit }
      width = "full"   
      display = "flex"   
      { ...props }
    >
      <EditablePreview textAlign = { textAlign } />
      <EditableInput textAlign = { textAlign } />
      { showButtons ? <EditableControls /> : null }
    </Editable>
  )
};

export default CurrencyInputField;
 import {
  Box,
  Button,
  HStack,
  Text,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";
import moment from "moment-timezone";

import ShortcutTooltip from "components/ShortcutTooltip";
import useHotKeys from "hooks/useHotKeys";
import { getDatesBetween } from "utils/calculations";
import { formatCurrency } from "utils/format";

const SpendingPower = ({ scheduledTransactions, totalActualExpenses, totalBudgetedExpenses, currency, goToScreen, onComplete }) => {

  const afterDate = moment().startOf('month').format("YYYY-MM-DD");
  const beforeDate = moment().endOf('month').format("YYYY-MM-DD");

  const totalScheduledTransactions = scheduledTransactions
  .filter(transaction => transaction.needs_category && ( !transaction.category_id || !transaction.category.is_income ))
  .map(transaction => {
    const upcomingDates = getDatesBetween({ 
      startDate: transaction.next_date, 
      endDate: transaction.end_after_date, 
      frequency: transaction.frequency, 
      afterDate,
      beforeDate
    });

    return upcomingDates.length * transaction.amount
  })
  .reduce((total, curr) => total + curr, 0)

  const spendingPower = totalBudgetedExpenses + totalActualExpenses + totalScheduledTransactions;
  const daysRemaining = moment().endOf('month').diff(moment(), 'days') + 1;

  const spendingPowerPerDay = spendingPower / daysRemaining;

  useHotKeys('left', () => goToScreen("previous") );
  useHotKeys('enter', onComplete );

  return (
    <Box 
      width = "full" 
      shadow = {{ base: "none", md: mode("light.xl", "dark.xl")}} 
      px = {{ base: "4", md: "8" }} py = "8"
      bg = { mode("white", "gray.800") }
    >
      <VStack width = "full">
        <Text>Your remaining spending power for the month is</Text>
        <Text fontSize = "5xl" color = "primary.500" fontWeight = "medium">{formatCurrency(spendingPower, currency)}</Text>
        <Text textAlign = "center" width = "full">That's <span style = {{ fontWeight: "600" }}>{formatCurrency(spendingPowerPerDay, currency)}</span> per day for the month of { moment().format("MMMM")}.</Text>
      </VStack>
      <HStack justifyContent = "space-between" mt = "6">
        <ShortcutTooltip label = "Back" keys = {['left']}>
          <Button 
            colorScheme = "gray"
            onClick = { () => goToScreen("previous") }
            size = "sm"
            variant = "ghost"
          >Back</Button>
        </ShortcutTooltip>

        <ShortcutTooltip label = "Done" keys = {['enter']}>
          <Button
            colorScheme = "primary"
            onClick = { onComplete }
            size = "sm"
          >Done</Button>
        </ShortcutTooltip>
      </HStack>
    </Box>
  )
};

export default SpendingPower;
import {
  Heading,
  HStack
} from "@chakra-ui/react";

const PageHeader = ({ title, ...props }) => (
  <HStack px = {{ base: "2", md: "4", lg: "8" }} justifyContent = "space-between" alignItems = "center" my = "6" { ...props }>
    <Heading size = "lg" as = "h1">{ title }</Heading>
    { props.children }
  </HStack>
);

export default PageHeader;
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  useTheme
} from "@chakra-ui/react";
import { Line } from "react-chartjs-2";

import { formatCurrency, formatPercentage } from "utils/format";
import iex from "utils/iex";

const moment = require('moment-timezone');

const PriceHistory = ({ symbol }) => {
  const [ period, setPeriod ] = useState("1D");
  const [ prices, setPrices ] = useState({ hasData: true });
  const [ currentPrice, setCurrentPrice ] = useState(null);
  const [ startingPrice, setStartingPrice ] = useState(null);
  const [ isLoading, toggleIsLoading ] = useState(true);

  const theme = useTheme();

  useEffect(() => {
    if ( symbol ) {
      iex.batch({ symbol, types: ["chart", "price"], params: { range: "5dm" }})
      .then(resp => {
        const { chart, price } = resp;
        chart.forEach(c => c.datetimeMoment = moment(c.date + " " + c.minute))
        const lastDate = chart[chart.length - 1]?.date;
        const lastPrices = lastDate && lastDate !== undefined ? chart.filter(r => r.date === lastDate) : [];
        setPrices({
          hasData: lastPrices.length > 0,
          "1D": lastPrices,
          "1W": chart
        })
        setCurrentPrice(price);
        setStartingPrice(moment(lastDate).isSame(moment(), "d") ? lastPrices[0]?.close : lastPrices[lastPrices.length -1]?.close )
        toggleIsLoading(false);
      })
      .catch(err => console.log(err))
    }
  }, [ symbol ]);
  
  const switchPeriod = period => {
    const periodPrices = prices[period];
    if ( !periodPrices ) {
      iex.getPriceHistory({ symbol, range: period.toLowerCase()})
      .then(resp => {
        prices[period] = resp.map( r => ({ ...r, datetimeMoment: moment(r.minute ? r.date + " " + r.minute : r.date )}));
        setPrices(prices);
        setPeriod(period);
        setStartingPrice(resp[0].close);
      })
      .catch(err => console.log(err))
    } else {
      setPeriod(period)
      setStartingPrice(periodPrices[0].close);
    }
  }

  const periodReturn = currentPrice - startingPrice;
  const lineColor = periodReturn > 0 ? theme.colors.green[400] : theme.colors.red[400];
  const labels = prices[period]?.map(p => p.datetimeMoment);

  const chartData = {
    labels,
    datasets: [{
      data: prices[period]?.map(p => p.close),
      backgroundColor: lineColor,
      borderColor: lineColor,
      borderWidth: 2,
      fill: false,
      pointRadius: 0
    }]
  };

  const chartOptions = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        position: 'average',
        callbacks: {
          title: context => {
            const datetimeMoment = moment(parseInt(context[0].label));

            if ( period === "1D" ) {
              return datetimeMoment.format("LT")
            } else if ( period === "1W" ) {
              return datetimeMoment.format("dddd h:mm a")
            } else {
              return datetimeMoment.format("LL")
            }
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          callback: (value, index, values) => {
            const datetimeMoment = labels[index];
            const isStartOfYear = ( index === 0 && datetimeMoment.month() === 1 ) || ( index > 0 && datetimeMoment.year() !== labels[index - 1].year() )
            const isStartOfMonth = ( index === 0 && datetimeMoment.date() === 1 ) || ( index > 0 && datetimeMoment.month() !== labels[index - 1].month() )
            const isStartOfWeek = ( index === 0 && datetimeMoment.day() === 1 ) || ( index > 0 && datetimeMoment.week() !== labels[index - 1].week() )
            const isPointInWeek = ( index === 0 ) || ( index > 0 && datetimeMoment.day() !== labels[index - 1].day())
            const isStartOfHour = ( index === 0 && datetimeMoment.minute() === 0 ) || ( index > 0 && datetimeMoment.hour() !== labels[index - 1].hour() )
            
            if ( period === '5Y' && isStartOfYear ) {
              return datetimeMoment.format("YYYY")
            }

            if ( period === "1Y" && isStartOfMonth ) {
              return datetimeMoment.format("MMM")
            }

            if ( period === "1M" && isStartOfWeek ) {
              return datetimeMoment.format("MMM DD")
            }

            if ( period === "1W" && isPointInWeek ) {
              return datetimeMoment.format("dddd")
            }

            if ( period === "1D" && isStartOfHour ) {
              return datetimeMoment.format("LT")
            }
          }
        }
      }
    }
  }
  
  return (
    <Box width = "full" visibility = { isLoading ? "hidden" : "visible" }>
      <Stat>
        <StatLabel>Current Price</StatLabel>
        <StatNumber>{ formatCurrency(currentPrice) }</StatNumber>
        <StatHelpText display = { isNaN(periodReturn) ? "none" : "block"}>
          <StatArrow type = { periodReturn > 0 ? "increase" : "decrease" } />
          { formatPercentage(periodReturn / startingPrice, 2)} ( { formatCurrency(periodReturn) } )
        </StatHelpText>
      </Stat>
      <Box display = { prices.hasData ? "block" : "none" }>
        <Box width = "full" display = "flex" justifyContent = "flex-end">
          <ButtonGroup colorScheme = "primary" isAttached size = "sm" variant = "ghost">
            {["1D", "1W", "1M", "1Y", "5Y"].map(period_ => (
              <Button 
                rounded = "full"
                key = { period_}
                disabled = { period === period_ }
                onClick = { () => switchPeriod(period_) }
              >
                { period_ }
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Line 
          data = { chartData }
          options = { chartOptions }
        />
      </Box>
    </Box>
  )
};

export default PriceHistory;
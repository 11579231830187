import {
  Box
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import Transaction from "./Transaction";
import LoadingSpinner from "components/LoadingSpinner";
import { ALL_TRANSACTION_FIELDS } from "fragments";

const GET_TRANSACTION = gql`
  ${ALL_TRANSACTION_FIELDS}
  query GetTransaction($transaction_id: uuid!) {
    transaction: transactions_by_pk(
      id: $transaction_id
    ) {
      ...AllTransactionFields
    }
  }
`;

const TransactionDetails = ({ transaction, transactionId, onUpdate = () => null, onDeleteCallback = () => null }) => {
  const { data } = useQuery(GET_TRANSACTION, {
    variables: { transaction_id: transactionId },
    skip: transaction || !transactionId
  });

  const _transaction = transaction || data?.transaction;
  const isLoaded = transaction || (_transaction && _transaction.id === transactionId);

  return (
    <Box>
      { isLoaded ? ( 
      <Transaction
        transaction = { _transaction }
        onUpdate = { onUpdate }
        onDeleteCallback = { onDeleteCallback }
      />
      ) : (
      <LoadingSpinner />
      )}
    </Box>
  )
};

export default TransactionDetails;
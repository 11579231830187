import {
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  useBreakpointValue
} from "@chakra-ui/react";

import CategoryGroup from "./CategoryGroup";

const CategoryGroupDrawer = ({ isOpen, onClose, categoryGroup, categories, setTargetCategory }) => {
  const size = useBreakpointValue({ base: "full", md: "sm" });

  return (
    <Drawer
      onClose = { onClose }
      isOpen = { isOpen }
      placement = "right"
      size = { size }
    >
      <DrawerOverlay>
        <DrawerContent>
        <DrawerCloseButton />
          <DrawerHeader>Category Group</DrawerHeader>
          <DrawerBody>
            <CategoryGroup
              categoryGroup = { categoryGroup }
              categories = { categories }
              onClose = { onClose }
              setTargetCategory = { setTargetCategory }
            />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
};

export default CategoryGroupDrawer;
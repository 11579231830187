import { useEffect, useMemo, useState } from "react";
import { KBarProvider, 
  KBarPortal, 
  KBarPositioner, 
  KBarAnimator, 
  KBarSearch, 
  KBarResults,
  useMatches
} from "kbar";
import { 
  Box, 
  useColorModeValue as mode, 
  useStyleConfig, 
  Text, 
  useTheme, 
  css 
} from "@chakra-ui/react";
import { useAuth } from '@nhost/react-auth';

import actions from "./actions";
import API from "./API";
import ChangePassword from "./ChangePassword";
import ChangeTheme from "./ChangeTheme";
import CreateTransaction from "./CreateTransaction";
import KBarResultItem from "./KBarResultItem";
import Notifications from "./Notifications";
import QuickActionsContext from "./QuickActionsContext";
import QuickReview from "./QuickReview";
import useHotKeys from "hooks/useHotKeys";

const toCamelCase = string => string.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
const formatStylesObject = originalObj => {
  const formattedObj = {};
  
  for (const [key, value] of Object.entries(originalObj)) {
    formattedObj[key.includes('-') ? toCamelCase(key) : key ] = value
  }

  return formattedObj;
};

const RenderResults = ({ signedIn }) => {
  const groups = useMatches();
  const groupsSorted = groups.map(group => ({
    ...group,
    actions: group.actions.sort((a1, a2) => a1.name > a2.name ? 1: -1 )
      .filter(action => signedIn || !action.authRequired)
  }))
  .filter(group => group.actions.length > 0)

  const flattened = useMemo(
    () =>
      groupsSorted.reduce((acc, curr) => {
        acc.push(curr.name);
        acc.push(...curr.actions);
        return acc;
      }, []),
    [groupsSorted]
  );

  return (
    <KBarResults
      items = { flattened.filter((i) => i !== "none") }
      onRender = {({ item, active }) => 
        typeof item === "string"
          ? <Text 
              fontFamily = "heading"
              fontWeight = "semibold"
              textTransform = "uppercase"
              letterSpacing = "wider"
              opacity = "0.6"
              padding = "0 20px" 
              fontSize = "xs"
              py = "1"
              bg = { mode("gray.200", "gray.600")}
              >{ item }</Text>
          : <KBarResultItem item = { item } isActive = { active } />
      }
      maxHeight = { 300 }
    />
  );
};

const QuickActions = ({ defaultTheme, ...props }) => {
  const theme = useTheme();
  const modalStyleConfig = useStyleConfig("Modal", {});
  const inputStyleConfig = useStyleConfig("Input", {});
  const modalStyles = css(modalStyleConfig)(theme);
  const inputStyles = css(inputStyleConfig)(theme);
  const [ activeAction, setActiveAction ] = useState("");
  const [ header, setHeader ] = useState(null);

  const { signedIn } = useAuth();
  
  useEffect(() => {
    const qaFromQuery = new URLSearchParams(window.location.search).get('qa');
    if ( signedIn && qaFromQuery ) {
      setActiveAction(qaFromQuery)
    }
  }, [ signedIn ]);

  const goToPage = url => window.location.assign(url);
  const _setActiveAction = ( action, keyboard = false ) => {
    window.analytics.track("Quick Action Opened", {
      action,
      keyboard
    });
    
    setActiveAction(action);
  };

  const onClose = () => {
    window.analytics.track("Quick Action Closed");
    setActiveAction(null);
  };

  useHotKeys('t', () => {
    signedIn && _setActiveAction("new_transaction", true);
  }, [ signedIn ]);

  useHotKeys('t', () => {
    signedIn && _setActiveAction("new_transaction");
  }, [ signedIn], true, 'click');

  useHotKeys("r", () => {
    signedIn && _setActiveAction("quick_review", true);
  }, [ signedIn ]);

  useHotKeys('r', () => {
    signedIn && _setActiveAction("quick_review");
  }, [ signedIn ], true, 'click');

  useHotKeys("i t", () => {
    signedIn && goToPage('/insights/trends');
  }, [ signedIn ]);

  useHotKeys("b p", () => {
    signedIn && goToPage("/budget/plan");
  }, [ signedIn ]);

  useHotKeys("b t", () => {
    signedIn && goToPage("/budget/transactions");
  }, [ signedIn ]);

  useHotKeys("b a", () => {
    signedIn && goToPage('/budget/accounts');
  }, [ signedIn ]);

  useHotKeys("b s", () => {
    signedIn && goToPage('/budget/scheduled-transactions');
  }, [ signedIn ]);  

  useHotKeys("i p", () => {
    signedIn && goToPage('/invest/portfolio');
  }, [ signedIn ]);

  useHotKeys("i a", () => {
    signedIn && goToPage('/invest/activity');
  }, [ signedIn ]);

  useHotKeys("i r", () => {
    signedIn && goToPage('/invest/research');
  }, [ signedIn ]);

  const kbarActions = actions
    .map(action => ({ ...action, perform: action.command ? () => _setActiveAction(action.command) : action.perform }))
    .filter(action => signedIn || !action.authRequired)
  const transformedModalStyles = {
    ...modalStyles.dialog,
    width: "100%",
    maxWidth: "640px",
  };

  return (
    <KBarProvider actions = { kbarActions }>
      <KBarPortal>
        <KBarPositioner>
          <KBarAnimator style = {transformedModalStyles}> 
            <Box display = { header ? "inline-flex" : "none"} py = "2">
              <Text 
                fontFamily = "heading"
                fontWeight = "semibold"
                textTransform = "uppercase"
                letterSpacing = "wider"
                opacity = "0.8"
                padding = "0 20px" 
                fontSize = "sm"
                py = "1"
              >{ header }</Text>
            </Box>
            <KBarSearch 
              style = {{ ...formatStylesObject(inputStyles.field), borderWidth: "0", fontSize: "16px", padding: "12px 16px", height: "60px" }} 
              placeholder = "Type a command or search..."
            />
            <RenderResults signedIn = { signedIn } />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>

      <div>
        <API onClose = { onClose } isOpen = { activeAction === "api" } />
        <ChangePassword onClose = { onClose } isOpen = { activeAction === "change_password" } />
        <ChangeTheme onClose = { onClose } isOpen = { activeAction === "change_theme" } defaultTheme = { defaultTheme } />
        <CreateTransaction onClose = { onClose } isOpen = { activeAction === "new_transaction" } />
        <QuickReview onClose = { onClose } isOpen = { activeAction === "quick_review" } />
        <Notifications onClose = { onClose } isOpen = { activeAction === "notifications" } />
      </div>
      <QuickActionsContext.Provider
        value = {{ 
          closeQuickActions: () => setActiveAction(null),
          setHeader
        }}
      >
        { props.children }
      </QuickActionsContext.Provider>
    </KBarProvider>
  )
};

export default QuickActions;
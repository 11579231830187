import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack
} from "@chakra-ui/react";

import PasswordField from "components/PasswordField";
import useToast from "hooks/useToast";
import { auth } from "utils/nhost";
import parseAuthError from "utils/parseAuthError";

const ChangePassword = ({ onClose, isOpen }) => {
  const renderToast = useToast();
  const [ currentPassword, setCurrentPassword ] = useState("");
  const [ error, setError ] = useState({});
  const [ newPassword, setNewPassword ] = useState("");
  const [ isLoading, toggleIsLoading ] = useState(false);

  const isValid = currentPassword.length > 0 && newPassword.length >= 8;

  useEffect(() => {
    setCurrentPassword("");
    setNewPassword("");
    setError({});
    toggleIsLoading(false);
  }, [ isOpen ]);
  
  const onPasswordChange = e => {
    e.preventDefault();
    toggleIsLoading(true);

    auth.changePassword(currentPassword, newPassword)
    .then(() => {
      onClose();
      window.analytics.track("Password Changed");
      renderToast("Password Changed");
    })
    .catch(err => {
      toggleIsLoading(false);
      const parsedError = parseAuthError(err);
      setError(parsedError);

      window.analytics.track("Error Triggered", {
        error: parsedError.code
      });
    })
  }

  return (
    <Modal isOpen = { isOpen } onClose = { onClose } size = "xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Password</ModalHeader>
        <ModalCloseButton />
        <form onSubmit = { onPasswordChange }>
          <ModalBody>
            <Stack spacing = "4">
              <FormControl id = "current-password">
                <PasswordField 
                  isInvalid = { error.field === "current-password" }
                  errorMessage = { error.message }
                  autoComplete = "current-password"
                  id = "current-password"
                  label = "Current Password"
                  name = "current-password"
                  onChange = { e => setCurrentPassword(e.target.value) }
                  value = { currentPassword }
                  autoFocus
                />
              </FormControl>

              <FormControl id = "new-password">
                <PasswordField
                  isInvalid = { error.field === "new-password" }
                  errorMessage = { error.message }
                  autoComplete = "new-password"
                  helpText = "Password must be at least 8 characters"
                  id = "new-password"
                  label = "New Password"
                  name = "new-password"
                  onChange = { e => setNewPassword(e.target.value) }
                  value = { newPassword }
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter mt = "2">
            <Stack width = "full" direction = {{ base: "column", sm: "row" }} justifyContent = {{ base: "unset", sm: "flex-end"}}>
              <Button width = {{ base: "full", sm: "auto" }} size = "sm" onClick = { onClose }>Cancel</Button>
              <Button width = {{ base: "full", sm: "auto" }} size = "sm" isDisabled = { !isValid } isLoading = { isLoading } loadingText = "Saving..." colorScheme = "brand" type = "submit">Change Password</Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
};

export default ChangePassword;
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import axios from "axios";

import PopoutSelect from "./PopoutSelect";
import getDefaultBudget from "utils/getDefaultBudget";
import { currencyOptions } from "utils/options";

const GET_DEFAULT_CURRENCY = gql`
  query GetDefaultCurrency($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      default_currency
    }
  }
`;

const CurrencySelect = ({ value, defaultCurrency, onChange, shouldLoadDefaultCurrency = false, shouldLoadIPCurrency = false, buttonLabel = "Select Currency", tooltipText = "Select Currency", fieldDisplay = "label", isFullWidth, ...props }) => {
  const budgetId = getDefaultBudget();
  const { data: budgetData } = useQuery(GET_DEFAULT_CURRENCY, {
    variables: {
      budget_id: budgetId
    },
    skip: !!value || !shouldLoadDefaultCurrency || defaultCurrency || shouldLoadIPCurrency
  });

  useEffect(() => {
    if ( shouldLoadIPCurrency ) {
      axios.get(`https://api.ipdata.co/?api-key=${process.env.REACT_APP_IP_DATA_API_KEY}`)
      .then(response => {
        const currency = currencyOptions.find(option => option.value === response.data.currency.code);
        onChange(currency);
      })
      .catch(err => console.log(err.response))
    } else if ( budgetData ) {
      const currency = currencyOptions.find(option => option.value === budgetData.budget.default_currency);
      onChange(currency)
    } else if ( defaultCurrency ) {
      const currency = currencyOptions.find(option => option.value === defaultCurrency);
      onChange(currency)
    }
  }, [ budgetData, onChange, defaultCurrency, shouldLoadIPCurrency ]);

  return (
    <PopoutSelect
      shortcutKey = "$"
      tooltipText = { tooltipText }
      buttonLabel = { buttonLabel }
      onChange = { onChange}
      value = { value }
      options = { currencyOptions }
      fieldDisplay = { fieldDisplay }
      isFullWidth = { isFullWidth }
      { ...props }
    />
  )
};

export default CurrencySelect;
import gql from "graphql-tag";

export default gql`
  fragment AllScheduledTransactionFields on scheduled_transactions {
    __typename
    id
    account {
      id
      name
    }
    transfer_account {
      id
      name
    }
    amount
    currency
    category {
      id
      name
      is_income
    }
    payee {
      id
      name
    }
    frequency
    start_date
    end_after_date
    next_date
    needs_category
    notes
  }
`;
import { useMemo } from "react";
import {
  Box,
  Image,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

import BodyRow from "./BodyRow";
import HeaderRow from "./HeaderRow";
import Table from "components/Table";
import officeIcon from "images/icons/office.svg";
import { formatCurrency, formatPercentage } from "utils/format";

const HoldingsTable = ({ disableHotkeys, holdings }) => {
  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      id: "name",
      Cell: props => {
        const holding = props.row.original;
        return (
          <Box display = "flex" overflow = "hidden">
            <Box rounded = "full" height = "auto" mr = "2" display = "flex" alignItems = "center">
              <Image
                rounded = "sm"
                width = "40px"
                height = "auto"
                src = { holding.logo || officeIcon }
                shadow = { mode("light.md", "dark.md") }
                fallbackSrc = { officeIcon }
                m = "1"
              />
            </Box>
            <Box 
              overflow = "hidden"
              display = "flex"
              flexDir = "column"
              justifyContent = "center"
            >
              <Text 
                fontSize = "xs"
                opacity = {{ base: "1", md: "0.7" }}
              >{ holding.symbol }</Text>
              <Text display = {{ base: "none", md: "inline-table" }} fontSize = "sm">{ holding.name }</Text>
            </Box>
          </Box>
        )
      }
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      id: "quantity",
      Cell: props => <Text fontSize = "sm" textAlign = "right">{ props.value?.toFixed(2) }</Text>
    },
    {
      Header: "Value",
      accessor: "value",
      id: "value",
      Cell: props => <Text fontSize = "sm" textAlign = "right">{ formatCurrency(props.value) }</Text>
    },
    {
      Header: "Return",
      accessor: "return",
      id: "return",
      Cell: props => {
        const holding = props.row.original;
        return (
          <Box 
            overflow = "hidden"
            display = "flex"
            flexDir = "column"
            justifyContent = "center"
            color = { props.value > 0 ? mode("green.500", "green.200") : mode("red.500", "red.200")}
          >
            <Text 
              fontSize = "xs"
              opacity = "0.7"
              textAlign = "right"
            >{ formatPercentage(props.value, 1) }</Text>
            <Text textAlign = "right" fontSize = "sm">{ formatCurrency(holding.value - holding.costBasis, undefined, { signDisplay: "exceptZero"}) }</Text>
          </Box>
        )
      }
    },
    {
      Header: "Weight",
      accessor: "weight",
      id: "weight",
      Cell: props => <Text fontSize = "sm" textAlign = "right">{ formatPercentage(props.value, 1) }</Text>
    }
  ], []);

  const data = useMemo(() => holdings || [], [ holdings ]);
  
  const sortBy = useMemo(() => [
    { id: 'value', desc: true }
  ], []);

  return (
    <Box mt = "8">
      <Table
        columns = { columns }
        data = { data }
        BodyRow = { ({ row }) => <BodyRow row = { row } /> }
        HeaderRow = { HeaderRow }
        sortBy = { sortBy }
        filters = {[]}
        displayPagination = { data?.length > 10 }
        isLoading = { !holdings }
        variant = "styledFocus"
      />
    </Box>
  )
};

export default HoldingsTable;
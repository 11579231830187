import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  useDisclosure,
  Text,
  HStack
} from "@chakra-ui/react";
import moment from "moment-timezone";

import DateField from "components/DateField";
import FieldGroup from "components/FieldGroup";
import useToast from "hooks/useToast";
import API from "utils/api";

const api = new API();

const SyncData = ({ account }) => {
  const renderToast = useToast();
  const originalSyncStartDate = account.sync_start_date;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ selectedDate, setSelectedDate ] = useState(moment(originalSyncStartDate).toDate());
  const [ isSyncInitiated, toggleSyncInitiated ] = useState(false);

  const canDoHistoricalImport = !!account.plaid_item?.historical_update_completed_at;
  const canDoMoreInitialImport = moment(originalSyncStartDate).isAfter(moment(account.plaid_item.created_at).subtract(30, 'days'), 'day');

  useEffect(() => {
    toggleSyncInitiated(false)
    setSelectedDate(moment(originalSyncStartDate).toDate());
  }, [ originalSyncStartDate ]);

  const reset = () => {
    setSelectedDate(moment(originalSyncStartDate).toDate());
    onClose();
  }

  const filterDate = date => {
    if ( canDoHistoricalImport ) {
      return moment(date).isBefore(moment(originalSyncStartDate), 'day') && moment(date).isSameOrAfter(moment(originalSyncStartDate).subtract(3, 'months'), 'day');
    } else {
      return moment(date).isBefore(moment(originalSyncStartDate), 'day') && moment(date).isSameOrAfter(moment(account.plaid_item.created_at).subtract(30, 'days'), 'day');
    }
  };

  const onStartSync = () => {
    window.analytics.track("Account Sync Initiated", {
      provider: "plaid",
      months_diff: moment(originalSyncStartDate).diff(moment(selectedDate), 'months')
    });

    api.syncPlaidHistory(account.id, moment(selectedDate).format("YYYY-MM-DD"), originalSyncStartDate)
    toggleSyncInitiated(true);
    reset();
    renderToast("Sync Started", "Your data will be imported shortly.");
  }

  return (
    <FieldGroup title = "">
      <FormControl display = "flex" alignItems = "center">
        <FormLabel width = "60%" mb = "0">Sync Start Date</FormLabel>
        <DateField 
          selected = { selectedDate }
          open = { isOpen }
          onCalendarOpen = { onOpen }
          onCalendarClose = { onClose }
          shouldCloseOnSelect = { false }
          onChange = { setSelectedDate }
          onClickOutside = { reset }
          onFocus = { onOpen }
          filterDate = { filterDate }
          disabled = { isSyncInitiated || account.plaid_item.error }
        >
          <Box pb = "4">
            <Text my = "2" textAlign = "center" px = "2">{ canDoHistoricalImport ? "You can import up to the last three months of data.": ( canDoMoreInitialImport ? "You can import up to the last month of data." : "We're still fetching more data for this account. Check back shortly to import more transactions." ) }</Text>
            <HStack px = "1" justifyContent = "space-between">
              <Button onClick = { reset } variant = "ghost" size = "sm" >Cancel</Button>
              <Button 
                variant = "ghost" 
                colorScheme = "brand" 
                size = "sm"
                isDisabled = { moment(selectedDate).isSame(moment(originalSyncStartDate), 'day')}
                onClick = { onStartSync }
              >Start Sync</Button>
            </HStack>
          </Box>
        </DateField>
      </FormControl>
      { account.plaid_item.error ? <Text fontSize = "sm" variant = "helper">You will need to fix your { account.plaid_item.plaid_institution.name } connection before you can import any more data for this account.</Text> : null }    
    </FieldGroup>
  )
};

export default SyncData;
import {
  FormControl,
  Textarea
} from "@chakra-ui/react";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import useToast from "hooks/useToast";

const UPDATE_NOTES = gql`
  mutation UpdateNotes($scheduled_transaction_id: uuid!, $notes: String!) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { notes: $notes }
    ) {
      id
    }
  }
`;

const Notes = ({ scheduledTransaction }) => {
  const renderToast = useToast();
  const [ updateNotes ] = useMutation(UPDATE_NOTES);

  const onNotesChange = newNotes => {
    if ( newNotes !== scheduledTransaction.notes ) {
      updateNotes({
        variables: {
          scheduled_transaction_id: scheduledTransaction.id,
          notes: newNotes
        }
      });

      renderToast("Notes Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "notes"
      })
    }
  }

  return (
    <FormControl>
      <Textarea 
        placeholder = "Transaction notes..." 
        defaultValue = { scheduledTransaction.notes || "" }
        resize = "none"
        rounded = "sm"
        rows = "2"
        onBlur = { e => onNotesChange(e.target.value) }
      />
    </FormControl>
  )
};

export default Notes;
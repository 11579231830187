import {
  Button
} from "@chakra-ui/react";

import ShortcutTooltip from "components/ShortcutTooltip";

const HeaderButton = ({ buttonLabel, LeftIcon, onClick }) => (
  <>
    <Button
      variant = "ghost"
      colorScheme = "gray"
      leftIcon = { <LeftIcon /> }
      fontSize = "sm"
      onClick = { onClick }
      rounded = "sm"
      display = {{ base: "none", md: "inline-flex" }}
    >{ buttonLabel }</Button>

    <ShortcutTooltip label = { buttonLabel }>
      <Button 
        variant = "icon"
        onClick = { onClick }
        display = {{ base: "inline-flex", md: "none"}}
      ><LeftIcon /></Button>
    </ShortcutTooltip>
  </>
);

export default HeaderButton;
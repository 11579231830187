import { useEffect, useState } from "react";
import {
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormLabel,
  VStack
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import FieldGroup from "components/FieldGroup";
import { AccountTypeSelect } from "components/Select";
import useToast from "hooks/useToast";

const UPDATE_ACCOUNT_NAME = gql`
  mutation UpdateAccountName($id: uuid!, $name: String!) {
    update_accounts(_set: { name: $name }, where: { id: { _eq: $id }}) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_ACCOUNT_SUBTYPE = gql`
  mutation UpdateAccountSubtype($id: uuid!, $subtype: String!, $type: String!) {
    update_accounts(_set: { subtype: $subtype, type: $type }, where: { id: { _eq: $id }}) {
      returning {
        id
      }
    }
  }
`;

const AccountDetails = ({ account }) => {
  const renderToast = useToast();
  const originalName = account.name;

  const [ accountName, setAccountName ] = useState(originalName);
  const [ updateAccountName ] = useMutation(UPDATE_ACCOUNT_NAME);
  const [ updateAccountSubtype ] = useMutation(UPDATE_ACCOUNT_SUBTYPE);

  useEffect(() => {
    setAccountName(originalName);
  }, [ originalName ]);

  const onNameChange = newName => {
    if ( newName && newName !== account.name ) {
      updateAccountName({
        variables: {
          id: account.id,
          name: accountName
        }
      });

      renderToast("Account updated");
      window.analytics.track("Account Updated", {
        field: 'name'
      })
    } else {
      setAccountName(account.name);
    }
  };

  const onTypeChange = data => {
    const { type, subtype } = data;

    if ( type !== account.type || subtype !== account.subtype ) {
      updateAccountSubtype({
        variables: {
          id: account.id,
          subtype,
          type
        }
      });

      renderToast("Account updated");
      window.analytics.track("Account Updated", {
        field: 'type'
      })
    }
  }

  return (  
    <FieldGroup title = "Account Details">
      <VStack spacing = "2">
        <FormControl>
          <Editable 
            onCancel = { () => setAccountName(account.name) }
            width = "full"
            value = { accountName }
            submitOnBlur = { true }
            onSubmit = { onNameChange }
            onChange = { setAccountName }

          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </FormControl>
        <FormControl display = "flex" alignItems = "center">
          <FormLabel width = "60%" mb = "0">Account Type</FormLabel>
          <AccountTypeSelect
            accountType = { account.type }
            accountSubtype = { account.subtype }
            onChange = { onTypeChange }
            isDisabled = { account.plaid_item }
          />
        </FormControl>
      </VStack>
    </FieldGroup>
  )
};

export default AccountDetails;
import { useEffect, useState } from "react";
import { useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { Container } from "@chakra-ui/react";

import AddAccount from "./AddAccount";
import InstitutionsList from "./InstitutionsList";
import AccountDrawer from "components/AccountDrawer";
import NullState from "components/NullState";
import { Page, Main, Header } from "components/Page";
import creditCardIcon from "images/icons/credit-card.svg";
import getDefaultBudget from "utils/getDefaultBudget";

const GET_ACCOUNTS = gql`
  subscription GetAccounts($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      default_currency
      manual_accounts: accounts(where: {plaid_item_id: {_is_null: true}}, order_by: {name: asc}) {
        id
        name
        current_balance
        available_balance
        subtype
        type
        currency
      }
      plaid_items(order_by: {plaid_institution: {name: asc}}) {
        id
        error
        plaid_institution {
          id
          name
          logo
        }
        synced_at
        access_token
        accounts(order_by: { name: asc }) {
          id
          name
          sync_start_date
          current_balance
          available_balance
          subtype
          type
          currency
        }
      }
    }
  }
`;

const Accounts = () => {
  const budgetId = getDefaultBudget();
  const [ targetAccount, setTargetAccount ] = useState(null);
  const { data, loading } = useSubscription(GET_ACCOUNTS, { variables: { budget_id: budgetId }});

  const budget = data?.budget;
  const manualAccounts = budget?.manual_accounts;
  const plaidItems = budget?.plaid_items;

  useEffect(() => {
    window.analytics.page("Accounts");
  }, []);

  return (
    <Page>
      <Main isLoading = { loading }>
        <Header title = "Accounts"><AddAccount budgetId = { budgetId }/></Header>
        <Container maxW = "container.xl">
          { manualAccounts?.length > 0 || plaidItems?.length > 0 ? (
            <>
              <InstitutionsList
                manualAccounts = { manualAccounts }
                plaidItems = { plaidItems }
                setTargetAccount = { setTargetAccount }
              />

              <AccountDrawer 
                isOpen = { !!targetAccount }
                onClose = { () => setTargetAccount(null) }
                accountId = { targetAccount }
              />
            </>
          ) : (
            <NullState
              icon = { creditCardIcon }
              message = "Get started by adding your first account."
              mt = "8"
            />
          )}
        </Container>
      </Main>
    </Page>
  )
};

export default Accounts;
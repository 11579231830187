import { Box, Heading, Stack, useColorModeValue as mode } from '@chakra-ui/react'

const FieldGroup = ({ title, children, Icon, titleHref, ...props }) => (
  <Stack 
    direction = "column" 
    width = "full" spacing = "2" 
    {...props }
    border = "1px"
    py = "4"
    px = "4"
    rounded = "sm"
    borderColor = { mode("gray.200", "gray.500") }
  >
    <Box 
      display = "flex" 
      minW="3xs" 
      width = "full" 
      alignItems = "center"
      as = { titleHref ? "a" : "div" }
      href = { titleHref }
    >
      { Icon && <Box height = "full" mr = "2"><Icon /></Box> }
      { title && (
        <Heading as="h4" fontSize="md" flexShrink={0}>
          { title }
        </Heading>
      )}
    </Box>
    { children }
  </Stack>
);

export default FieldGroup;
import {
  VStack
} from "@chakra-ui/react";

import FieldGroup from "components/FieldGroup";
import { Aside } from "components/Page";
import { AccountSelect, ActivityTypeSelect } from "components/Select";

const ActivityAside = ({ setFilters }) => {
  const _setFilters = (filter, items ) => {
    window.analytics.track("Investment Activity Filtered", {
      filter
    });

    setFilters(filter, items);
  };

  return (
    <Aside>
      <FieldGroup title = "Filters" pt = "6">
        <VStack spaving = "2">
          <AccountSelect 
            isMulti = { true }
            onChange = { items => _setFilters("account", items.map(item => item.value)) }
            shouldLoadData = { true }
            filterFunction = { account => account.type === "investment" }
          />

          <ActivityTypeSelect
            isMulti = { true }
            onChange = { items => _setFilters("type", items.map(item => item.value)) }
          />
        </VStack>
      </FieldGroup>
    </Aside>
  )
};

export default ActivityAside;
import {
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

import { useQuery } from "@apollo/client";

import { GET_UNCAT_TRANSACTIONS } from "./gql";
import StatCard from "components/StatCard";
import getDefaultBudget from "utils/getDefaultBudget";

const UncatTransactions = () => {
  const budgetId = getDefaultBudget();
  const { data, loading } = useQuery(GET_UNCAT_TRANSACTIONS, {
    variables: {
      budget_id: budgetId
    },
    pollInterval: 60000
  });

  const count = data?.transactions.filter(transaction => transaction.needs_category).length; // Currently unable to use computer field in query filter

  return (
    <StatCard
      label = {`Uncategorized Transaction${count !== 1 ? "s" : ""}`}
      onClick = { () => window.location.assign("/budget/transactions?category=none") }
      isLoading = { loading && !data }
    >
      <Text 
        as = "span"
        color = { mode('gray.800', 'white') }
        fontSize = "3xl"
        fontWeight = "semibold"
        lineHeight = "1"
      >
        { count || 0 }
      </Text>
    </StatCard>
  )
};

export default UncatTransactions;
import { useEffect, useState } from "react";
import { AiOutlineFilter } from "react-icons/ai";

import ActivityAside from "./ActivityAside";
import ActivityTable from "./ActivityTable";
import { Main, Page, Header } from "components/Page";

const Activity = () => {
  const [ filters, setFilters ] = useState([]);
  const _setFilters = (id, value) => {
    setFilters(prev => prev.filter(filter => filter.id !== id).concat({ id, value}));
  };

  useEffect(() => {
    window.analytics.page("Invest Activity");
  }, []);

  return (
    <Page>
      <ActivityAside setFilters = { _setFilters } />
      <Main
        asideButtonLabel = "Filter Activity"
        AsideButtonIcon = { AiOutlineFilter }
        asideShortcutKey = "f"
        hasAside = { true }
      >
        <Header title = "Activity"></Header>
        <ActivityTable 
          filters = { filters }
        />
      </Main>
    </Page>
  )
};

export default Activity;
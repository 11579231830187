import { 
  Box, 
  Flex, 
  Text, 
  Tooltip,
  useColorModeValue as mode 
} from '@chakra-ui/react'

import { formatCurrency } from "utils/format";

const BudgetStat = ({ label, amount, tooltipStats, ...props }) => (
  <Tooltip isDisabled = { !tooltipStats } label = {
    <Box py = "2">
      { tooltipStats?.map((tooltipStat, index) => (
        <Box display = "flex" justifyContent = "space-between" key = { index }>
          <Text mr = "4">{ tooltipStat.label }</Text>
          <Text>{ formatCurrency(tooltipStat.value) }</Text>
        </Box>
      ))}
    </Box>
  }
  >
    <Flex
      direction="column"
      align="center"
      p="6"
      rounded = "sm"
      color={ mode('gray.500', 'gray.400') }
      textAlign="center"
      width = "full"
      { ...props }
    >
      <Box>
        <Text 
          as = "span"
          color = { mode('gray.800', 'white') }
          fontSize = "2xl"
          fontWeight = "normal"
          lineHeight = "1"
        >
          { formatCurrency(amount) }
        </Text>
        <Text mt = "2" fontSize="xs" fontWeight="bold" textTransform="uppercase" letterSpacing="wide">
          { label }
        </Text>
      </Box>
    </Flex>
  </Tooltip>
);

export default BudgetStat;

import {
  Th,
  Tr
} from "@chakra-ui/react";

const ThProps = {
  scope: "col",
  px: { base: "2", md: "4"},
  fontSize: { base: "xs", md: "sm"}
};

const HeaderRow = ({ headerGroup }) => {
  const renderHeader = headerName => headerGroup.headers.find(column => column.Header === headerName).render('Header');
  const getHeaderProps = headerName => headerGroup.headers.find(column => column.Header === headerName).getHeaderProps();

  return (
    <Tr { ...headerGroup.getHeaderGroupProps() }>
      <Th
        { ...getHeaderProps('Name') }
        { ...ThProps }
        width = {{ base: "150px", sm: "200px", md: "375px"}}
      >{ renderHeader('Name') }</Th>
      <Th
        { ...getHeaderProps('Quantity') }
        { ...ThProps }
        textAlign = "right"
        display = {{ base: "none", sm: "table-cell"}}
      >{ renderHeader('Quantity') }</Th>
      <Th
        { ...getHeaderProps('Value') }
        { ...ThProps }
        textAlign = "right"
      >{ renderHeader('Value') }</Th>
      <Th
        { ...getHeaderProps('Return') }
        { ...ThProps }
        textAlign = "right"
      >{ renderHeader('Return') }</Th>
      <Th
        { ...getHeaderProps('Weight') }
        { ...ThProps }
        textAlign = "right"
        display = {{ base: "none", sm: "table-cell"}}
      >{ renderHeader('Weight') }</Th>
    </Tr>
  )
};

export default HeaderRow;
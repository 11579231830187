import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Stack,
  useColorModeValue as mode
} from "@chakra-ui/react";

import PasswordField from "components/PasswordField";
import { auth } from "utils/nhost";
import parseAuthError from 'utils/parseAuthError';

const ChangePasswordForm = ({ verificationCode, callback }) => {
  const [ password, setPassword ] = useState("");
  const [ error, setError ] = useState({});
  const [ isLoading, toggleLoading ] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    toggleLoading(true);

    auth.confirmPasswordChange(password, verificationCode)
    .then(() => {
      window.analytics.track("Password Reset");
      callback();
    })
    .catch(err => {
      toggleLoading(false);
      const parsedError = parseAuthError(err);
      setError(parsedError);

      window.analytics.track("Error Triggered", {
        error: parsedError.code
      })
    })
  }

  return (
    <Box 
      bg = {{ base: "transparent", sm: mode("white", "gray.800") }}
      rounded = "sm" 
      shadow = {{ base: "none", sm: "xl" }}
      width = {{ base: "full", sm: "md" }}
      py = {{ base: "2", sm: "6"}}
      px = {{ base: "4", sm: "6" }}
    >
      <form onSubmit = { onSubmit }>
        <Stack spacing = "4" py = "4">
          <FormControl id = "password">
            <PasswordField 
              isInvalid = { error.field === "new-password" }
              errorMessage = { error.message }
              autoComplete = "new-password"
              helpText = "Password must be at least 8 characters"
              id = "password"
              label = "New Password"
              name = "new-password"
              onChange = { e => setPassword(e.target.value) }
              value = { password }
            />
          </FormControl>
          <Button
            colorScheme = "primary"
            loadingText = "Updating Password..."
            fontSize = "md"
            isDisabled = { !password.length >= 8 }
            isLoading = { isLoading }
            type = "submit"
            width = "full"
          >Reset Password</Button>
        </Stack>
      </form>
    </Box>
  );
}

export default ChangePasswordForm;
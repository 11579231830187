import {
  Box,
  CloseButton,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

const FullToast = ({ title, onClose, message, ...props }) => (
  <Box 
    width = {{ base: "100vw", md: "80vw" }}
    shadow = { mode("light.lg", "dark.lg") } 
    bg = { mode("white", "gray.600") } p = "3" rounded = "sm" borderLeftWidth = "4px" borderLeftColor = "brand.400">
    <Box display = "flex" justifyContent = "space-between">
      { title ? <Text fontSize = "sm" display = "flex" flexWrap = "wrap" fontWeight = "medium">{ title }</Text> : null }
      <Box display = "flex">
        <Text mr = "4" fontSize = "sm">{ message }</Text>
        <CloseButton onClick = { onClose } size = "sm" />
      </Box>
    </Box>
  </Box>
);

export default FullToast;
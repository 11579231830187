import {
  Box,
  FormControl,
  FormLabel,
  Switch
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import useToast from "hooks/useToast";

const UPDATE_IS_ROLLOVER = gql`
  mutation ToggleIsRollover($id: uuid!, $is_rollover: Boolean!) {
    update_categories_by_pk(
      pk_columns: { id: $id },
      _set: { is_rollover: $is_rollover }
    ) {
      id,
      is_rollover
    }
  }
`;

const IsRolloverToggle = ({ category }) => {
  const renderToast = useToast();
  const [ updateIsRollover ] = useMutation(UPDATE_IS_ROLLOVER);

  const toggleIsRollover = async newValue => {
    await updateIsRollover({
      variables: {
        id: category.id,
        is_rollover: newValue
      }
    })

    renderToast("Category Updated");
    window.analytics.track("Category Updated", {
      field: "is_rollover"
    });
  }

  return (
    <Box mt = "2" display = { category.is_income ? "none" : "block"}>
      <FormControl display = "flex" alignItems = "center">
        <Switch
          id = "is-rollover"
          onChange = { e => toggleIsRollover(e.target.checked) }
          colorScheme = "brand"
          defaultChecked = { category.is_rollover }
          isDisabled = { category.is_income }
        />
        <FormLabel mb = "0" fontWeight = "normal" ml = "2" htmlFor = "is-income">Rollover budget?</FormLabel>
      </FormControl>
    </Box>
  )
};

export default IsRolloverToggle;
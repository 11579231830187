import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import PopoutSelect from "./PopoutSelect";
import MultiSelectDropdown from "./MultiSelectDropdown";
import getDefaultBudget from "utils/getDefaultBudget";

const GET_PAYEES_AND_ACCOUNTS = gql`
  query GetPayeesAndAccounts($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      accounts(order_by: { name: asc }) {
        id
        label: name
        value: id
        type
        __typename
      }
      payees(order_by: { name: asc }) {
        id
        label: name
        value: id
        __typename
      }
    }
  }
`;


const PayeeSelect = ({ isDisabled, isCreatable, filterFunction = () => true, onChange, value, isMulti, transferDirection = null, buttonLabel = "Select Payee", shouldLoadData = false, tooltipText = "Select Payee" }) => {
  const budgetId = getDefaultBudget();
  const { refetch } = useQuery(GET_PAYEES_AND_ACCOUNTS, {
    variables: {
      budget_id: budgetId
    },
    skip: true
  });

  const loadOptions = async () => {
    if ( shouldLoadData ) {
      const { data } = await refetch();
      const payees = data?.budget.payees || [];
      const accounts = (data?.budget.accounts || []).filter(filterFunction);
      const options = [
        { label: "Transfer", value: "transfer", options: accounts.map(account => ({ ...account, label: `Transfer${transferDirection ? (transferDirection === "incoming" ? " from" : " to"): ""}: ${account.label}` })) },
        { label: "Payees", value: "payees", options: payees }
      ];

      return options;
    }

    return [];
  }

  const props = {
    isCreatable,
    onChange,
    value,
    loadOptions,
    isDisabled
  }

  return isMulti ? (
    <MultiSelectDropdown
      label = {{ singular: "Payee", plural: "Payees"}}
      isMulti = { true }
      { ...props }
    />
  ) : (
    <PopoutSelect 
      shortcutKey = "@" 
      tooltipText = { tooltipText}
      buttonLabel = { buttonLabel }
      { ...props } 
    />
  );
};

export default PayeeSelect;
import {
  Box,
  FormLabel
} from "@chakra-ui/react";

import CurrencyInputField from "components/CurrencyInputField";

const AvailableBalanceField = ({ account }) => {
  const { available_balance, current_balance, currency } = account;

  return available_balance && available_balance !== current_balance ? (
    <>
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Available</FormLabel>
      </Box>
      <CurrencyInputField 
        value = { available_balance }
        currency = { currency }
        variant = "flushed"
        disabled = { true }
      />
    </>
  ) : null;
};

export default AvailableBalanceField;
import { useMemo } from "react";
import {
  Box
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

import BodyRow from "./BodyRow";
import HeaderRow from "./HeaderRow";
import NullState from "components/NullState";
import Table from "components/Table";
import { GET_TRANSACTIONS } from "gql/transactions";
import shoppingCartIcon from "images/icons/shopping-cart.svg";
import { formatCurrency } from "utils/format";
import getDefaultBudget from "utils/getDefaultBudget";

const TransactionsTable = ({ setFocusTransaction, disableHotKeys, filters }) => {
  const budgetId = getDefaultBudget();
  const { data: transactionsData } = useQuery(GET_TRANSACTIONS, {
    variables: {
      budget_id: budgetId
    }
  });

  const data = useMemo(() => transactionsData?.transactions.map(transaction => ({
    ...transaction,
    payee: {
      ...transaction.payee,
      name: transaction.transfer_account ? `Transfer ${ transaction.amount < 0 ? "to" : "from" }: ${transaction.transfer_account.name}` : transaction.payee?.name
    },
    category: {
      ...transaction.category,
      name: transaction.transfer_account && !transaction.needs_category ? "Transfer" : transaction.category?.name
    }
  })) || [], [ transactionsData ]);


  const columns = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'authorized_at',
      id: 'date',
      Cell: props => <span>{ moment(props.value).format("MMM D") }</span>
    },
    {
      Header: "Payee",
      accessor: "payee.id",
      id: "payee",
      Cell: props => {
        const transaction = props.row.original;
        return <span>{ transaction.payee.name }</span>
      },
      filter: (rows, columnIds, filterValue) => {
        if ( filterValue.length === 0 ) {
          return rows
        } else {
          return rows.filter(row => filterValue.includes(row.original.payee?.id) || filterValue.includes(row.original.transfer_account?.id))
        }
      }
    },
    {
      Header: "Account",
      accessor: "account.id",
      id: "account",
      Cell: props => <span>{ props.row.original.account.name }</span>,
      filter: (rows, columnIds, filterValue) => {
        if ( filterValue.length === 0 ) {
          return rows
        } else {
          return rows.filter(row => filterValue.includes(row.original.account.id))
        }
      }
    },
    {
      Header: "Amount",
      accessor: "amount",
      id: "amount",
      Cell: props => <span>{ formatCurrency(props.value, props.row.original.currency) } </span>
    },
    {
      Header: "Category",
      accessor: "category.id",
      id: "category",
      Cell: props => {
        const transaction = props.row.original;
        return <span>{ transaction.category?.name }</span>
      },
      filter: (rows, columnIds, filterValue) => {
        if ( filterValue.length === 0 ) {
          return rows
        } else {
          const noCategoryFilterResults = filterValue.includes('none') ? rows.filter(row => !row.original.category && row.original.needs_category) : [];
          return rows.filter(row => filterValue.includes(row.original.category?.id)).concat(noCategoryFilterResults);
        }
      }
    }
  ], []);

  const sortBy = useMemo(() => [
    { id: 'date', desc: true },
    { id: 'id', desc: true }
  ], []);

  return (
    <Box>
      <Table
        variant = "styledFocus"
        columns = { columns }
        data = { data }
        sortBy = { sortBy }
        disableHotKeys = { disableHotKeys }
        BodyRow = { ({ row }) => <BodyRow row = { row } onRowClick = { () => setFocusTransaction(row.original.id) } /> }
        HeaderRow = { HeaderRow }
        filters = { filters }
        isLoading = { !transactionsData }
        NoDataComponent = { () => (
          <NullState
            icon = { shoppingCartIcon }
            message = "Get started by adding your first transaction."
            mt = "8"
            py = "4"
          />
        )}
        isDownloadable = { true }
        object = "Transactions"
      />
    </Box>
  )
};

export default TransactionsTable;
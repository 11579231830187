import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";
import moment from "moment-timezone";

import DateField from "components/DateField";
import FieldGroup from "components/FieldGroup";
import { Aside } from "components/Page";
import { AccountSelect, CategorySelect } from "components/Select";

const TrendsAside = ({ setAccounts, setCategories, startMonthEnd, setStartMonthEnd, endMonthEnd, setEndMonthEnd }) => {
  const _setAccounts = (accountItems) => {
    window.analytics.track("Trends Data Filtered", {
      filter: "accounts"
    });

    setAccounts(accountItems);
  };

  const _setCategories = (categoryItems) => {
    window.analytics.track("Trends Data Filtered", {
      filter: "categories"
    });

    setCategories(categoryItems);
  };

  const setDateFilterT12 = () => {
    setStartMonthEnd(moment().subtract(12, 'months').endOf('month'));
    setEndMonthEnd(moment().endOf('month'))
  };

  const setDateFilterYtd = () => {
    setStartMonthEnd(moment().startOf('year').subtract(1, 'month').endOf('month'));
    setEndMonthEnd(moment().endOf('month'))
  }

  return (
    <Aside>
      <FieldGroup title = "Filters" pt = "6">
        <VStack spacing = "4">
          <VStack spacing = "2" width = "full">
            <AccountSelect
              isMulti = { true }
              onChange = { _setAccounts }
              shouldLoadData = { true }
              selectAll = { true }
            />

            <CategorySelect
              isMulti
              onChange = { _setCategories }
              shouldLoadData = { true }
              selectAll = { true }
              includeNoCategory = { true }
            />
          </VStack>

          <VStack spacing = "2" width = "full">
            <Button onClick = { setDateFilterT12 } shadow = { mode("light.sm", 'dark.sm') } width = "full">Last 12 Months</Button>
            <Button onClick = { setDateFilterYtd } shadow = { mode("light.sm", 'dark.sm') } width = "full">Year to Date</Button>

            <Box width = "full">
              <Text textAlign = "left" mb = "2">Custom</Text>
              <FormControl display = "flex" alignItems = "center">
                <FormLabel width = "60%" mb = "0">From</FormLabel>
                <DateField 
                  showMonthYearPicker 
                  value = { startMonthEnd.clone().add(1, 'month').toDate() }
                  dateFormat="MMM yyyy"
                  onChange = { date => setStartMonthEnd(moment(date).subtract(1, 'month')) }
                  filterDate = { date => moment(date).isSameOrBefore(moment(), 'month') }
                />
              </FormControl>

              <FormControl mt = "2" display = "flex" alignItems = "center">
                <FormLabel width = "60%" mb = "0">To</FormLabel>
                <DateField 
                  showMonthYearPicker 
                  value = { endMonthEnd.toDate() }
                  dateFormat="MMM yyyy"
                  onChange = { date => setEndMonthEnd(moment(date)) }
                  filterDate = { date => moment(date).isSameOrBefore(moment(), 'month') }
                />
              </FormControl>
            </Box>
          </VStack>
        </VStack>
      </FieldGroup>
    </Aside>
  )
};

export default TrendsAside;
import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useBoolean,
  useDisclosure
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BiPlus } from "react-icons/bi";

import { GET_CATEGORIES, INSERT_CATEGORY } from "./gql";
import ShortcutTooltip from "components/ShortcutTooltip";
import Toggle from "components/Toggle";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const AddCategory = ({ categoryGroupId, isIncome }) => {
  const [ createAnother, { toggle: toggleCreateAnother }] = useBoolean(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderToast = useToast();
  const budgetId = getDefaultBudget();
  const [ categoryName, setCategoryName ] = useState("");
  const [ error, setError ] = useState(null);

  const [ createCategory, { loading } ] = useMutation(INSERT_CATEGORY, {
    update: (cache, { data: { insert_categories_one }}) => {
      const data = cache.readQuery({ query: GET_CATEGORIES, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_CATEGORIES,
        variables: { budget_id: budgetId },
        data: {
          categories: [ ...(data?.categories || []), insert_categories_one ].sort((c1, c2) => c1.name > c2.name ? 1 : -1)
        }
      })
    }
  });

  useEffect(() => {
    setCategoryName("");
    setError(null);
  }, [ isOpen ]);

  const onSubmit = async e => {
    e.preventDefault();

    await createCategory({
      variables: {
        category: {
          name: categoryName,
          category_group_id: categoryGroupId || undefined,
          budget_id: budgetId,
          is_income: isIncome
        }
      }
    })
    .then(() => {
      if ( createAnother ) {
        setCategoryName("");
      } else {
        onClose();
      }
  
      renderToast("Category Created");
      window.analytics.track("Category Created");
    })
    .catch(err => {
      const { graphQLErrors } = err;
      if ( graphQLErrors.map(error => error.extensions?.code).includes("constraint-violation")) {
        setError(`Category "${categoryName}" already in budget.`)
      } else {
        Sentry.captureException(err);
      }
    })
  }

  return (
    <>
      <ShortcutTooltip label = "Add Category">
        <Button
          size = "sm"
          variant = "icon"
          onClick = { onOpen }
        >
          <BiPlus />
        </Button>
      </ShortcutTooltip>

      <Modal
        isOpen = { isOpen }
        onClose = { onClose }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Category</ModalHeader>
          <ModalCloseButton />
          <form onSubmit = { onSubmit }>
            <ModalBody>
              <FormControl pb = "2" isInvalid = { !!error }>
                <Input
                  value = { categoryName }
                  onChange = { e => setCategoryName(e.target.value) }
                  autoFocus = { true }
                  placeholder = "Category Name"
                  variant = "flushed"
                />
                <FormErrorMessage>{ error }</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter display = "flex" justifyContent = "space-between">
              <Toggle
                isChecked = { createAnother }
                onToggle = { toggleCreateAnother }
                label = "Create another?"
              />
              <Button
                width = "120px"
                size = "sm"
                isLoading = { loading }
                loadingText = "Saving..."
                type = "submit"
                isDisabled = { categoryName.length === 0 }
                colorScheme = "brand"
              >Save</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
};

export default AddCategory;
import { useMutation } from "@apollo/client";
import {
  Editable,
  EditableInput,
  EditablePreview,
  FormControl
} from "@chakra-ui/react";

import { UPDATE_CATEGORY_NAME } from "../gql";
import useToast from "hooks/useToast";

const CategoryName = ({ category }) => {
  const renderToast = useToast();
  const [ updateCategoryName ] = useMutation(UPDATE_CATEGORY_NAME);

  const onNameChange = newName => {
    if ( newName !== category.name ) {
      updateCategoryName({
        variables: {
          category_id: category.id,
          name: newName
        }
      });

      renderToast("Category Updated");
      window.analytics.track("Category Updated", {
        field: 'name'
      })
    }
  }

  return (
    <FormControl>
      <Editable
        defaultValue = { category.name }
        width = "full"
        submitOnBlur = { true }
        onSubmit = { onNameChange }
      >
        <EditablePreview />
        <EditableInput />
      </Editable>
    </FormControl>
  )
};

export default CategoryName;
import {
  Box,
  Link,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

const RequestSuccess = () => (
  <Box 
    bg = {{ base: "transparent", sm: mode("white", "gray.800") }}
    rounded = "sm" 
    shadow = {{ base: "none", sm: "xl" }}
    width = {{ base: "full", sm: "md" }}
    py = {{ base: "2", sm: "6"}}
    px = {{ base: "4", sm: "6" }}
  >
    <Text mb = "6" textAlign = "center">Success! You may now log in with your new password.</Text>
    <Link color = "primary.500" href = "/login">Log in</Link>
  </Box>
);

export default RequestSuccess;
import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  HStack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { FiTrash } from 'react-icons/fi';
import { FaCheckCircle } from "react-icons/fa";
import { useMutation } from "@apollo/client";

import ShortcutTooltip from "components/ShortcutTooltip";
import { DELETE_TRANSACTION, GET_TRANSACTIONS, TOGGLE_IS_PENDING } from "gql/transactions";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const moment = require("moment-timezone");

const DeleteTransaction = ({ transactionId, callback }) => {
  const budgetId = getDefaultBudget();
  const renderToast = useToast();
  const cancelRef = useRef(null);
  const [ deleteTransaction ] = useMutation(DELETE_TRANSACTION, {
    update: (cache, { data: { delete_transactions_by_pk: { id } }}) => {
      const data = cache.readQuery({ query: GET_TRANSACTIONS, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_TRANSACTIONS,
        variables: { budget_id: budgetId },
        data: {
          transactions: data.transactions.filter(transaction => transaction.id !== id )
        }
      })
    }
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onDelete = async () => {
    await deleteTransaction({
      variables: {
        transaction_id: transactionId
      }
    });

    renderToast("Transaction Deleted");
    window.analytics.track("Transaction Deleted");
    callback();
  };

  return (
    <>
      <ShortcutTooltip label = "Delete transaction">
        <Button
          size = "sm"
          variant = "icon"
          onClick = { onOpen }
        ><FiTrash /></Button>
      </ShortcutTooltip>

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Delete Transaction</AlertDialogHeader>
          <AlertDialogBody>
          Are you sure?
          </AlertDialogBody>
          <AlertDialogFooter justifyContent = "space-between">
            <Button size = "sm" ref = { cancelRef } onClick = { onClose }>Cancel</Button>
            <Button size = "sm" colorScheme = "red" onClick = { onDelete }>Delete Transaction</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const TransactionActions = ({ transaction, onDeleteCallback, onUpdate }) => {
  const renderToast = useToast();
  const [ toggleIsPending ] = useMutation(TOGGLE_IS_PENDING);

  const onToggleIsPending = async () => {
    const toggleIsPendingResponse = await toggleIsPending({
      variables: {
        today: moment().format("YYYY-MM-DD"),
        transaction_id: transaction.id
      }
    });

    onUpdate({ ...transaction, ...toggleIsPendingResponse.data.transaction });

    renderToast("Transaction Marked as Cleared");
    window.analytics.track("Transaction Marked Cleared");
  }

  return (
    <Box width = "full">
      { transaction.is_pending ? <Text mb = "1" textAlign = "right" fontSize = "sm" variant = "helper">This transaction is still pending.</Text> : null }
      { !transaction.plaid_id ? (
        <>
        <Divider mb = "2"/>
          <HStack width = "full" justifyContent = "flex-end"> 
            { transaction.is_pending ? (
              <ShortcutTooltip label = "Mark transaction as posted">
                <Button
                  size = "sm"
                  variant = "icon"
                  onClick = { onToggleIsPending }
                >
                  <FaCheckCircle />
                </Button>
              </ShortcutTooltip>
            ) : null}
            
              <DeleteTransaction transactionId = { transaction.id} callback = { onDeleteCallback } />
          </HStack>
        </>
      ) : null } 
    </Box>
  )
};

export default TransactionActions;
import { useMutation } from "@apollo/client";
import {
  Editable,
  EditableInput,
  EditablePreview,
  FormControl
} from "@chakra-ui/react";

import { UPDATE_CATEGORY_GROUP_NAME } from "../gql";
import useToast from "hooks/useToast";

const CategoryGroupName = ({ categoryGroup }) => {
  const renderToast = useToast();
  const [ updateCategoryGroupName ] = useMutation(UPDATE_CATEGORY_GROUP_NAME);

  const onNameChange = newName => {
    if ( newName !== categoryGroup.name ) {
      updateCategoryGroupName({
        variables: {
          category_group_id: categoryGroup.id,
          name: newName
        }
      });

      renderToast("Category Group Updated");
      window.analytics.track("Category Group Updated", {
        field: 'name'
      })
    }
  }

  return (
    <FormControl>
      <Editable
        defaultValue = { categoryGroup.name }
        width = "full"
        submitOnBlur = { true }
        onSubmit = { onNameChange }
      >
        <EditablePreview />
        <EditableInput />
      </Editable>
    </FormControl>
  )
};

export default CategoryGroupName;
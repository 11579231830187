import {
  HStack,
  Icon,
  Text
} from "@chakra-ui/react";
import { AiOutlineCalendar } from "react-icons/ai";
import moment from "moment-timezone";

const NextDate = ({ scheduledTransaction }) => (
  <HStack width = "full" py = "2" px = "2" shadow = "md" justifyContent = "center">
    <Icon as = { AiOutlineCalendar } />
    <Text>
      { scheduledTransaction.frequency === "once" ? (
        "Scheduled Date: "
      ) : "Next Date: " } { moment(scheduledTransaction.next_date).format("LL") }
    </Text>
  </HStack>
);

export default NextDate;
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  Box,
  FormLabel
} from "@chakra-ui/react";

import CurrencyInputField from "components/CurrencyInputField";
import useToast from "hooks/useToast";

const UPDATE_LIMIT = gql`
  mutation UpdateAccountBalance($id: uuid!, $limit: numeric!) {
    update_accounts(_set: { limit: $limit }, where: { id: { _eq: $id }}) {
      returning {
        id
      }
    }
  }
`;

const AccountLimit = ({ account }) => {
  const { limit, currency } = account;
  const renderToast = useToast();
  const [ updateLimit ] = useMutation(UPDATE_LIMIT);
  const [ value, setValue ] = useState(limit);

  useEffect(() => {
    setValue(limit);
  }, [ limit ]);

  const onSubmit = () => {
    const newLimitFloat = parseFloat(value);
    
    if ( newLimitFloat !== account.limit ) {
      updateLimit({
        variables: {
          id: account.id,
          limit: newLimitFloat
        }
      });

      renderToast("Limit updated");
      window.analytics.track("Account Updated", {
        field: 'limit'
      })
    }
  };

  return account.type === "credit" ? (
    <>
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Limit</FormLabel>
      </Box>
      <CurrencyInputField 
        value = { value }
        currency = { currency }
        variant = "flushed"
        disabled = { !!account.plaid_item }
        onSubmit = { onSubmit }
        onChange = { setValue }
        showButtons = { true }
      />
    </>
  ) : null;
};

export default AccountLimit;
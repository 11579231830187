import {
  Box,
  Heading,
  ScaleFade,
  Text,
  VStack,
  useDisclosure,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useAuth } from '@nhost/react-auth';

import SignupForm from "./SignupForm";
import Logo from "components/Logo";

const Signup = () => {
  const { signedIn } = useAuth();
  const { isOpen: isDisplayed, onOpen: onDisplay } = useDisclosure();

  useEffect(() => {
    onDisplay();
  }, [ onDisplay ]);

  useEffect(() => {
    if ( !signedIn ) {
      window.analytics.page("Sign up");
    }
  }, [ signedIn ]);

  return (
    <Box
      bg = {{ base: mode("white", "gray.800"), sm: mode("gray.100", "gray.900") }}
      minH = "100vh"
    >
      <VStack pt = {{ base: "12", lg: "16" }} spacing = "4">
      <ScaleFade initialScale = { 0.5 } in = { isDisplayed }><Logo size = "sm" height = "24"/></ScaleFade>
        <Box width = "full" px = "12">
          <Heading textAlign = "center">Let's get started</Heading>
          <Text align="center">Today's the first day of your new financial future.</Text>
        </Box>
        <SignupForm />
      </VStack>
    </Box>
  )
};

export default Signup;
import gql from "graphql-tag";

import { ALL_HOLDING_FIELDS } from "fragments";

const ADD_HOLDING = gql`
  ${ALL_HOLDING_FIELDS}
  mutation AddHolding($investment_holding: investment_holdings_insert_input!) {
    insert_investment_holdings_one(
      object: $investment_holding,
      on_conflict: {
        constraint: investment_holdings_pkey,
        update_columns: [ cost_basis, quantity ]
      }
    ) {
      ...AllHoldingFields
    }
  }
`;

const ADD_HOLDINGS = gql`
  ${ALL_HOLDING_FIELDS}
  mutation AddHolding($objects: [investment_holdings_insert_input!]!) {
    insert_investment_holdings(
      objects: $objects,
      on_conflict: {
        constraint: investment_holdings_pkey,
        update_columns: [ cost_basis, quantity ]
      }
    ) {
      returning {
        ...AllHoldingFields
      }
    }
  }
`;

const GET_HOLDINGS = gql`
  ${ALL_HOLDING_FIELDS}
  query GetHoldings($budget_id: uuid!) {
    investment_holdings(where: { account: { is_hidden: { _eq: false }, budget_id: { _eq: $budget_id }}}) {
      ...AllHoldingFields
    }
  }
`;

export {
  ADD_HOLDING,
  ADD_HOLDINGS,
  GET_HOLDINGS
}
import { useEffect } from "react";
import { 
  Box,
  Container,
  SimpleGrid
} from "@chakra-ui/react";

import Budget from "./Budget";
import HomeAside from "./HomeAside";
import QuickReviewStreak from "./QuickReviewStreak";
import SpendingPower from "./SpendingPower";
import UncatTransactions from "./UncatTransactions";
import { Page, Main } from "components/Page"

const Home = () => {
  useEffect(() => {
    window.analytics.page("Home");
  }, []);
  
  return (
    <Page>
      <HomeAside />
      <Main 
        hasAside = { true }
      >
        <Container 
          as = "section" 
          pb = "10"
          maxW = "container.xl"
        >
          <Box
            maxW = "7xl"
            mx = "auto"
            px = {{
              base: 2,
              md: 3
            }}
          >
            <SimpleGrid
              columns = {{
                base: 1,
                md: 3
              }}
              spacing = "6"
              mb = "8"
            >
              <UncatTransactions />
              <SpendingPower />
              <QuickReviewStreak />
            </SimpleGrid>
            <Budget />
          </Box>
        </Container>
      </Main>
    </Page>
  );
}

export default Home;
import gql from "graphql-tag";

export default gql`
  fragment AllCategoryFields on categories {
    __typename
    id
    name
    is_income
    is_rollover
    is_system
    category_group_id
    category_group {
      id
      value: id,
      label: name
    }
    budget_id
    transactions_by_month {
      amount
      year_month
    }
    transactions_aggregate {
      aggregate {
        count
      }
    }
    scheduled_transactions {
      amount
      start_date
      next_date
      frequency
      end_after_date
    }
    notes
  }
`;
import {
  Box,
  Divider,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
  useColorModeValue as mode
} from "@chakra-ui/react";
import newspaperIcon from "images/icons/newspaper.svg";

const moment = require("moment-timezone");

const Article = ({ article }) => (
  <Box display = "flex" alignItems = "center" rel = "noreferrer" target = "_blank" href = { article.url } as = "a" cursor = "pointer" width = "full" height = "125px" py = "2" px = "3" _hover = {{ bg: mode("gray.200", "gray.500")}}>
    <HStack width = "full">
      <Box display = "flex" width = "20%" height = "full" alignItems = "center">
        <Image src = { article.image } alt = "article image" fallbackSrc = { newspaperIcon } height = "125px" />
      </Box>
      <VStack width = "80%" alignItems = "flex-start">
        <HStack fontSize = "xs"><Text fontWeight = "semibold">{ article.source }</Text><Text>{ moment(article.datetime).isSame(moment(), 'd') ? "today" : moment(article.datetime).format("LL") }</Text></HStack>
        <Heading fontSize = "sm" fontWeight = "semibold" as = "h5">{ article.headline }</Heading>
        <Box display = {{ base: "none", md: "block"}}><Text noOfLines = { 2 } lineHeight = "normal" textOverflow = "ellipsis" fontSize = "sm">{ article.summary }</Text></Box>
      </VStack>
    </HStack>
  </Box>
);

const News = ({ news }) => (
  <VStack spacing = "3" alignItems = "flex-start" width = "full">
    <Heading fontSize = "xl" as = "h3">News</Heading>
    <Divider />
    <VStack alignItems = "flex-start" width = "full">
      { news.map((article, idx) => <Article key = { idx } article = { article } /> )}
    </VStack>
  </VStack>
);

export default News;
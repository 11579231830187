import {
  FormControl,
  FormLabel,
  Switch
} from "@chakra-ui/react";

const Toggle = ({ label, isChecked, onToggle, ...props }) => (
  <FormControl fontSize = "sm" display = "flex" alignItems = "center" width = "unset" { ...props }>
    <Switch
      id = "create-another"
      size = "sm"
      colorScheme = "primary"
      mr = "2"
      isChecked = { isChecked }
      onChange = { onToggle }
    />
    <FormLabel htmlFor = "create-another" mb = "0">{ label }</FormLabel>
  </FormControl>
);

export default Toggle;
import { useCallback, useEffect, useState } from "react";
import {
  css,
  HStack,
  Icon,
  Text,
  useStyleConfig,
  useTheme
} from "@chakra-ui/react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import Select, { components } from 'react-select';

const Control = ({ ...props }) => {
  const { options, selectProps: { selectAll, includeNoCategory, defaultValues }, setValue } = props;
  const allOptions = ( includeNoCategory ? [ options.find(option => option.id === "none" )] : []).concat(options.reduce((total, section) => total.concat(section.options), [])).filter(x => !!x);
  const [ hasCompletedFirstLoad, setHasCompletedFirstLoad ] = useState(false);

  useEffect(() => {
    if ( !hasCompletedFirstLoad && allOptions.length > 0 ) {
      if ( selectAll ) {
        setValue(allOptions);
      } else if ( defaultValues?.length > 0 ) {
        setValue(allOptions.filter(option => defaultValues.includes(option.value)) )
      }

      setHasCompletedFirstLoad(true);
    }
  }, [ allOptions, hasCompletedFirstLoad, selectAll, setValue, defaultValues ]);

  return <components.Control { ...props } />
}

const Option = ({ isSelected, label, ...props}) => {
  return (
      <components.Option {...props}>
        <HStack>
          <Icon
            as = { isSelected ? MdCheckBox : MdCheckBoxOutlineBlank }
            color = "primary.500"
            width = { 5 }
            height = { 5 }
          />
          <Text>{label}</Text>
        </HStack>
      </components.Option>
  );
};

const Placeholder = ({ hasValue, getValue, selectProps, ...props }) => {
  const value = getValue();
  return (
    <components.Placeholder { ...props}>
      { hasValue ? `${value.length} ${ value.length === 1 ? selectProps.label.singular : selectProps.label.plural}` : selectProps.label.plural }
    </components.Placeholder>
  )
}

const MultiSelectDropdown = ({ defaultValues, label, selectAll, includeNoCategory, value, isDisabled, placeholder, options, onChange, isLoading, loadOptions, ...props }) => {
  const [ _options, setOptions ] = useState(options);
  const theme = useTheme();
  const styles = useStyleConfig("SelectDropdown", {});
  const _theme = css(styles)(theme);

  const _loadOptions = useCallback(async () => {
    if ( options ) {
      setOptions(options)
    } else if (loadOptions && !_options ) {
      const response = await loadOptions();
      setOptions(response);
    }
  }, [ loadOptions, options, _options ]);

  useEffect(() => {
    _loadOptions();
  }, [ _loadOptions ]);

  const selectStyle = {
    container: (base, { theme }) => ({
      ...base,
      ...theme.container
    }),
    control: (base, { theme }) => ({
      ...base,
      ...theme.control
    }),
    indicatorsContainer: (base, { theme }) => ({
        ...base,
        ...theme.indicatorsContainer,
    }),
    input: (base, { theme }) => ({
      ...base,
      ...theme.input
    }),
    menu: (base, { theme }) => ({
      ...base,
      ...theme.menu
    }),
    option: (base, { theme, isSelected, isFocused }) => ({
      ...base,
      ...theme.option,
      background: isSelected ? theme.selectedOption.background : ( isFocused ? theme.focusedOption.background : theme.option.background ),
      borderColor: isFocused ? theme.focusedOption.borderColor : theme.option.borderColor
    }),
    placeholder: (base, { theme }) => ({
      ...base,
      ...theme.input,
      ...theme.placeholder
    }),
    singleValue: (base, { theme }) => ({
      ...base,
      ...theme.singleValue
    }),
    valueContainer: (base, { theme }) => ({
      ...base,
      ...theme.valueContainer
    })
  };

  return (
    <Select 
      closeMenuOnSelect = { false }
      components = {{
        Control,
        Option,
        Placeholder
      }}
      controlShouldRenderValue = { false }
      hideSelectedOptions = { false }
      isDisabled = { isDisabled }
      loadingMessage = { () => "Loading Options..." }
      onChange = { onChange }
      openMenuOnFocus = { true }
      placeholder = { placeholder }
      styles = { selectStyle }
      tabSelectsValue = { false }
      theme = { _theme }
      label = { label }
      selectAll = { selectAll }
      includeNoCategory = { includeNoCategory }
      options = { _options }
      defaultValues = { defaultValues }
      { ...props }
    />
  )
};

export default MultiSelectDropdown;
import {
  Box,
  css,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue as mode,
  useDisclosure,
  useStyleConfig,
  useTheme,
  Text
} from "@chakra-ui/react";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useRef, useCallback, useState, useEffect } from "react";

import HotKeyCombination from "components/HotKeyCombination";
import ShortcutTooltip from "components/ShortcutTooltip";
import useHotKeys from "hooks/useHotKeys";

const selectStyle = {
  container: (base, { theme }) => ({
    ...base,
    ...theme.container
  }),
  control: (base, { theme }) => ({
    ...base,
    ...theme.control
  }),
  indicatorsContainer: (base, { theme }) => ({
      ...base,
      ...theme.indicatorsContainer,
  }),
  input: (base, { theme }) => ({
    ...base,
    ...theme.input
  }),
  menu: (base, { theme }) => ({
    ...base,
    ...theme.menu
  }),
  menuList: (base, { theme }) => ({
    ...base,
    ...theme.menuList
  }),
  option: (base, { theme, isSelected, isFocused }) => ({
    ...base,
    ...theme.option,
    background: isSelected ? theme.selectedOption.background : ( isFocused ? theme.focusedOption.background : theme.option.background ),
    borderColor: isFocused ? theme.focusedOption.borderColor : theme.option.borderColor
  }),
  placeholder: (base, { theme }) => ({
    ...base,
    ...theme.input,
    ...theme.placeholder
  }),
  singleValue: (base, { theme }) => ({
    ...base,
    ...theme.singleValue
  }),
  valueContainer: (base, { theme }) => ({
    ...base,
    ...theme.valueContainer
  })
};

const PopoutSelect = ({ shortcutKey, tooltipText, buttonLabel, fieldDisplay = "label", hideSelectedOptions = false, isClearable = false, isFullWidth = true, isMulti, onChange, options, placeholder, value, isLoading, isCreatable = false, isDisabled, buttonProps, loadOptions, ...otherProps }) => {
  const [ _options, setOptions ] = useState(options);
  const theme = useTheme();
  const styles = useStyleConfig("PopoutSelect", {});
  const _theme = css(styles)(theme);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectRef = useRef(null);

  const _loadOptions = useCallback(async () => {
    if ( loadOptions ) {
      const response = await loadOptions();
      setOptions(response);
    }
  }, [ loadOptions ]);
  

  useEffect(() => {
    if ( options ) {
      setOptions(options)
    } else {
      _loadOptions()
    }
  }, [ _loadOptions, options ]);

  const _onChange = value => {
    onChange(value);
    if ( !isMulti ) {
      onClose();
    }
  };
  
  useHotKeys(shortcutKey, e => {
    if ( !isOpen ) {
      e.stopPropagation();
      e.preventDefault();
      _onOpen();
    }
  }, [ isOpen ], !!shortcutKey);

  const handleKeyDown = e => {
    // React Select doesn't close when user hits Escape
    const key = e.key;
    if ( key === "Escape" ) {
      e.stopPropagation();
      e.preventDefault();
      onClose();
    }
  }
  
  const HotKeyComponent = () => (
    <Box px = "2">
      { shortcutKey ? <HotKeyCombination keys = { [ shortcutKey.toUpperCase() ]} /> : null }
    </Box>
  );

  const _onOpen = () => {
    if ( !isDisabled ) {
      onOpen();
    }
  }

  const selectProps = {
    autoFocus: isOpen, 
    backspaceRemovesValue: false, 
    controlShouldRenderValue: false, 
    hideSelectedOptions: hideSelectedOptions, 
    isClearable, 
    menuIsOpen: isOpen,
    onChange: _onChange, 
    options: _options, 
    placeholder: "Search", 
    tabSelectsValue: false, 
    value: value, 
    ref: selectRef, 
    components: {
      IndicatorSeparator: null,
      IndicatorsContainer: HotKeyComponent
    }, 
    onKeyDown: handleKeyDown, 
    theme: _theme, 
    styles: selectStyle, 
    isLoading: isLoading, 
    isMulti: isMulti, 
    onFocus: onOpen,
    isDisabled,
    ...otherProps
  }

  return (
    <Popover
      returnFocusOnClose = { true }
      isOpen = { !isDisabled && isOpen }
      onClose = { onClose }
      closeOnBlur = { true }
      initialFocusRef = { selectRef }
      placement = "bottom-start"
      onOpen = { _onOpen }
      isLazy = { true }
    >
        <PopoverTrigger width = { isFullWidth ? "full" : "unset"}>
          <Box width = { isFullWidth ? "full" : "unset"}>
            <Button
              variant = { isDisabled ? "disabledSelectButton" : "selectButton" }
              onKeyUp = { e => { if (e.code === "Tab") { _onOpen() } } }
              py = "1"
              shadow = { isDisabled ? "none" : mode("light.xs", "dark.xs") }
              width = { isFullWidth ? "full" : "unset"}
              { ...buttonProps }
            >
              <ShortcutTooltip 
                isDisabled = { isDisabled } 
                label = { tooltipText } 
                keys = { shortcutKey ? [ shortcutKey.toUpperCase() ] : null }
                width = { isFullWidth ? "full" : "unset"}
                height = "full"
                display = "flex"
                alignItems = "center"
                justifyContent = "space-between"
              >
                { Object.keys(value || {} ).length === 0 ? <Text textAlign = "left">{ buttonLabel }</Text> : <Text overflowX = "hidden" textOverflow = "ellipsis" textAlign = "left">{ fieldDisplay === "label" ? value.label : value.value }</Text> }
              </ShortcutTooltip>
            </Button>
          </Box>
        </PopoverTrigger>
      <PopoverContent rounded = "sm" border = "none">
        <Box>
        { isCreatable ? <CreatableSelect { ...selectProps } /> : <Select { ...selectProps } /> }
        </Box>
      </PopoverContent>
    </Popover>
  )
};

export default PopoutSelect;
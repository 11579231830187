import {
  ChakraProvider,
  Box
} from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import { useAuth } from '@nhost/react-auth';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";

import QuickActions from "./QuickActions";
import getTheme from "./theme";
import AuthGate from "components/AuthGate";
import Accounts from "pages/Accounts";
import Activity from "pages/Activity";
import CannyAuth from "pages/CannyAuth";
import Portfolio from "pages/Portfolio";
import Home from "pages/Home";
import Trends from "pages/Trends";
import Login from "pages/Login";
import Research from "pages/Research";
import ResetPassword from "pages/ResetPassword";
import Plan from "pages/Plan";
import ScheduledTransactions from "pages/ScheduledTransactions";
import Signup from "pages/Signup";
import Transactions from "pages/Transactions";

import { auth } from "utils/nhost";

const GET_USER = gql`
  query GetUser($user_id: uuid!) {
    user: users_by_pk(id: $user_id) {
      id
      default_theme
    }
  }
`;

const AppWrapper = () => {
  const { signedIn } = useAuth();

  if ( signedIn === null ) {
    return null;
  }

  // Only render App when nhost is done checking for authentication
  return <App signedIn = { signedIn } />
}

const App = ({ signedIn }) => {
  const user = auth.user();
  const { data: userData } = useQuery(GET_USER, {
    variables: {
      user_id: user?.id
    },
    skip: !signedIn
  });

  const defaultTheme = !signedIn ? "system" : userData?.user.default_theme;
  const theme = defaultTheme && getTheme(defaultTheme);

  return theme ? (
    <ChakraProvider theme = { theme }>
      <QuickActions defaultTheme = { defaultTheme }>
        <Box minH = "max" id = "App">
          <Router>
            <Switch>
              <AuthGate shouldBeSignedIn exact path = "/" component = { Home } />

              <AuthGate shouldBeSignedIn exact path = "/budget" redirect = "/budget/plan" />
              <AuthGate shouldBeSignedIn path = "/budget/accounts" component = { Accounts } />
              <AuthGate shouldBeSignedIn path = "/budget/plan" component = { Plan } />
              <AuthGate shouldBeSignedIn path = "/budget/scheduled-transactions" component = { ScheduledTransactions } />
              <AuthGate shouldBeSignedIn path = "/budget/transactions" component = { Transactions } />

              <AuthGate shouldBeSignedIn exact path = "/insights" redirect = "/insights/trends"/>
              <AuthGate shouldBeSignedIn path = "/insights/trends" component = { Trends }/>

              <AuthGate shouldBeSignedIn exact path = "/invest" redirect = "/invest/portfolio" />
              <AuthGate shouldBeSignedIn path = "/invest/portfolio" component = { Portfolio } />
              <AuthGate shouldBeSignedIn path = "/invest/activity" component = { Activity } />
              <AuthGate shouldBeSignedIn path = "/invest/research/:symbol?" component = { Research } />
              

              <AuthGate exact path = "/login" component = { Login } />
              <AuthGate shouldBeSignedIn path = "/login/canny" component = { CannyAuth } />
              <AuthGate ignoreAuth path = "/reset" component = { ResetPassword } />
              <AuthGate ignoreAuth path = "/signup" component = { Signup } />
            </Switch>
          </Router>
        </Box>
      </QuickActions>
    </ChakraProvider>
  ) : null ;
};

export default AppWrapper;
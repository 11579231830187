import { useEffect, useState } from "react";
import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import CurrencyInputField from "components/CurrencyInputField";
import { CurrencySelect } from "components/Select";
import useToast from "hooks/useToast";

const UPDATE_AMOUNT = gql`
  mutation UpdateAmount($scheduled_transaction_id: uuid!, $amount: numeric!) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { amount: $amount } 
    ) {
      id
    }
  }
`;

const UPDATE_CURRENCY = gql`
  mutation UpdateCurrency($scheduled_transaction_id: uuid!, $currency: String!) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { currency: $currency } 
    ) {
      id
    }
  }
`;

const Amount = ({ scheduledTransaction }) => {
  const { amount, currency } = scheduledTransaction;
  const renderToast = useToast();
  const [ updateAmount ] = useMutation(UPDATE_AMOUNT);
  const [ updateCurrency ] = useMutation(UPDATE_CURRENCY);
  const [ currentAmount, setCurrentAmount ] = useState(amount);

  useEffect(() => {
    setCurrentAmount(amount);
  }, [ amount ] );

  const onAmountChange = () => {
    const newAmountFloat = parseFloat(currentAmount);

    if ( newAmountFloat !== amount ) {
      updateAmount({
        variables: {
          amount: newAmountFloat,
          scheduled_transaction_id: scheduledTransaction.id
        }
      });

      renderToast("Amount Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "amount"
      })
    }
  };

  const onCurrencyChange = item => {
    const { value: currency } = item;

    if ( currency !== scheduledTransaction.currency ) {
      updateCurrency({
        variables: {
          currency,
          scheduled_transaction_id: scheduledTransaction.id
        }
      });

      renderToast("Currency Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "currency"
      })
    }
  }

  return (
    <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
      <Box display = "flex" alignItems = "center">
        <FormLabel>Amount</FormLabel>
      </Box>
      <CurrencyInputField
        value = { currentAmount }
        currency = { currency }
        variant = "flushed"
        onSubmit = { onAmountChange }
        onChange = { setCurrentAmount }
        disabled = { !!scheduledTransaction.plaid_id }
        showButtons = { true }
      />

      <Box display = "flex" alignItems = "center">
        <FormLabel>Currency</FormLabel>
      </Box>
      <CurrencySelect
        value = {{ label: currency, value: currency }}
        onChange = { onCurrencyChange }
      />
    </Grid>
  )
};

export default Amount;
import { useEffect } from 'react';
import {
  Box,
  Divider,
  Text,
  VStack
} from "@chakra-ui/react";

import Article from "./Article";

const News = ({ news }) => {
  useEffect(() => {
    window.analytics.page("Research");
  }, []);

  return (
    <Box>
      <VStack alignItems = "flex-start" width = "full" spacing = "4">
        { news.map((article, idx) => <Article key = { idx } article = { article } /> )}
      </VStack>
      <Divider />
      <Text textAlign = "center" my = "2">End</Text>
    </Box>
  );
};

export default News;
import { useState } from "react";

import CreateManualAccount from "./CreateManualAccount";
import SelectAccountType from "./SelectAccountType";

const AddAccount = ({ budgetId, createAccountCallback = () => null, defaultCurrency }) => {
  const [ screen, setScreen ] = useState("selectAccountType");
 
  if ( screen === "selectAccountType" ) {
    return (
      <SelectAccountType
        budgetId = { budgetId }
        createAccountCallback = { createAccountCallback }
        onManualAccountClick = { () => setScreen("manual") }
      />
    )
  } else {
    return (
      <CreateManualAccount
        budgetId = { budgetId }
        onBack = { () => setScreen("selectAccountType") }
        createAccountCallback = { createAccountCallback }
        defaultCurrency = { defaultCurrency }
      />
    )
  }
};

export default AddAccount;
import gql from "graphql-tag";

const CREATE_BUDGET = gql`
  mutation InsertBudget($budget: budgets_insert_input!) {
    insert_budgets_one(object: $budget) {
      id
    }
  }
`;

const CREATE_CATEGORIES = gql`
  mutation InsertCategories($categories: [categories_insert_input!]!) {
    insert_categories(objects: $categories) { affected_rows }
  }
`;

const CREATE_CATEGORY_GROUPS = gql`
  mutation InsertCategoryGroups($category_groups: [category_groups_insert_input!]!) {
    insert_category_groups(objects: $category_groups) { affected_rows }
  }
`

const ADD_DEFAULT_BUDGET = gql`
  mutation AddDefaultBudget($user_id: uuid!, $budget_id: uuid!) {
    update_users_by_pk(
      pk_columns: { id: $user_id },
      _set: { default_budget_id: $budget_id }
    ) {
      id
    }
  }
`;

export {
  CREATE_BUDGET,
  CREATE_CATEGORIES,
  CREATE_CATEGORY_GROUPS,
  ADD_DEFAULT_BUDGET
}
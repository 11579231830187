import {
  Image,
  Text,
  VStack
} from "@chakra-ui/react";

const NullState = ({ icon, message, children, ...props }) => (
  <VStack { ...props }>
    <Image src = { icon } alt = { `${message} icon` } />
    { message ? <Text>{ message }</Text> : null }
    { children }
  </VStack>
);

export default NullState;
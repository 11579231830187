import {
  Button,
  Box,
  HStack
} from "@chakra-ui/react";
import { FiZap } from 'react-icons/fi';
import * as Sentry from "@sentry/react";

import ShortcutTooltip from "components/ShortcutTooltip";
import useKBar from "hooks/useKBar";
import { auth } from "utils/nhost";

const NavFooter = () => {
  const { open } = useKBar();
  const onLogout = () => {
    Sentry.configureScope(scope => scope.setUser(null));
    window.analytics.track("User Logged Out");
    auth.logout();
    window.analytics.reset();
  };

  const onToggleQuickActions = () => {
    open();
  }

  return (
    <Box width = "full">
      <HStack
        justifyContent = "space-between"
        width = "full"
        opacity = "0.7"
      >
        <Button size = "sm" onClick = { onLogout } variant = "ghost">Logout</Button>
        <HStack>
          <ShortcutTooltip label = "Quick Actions" keys = { ["⌘", "K"] }>
            <Button variant = "icon" onClick = { onToggleQuickActions }>
              <FiZap />
            </Button>
          </ShortcutTooltip>
        </HStack>
      </HStack>
    </Box>
  )
};

export default NavFooter;
import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import CurrencyInputField from "components/CurrencyInputField";
import { AccountTypeSelect, CurrencySelect } from "components/Select";
import useToast from "hooks/useToast";
import getDefaultBudget from "utils/getDefaultBudget";

const INSERT_ACCOUNT = gql`
  mutation($account: accounts_insert_input!) {
    insert_accounts(objects: [$account]) {
      affected_rows
    }
  }
`;

const CreateManualAccount = ({ budgetId, defaultCurrency, onBack, createAccountCallback }) => {
  const renderOnSuccessToast = useToast();
  const [ accountName, setAccountName ] = useState("");
  const [ accountType, setAccountType ] = useState("");
  const [ accountSubtype, setAccountSubtype ] = useState("");
  const [ currentBalance, setCurrentBalance ] = useState(0);
  const [ limit, setLimit ] = useState(null);
  const [ currency, setCurrency ] = useState(null);

  const [createAccount, { loading: isCreatingAccount }] = useMutation(INSERT_ACCOUNT);

  const onTypeChange = data => {
    const { type, subtype } = data;
    setAccountType(type);
    setAccountSubtype(subtype);
  };

  const onSubmit = async e => {
    e.preventDefault();

    await createAccount({
      variables: {
        account: {
          name: accountName,
          type: accountType,
          subtype: accountSubtype,
          currency: currency.value,
          limit,
          current_balance: currentBalance,
          budget_id: budgetId || getDefaultBudget()
        }
      }
    });

    window.analytics.track("Manual Account Created", {
      type: accountType,
      subtype: accountSubtype
    });
    renderOnSuccessToast("Bank account created", "You can update your account details at any time.");
    createAccountCallback();
  };

  const isValid = accountName && accountSubtype;

  return (
    <Box>
      <Box>
        <form onSubmit = { onSubmit }>
          <Stack spacing = "6">
            <FormControl>
              <FormLabel>Account Name</FormLabel>
              <Input
                type = "text"
                value = { accountName }
                onChange = { e => setAccountName(e.target.value) }
                autoFocus = { true }
                placeholder = "Main Checking Account"
                variant = "flushed"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Account Type</FormLabel>
              <AccountTypeSelect
                accountType = { accountType }
                accountSubtype = { accountSubtype }
                onChange = { onTypeChange }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Currency</FormLabel>
              <CurrencySelect
                value = { currency }
                onChange = { setCurrency }
                shouldLoadData = { true }
                defaultCurrency = { defaultCurrency }
              />
            </FormControl>

            <FormControl>
              <FormLabel>Current Balance</FormLabel>
              <CurrencyInputField
                value = { currentBalance }
                onChange = { setCurrentBalance }
                currency = { currency?.value }
                variant = "flushed"
              />
            </FormControl>

            { accountType === "credit" ? (
              <FormControl>
                <FormLabel>Credit Limit</FormLabel>
                <CurrencyInputField
                  value = { limit }
                  onChange = { setLimit }
                  currency = { currency?.value }
                  variant = "flushed"
                />
              </FormControl>
            ) : null }
          </Stack>

          <HStack mt = "4" justifyContent = "space-between">
            <Button size = "sm" onClick = { onBack } variant = "link">Back</Button>
            <Button
              type = "submit"
              onClick = { onSubmit }
              isLoading = { isCreatingAccount }
              loadingText = "Creating account..."
              colorScheme = "brand"
              isDisabled = { !isValid }
              size = "sm"
            >Create Account</Button>
          </HStack>
        </form>
      </Box>
    </Box>
  )
};

export default CreateManualAccount;
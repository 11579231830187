import { theme } from "@chakra-ui/react";

const colors = {
  white: "#FFFFFF",
  black: "#151619",
  primary: {
    100: "#E4E9FC",
    200: "#CED6F8",
    300: "#AFBBF3",
    400: "#8F98EB",
    500: "#7476E2",
    600: "#5F59D4",
    700: "#5F59D4",
    800: "#5F59D4",
    900: "#393776",
  },
  secondary: {
    100: "#EEE3F9",
    200: "#DDC7F3",
    300: "#CBACEE",
    400: "#BA90E8",
    500: "#A974E2",
    600: "#875DB5",
    700: "#654688",
    800: "#553A71",
    900: "#442E5A",
  },
  tertiary: {
    100: "#E3EFF9",
    200: "#C7DEF3",
    300: "#ACCEEE",
    400: "#90BDE8",
    500: "#74ADE2",
    600: "#689CCB",
    700: "#5D8AB5",
    800: "#466888",
    900: "#2E455A",
  },
  success: theme.colors.green,
  warning: theme.colors.yellow,
  error: theme.colors.red,
  gray: {
    50: "#FAFCFF",
    100: "#F4F6FB",
    200: "#DFE1E4", 
    300: "#9197A1",
    400: "#717884",
    500: "#464B53",
    600: "#303236",
    700: "#2d2e31",
    800: "#27282b",
    900: "#1F2023"
  },
  brand: {
    50: "#F1F4FD",
    100: "#E4E9FC",
    200: "#CED6F8",
    300: "#AFBBF3",
    400: "#8F98EB",
    500: "#7476E2",
    600: "#5F59D4",
    700: "#534ABB",
    800: "#423C95",
    900: "#393776",
  },
  brandAlpha: {
    400: "rgba(143, 152, 235, 0.8)",
    500: "rgba(116, 118, 226, 0.8)",
    600: "rgba(95, 89, 212, 0.8)"
  },
  grayAlpha: {
    100: "rgba(250, 252, 255, 0.7)"
  }
};

export default colors;
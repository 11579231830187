import gql from "graphql-tag";

export default gql`
  fragment AllInvestmentTransactionFields on investment_transactions {
    __typename
    id
    account {
      id
      name
    }
    security {
      id
      symbol
    }
    amount
    date
    fees
    plaid_id
    currency
    price
    quantity
    type
    subtype
    notes
  }
`;
import {
  Box,
  Button,
  Heading,
  HStack,
  StackDivider,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { FaCheckCircle } from "react-icons/fa";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import ShortcutTooltip from "components/ShortcutTooltip";
import useHotKeys from "hooks/useHotKeys";
import { getNextDate } from "utils/calculations";
import { formatCurrency } from "utils/format";

const UPDATE_NEXT_DATE = gql`
  mutation UpdateNextDate($scheduled_transaction_id: uuid!, $next_date: String!) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: { next_date: $next_date }
    ) {
      id
      next_date
    }
  }
`;;

const DELETE_SCHEDULED_TRANSACTION = gql`
  mutation DeleteScheduledTransaction($scheduled_transaction_id: uuid!) {
    delete_scheduled_transactions_by_pk (
      id: $scheduled_transaction_id
    ) {
      id
    }
  }
`;

const ScheduledTransactionsList = ({ scheduledTransactions, onMarkPaid }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return isMobile ? (
    <VStack divider={<StackDivider borderColor="gray.200" /> }>
      { scheduledTransactions.map(transaction => (
        <HStack width = "full" align = "center" key = { transaction.id}>
          <Box width = "70%">
            <Text fontWeight = "medium">{ transaction.payee?.name || `Transfer${transaction.amount > 0 ? " from" : " to"}: ${transaction.transfer_account.name}` }</Text>
            <Text fontSize = "sm">{ transaction.account.name }</Text>
            <Text fontSize = "sm">{ moment(transaction.next_date).format("MMM D") }</Text>
          </Box>
          <Box width = "30%">
            <Text textAlign = "right">{ formatCurrency(transaction.amount, transaction.currency) }</Text>
            <Button onClick = { () => onMarkPaid(transaction)} leftIcon = { <FaCheckCircle /> } size = "sm" colorScheme = "gray">Mark Paid</Button>
          </Box>
        </HStack>
      )) }
    </VStack>
  ) : (
    <VStack divider={<StackDivider borderColor="gray.200" /> }>
      { scheduledTransactions.map(transaction => (
        <HStack width = "full" align = "center" key = { transaction.id}>
          <Text width = "15%">{ moment(transaction.next_date).format("MMM D") }</Text>
          <Box width = "40%">
            <Text>{ transaction.payee?.name || `Transfer${transaction.amount > 0 ? " from" : " to"}: ${transaction.transfer_account.name}` }</Text>
            <Text fontSize = "sm">{ transaction.account.name }</Text>
          </Box>
          <Text width = "20%">{ formatCurrency(transaction.amount, transaction.currency) }</Text>
          <Button onClick = { () => onMarkPaid(transaction)} leftIcon = { <FaCheckCircle /> } width = "25%" size = "sm" colorScheme = "gray">Mark Paid</Button>
        </HStack>
      )) }
    </VStack>
  )
}

const ScheduledTransactions = ({ scheduledTransactions, goToScreen }) => {
  const [ updateNextDate ] = useMutation(UPDATE_NEXT_DATE);
  const [ deleteScheduledTransaction ] = useMutation(DELETE_SCHEDULED_TRANSACTION);

  const onMarkPaid = async scheduledTransaction => {
    const startDate = scheduledTransaction.start_date;
    const endDate = scheduledTransaction.end_after_date;
    const frequency = scheduledTransaction.frequency;
    const afterDate = moment(scheduledTransaction.next_date);

    const nextDate = getNextDate({ startDate, endDate, frequency, afterDateMoment: afterDate, include: false });

    if ( nextDate ) {
      await updateNextDate({
        variables: {
          scheduled_transaction_id: scheduledTransaction.id,
          next_date: nextDate
        }
      });
    } else {
      await deleteScheduledTransaction({
        variables: {
          scheduled_transaction_id: scheduledTransaction.id
        }
      });
    }

    window.analytics.track("Scheduled Transaction Paid");
  }

  const dueToday = scheduledTransactions.filter(transaction => moment(transaction.next_date).isSame(moment(), 'day'));
  const overDue = scheduledTransactions.filter(transaction => moment(transaction.next_date).isBefore(moment(), 'day'));

  useHotKeys('left', () => goToScreen("previous"), [ goToScreen ] );
  useHotKeys('right', () => goToScreen("next"), [ goToScreen ]);

  return (
    <Box 
      shadow = {{ base: "none", md: mode("light.xl", "dark.xl")}} 
      px = {{ base: "4", md: "8" }} py = "8"
      bg = { mode("white", "gray.800") }
    >
      <Heading fontSize = "2xl" mb = "4">Scheduled Transactions</Heading>
      { dueToday.length + overDue.length > 0 ? ( 
        <>
          { dueToday.length > 0 ? (
            <Box>
              <Heading mb = "3" fontSize = "lg">Due Today</Heading>
              <ScheduledTransactionsList scheduledTransactions = { dueToday } onMarkPaid = { onMarkPaid }/>
            </Box>
          ) : null }

          { overDue.length > 0 ? (
            <Box>
              <Heading mb = "3" fontSize = "lg">Overdue</Heading>
              <ScheduledTransactionsList scheduledTransactions = { overDue } onMarkPaid = { onMarkPaid }/>
            </Box>
          ) : null }
        </>
      ) : (
        <Heading py = "6" textAlign = "center">All Caught Up!</Heading>
      )}
      

      <HStack justifyContent = "space-between" mt = "6">
        <ShortcutTooltip label = "Back" keys = {['left']}>
          <Button 
            colorScheme = "gray"
            onClick = { () => goToScreen("previous") }
            size = "sm"
            variant = "ghost"
          >Back</Button>
        </ShortcutTooltip>

        <ShortcutTooltip label = "Next" keys = {['right']}>
          <Button
            colorScheme = "primary"
            onClick = { () => goToScreen("next") }
            size = "sm"
          >Next</Button>
        </ShortcutTooltip>
      </HStack>
    </Box>
  )
};

export default ScheduledTransactions;
import {
  Box,
  FormLabel,
  Grid
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { PayeeSelect } from "components/Select";
import useToast from "hooks/useToast";
import { needsCategory } from "utils/calculations";

const INSERT_PAYEE = gql`
  mutation($payee_name: citext!) {
    insert_payees(objects: [{ name: $payee_name }]) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_PAYEE = gql`
  mutation UpdatePayee($scheduled_transaction_id: uuid!, $payee_id: uuid, $transfer_account_id: uuid, $category_id: uuid) {
    update_scheduled_transactions_by_pk(
      pk_columns: { id: $scheduled_transaction_id },
      _set: {
        payee_id: $payee_id,
        transfer_account_id: $transfer_account_id
        category_id: $category_id
      }
    ) {
      id
    }
  }
`;

const Payee = ({ scheduledTransaction }) => {
  const renderToast = useToast();
  const [ createPayee ] = useMutation(INSERT_PAYEE);
  const [ updatePayee ] = useMutation(UPDATE_PAYEE);

  const onPayeeChange = async item => {
    if ( item.value !== scheduledTransaction.payee?.value && item.value !== scheduledTransaction.transfer_account?.id ) {
      let payeeId = null;
      let transferAccountId = null;

      if ( item.__isNew__ ) {
        const resp = await createPayee({
          variables: {
            payee_name: item.label
          }
        });

        payeeId = resp.data.insert_payees.returning[0].id;
      } else {
        payeeId = item.__typename === "payees" ? item.value : null;
        transferAccountId = item.__typename === "accounts" ? item.value : null;
      }

      const transactionNeedsCategory = needsCategory(scheduledTransaction.account.type, item.__typename === "accounts" ? item.type : null)

      await updatePayee({
        variables: {
          payee_id: payeeId,
          transfer_account_id: transferAccountId,
          scheduled_transaction_id: scheduledTransaction.id,
          category_id: transactionNeedsCategory ? scheduledTransaction.category?.id : null
        }
      });

      renderToast("Payee Updated");
      window.analytics.track("Scheduled Transaction Updated", {
        field: "payee"
      })
    }
  };

  return (
    <Grid width = "full" templateColumns = "40% 60%" rowGap = "2">
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Payee</FormLabel>
      </Box>
      <PayeeSelect
        value = { scheduledTransaction.payee || scheduledTransaction.transfer_account }
        onChange = { onPayeeChange }
        shouldLoadData = { true }
        isCreatable = { true }
        transferDirection = { scheduledTransaction.amount >= 0 ? "incoming" : "outgoing" }
      />
    </Grid>
  )
};

export default Payee;
import {
  Box,
  Button,
  HStack,
  SimpleGrid,
  StackDivider,
  Text,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";

import Category from "./Category";
import AddCategory from "../AddCategory";
import { formatCurrency } from "utils/format";

const CategoryGroup = ({ currentMonth, categoryGroup, currency, setTargetCategory, setTargetCategoryGroup, categories = [], categoryGroupListRef }) => {
  const totalBudgeted = categories.reduce((total, category) => total + category.currentMonth.budget || 0, 0);
  const totalActivity = categories.reduce((total, category) => total + category.currentMonth.activity || 0, 0);
  const totalRemaining = categories.reduce((total, category) => total + category.currentMonth.remaining || 0, 0);

  return (
    <Box
      fontSize = {{ base: "sm", lg: "unset" }}
      rounded = "sm"
      py = "4"
      mb = "2"
      shadow = { mode("light.sm", "dark.sm") }
      className = "category-group"
    >
      <SimpleGrid 
        columns = {{ base: 3, sm: 4 }} 
        fontSize = {{ base: "sm", sm: "unset" }} 
        py = "1" 
        px = {{ base: "4", sm: "8" }} 
        mb = "2" 
        fontWeight = "medium"
      >
        <HStack>
          <AddCategory
            categoryGroupId = { categoryGroup.id }
            isIncome = { categoryGroup.is_income || false }
          />
          <Button
            _disabled = {{
              opacity: 1,
              cursor: "default"
            }}
            variant = "link"
            colorScheme = "brand"
            isDisabled = { !setTargetCategoryGroup }
            onClick = { () => { setTargetCategoryGroup && setTargetCategoryGroup(categoryGroup.id) } }
          >{ categoryGroup.name }</Button>
        </HStack>
        <Text pr = "1" textAlign = "right">{ formatCurrency(totalBudgeted, currency) }</Text>
        <Text display = {{ base: "none", sm: "grid"}}  textAlign = "right">{ formatCurrency(totalActivity, currency) }</Text>
        <Text textAlign = "right">{ formatCurrency(totalRemaining, currency) }</Text>
      </SimpleGrid>

      <VStack spacing = "0" mt = "2" divider = { <StackDivider borderColor = { mode("gray.200", "gray.500")} /> }>
        { categories.map(category => (
          <Category
            key = { category.id }
            category = { category }
            setTargetCategory = { () => setTargetCategory(category.id) }
            currentMonth = { currentMonth }
            categoryGroupListRef = { categoryGroupListRef }
          />
        ))}
      </VStack>
    </Box>
  )
};

export default CategoryGroup;
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { HiPlus } from 'react-icons/hi';
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";

import { ADD_SCHEDULED_TRANSACTION, GET_SCHEDULED_TRANSACTIONS } from "./gql";
import DateField from "components/DateField";
import { AccountSelect, CategorySelect, CurrencySelect, FrequencySelect, PayeeSelect } from "components/Select";
import LargeCurrencyInput from "components/LargeCurrencyInput";
import useToast from "hooks/useToast";
import { getNextDate, needsCategory } from "utils/calculations";
import getDefaultBudget from "utils/getDefaultBudget";

const AddScheduledTransaction = () => {
  const budgetId = getDefaultBudget();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const renderToast = useToast();
  const [ startDate, setStartDate ] = useState(null);
  const [ endAfterDate, setEndAfterDate ] = useState(null);
  const [ amount, setAmount ] = useState(0);
  const [ currency, setCurrency ] = useState(null);
  const [ notes, setNotes ] = useState("");
  const [ account, setAccount ] = useState(null);
  const [ category, setCategory ] = useState(null);
  const [ payee, setPayee ] = useState("");
  const [ frequency, setFrequency ] = useState(null);

  const [ addScheduledTransaction ] = useMutation(ADD_SCHEDULED_TRANSACTION, {
    update: (cache, { data: { insert_scheduled_transactions_one } }) => {
      const data = cache.readQuery({ query: GET_SCHEDULED_TRANSACTIONS, variables: { budget_id: budgetId } });
      cache.writeQuery({ 
        query: GET_SCHEDULED_TRANSACTIONS, 
        variables: { budget_id: budgetId },
        data: {
          scheduled_transactions: [ ...data.scheduled_transactions, insert_scheduled_transactions_one ]
        }
      });
    }
  });

  const endDate = endAfterDate ? moment(endAfterDate).format("YYYY-MM-DD") : null
  const nextDate = getNextDate({ startDate, endDate, frequency: frequency?.value, include: true });

  const clearValues = () => {
    setStartDate(null);
    setEndAfterDate(null);
    setAmount(0);
    setNotes("");
    setAccount(null);
    setCategory(null);
    setPayee(null);
    setFrequency(null);
  };

  useEffect(() => {
    clearValues();
  }, [ isOpen ]);

  const onSubmit = e => {
    e.preventDefault();
    
    if ( nextDate ) {
      const scheduledTransaction = {
        account_id: account.value,
        amount,
        frequency: frequency.value,
        next_date: frequency.value === "once" ? moment(startDate).format("YYYY-MM-DD") : nextDate,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_after_date: endDate,
        currency: currency.value,
        notes
      }
  
      if ( payee.__typename === "accounts" ) {
        scheduledTransaction.transfer_account_id = payee.value
      } else {
        scheduledTransaction.payee = {
          data: {
            name: payee.label,
            budget_id: budgetId
          },
          on_conflict: {
            constraint: 'payees_budget_id_name_key',
            update_columns: [ 'budget_id' ]
          }
        }
      }

      if ( !disableCategory ) {
        scheduledTransaction.category = {
          data: {
            name: category.label,
            budget_id: budgetId
          },
          on_conflict: {
            constraint: 'categories_budget_id_name_key',
            update_columns: [ 'budget_id' ]
          }
        }
      }
  
      addScheduledTransaction({
        variables: {
          scheduled_transaction: scheduledTransaction
        }
      });

      renderToast("Scheduled Transaction Created");
      window.analytics.track("Scheduled Transaction Created");
      onClose();
    }
  }
  const disableCategory = account && !needsCategory(account?.type, payee?.type);
  const isTransferCategory = disableCategory && payee?.__typename === "accounts";

  const isValid = startDate && account && ( disableCategory || category ) && frequency && payee && nextDate;

  return (
    <>
      <Button
        variant = "ghost"
        colorScheme = "gray"
        leftIcon = { <HiPlus /> }
        fontSize = "sm"
        onClick = { onOpen }
        rounded = "sm"
        display = {{ base: "none", md: "inline-flex"}}
      >Add Scheduled Transaction</Button>

      <Button
        variant = "icon"
        onClick = { onOpen }
        display = {{ base: "inline-flex", md: "none"}}
      ><HiPlus /></Button>

      <Modal
        isOpen = { isOpen }
        onClose = { onClose }
        size = "3xl"
        returnFocusOnClose = { false }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Scheduled Transaction</ModalHeader>
          <ModalCloseButton />
          <form onSubmit = { onSubmit }>
            <ModalBody>
              <Box width = "20" alignItems = "center">
                <CurrencySelect
                  value = { currency }
                  onChange = { setCurrency }
                  shouldLoadDefaultCurrency = { isOpen }
                  buttonProps = {{ width: "unset" }}
                />
              </Box>
              <LargeCurrencyInput
                value = { amount }
                onChange = { setAmount }
                currency = { currency?.value }
                placeholder = "0"
              />
              <SimpleGrid mt = "3" columns = {{ base: 1, md: 2, lg: 3 }} py = "4" spacing = "1">
                <Box>
                  <DateField
                    value = { startDate }
                    onChange = { setStartDate }
                    filterDate = { date => endAfterDate ? date < endAfterDate : true }
                    placeholderText = "Start On"
                  />
                </Box>

                <Box>
                  <DateField
                    value = { endAfterDate }
                    onChange = { setEndAfterDate }
                    filterDate = { date => date > startDate }
                    isClearable = { true }
                    placeholderText = "End After"
                  />
                </Box>

                <Box>
                  <FrequencySelect
                    value = { frequency }
                    onChange = { setFrequency }
                    buttonLabel = "Frequency"
                  />
                </Box>

                <Box>
                  <AccountSelect
                    value = { account }
                    onChange = { setAccount }
                    buttonLabel = "Account"
                    shouldLoadData = { isOpen }
                  />
                </Box>

                <Box>
                  <PayeeSelect
                    value = { payee }
                    onChange = { setPayee }
                    isCreatable = { true }
                    buttonLabel = "Payee"
                    shouldLoadData = { isOpen }
                    transferDirection = { amount >= 0 ? "incoming" : "outgoing" }
                  />
                </Box>

                <Box>
                  <CategorySelect
                    value = { isTransferCategory ? ({ label: "Transfer", value: "transfer" }) : category }
                    onChange = { setCategory }
                    isCreatable = { true }
                    shouldLoadData = { isOpen }
                    buttonLabel = "Category"
                    isDisabled = { disableCategory }
                  />
                </Box>
              </SimpleGrid>
              <Textarea
                value = { notes }
                onChange = { e => setNotes(e.target.value) }
                placeholder = "Transaction notes"
                rows = "2"
                resize = "none"
                size = "sm"
              />
            </ModalBody>
            <ModalFooter justifyContent = "space-between">
            <Text fontSize = "sm" >{ nextDate ? `Next Date: ${ moment(nextDate).format("LL") }` : "" }</Text>
              <Button
                colorScheme = "brand"
                type = "submit"
                isLoading = { false }
                loadingText = "Creating transaction"
                isDisabled = { !isValid }
                size = "sm"
                onClick = { onSubmit }
              >Create</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
};

export default AddScheduledTransaction;
import { Box, HStack, Kbd, Text, Tooltip } from '@chakra-ui/react';

const ShortcutTooltip = ({ label, keys, placement = "auto", isDisabled = false, ...props }) => (
  <Tooltip 
    closeOnClick = { true }
    closeOnMouseDown = { true }
    isDisabled = { isDisabled }
    placement = { placement }
    openDelay = "200"
    label = { 
      <HStack justifyContent = "space-between" spacing = "6">
        <Text>{ label }</Text>
        { keys ? (
          <HStack spacing = "1">
            { keys.map(key => <Kbd key = { key }>{ key }</Kbd>)}
          </HStack>
        ) : null }
      </HStack>
    }
  >
    <Box { ...props }>{ props.children }</Box>
  </Tooltip>
);

export default ShortcutTooltip;
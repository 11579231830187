import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";

import News from "./News";
import { GET_DISTINCT_HOLDINGS } from "./gql";
import Symbol from "./Symbol";
import LoadingSpinner from "components/LoadingSpinner";
import { Page, Main, Header } from "components/Page";
import SymbolSearchInput from "components/SymbolSearchInput";
import getDefaultBudget from "utils/getDefaultBudget";
import iex from "utils/iex";

const Research = () => {
  const budgetId = getDefaultBudget();
  const { symbol: symbolParam } = useParams();
  const [ symbol, setSymbol ] = useState(symbolParam);
  const [ holdingsNews, setHoldingsNews ] = useState([]);
  const [ symbolData, setSymbolData ] = useState({});
  const [ isLoadingSymbolData, toggleIsLoadingSymbolData ] = useState(!!symbolParam); 

  const { data: holdingsData } = useQuery(GET_DISTINCT_HOLDINGS, {
    variables: {
      budget_id: budgetId
    }
  });

  const holdings = holdingsData?.investment_holdings;

  useEffect(() => {
    toggleIsLoadingSymbolData(true);
    setSymbol(symbolParam);
  }, [ symbolParam ]);

  useEffect(() => {
    const symbols = holdings?.map(holding => holding.security.symbol);
    if ( symbols && symbols.length > 0 ) {
      iex.batch({ symbols, types: ['news'], params: {
        language: 'en',
        last: 2,
        subattribute: "source~Medium"
      }})
      .then(resp => {
        const allNews = Object.values(resp)
        .reduce((all, curr) => all.concat(curr.news), [])
        .sort((a1, a2) => a2.datetime > a1.datetime ? 1 : -1);
        setHoldingsNews(allNews);
      })
    }
  }, [ holdings ]);

  useEffect(() => {
    if ( symbol ) {
      iex.batch({ symbol, types: ["company", "stats", "news", "logo"], params: { last: 5, subattribute: "source~Medium", language: 'en' } })
      .then(resp => {
        setSymbolData(resp);
        toggleIsLoadingSymbolData(false);
      })
      .catch(err => console.log(err.response))
    }
  }, [ symbol ]);

  const onSymbolChange = item => {
    toggleIsLoadingSymbolData(true);
    setSymbol(item.symbol);
    window.history.pushState({}, "", `/invest/research/${item.symbol}`)
  };

  const goBackToNews = () => {
    setSymbol(null);
    setSymbolData({});
    window.history.pushState({}, "", `/invest/research`)
  };

  return (
    <Page>
      <Main>
        <Header title = "Research" display = {{ base: "block", md: "flex" }} spacing = "0">
          <Box width = {{ base: "full", md: "60" }} pt = {{ base: "2", md: "0" }}>
            <SymbolSearchInput onChange = { onSymbolChange } />
          </Box>
        </Header>
        { symbol 
          ? !isLoadingSymbolData ? (
            <Symbol 
              goBackToNews = { goBackToNews } 
              symbol = {{ ...symbolData, symbol }} 
            />
          ) : <LoadingSpinner />
          : (
            <News news = { holdingsNews } />
          )
        }
      </Main>
    </Page>
  );
};

export default Research;
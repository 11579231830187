import PopoutSelect from "./PopoutSelect";
import { timezoneOptions } from "utils/options";

const TimezoneSelect = ({ value, defaultCurrency, onChange, shouldLoadDefaultCurrency = false, shouldLoadIPCurrency = false, buttonLabel = "Select Timezone", tooltipText = "Select Timezone", isFullWidth, ...props }) => {
  return (
    <PopoutSelect
      tooltipText = { tooltipText }
      buttonLabel = { buttonLabel }
      onChange = { onChange }
      value = { value }
      options = { timezoneOptions }
      fieldDisplay = "label"
      isFullWidth = { isFullWidth }
      { ...props }
    />
  )
};

export default TimezoneSelect;
import {
  Box,
  Heading,
  Text,
  useColorModeValue as mode,
  useTheme
} from "@chakra-ui/react";
import { Bar } from 'react-chartjs-2';
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import ReactDOM from "react-dom";

import { GET_SPENDING } from "./gql";
import { formatCurrency } from "utils/format";
import getDefaultBudget from "utils/getDefaultBudget";

const colors = ['primary', 'secondary', 'tertiary', 'yellow', 'teal', 'red', 'pink', 'orange', 'green', 'gray', 'cyan'];

const Spending = ({ selectedAccounts, selectedCategories, monthEnds, yearMonths }) => {
  const theme = useTheme();
  const budgetId = getDefaultBudget();
  const { data } = useQuery(GET_SPENDING, { variables: { budget_id: budgetId }});
  const budget = data?.budget;

  const categoryGroups = budget?.category_groups.map((categoryGroup, index) => {
    const spending = monthEnds.map(monthEnd => {
      return -categoryGroup.categories
      .filter(category => selectedCategories.includes(category.id))
      .reduce((total, category) => {
        const amount = category.transactions_by_account_month
          .filter(tbam => selectedAccounts.includes(tbam.account_id))
          .filter(tbam => tbam.year_month === monthEnd.slice(0, 7))
          .reduce((totalAmount, tbam) => totalAmount + tbam.amount, 0);

        return amount + total;
      }, 0);
    });

    const backgroundColorBase = colors[index % colors.length];


    return {
      label: categoryGroup.name,
      data: spending.reverse(),
      type: 'bar',
      backgroundColor: theme.colors[backgroundColorBase][300],
      hoverBackgroundColor: theme.colors[backgroundColorBase][300]
    }
  }) || [];

  const otherCategories = budget?.categories
    .concat({ id: 'none', transactions_by_account_month: budget?.uncat_transactions })
    .filter(category => selectedCategories.includes(category.id)) || [];

  const otherSpending = monthEnds.map(monthEnd => {
    return -otherCategories
    .reduce((total, category) => {
      const amount = category.transactions_by_account_month
        .filter(tbam => selectedAccounts.includes(tbam.account_id))
        .filter(tbam => tbam.year_month === monthEnd.slice(0, 7))
        .reduce((totalAmount, tbam) => totalAmount + tbam.amount, 0);
      return amount + total
    }, 0);
  });

  const otherGroup = {
    label: 'Other',
    type: 'bar',
    backgroundColor: theme.colors[colors[categoryGroups.length % colors.length]][300],
    hoverBackgroundColor: theme.colors[colors[categoryGroups.length % colors.length]][300],
    data: otherSpending.reverse()
  }

  const chartFontColor = mode(theme.colors.gray[800], theme.colors.whiteAlpha[900]);
  const labels = monthEnds.map(me => moment(me)).reverse();
  const chartData = {
    labels,
    datasets: [
      otherGroup,
      ...categoryGroups.sort((cg1, cg2) => cg2.label > cg1.label ? 1 : -1)
    ]
  };

  const tooltipBackgroundColor = mode("white", theme.colors.gray[700]);
  const tooltipShadow = mode(theme.shadows.light.sm, theme.shadows.dark.sm)

  const chartOptions = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: context => {
          let tooltipEl = document.getElementById('chartjs-tooltip');

          if ( !tooltipEl ) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div></div>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          var tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
              tooltipEl.classList.add('no-transform');
          }

          if ( tooltipModel.body) {
            const { dataPoints, title } = context.tooltip;
            const totalSpending = dataPoints.reduce((total, dp) => total + dp.raw, 0);
            const otherCategory = dataPoints.find(dp => dp.dataset.label === "Other");
            const categoryGroups = dataPoints
              .filter(dp => dp.dataset.label !== "Other")
              .sort((cg1, cg2) => cg2.label > cg1.label ? 1 : -1)

            const DataPoint = ({ dataPoint }) => (
              <Box display = "flex" width = "full" justifyContent = "space-between">
                <Box display = "flex" alignItems = "center">
                  <div style = {{ boxShadow: tooltipShadow, marginRight: "4px", borderRadius: "100%", width: "12px", height: "12px", backgroundColor: dataPoint.dataset.backgroundColor}}></div>
                  <Text mr = "12">{ dataPoint.dataset.label }</Text>
                </Box>
                <Text textAlign = "right">{ formatCurrency(dataPoint.raw) }</Text>
              </Box>
            );


            const ToolTipBody = () => (
              <Box backgroundColor = { tooltipBackgroundColor } p = "8">
                <Heading mb = "4" fontWeight = "bold" as = "h6">{ moment(parseInt(title[0])).format("MMM YYYY") }</Heading>
                <Box>
                  { categoryGroups.map((data, index) => <DataPoint key = { index } dataPoint = { data } />) }
                  <DataPoint dataPoint = { otherCategory } />
                  <Box borderTop = "1px solid" mt = "4" display = "flex" width = "full" justifyContent = "space-between" fontWeight = "medium">
                    <Text mr = "8">Total</Text>
                    <Text textAlign = "right">{ formatCurrency(totalSpending) }</Text>
                  </Box>
                </Box>
              </Box>
            )

            ReactDOM.render(<ToolTipBody />, tooltipEl);

            var position = context.chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.backgroundColor = tooltipBackgroundColor;
            tooltipEl.style.boxShadow = tooltipShadow;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = Math.min(position.left + window.pageXOffset + tooltipModel.caretX, window.innerWidth - 250) + 'px';
            tooltipEl.style.top = Math.min(position.top + window.pageYOffset + tooltipModel.caretY, window.innerHeight - 200) + 'px';
            tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.width = '200px'
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        title: {
          color: chartFontColor
        },
        ticks: {
          color: chartFontColor,
          callback: (value, index, values) => {
            return labels[index].format("MMM")
          }
        }
      },
      y: {
        stacked: true,
        title: {
          color: chartFontColor
        },
        ticks: {
          color: chartFontColor
        }
      }
    }
  }

  return (
    <Box shadow = { mode("light.sm", "dark.sm")} py = "2" px = "4">
      <Heading fontSize = "2xl" mb = "2">Spending</Heading>
      <Box id = "spending-chart">
        <Bar data = { chartData } options = { chartOptions } style = {{ maxHeight: "400px", padding: "10px 5px"}}/>
      </Box>
    </Box>
  )
};

export default Spending;
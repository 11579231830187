import { useEffect, useState } from "react";
import { 
  Container
} from "@chakra-ui/react";
import { AiOutlineFilter } from "react-icons/ai";
import moment from "moment-timezone";

import NetWorth from "./NetWorth";
import Spending from "./Spending";
import TrendsAside from "./TrendsAside";
import { Header, Page, Main } from "components/Page";

const Trends = () => {
  const [ accounts, setAccounts ] = useState([]);
  const [ categories, setCategories ] = useState([]);
  const [ startMonthEnd, setStartMonthEnd ] = useState(moment().subtract(6, 'months').endOf('month'));
  const [ endMonthEnd, setEndMonthEnd ] = useState(moment().endOf('month'));

  
  const monthsCount = endMonthEnd.diff(startMonthEnd, 'months') || 0;
  const monthEnds = Array.from(new Set([...Array(monthsCount).keys()].map(index => endMonthEnd.clone().subtract(index, 'months').format("YYYY-MM-DD"))))
  const yearMonths = Array.from( new Set(monthEnds.map(monthEnd => monthEnd?.slice(0, 7))));

  useEffect(() => {
    window.analytics.page("Trends");
  }, []);

  const selectedAccounts = accounts?.map(account => account.id) || [];
  const selectedCategories = categories?.map(category => category?.id) || [];

  const chartProps = {
    selectedAccounts,
    selectedCategories,
    monthEnds,
    yearMonths
  }

  return (
    <Page>
      <TrendsAside 
        setAccounts = { setAccounts } 
        setCategories = { setCategories } 
        startMonthEnd = { startMonthEnd }
        setStartMonthEnd = { setStartMonthEnd }
        endMonthEnd = { endMonthEnd }
        setEndMonthEnd = { setEndMonthEnd }
      />
      <Main
        hasAside = { true }
        asideButtonLabel = "Filter"
        AsideButtonIcon = { AiOutlineFilter }
        asideShortcutKey = "f"
      >
        <Header title = "Trends"/>
        <Container as = "section" maxW = "container.2xl" experimental_spaceY = "2">
          <NetWorth { ...chartProps } />
          <Spending { ...chartProps } />
        </Container>
      </Main>
    </Page>
  )
};

export default Trends;
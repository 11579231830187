import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { AiOutlineCalendar } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import FocusLock from 'react-focus-lock';
import _ from "lodash";

import { MOVE_MONEY, UPSERT_BUDGET_MONTH, GET_BUDGET_MONTHS } from "../gql";
import CurrencyInputField from "components/CurrencyInputField";
import { CategorySelect } from "components/Select";
import useToast from "hooks/useToast";
import { formatCurrency } from "utils/format";

const getTagColorAndIcon = (remaining, upcomingTransactions, isIncome) => {
  const actualRemaining = isIncome ? remaining - upcomingTransactions : remaining + upcomingTransactions;

  if ( actualRemaining === 0 ) {
    return [ "gray", null ]
  }

  if ( remaining < 0 ) {
    if ( isIncome ) {
      return [ "green", GiMoneyStack ]
    } else {
      return [ "red", null ]
    }
  }

  if ( actualRemaining > 0 ) {
    return [ "green", null ]
  }

  if ( remaining >= 0 && actualRemaining < 0 ) {
    return [ "orange", AiOutlineCalendar ]
  }

  return [ "gray", null ]
};

const CoverSpendingForm = ({ toCategory, currentMonth, onClose, isVisible, amountToCover }) => {
  const renderToast = useToast();
  const [ fromCategory, setFromCategory ] = useState(null);
  const [ coverSpendingMutation ] = useMutation(MOVE_MONEY, {
    update: (cache, { data: { updated_to_budget_month, updated_from_budget_month } }) => {
      const data = cache.readQuery({ query: GET_BUDGET_MONTHS, variables: { budget_id: toCategory.budget_id }});
      cache.writeQuery({
        query: GET_BUDGET_MONTHS,
        variables: { budget_id: toCategory.budget_id },
        data: {
          budget_months: _.unionWith(
            [updated_to_budget_month, updated_from_budget_month, ...data.budget_months], 
            (bm1, bm2) => bm1.category_id === bm2.category_id && bm1.year_month === bm2.year_month)
        }
      })
    }
  });

  useEffect(() => {
    setFromCategory(null);
  }, [ isVisible ]);

  const onSubmit = () => {
    coverSpendingMutation({
      variables: {
        budget_id: toCategory.budget_id,
        year_month: currentMonth,
        from_category_id: fromCategory.id,
        to_category_id: toCategory.id,
        from_amount_increment: -amountToCover,
        to_amount_increment: amountToCover
      }
    })
    .then(() => {
      window.analytics.track("Category Budget Updated", {
        method: "cover_spending"
      });

      renderToast("Category Budgets Updated");
      onClose();
    })
  };

  return (
    <Stack spacing = { 4 }>
      <FormControl>
        <FormLabel htmlFor = "category-select">Cover Spending With:</FormLabel>
        <CategorySelect 
          value = { fromCategory }
          onChange = { setFromCategory }
          shouldLoadData = { isVisible }
          filterFunction = { category => category.id !== toCategory.id && !category.is_income }
        />

      </FormControl>

      <ButtonGroup
        display = "flex"
        flexWrap = "wrap"
        justifyContent = "space-between"
        size = "sm"
      >
        <Button onClick = { onClose }>Cancel</Button>
        <Button
          colorScheme = "primary"
          isDisabled = { !fromCategory }
          onClick = { onSubmit }
        >
          Save
        </Button>
      </ButtonGroup>
    </Stack>
  );
}

const MoveMoneyForm = ({ fromCategory, currentMonth, onClose, isVisible }) => {
  const renderToast = useToast();
  const [ moveAmount, setMoveAmount ] = useState(0);
  const [ toCategory, setToCategory ] = useState(null);
  const [ moveMoneyMutation ] = useMutation(MOVE_MONEY, {
    update: (cache, { data: { updated_to_budget_month, updated_from_budget_month } }) => {
      const data = cache.readQuery({ query: GET_BUDGET_MONTHS, variables: { budget_id: fromCategory.budget_id }});
      cache.writeQuery({
        query: GET_BUDGET_MONTHS,
        variables: { budget_id: fromCategory.budget_id },
        data: {
          budget_months: _.unionWith(
            [updated_to_budget_month, updated_from_budget_month, ...data.budget_months], 
            (bm1, bm2) => bm1.category_id === bm2.category_id && bm1.year_month === bm2.year_month)
        }
      })
    }
  })

  useEffect(() => {
    setMoveAmount(0);
    setToCategory(null);
  }, [ isVisible ]);

  const onSubmit = () => {
    moveMoneyMutation({
      variables: {
        budget_id: fromCategory.budget_id,
        year_month: currentMonth,
        from_category_id: fromCategory.id,
        to_category_id: toCategory.id,
        from_amount_increment: -moveAmount,
        to_amount_increment: moveAmount
      }
    })
    .then(() => {
      window.analytics.track("Category Budget Updated", {
        method: "move_money"
      });

      renderToast("Category Budgets Updated");
      onClose();
    })
  }

  const isValid = moveAmount && toCategory;
  return (
    <Stack spacing = { 4 }>
      <FormControl>
        <FormLabel htmlFor = "move-amount">Move:</FormLabel>
        <CurrencyInputField
          value = { moveAmount }
          onChange = { setMoveAmount }
          variant = "flushed"
          id = "move-amount"
          autoFocus = { true }
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor = "category-select">To:</FormLabel>
        <CategorySelect 
          value = { toCategory }
          onChange = { setToCategory }
          shouldLoadData = { isVisible }
          filterFunction = { category => category.id !== fromCategory.id && !category.is_income }
        />

      </FormControl>

      <ButtonGroup
        display = "flex"
        flexWrap = "wrap"
        justifyContent = "space-between"
        size = "sm"
      >
        <Button onClick = { onClose }>Cancel</Button>
        <Button
          colorScheme = "primary"
          isDisabled = { !isValid }
          onClick = { onSubmit }
        >
          Save
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const Category = ({ category, currentMonth, setTargetCategory, categoryGroupListRef }) => {
  const renderToast = useToast();
  const { onOpen: onOpenMoveMoney, onClose: onCloseMoveMoney, isOpen: isOpenMoveMoney } = useDisclosure();
  const currentMonthBudget = category.currentMonth.budget;
  const currentMonthActivity = category.currentMonth.activity;
  const totalScheduledTransactions = category.currentMonth.scheduledTransactions;
  const remaining = Math.round(category.currentMonth.remaining * 100) / 100;

  const [ upsertBudgetMonth ] = useMutation(UPSERT_BUDGET_MONTH, {
    update: (cache, { data: { insert_budget_months_one }}) => {
      const data = cache.readQuery({ query: GET_BUDGET_MONTHS, variables: { budget_id: category.budget_id }});
      cache.writeQuery({
        query: GET_BUDGET_MONTHS,
        variables: { budget_id: category.budget_id },
        data: {
          budget_months: _.unionWith(
            [insert_budget_months_one, ...data.budget_months], 
            (bm1, bm2) => bm1.category_id === bm2.category_id && bm1.year_month === bm2.year_month)
        }
      })
    }
  });

  const onSubmit = async newValue => {
    const newValueFloat = parseFloat(newValue.toFixed(2));
    if ( newValueFloat !== currentMonthBudget ) {
      await upsertBudgetMonth({
        variables: {
          budget_month: {
            category_id: category.id,
            year_month: currentMonth,
            amount: newValueFloat || 0,
            budget_id: category.budget_id
          }
        }
      });
  
      renderToast("Category Budget Updated");
      window.analytics.track("Category Budget Updated", {
        method: "manual"
      });
    }
  };

  const [ tagColor, tagIcon ] = getTagColorAndIcon(remaining, totalScheduledTransactions, category.is_income);

  const onOpenMoveMoney_ = () => {
    if ( !category.is_income ) {
      onOpenMoveMoney();
    }
  }

  return (
    <SimpleGrid 
      columns = {{ base: 3, sm: 4 }} 
      px = {{ base: 4, sm: 8 }} 
      width = "full"
      py = "1"
      alignItems = "center"
      className = "category list-item"
      id = { category.id }
      onMouseEnter = { () => {
        // Remove highlight class from any current element
        categoryGroupListRef.current?.querySelector(".list-item[data-highlighted=true]")?.setAttribute("data-highlighted", false);

        // Add highlight class to current element
        document.getElementById(category.id).setAttribute('data-highlighted', true);
      }}

      onMouseLeave = { () => {
        // Remove highlight class from element
        document.getElementById(category.id).setAttribute('data-highlighted', false);
      }}
      data-highlighted = { false }
    >
      <Button
        variant = "unstyled"
        size = "sm"
        textAlign = "left"
        isTruncated
        cursor = "pointer"
        onClick = { setTargetCategory }
        _hover = {{
          color: "brand.400"
        }}
        tabIndex = { -1 }
      >{ category.name }</Button>
      <Box display = "flex" justifyContent = "flex-end" ml = "8">
        <CurrencyInputField 
          value = { currentMonthBudget }
          onSubmit = { onSubmit }
          textAlign = "right"
          variant = "flushed"
          height = "unset"
          borderBottomColor = "transparent"
        />
      </Box>
      <Text  display = {{ base: "none", sm: "grid"}}  textAlign = "right">{ formatCurrency(currentMonthActivity)}</Text>
      <Box display = "flex" justifyContent = "flex-end">
        <Popover
          isOpen = { isOpenMoveMoney }
          onOpen = { onOpenMoveMoney_ }
          onClose = { onCloseMoveMoney }
          enabled = { false }
        >
          <PopoverTrigger>
            <Button
              variant = "unstyled"
              tabIndex = { -1 }
              opacity = { 1 }
              cursor = { category.is_income ? "default" : "pointer" }
            >
              <Box 
                display = "flex" 
                justifyContent = "flex-end"
              >
                <Tag colorScheme = { tagColor }>
                  { tagIcon ? <TagLeftIcon as = { tagIcon } /> : null }
                  <TagLabel>{ formatCurrency(remaining) }</TagLabel>
                </Tag>
              </Box>
            </Button>
          </PopoverTrigger>
          <PopoverContent _focus = {{ boxShadow: "none" }} data-is-open = { isOpenMoveMoney }>
            <FocusLock returnFocus persistentFocus = { true } autoFocus = { false }>
              <PopoverCloseButton />
              <PopoverHeader fontSize = "sm">Move Funds</PopoverHeader>
              <PopoverBody>
                { remaining >= 0 ? (
                <MoveMoneyForm 
                  onClose = { onCloseMoveMoney }
                  isVisible = { isOpenMoveMoney }
                  fromCategory = { category }
                  currentMonth = { currentMonth }
                />
                ) : (
                  <CoverSpendingForm
                    onClose = { onCloseMoveMoney }
                    isVisible = { isOpenMoveMoney }
                    toCategory = { category }
                    currentMonth = { currentMonth }
                    amountToCover = { -remaining }
                  />
                ) }
              </PopoverBody>
            </FocusLock>
          </PopoverContent>
        </Popover>
      </Box>
    </SimpleGrid>
  )
};

export default Category;
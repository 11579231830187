const styles = {
  global: props => ({
    ".list-item": {
      outline: "none",
      borderLeft: "2px"
    },
    ".list-item[data-highlighted=true]": {
      bg: props.colorMode === "light" ? "gray.100" : "gray.700",
      borderColor: "brand.400",
    },
    ".list-item[data-highlighted=false]": {
      bg: "transparent",
      borderColor: "transparent"
    }
  })
};

export default styles;
const Modal = {
  parts: ["closeButton", "dialog", "footer", "modal", "header", "overlay"],
  baseStyle: props => ({
    closeButton: {
      borderRadius: "full",
      _focus: {
        boxShadow: "none"
      }
    },
    dialog: {
      rounded: "md",
      shadow: "2xl",
      bg: props.colorMode === "light" ? "white" : "gray.800"
    },
    footer: {
      bg: props.colorMode === "dark" ? "transparent" : "gray.50",
      py: "3",
      borderTop: "1px",
      borderColor: props.colorMode === "dark" ? "transparent" : "gray.100",
      roundedBottom: "md"
    },
    header: {
      fontWeight: "light"
    },
    overlay: {
      bg: props.colorMode === "dark" ? "gray.900" : "gray.100",
      opacity: "60% !important"
    }
  }),
  variants: {
    fullScreen: props => ({
      dialog: {
        height: "full",
        m: "0",
        maxW: "100%",
        width: "full",
        bg: {
          base: props.colorMode === "light" ? "white" : "gray.800",
          md: props.colorMode === "light" ? "white" : "gray.900"
        },
        overflow: "scroll",
        rounded: "none"
      }
    })
  }
};

export default Modal;
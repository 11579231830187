import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { BiUpload } from "react-icons/bi";
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { useMutation } from "@apollo/client";

import Edit from "./Edit";
import { ADD_HOLDINGS, GET_HOLDINGS } from "../gql";
import Upload from "./Upload";
import ShortcutTooltip from "components/ShortcutTooltip";
import getDefaultBudget from "utils/getDefaultBudget";
import iex from "utils/iex";

const UploadHoldings = () => {
  const budgetId = getDefaultBudget();
  const [ account, setAccount ] = useState(null);
  const [ currency, setCurrency ] = useState(null);
  const [ error, setError ] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ uploadData, setUploadData ] = useState(null);
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });
  const [ addHoldings, { loading } ] = useMutation(ADD_HOLDINGS, {
    update: (cache, { data: { insert_investment_holdings } }) => {
      const data = cache.readQuery({ query: GET_HOLDINGS, variables: { budget_id: budgetId } });
      const newHoldings = insert_investment_holdings.returning;
      const oldHoldings = data.investment_holdings.filter(holding => !newHoldings.map(newHolding => newHolding.security.id).includes(holding.security.id))
      cache.writeQuery({ 
        query: GET_HOLDINGS, 
        variables: { budget_id: budgetId },
        data: {
          investment_holdings: [ ...oldHoldings, ...newHoldings ]
        }
      });
    }
  });

  useEffect(() => {
    setAccount(null);
    setError({});
    setUploadData(null);
    reset();
    // eslint-disable-next-line
  }, [ isOpen ]);

  const onFileUpload = async uploadDataArray => {
    // Column check
    const requiredColumns = ['symbol', 'quantity', 'cost basis'];
    const fileColumns = uploadDataArray[0].meta.fields;
    const missingColumn = requiredColumns.find(column => !fileColumns.includes(column))

    if ( missingColumn ) {
      setError({
        screen: 'upload',
        message: `Missing "${missingColumn}" column in csv`
      })
    } else {
      const uploadData = uploadDataArray.map(arr => arr.data);
      setError({});
      const symbols = Array.from(new Set(uploadData.map(row => row.symbol)));
      const symbolsData = await iex.batch({symbols, types: ['company']}).catch(() => {})

      const formattedData = uploadData.map(row => {
        const symbolData = symbolsData[row.symbol]?.company;
        const symbol = symbolData ? {
          label: symbolData ? symbolData.symbol + ": " + symbolData.companyName : null,
          value: symbolData?.symbol
        } : null;

        return {
          symbol,
          quantity: parseFloat(row.quantity || 0) || null,
          costBasis: parseFloat(row['cost basis'] || 0) || null
        }
      })
      setUploadData(formattedData)
    }
  };

  const onFileRemove = () => {
    setError({});
    setUploadData(null)
  }

  const isUploadDataValid = uploadData => {
    const isInvalidRow = row => !row.symbol?.value || !row.quantity || !row.costBasis;
    return uploadData.filter(isInvalidRow).length === 0
  }

  const updateHolding = (field, index, newValue) => {
    setUploadData(prev => prev.map((row, rowIndex) => {
      if ( index === rowIndex ) {
        return {
          ...row,
          [field]: newValue
        }
      } else {
        return row
      }
    }))
  };

  const onSubmit = () => {
    const objects = uploadData.map(row => ({
      account_id: account.value,
      cost_basis: parseFloat(row.costBasis),
      currency: currency.value,
      quantity: parseFloat(row.quantity),
      security: {
        data: {
          symbol: row.symbol.value
        },
        on_conflict: {
          constraint: 'securities_symbol_key',
          update_columns: [ 'symbol' ]
        }
      }
    }));

    addHoldings({
      variables: {
        objects
      }
    })
    .then(onClose);
  }

  const steps = [
    { 
      label: "Upload", 
      content: Upload,
      props: { account, setAccount, isOpen, error, onFileUpload, onFileRemove },
      nextButtonText: "Next",
      isValid: account && uploadData,
      onClick: nextStep
    },
    { 
      label: "Edit", 
      content: Edit ,
      props: { uploadData, account, setAccount, currency, setCurrency, isOpen, updateHolding },
      nextButtonText: "Save Holdings",
      isValid: account && uploadData && isUploadDataValid(uploadData) && currency,
      onClick: onSubmit
    }
  ];

  return (
    <>
      <Button 
        variant = "ghost"
        colorScheme = "gray"
        leftIcon = { <BiUpload /> }
        fontSize = "sm"
        onClick = { onOpen }
        rounded = "sm"
        display = {{ base: "none", md: "inline-flex" }}
      >Upload Holdings</Button>

      <ShortcutTooltip label = "Upload Holdings">
        <Button
          variant = "icon"
          onClick = { onOpen }
          display = {{ base: "inline-flex", md: "none"}}
        ><BiUpload /></Button>
      </ShortcutTooltip>

      <Modal
        isOpen = { isOpen }
        onClose = { onClose }
        size = "3xl"
        returnFocusOnClose = { false }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Holdings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Steps activeStep={activeStep} colorScheme = "primary" responsive = { false }>
              {steps.map(({ label, content, props }) => (
                <Step label={label} key={label}>
                  { content(props) }
                </Step>
              ))}
            </Steps>
          </ModalBody>
          <ModalFooter display = "flex" justifyContent = "space-between">
            <Button
              colorScheme = "gray"
              size = "sm"
              onClick = { prevStep }
              visibility = { activeStep === 0 ? "hidden" : "visible"}
            >
              Back
            </Button>

            <Button
              colorScheme = "primary"
              size = "sm"
              onClick = { steps[activeStep].onClick }
              isDisabled = { !steps[activeStep].isValid }
              isLoading = { loading }
            >
              { steps[activeStep].nextButtonText }
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadHoldings;
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Box, FormLabel } from "@chakra-ui/react";

import CurrencyInputField from "components/CurrencyInputField";
import useToast from "hooks/useToast";

const UPDATE_CURRENT_BALANCE = gql`
  mutation UpdateAccountBalance($id: uuid!, $current_balance: numeric!) {
    update_accounts(_set: { current_balance: $current_balance }, where: { id: { _eq: $id }}) {
      returning {
        id
      }
    }
  }
`;

const AccountCurrentBalance = ({ account }) => {
  const { current_balance, currency } = account;
  const renderToast = useToast();
  const [ updateCurrentBalance ] = useMutation(UPDATE_CURRENT_BALANCE);
  const [ value, setValue ] = useState(current_balance);

  useEffect(() => {
    setValue(current_balance);
  }, [ current_balance ]);

  const onSubmit = () => {
    const newBalanceFloat = parseFloat(value);
    
    if ( newBalanceFloat !== account.current_balance ) {
      updateCurrentBalance({
        variables: {
          id: account.id,
          current_balance: newBalanceFloat
        }
      });

      renderToast("Current balance updated");
      window.analytics.track("Account Updated", {
        field: 'current_balance'
      })
    }
  };

  return (
    <>
      <Box display = "flex" alignItems = "center">
        <FormLabel mb = "0">Current</FormLabel>
      </Box>
      <CurrencyInputField 
        value = { value }
        currency = { currency }
        variant = "flushed"
        disabled = { !!account.plaid_item }
        onSubmit = { onSubmit }
        onChange = { setValue }
        showButtons = { true }
      />
    </>
  )
};

export default AccountCurrentBalance;
import { useContext } from "react";
import {
  Box,
  Container,
  Flex,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { HiMenu } from 'react-icons/hi';
import { AiOutlineClose } from "react-icons/ai";

import AsideButton from "./AsideButton";
import PageContext from "./PageContext";

import LoadingSpinner from "components/LoadingSpinner";
import ShortcutTooltip from "components/ShortcutTooltip";

const Main = ({ children, hasAside = false, asideButtonLabel, isLoading, AsideButtonIcon, asideShortcutKey }) => {
  const { 
    asideBreakpoint,
    closeAside,
    closeNav,
    isAsideOpen,
    isNavOpen,
    navBreakpoint,
    toggleAside,
    toggleNav
  } = useContext(PageContext);

  return (
    <Box
      flex = "1"
      marginStart = {{ [navBreakpoint]: 'var(--nav-width)' }}
      marginEnd = {{ [asideBreakpoint]: hasAside ? 'var(--aside-width)' : 0 }}
      left = { hasAside && isAsideOpen ? "unset" : ( isNavOpen ? 'var(--nav-width)' : 0) }
      right = {isNavOpen ? "unset" : (hasAside && isAsideOpen ? 'var(--aside-width)' : 0) }
      position = "relative"
      transition = "all 0.2s"
    >
      <Box
        maxW = "2560px"
        bg = { mode("white", "gray.800") }
        shadow = { mode("light.lg", "dark.lg")}
        height = "100%"
        pb = "6"
      >
        <Flex direction = "column" height = "full">
          <Flex minH = "8" width = "full" py = "4" justifyContent = "space-between" align = "center" px = "10">
            <ShortcutTooltip isDisabled = { isNavOpen } label = { "Menu" } keys = {["M"]} placement = "left">
              <AsideButton 
                onClick = { toggleNav } 
                closeOther = { closeAside }
                isOpen = { isNavOpen } 
                OpenIcon = { AiOutlineClose }
                CloseIcon = { HiMenu } 
                breakpoint = { navBreakpoint } 
                type = "Navigation" 
                ml = "-8"
                mr = "2"
                _hover = {{ ml: -7 }}
              />
            </ShortcutTooltip>

            { hasAside ? (
              <ShortcutTooltip isDisabled = { isAsideOpen } label = { asideButtonLabel } keys = { asideShortcutKey ? [asideShortcutKey.toUpperCase()] : null } placement = "left">
                <AsideButton 
                  onClick = { toggleAside } 
                  closeOther = { closeNav }
                  isOpen = { isAsideOpen } 
                  OpenIcon = { AsideButtonIcon } 
                  CloseIcon = { AsideButtonIcon }
                  breakpoint = { asideBreakpoint } 
                  type = "Aside" 
                  ml = "6"
                  mr = "-8"
                  _hover = {{ mr: -7 }}
                />
              </ShortcutTooltip>
            ) : null }
          </Flex>
          <Flex direction = "column" flex = "1" overflow = "auto" pt = "2">
            <Container maxW = "container.2xl">
            { isLoading ? <LoadingSpinner /> : children }
            </Container>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
};

export default Main;
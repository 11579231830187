import {
  Box,
  DarkMode,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue as mode,
  useId,
  useColorMode,
  useRadio,
  useRadioGroup,
  VStack
} from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { BiMoon, BiSun } from 'react-icons/bi';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'

import { auth } from "utils/nhost";

const UPDATE_USER_THEME = gql`
  mutation UpdateUserTheme($user_id: uuid!, $default_theme: String!) {
    update_users_by_pk(pk_columns: {id: $user_id}, _set: { default_theme: $default_theme }) {
      id
      default_theme
    }
  }
`;

const CheckboxIcon = ({ checked }) => (
  <Box fontSize="4xl" color={ checked ? 'primary.500' : mode('gray.700', 'whiteAlpha.400')}>
    { checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
  </Box>
)

const RadioButton = ({ label, icon, ...props }) => {
  const radioButtonProps = {
    borderRadius: "sm",
    cursor: "pointer",
    transition: "all 0.2s",
    bg: mode("white", "gray.800")
  }

  const { getCheckboxProps, getInputProps, getLabelProps, state } = useRadio({ ...props });
  const id = useId();

  return (
    <label
      style = {{ width: "100%" }}
      { ...getLabelProps() }
    >
      <input { ...getInputProps() } aria-labelledby = { id } />
      <Box { ...getCheckboxProps() } id = { id } { ...radioButtonProps }>
        <VStack bg = { mode("white", "gray.700") } shadow = { mode("light.lg", "dark.lg")} spacing = "6" p = "4" borderRadius = "sm">
          <VStack 
            textAlign = "center" 
            color = {state.isChecked ? 'primary.500' : mode('gray.700', 'whiteAlpha.400')}>
            <Box
              aria-hidden
              fontSize = "4xl"
              mb = "3"
            >
              { icon }
            </Box>
            <Text fontWeight = "extrabold" fontSize = "xl">{ label }</Text>
          </VStack>
          <CheckboxIcon checked = { state.isChecked } />
        </VStack>
      </Box>
    </label>
  )
}

const ChangeTheme = ({ defaultTheme, onClose, isOpen }) => {
  const [ updateUserTheme ] = useMutation(UPDATE_USER_THEME);
  const { colorMode, toggleColorMode } = useColorMode();

  const systemMode = window.matchMedia ? (window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"): "light"

  const onColorThemeChange = newTheme => {
    if ( newTheme === defaultTheme ) {
      return null;
    }

    const user = auth.user();

    updateUserTheme({
      variables: {
        user_id: user.id,
        default_theme: newTheme
      }
    })
    .then(() => {
      if ( newTheme === "system" ) {
        localStorage.removeItem("chakra-ui-color-mode");

        if ( systemMode !== colorMode ) {
          toggleColorMode();
        }
      } else {
        localStorage.setItem("chakra-ui-color-mode", newTheme);
        if ( colorMode !== newTheme ) {
          toggleColorMode();
        }
      }
    });
  };

  const { getRadioProps, getRootProps } = useRadioGroup({ onChange: onColorThemeChange, defaultValue: defaultTheme, value: defaultTheme });
  
  return (
    <Modal isOpen = { isOpen } onClose = { onClose } size = "2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Theme</ModalHeader>
        <ModalCloseButton />
        <ModalBody py = "4">
          <Stack
            justify = "center"
            direction = {{ base: "column", md: "row" }}
            spacing = "4"
            { ...getRootProps() }
          >
            <LightMode>
              <RadioButton
                icon = { <BiSun /> }
                label = "Light"
                {...getRadioProps({
                  value: "light"
                })}
              />
            </LightMode>

            <DarkMode>
              <RadioButton
                icon = { <BiMoon /> }
                label = "Dark"
                {...getRadioProps({
                  value: "dark"
                })}
              />
            </DarkMode>

            { systemMode === "light" ? (
              <LightMode>
                <RadioButton
                icon = { <BiSun /> }
                label = "System"
                {...getRadioProps({
                  value: "system"
                })}
              />
              </LightMode>
            ) : (
              <DarkMode>
                <RadioButton
                  icon = { <BiMoon /> }
                  label = "System"
                  {...getRadioProps({
                    value: "system"
                  })}
                />
              </DarkMode>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

export default ChangeTheme;
import { useRef } from "react";
import {
  VStack
} from "@chakra-ui/react";

import Institution from "./Institution";
import useHotKeys from "hooks/useHotKeys";

const InstitutionsList = ({ manualAccounts = [], plaidItems = [], setTargetAccount, selectedAccountId, setSelectedAccountId  }) => {
  const institutionsListRef = useRef(null);

  useHotKeys('up', () => {
    const currentFocus = institutionsListRef.current?.querySelector('li:focus');
    if ( currentFocus ) {
      const previousSibling = currentFocus.previousElementSibling;
      if ( previousSibling ) {
        previousSibling.focus();
      }
    } else {
      institutionsListRef.current?.firstChild.querySelector('.accounts').firstChild?.focus();
    }
  }, [ institutionsListRef ]);

  useHotKeys('down', () => {
    const currentFocus = institutionsListRef.current?.querySelector('li:focus');
    if ( currentFocus ) {
      const nextSibling = currentFocus.nextElementSibling;
      if ( nextSibling ) {
        nextSibling.focus();
      }
    } else {
      institutionsListRef.current?.firstChild.querySelector('.accounts').firstChild?.focus();
    }
  }, [ institutionsListRef ]);

  useHotKeys('enter', e => {
    const currentFocus = institutionsListRef.current?.querySelector('li:focus');
    currentFocus?.click();

    e.stopPropagation();
    e.preventDefault();
  }, [ institutionsListRef ]);

  return (
    <VStack
      width = "full"
      mt = "4"
      ref = { institutionsListRef }
    >
      { plaidItems.map(plaidItem => (
        <Institution
          key = { plaidItem.id }
          institution = { plaidItem }
          setTargetAccount = { setTargetAccount }
        />
      ))}

      { manualAccounts.length > 0 ? (
        <Institution
          key = "manual"
          institution = {{ name: "Manual Accounts", accounts: manualAccounts }}
          setTargetAccount = { setTargetAccount }
        />
      ) : null}
    </VStack>
  );
}

export default InstitutionsList;
import gql from "graphql-tag";

import { ALL_BUDGET_MONTH_FIELDS, ALL_CATEGORY_FIELDS, ALL_CATEGORY_GROUP_FIELDS } from "fragments";

const HARD_RESET_PLAN = gql`
  mutation HardResetPlan($budget_id: uuid!) {
    delete_categories(where: {budget_id: {_eq: $budget_id}, is_system: {_eq: false}}) {
      affected_rows
    }
    delete_category_groups(where: {budget_id: {_eq: $budget_id}}) {
      affected_rows
    }
  }
`;

const SOFT_RESET_PLAN = gql`
  mutation SoftResetPlan($budget_id: uuid!) {
    delete_budget_months(where: {budget_id: {_eq: $budget_id}}) {
      affected_rows
    }
  }
`;

const GET_BUDGET_MONTHS = gql`
  ${ALL_BUDGET_MONTH_FIELDS}
  query GetBudgetMonths($budget_id: uuid!) {
    budget_months(where: { budget_id: { _eq: $budget_id }}) {
      ...BudgetMonthFields
    }
  }
`;

const GET_CASH_ACCOUNTS = gql`
  query GetAccountsData($budget_id: uuid!) {
    cash_accounts: accounts(where: {type: {_eq: "depository"}, is_hidden: { _eq: false }, budget_id: {_eq: $budget_id}}) {
      id
      transactions_aggregate(where: {is_deleted: {_eq: false}}) {
        aggregate {
          sum {
            amount_converted
          }
          min {
            authorized_at
          }
        }
      }
      current_balance
    }
  }
`;

const GET_CATEGORIES = gql`
  ${ALL_CATEGORY_FIELDS}
  query GetCategories($budget_id: uuid!) {
    categories(order_by: { name: asc }, where: { budget_id: { _eq: $budget_id }}) {
      ...AllCategoryFields
    }
  }
`;

const GET_CATEGORY_GROUPS = gql`
  ${ALL_CATEGORY_GROUP_FIELDS}
  query GetCategoryGroups($budget_id: uuid!) {
    category_groups(order_by: { name: asc }, where: { budget_id: { _eq: $budget_id }}) {
      ...AllCategoryGroupFields
    }
  }
`;

const GET_BUDGET_DATA = gql`
  query GetBudgetData($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      default_currency
      year_months {
        year_month
      }
    }
  }
`;

const GET_BUDGET_STATS_DATA = gql`
  subscription GetBudgetStatsData($budget_id: uuid!) {
    year_months(where: {budget_id: {_eq: $budget_id}}) {
      year_month
      activity {
        amount
        category {
          id
          is_income
          is_rollover
          is_system
        }
      }
      budgets {
        amount
        category {
          id
          is_income
          is_rollover
          is_system
        }
      }
    }
  }
`;

const INSERT_CATEGORY = gql`
  ${ALL_CATEGORY_FIELDS}
  mutation InsertCategory($category: categories_insert_input!) {
    insert_categories_one(object: $category) {
      ...AllCategoryFields
    }
  }
`;

const INSERT_CATEGORY_GROUP = gql`
  ${ALL_CATEGORY_GROUP_FIELDS}
  mutation InsertCategoryGroup($category_group: category_groups_insert_input!) {
    insert_category_groups_one(object: $category_group) {
      ...AllCategoryGroupFields
    }
  }
`;

const UPDATE_CATEGORY_NAME = gql`
  mutation UpdateCategoryName($category_id: uuid!, $name: citext!) {
    update_categories_by_pk(
      pk_columns: { id: $category_id },
      _set: { name: $name }
    ) {
      id
      name
    }
  }
`;

const UPDATE_CATEGORY_GROUP_NAME = gql`
  mutation UpdateCategoryGroupName($category_group_id: uuid!, $name: String!) {
    update_category_groups_by_pk(
      pk_columns: { id: $category_group_id },
      _set: { name: $name }
    ) {
      id
      name
    }
  }
`;

const UPSERT_BUDGET_MONTH = gql`
  ${ALL_BUDGET_MONTH_FIELDS}
  mutation UpsertBudgetMonth($budget_month: budget_months_insert_input!) {
    insert_budget_months_one(
      object: $budget_month,
      on_conflict: {
        constraint: budget_months_pkey,
        update_columns: [ amount ]
      }
    ) { ...BudgetMonthFields }
  }
`;

const MOVE_MONEY = gql`
  ${ALL_BUDGET_MONTH_FIELDS}
  mutation MoveMoney($budget_id: uuid!, $year_month: String!, $from_category_id: uuid!, $to_category_id: uuid!, $from_amount_increment: numeric!, $to_amount_increment: numeric!) {
    upsert_from_category: insert_budget_months_one(
      object: {
        category_id: $from_category_id,
        year_month: $year_month,
        amount: 0,
        budget_id: $budget_id
      },
      on_conflict: {
        constraint: budget_months_pkey,
        update_columns: []
      }
    ) { ...BudgetMonthFields }

    upsert_to_category: insert_budget_months_one(
      object: {
        category_id: $to_category_id,
        year_month: $year_month,
        amount: 0,
        budget_id: $budget_id
      },
      on_conflict: {
        constraint: budget_months_pkey,
        update_columns: []
      }
    ) { ...BudgetMonthFields }

    updated_from_budget_month: update_budget_months_by_pk(pk_columns: {
      budget_id: $budget_id, 
      category_id: $from_category_id, 
      year_month: $year_month
    }, _inc: {amount: $from_amount_increment }) { ...BudgetMonthFields }

    updated_to_budget_month: update_budget_months_by_pk(pk_columns: {
      budget_id: $budget_id, 
      category_id: $to_category_id, 
      year_month: $year_month
    }, _inc: {amount: $to_amount_increment }) { ...BudgetMonthFields }
  }
`;

const createBatchRequest = ({ name, type, queryBody }) => 
  gql`
    ${type} ${name} {
      ${queryBody}
    }
  `;

const DELETE_BUDGET_MONTH = gql`
  mutation DeleteBudgetMonth($category_id: uuid!, $year_month: String!, $budget_id: uuid!) {
    delete_budget_months_by_pk(budget_id: $budget_id, category_id: $category_id, year_month: $year_month) {
      budget_id
      category_id
      year_month
    }
  }
`;

const UPSERT_BUDGET_MONTHS = gql`
  ${ALL_BUDGET_MONTH_FIELDS}
  mutation UpsertBudgetMonths($objects: [budget_months_insert_input!]!) {
    insert_budget_months(
      objects: $objects,
      on_conflict: {
        constraint: budget_months_pkey,
        update_columns: [ amount ]
      }
    ) {
      returning {
        ...BudgetMonthFields
      }
    }
  }
`;

export {
  createBatchRequest,
  DELETE_BUDGET_MONTH,
  HARD_RESET_PLAN,
  GET_BUDGET_MONTHS,
  GET_BUDGET_DATA,
  GET_CASH_ACCOUNTS,
  GET_CATEGORIES,
  GET_CATEGORY_GROUPS,
  GET_BUDGET_STATS_DATA,
  INSERT_CATEGORY,
  INSERT_CATEGORY_GROUP,
  MOVE_MONEY,
  SOFT_RESET_PLAN,
  UPDATE_CATEGORY_NAME,
  UPDATE_CATEGORY_GROUP_NAME,
  UPSERT_BUDGET_MONTH,
  UPSERT_BUDGET_MONTHS
}
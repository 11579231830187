import {
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerContent,
    useBreakpointValue
  } from "@chakra-ui/react";
  
  import TransactionDetails from "components/TransactionDetails";
  
  const TransactionDrawer = ({ isOpen, onClose, transactionId }) => {
    const size = useBreakpointValue({ base: "full", md: "sm" });

    const _onClose = () => {
      window.analytics.track("Transaction Drawer Closed");
      onClose();
    }
  
    return (
      <Drawer
        onClose = { _onClose }
        isOpen = { isOpen }
        placement = "right"
        size = { size }
      >
        <DrawerOverlay>
          <DrawerContent>
          <DrawerCloseButton />
            <DrawerHeader>Transaction</DrawerHeader>
            <DrawerBody>
              <TransactionDetails 
                transactionId = { transactionId } 
                onDeleteCallback = { onClose }
              />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    )
  };
  
  export default TransactionDrawer;
import { useEffect, useState } from "react";
import { AiOutlineFilter } from "react-icons/ai";

import AddScheduledTransaction from "./AddScheduledTransaction";
import ScheduledTransactionsAside from "./ScheduledTransactionsAside";
import ScheduledTransactionDrawer from "./ScheduledTransactionDrawer";
import ScheduledTransactionsTable from "./ScheduledTransactionsTable";
import { Page, Main, Header } from "components/Page";

const ScheduledTransactions = () => {
  const [ filters, setFilters ] = useState([]);
  const _setFilters = (id, value) => {
    setFilters(prev => prev.filter(filter => filter.id !== id).concat({ id, value}));
  };

  const [ focusScheduledTransaction, setFocusScheduledTransaction ] = useState(null);

  const _setFocusScheduledTransaction = scheduledTransactionId => {
    window.analytics.track("Scheduled Transaction Drawer Opened");
    setFocusScheduledTransaction(scheduledTransactionId);
  }

  useEffect(() => {
    window.analytics.page("Scheduled Transactions");
  }, []);

  return (
    <Page>
      <ScheduledTransactionsAside setFilters = { _setFilters } />
      <Main
        asideButtonLabel = "Filter Scheduled Transactions"
        AsideButtonIcon = { AiOutlineFilter }
        hasAside = { true }
        asideShortcutKey = "f"
      >
        <Header title = "Scheduled Transactions"><AddScheduledTransaction /></Header>
        <ScheduledTransactionsTable
          setFocusScheduledTransaction = { _setFocusScheduledTransaction }
          disableHotkeys = { !!focusScheduledTransaction }
          filters = { filters }
        />
        <ScheduledTransactionDrawer
          isOpen = { !!focusScheduledTransaction }
          onClose = { () => setFocusScheduledTransaction(null) }
          scheduledTransactionId = { focusScheduledTransaction }
        />
      </Main>
    </Page>
  )
};

export default ScheduledTransactions;
import gql from "graphql-tag";

const GET_ACCOUNT_HISTORY = gql`
  query GetAccountHistory($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      accounts(where: {is_hidden: {_eq: false}}) {
        id
        name
        type
        transactions_by_month {
          amount
          year_month
        }
        current_balance
        sync_start_date
        transactions_aggregate {
          aggregate {
            min {
              authorized_at
            }
          }
        }
      }
    }
  }
`;

const GET_SPENDING = gql`
query GetSpending($budget_id: uuid!) {
  budget: budgets_by_pk(id: $budget_id) {
    category_groups {
      id
      name
      categories(where: {is_income: {_eq: false}}) {
        id
        name
        transactions_by_account_month {
          account_id
          amount
          year_month
        }
      }
    }
    categories(where: {is_income: {_eq: false}, category_group_id: {_is_null: true}, transactions_by_account_month: {}}) {
      id
      name
      transactions_by_account_month {
        account_id
        amount
        year_month
      }
    }
    uncat_transactions: transactions_by_account_category_month(where: {category_id: {_is_null: true}, type: {_eq: "transaction"}}) {
      account_id
      year_month
      amount
    }
  }
}

`;

export {
  GET_ACCOUNT_HISTORY,
  GET_SPENDING
}
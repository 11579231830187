import { memo } from "react";
import {
  Box,
  Divider,
  Fade,
  VStack
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { Aside } from "components/Page";

import BudgetStat from "./BudgetStat";
import QuickBudget from "./QuickBudget";

const shouldNotReRender = (prev, next) => {
  return next.isLoading;
}

const BudgetStats = ({ isLoading, hasData, accountsData, categories, currentMonth }) => {
  const startingBalances = accountsData?.map(account => {
    const currentBalance = account.current_balance;
    const yearMonth = moment(account.transactions_aggregate.aggregate.min.authorized_at || undefined).format("YYYY-MM");
    const totalAmount = account.transactions_aggregate.aggregate.sum.amount_converted || 0
    return {
      yearMonth,
      startingBalance: currentBalance - totalAmount
    }
  }) || [];

  const currentMonthStartingCashBalance = startingBalances
    .filter(balance => balance.yearMonth === currentMonth)
    .reduce((total, curr) => total + curr.startingBalance, 0);

  const currentMonthIncome = categories
    .filter(category => category.is_income)
    .map(category => {
      const { budget, activity } = category.currentMonth;
      const extraIncome = Math.max(activity - budget, 0);

      return {
        budget,
        extra: extraIncome,
        total: budget + extraIncome
      }
    });
  const currentMonthBudgetedIncome = currentMonthIncome.reduce((total, category) => total + category.budget, 0);
  const currentMonthExtraIncome = currentMonthIncome.reduce((total, category) => total + category.extra, 0);
  const currentMonthTotalIncome = currentMonthIncome.reduce((total, category) => total + category.total, 0);
  
  const currentMonthExpenses = categories
    .filter(category => !category.is_income)
    .map(category => {
      const { budget, activity } = category.currentMonth;
      const extraExpenses = Math.max(-activity - budget, 0);

      return {
        budget,
        extra: extraExpenses,
        total: budget + extraExpenses
      }
    });

  const currentMonthBudgetedExpenses = currentMonthExpenses.reduce((total, category) => total + category.budget, 0);
  const currentMonthExtraExpenses = currentMonthExpenses.reduce((total, category) => total + category.extra, 0);
  const currentMonthTotalExpenses = currentMonthExpenses.reduce((total, category) => total + category.total, 0);

  const rolloverStartingStartedCashBalance = startingBalances
    .filter(balance => balance.yearMonth < currentMonth)
    .reduce((total, curr) => total + curr.startingBalance, 0);
  const rolloverTotalIncome = categories
    .filter(category => category.is_income)
    .map(category => category.previousRollover.actuals)
    .reduce((total, curr) => total + curr, 0);
  const rolloverTotalExpenses = categories
    .filter(category => !category.is_income)
    .map(category => category.previousRollover.actuals)
    .reduce((total, curr) => total + curr, 0);

  const totalRollover = rolloverStartingStartedCashBalance + rolloverTotalIncome - rolloverTotalExpenses
  const availableToBudget = currentMonthStartingCashBalance + currentMonthTotalIncome - currentMonthTotalExpenses + totalRollover;

  return (
    <Aside>
      <Fade in = { hasData }>
        <Box width = "full" fontSize = {{ base: "sm", lg: "unset"}} rounded = "md" py = "4" px = {{ base: "4", sm: "8"}}>
          <VStack>
            <BudgetStat label = "Total Income" amount = { currentMonthStartingCashBalance + currentMonthTotalIncome } tooltipStats = {[
              { label: "Starting Cash Balance", value: currentMonthStartingCashBalance },
              { label: "Budgeted Income", value: currentMonthBudgetedIncome },
              { label: "Extra Income", value: currentMonthExtraIncome }
            ]} />
            <BudgetStat label = "Total Expenses" amount = { currentMonthTotalExpenses } tooltipStats = {[
              { label: "Budgeted Expenses", value: currentMonthBudgetedExpenses },
              { label: "Extra Expenses", value: currentMonthExtraExpenses }
            ]}/>
            <BudgetStat label = "Last Month Rollover" amount = { totalRollover } />
            <Divider />
            <BudgetStat label = "Available To Budget" amount = { availableToBudget || 0 } />
          </VStack>
        </Box>

        <QuickBudget />
      </Fade>
    </Aside>
  )
};

export default memo(BudgetStats, shouldNotReRender);;
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import { HiPlus } from 'react-icons/hi';

import AddAccountComponent from "components/AddAccount";

const AddAccount = ({ budgetId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const size = useBreakpointValue({ base: "full", sm: "sm" });

  const _onOpen = () => {
    window.analytics.track("Add Account Drawer Opened");
    onOpen();
  };

  const _onClose = () => {
    window.analytics.track("Add Account Drawer Closed");
    onClose();
  };

  return (
    <>
      <Button 
        variant = "ghost" 
        colorScheme = "gray" 
        leftIcon = { <HiPlus /> } 
        fontSize = "sm" 
        onClick = { _onOpen }
        rounded = "sm"
      >Add Account</Button>

      <Drawer
        isOpen = { isOpen }
        onClose = { _onClose }
        size = { size }
        trapFocus = { false }
        autoFocus = { false }
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Add Account</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <AddAccountComponent budgetId = { budgetId } createAccountCallback = { onClose } />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
};

export default AddAccount;
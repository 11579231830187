import { 
  Box,
  Button,
  Text
} from "@chakra-ui/react";
import mousetrap from "mousetrap";
import { BiArrowBack, BiBarChart } from "react-icons/bi";
import { FaBalanceScale } from "react-icons/fa";

import FieldGroup from "components/FieldGroup";
import ShortcutTooltip from "components/ShortcutTooltip";

const QuickBudget = () => (
  <FieldGroup title = "Quick Budget">
    <ShortcutTooltip label = "Last Month Budget" keys = {['⌥', 'L']}>
      <Button
        onClick = { () => mousetrap.trigger("option+l", 'click') }
        fontSize = "sm"
        display = "flex"
        justifyContent = "space-between"
        width = "full"
        colorScheme = "tertiary"
      >
        <Box><BiArrowBack /></Box>
        <Text>Last Month Budget</Text>
        <Box></Box>
      </Button>
    </ShortcutTooltip>

    <ShortcutTooltip label = "Average Spending" keys = {['⌥', 'A']}>
      <Button
        onClick = { () => mousetrap.trigger("option+a", 'click') }
        fontSize = "sm"
        display = "flex"
        justifyContent = "space-between"
        width = "full"
        colorScheme = "tertiary"
      >
        <Box><BiBarChart /></Box>
        <Text>Average Spending</Text>
        <Box></Box>
      </Button>
    </ShortcutTooltip>

    <ShortcutTooltip label = "True Up" keys = {['⌥', 'T']}>
      <Button
        onClick = { () => mousetrap.trigger("option+t", 'click') }
        fontSize = "sm"
        display = "flex"
        justifyContent = "space-between"
        width = "full"
        colorScheme = "tertiary"
      >
        <Box><FaBalanceScale /></Box>
        <Text>True Up</Text>
        <Box></Box>
      </Button>
    </ShortcutTooltip>
  </FieldGroup>
);

export default QuickBudget;

import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Text,
    useBoolean
} from "@chakra-ui/react";
import moment from "moment-timezone";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { ExternalLinkIcon } from "@chakra-ui/icons";

import CopyField from "components/CopyField";
import API from "utils/api";

const GET_ACCESS_TOKEN = gql`
  query GetAccessToken {
    access_tokens {
      id
      hash
      mask
      created_at
    }
  }
`;

const apiDocsUrl = "https://docs.omnimoney.app";

const APIScreen = ({ onClose, isOpen }) => {
  const { data } = useQuery(GET_ACCESS_TOKEN, { skip: !isOpen });
  const [ accessToken, setAccessToken ] = useState(null);
  const [ newAccessToken, setNewAccessToken ] = useState(null);
  const [ isLoading, setIsLoading ] = useBoolean();

  useEffect(() => {
    const _accessToken = data?.access_tokens[0];
    if ( _accessToken ) {
      setAccessToken(_accessToken)
    }
  }, [ data ]);

  useEffect(() => {
    if ( !isOpen ) {
      setIsLoading.off();
      setNewAccessToken(null);
      setAccessToken(null);
    }
  }, [ isOpen, setIsLoading ]);

  const generateNewAccessToken = () => {
    setIsLoading.on();
    const api = new API();
    api.generateAccessToken()
    .then(resp => {
      window.analytics.track("Access Token Generated");
      setNewAccessToken(resp);
      setAccessToken(resp);
      setIsLoading.off();
    })
  }

  return (
    <Modal isOpen = { isOpen } onClose = { onClose } size = "xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>OmniMoney API</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb = "1" fontWeight = "semibold">API documentation: <Link fontWeight = "normal" _hover = {{ color: "brand.600" }} href = { apiDocsUrl } isExternal>{ apiDocsUrl } <ExternalLinkIcon mx="2px" /></Link></Text>
          <Text>You can use the OmniMoney API to access your budget data in other applications or programmatically. Because you can view, create, and update your data through the api, it's best that you don't share your access token with any one else.</Text>
          

          <Box rounded = "sm" my = "4" p = "2" border = "1px" borderColor = "gray.300" display = { newAccessToken ? "block" : "none" } width = "full">
            <Text>This is your new access tken. To keep your account secure, it will not be shown to you again, so be sure to copy it down now.</Text>
            <CopyField copyText = { newAccessToken?.access_token || "" } mt = "2" />
          </Box>

          <Box display = { accessToken ? "block" : "none" } width = "full">
            <Divider my = "3" />
            <SimpleGrid columns = "2">
              <Text fontWeight = "medium">Current access token</Text>
              <Text>...{ accessToken?.mask }</Text>

              <Text fontWeight = "medium">Created on</Text>
              <Text>{ moment(accessToken?.created_at).format('LLL') }</Text>
            </SimpleGrid>
          </Box>
        </ModalBody>

        <ModalFooter mt = "2">
          <Button
            colorScheme = "brand"
            isLoading = { isLoading }
            loadingText = "Loading..."
            rounded = "sm"
            width = "full"
            onClick = { generateNewAccessToken }
          >Generate new access token</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

export default APIScreen;
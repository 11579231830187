import { useMemo } from "react";
import {
  Box
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";

import BodyRow from "./BodyRow";
import { GET_SCHEDULED_TRANSACTIONS } from "../gql";
import HeaderRow from "./HeaderRow";
import NullState from "components/NullState";
import Table from "components/Table";
import shoppingCartIcon from "images/icons/shopping-cart.svg";
import { formatCurrency } from "utils/format";
import getDefaultBudget from "utils/getDefaultBudget";

const ScheduledTransactionsTable = ({ setFocusScheduledTransaction, disableHotkeys, filters }) => {
  const budgetId = getDefaultBudget();
  const { data: scheduledTransactionsData } = useQuery(GET_SCHEDULED_TRANSACTIONS, {
    variables: {
      budget_id: budgetId
    }
  });

  const columns = useMemo(() => [
    {
      Header: "Next",
      accessor: "next_date",
      Cell: props => <span>{ moment(props.value).format("MMM D") }</span>
    }, {
      Header: 'Payee',
      accessor: "payee.id",
      id: "payee",
      Cell: props => {
        const scheduledTransaction = props.row.original;
        return <span>{ scheduledTransaction.transfer_account ? `Transfer ${ scheduledTransaction.amount < 0 ? "to" : "from" }: ${scheduledTransaction.transfer_account.name}` : scheduledTransaction.payee.name }</span>
      },
      filter: (rows, columnIds, filterValue) => {
        if ( filterValue.length === 0 ) {
          return rows
        } else {
          return rows.filter(row => filterValue.includes(row.original.payee?.id) || filterValue.includes(row.original.transfer_account?.id))
        }
      }
    }, {
      Header: "Account",
      accessor: "account.id",
      id: "account",
      Cell: props => <span>{ props.row.original.account.name }</span>,
      filter: (rows, columnIds, filterValue) => {
        if ( filterValue.length === 0 ) {
          return rows
        } else {
          return rows.filter(row => filterValue.includes(row.original.account.id))
        }
      }
    }, {
      Header: "Category",
      accessor: "category.id",
      id: "category",
      Cell: props => {
        const scheduledTransaction = props.row.original;
        return <span>{ scheduledTransaction.transfer_account && !scheduledTransaction.needs_category ? "Transfer" : scheduledTransaction.category?.name }</span>
      },
      filter: (rows, columnIds, filterValue) => {
        if ( filterValue.length === 0 ) {
          return rows
        } else {
          const noCategoryFilterResults = filterValue.includes('none') ? rows.filter(row => !row.original.category) : [];
          return rows.filter(row => filterValue.includes(row.original.category?.id)).concat(noCategoryFilterResults);
        }
      }
    }, {
      Header: "Amount",
      accessor: "amount",
      id: "amount",
      Cell: props => <span>{ formatCurrency(props.value, props.row.original.currency) } </span>
    }
  ], []);
  const data = useMemo(() => scheduledTransactionsData?.scheduled_transactions || [], [ scheduledTransactionsData ]);

  const sortBy = useMemo(() => [
    { id: 'next_date', desc: false },
    { id: 'id', desc: true }
  ], []);

  return (
    <Box>
      <Table
        variant = "styledFocus"
        columns = { columns }
        data = { data }
        sortBy = { sortBy }
        disableHotKeys = { disableHotkeys }
        BodyRow = { ({ row }) => <BodyRow row = { row } onRowClick = { () => setFocusScheduledTransaction(row.original.id )} /> }
        HeaderRow = { HeaderRow }
        filters = { filters }
        isLoading = { !scheduledTransactionsData }
        NoDataComponent = { () => (
          <NullState
            icon = { shoppingCartIcon }
            message = "Get started by adding your first scheduled transaction."
            mt = "8"
            py = "4"
          />
        )}
      />
    </Box>
  )
};

export default ScheduledTransactionsTable;
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { Box } from "@chakra-ui/react";

import AddCategoryGroup from "./AddCategoryGroup";
import PlanAside from "./PlanAside";
import PlanQuickActions from "./PlanQuickActions";
import CategoryDrawer from "./CategoryDrawer";
import CategoryGroupDrawer from "./CategoryGroupDrawer";
import CategoryGroupList from "./CategoryGroupList";
import PlanHeader from "./PlanHeader";
import useHotKeys from "hooks/useHotKeys";

import { 
  GET_BUDGET_MONTHS,
  GET_BUDGET_DATA, 
  GET_CASH_ACCOUNTS, 
  GET_CATEGORIES,
  GET_CATEGORY_GROUPS 
} from "./gql"
import { Page, Main, Header } from "components/Page";
import { getDatesBetween } from "utils/calculations";
import getDefaultBudget from "utils/getDefaultBudget";


const Plan = () => {
  const budgetId = getDefaultBudget();
  const [ targetCategoryGroupId, setTargetCategoryGroupId ] = useState(null);
  const [ targetCategoryId, setTargetCategoryId ] = useState(null);
  const [ focusCategoryId, setFocusCategoryId ] = useState(null);
  const [ currentMonth, setCurrentMonth ] = useState(moment().format("YYYY-MM"));
  const disableHotKeys = targetCategoryGroupId || targetCategoryId;

  const { data: budgetData } = useQuery(GET_BUDGET_DATA, { variables: { budget_id: budgetId }});
  const { data: cashAccountsData } = useQuery(GET_CASH_ACCOUNTS, { variables: { budget_id: budgetId }});
  const { data: categoryGroupsData } = useQuery(GET_CATEGORY_GROUPS, { variables: { budget_id: budgetId }});
  const { data: budgetMonthsData, loading: isLoadingBudgetMonthData } = useQuery(GET_BUDGET_MONTHS, { variables: { budget_id: budgetId }});
  const { data: categoriesData } = useQuery(GET_CATEGORIES, { variables: { budget_id: budgetId }});
  const hasData = budgetData && cashAccountsData && categoryGroupsData && budgetMonthsData && categoriesData;
  const isLoading = isLoadingBudgetMonthData;

  const budget = budgetData?.budget || {};
  const cashAccounts = cashAccountsData?.cash_accounts || [];
  const categoryGroups = categoryGroupsData?.category_groups || [];
  const budgetMonths = budgetMonthsData?.budget_months || [];
  const categories = categoriesData?.categories || [];

  const changeMonth = change => setCurrentMonth(prevMonth => moment(prevMonth).add(change, 'month').format("YYYY-MM"));

  const afterDate = moment(currentMonth).startOf('month').format("YYYY-MM-DD");
  const beforeDate = moment(currentMonth).endOf('month').format("YYYY-MM-DD");

  const calculatedCategories = categories.map(category => {
    const categoryBudgetMonths = budgetMonths.filter(budgetMonth => budgetMonth.category_id === category.id);
    const currentMonthBudget = categoryBudgetMonths.find(budgetMonth => budgetMonth.year_month === currentMonth)?.amount || 0;
    const currentMonthActivity = category.transactions_by_month.find(transactionMonth => transactionMonth.year_month === currentMonth)?.amount || 0;
    const currentMonthScheduledTransactions = category.scheduled_transactions.map(transaction => {
      const upcomingDates = getDatesBetween({
        startDate: transaction.next_date,
        endDate: transaction.end_after_date,
        frequency: transaction.frequency,
        afterDate,
        beforeDate
      });

      return transaction.amount * upcomingDates.length;
    })
    .reduce((total, curr) => total + curr, 0);

    const allPastYearMonths = Array.from(new Set(budgetMonths.map(budgetMonth => budgetMonth.year_month).concat(category.transactions_by_month.map(transactionMonth => transactionMonth.year_month))))
      .filter(yearMonth => yearMonth < currentMonth);
    
    const monthlyActuals = allPastYearMonths
      .map(yearMonth => {
        const budget = categoryBudgetMonths.find(budgetMonth => budgetMonth.year_month === yearMonth)?.amount || 0;
        const activity = category.transactions_by_month.find(transactionMonth => transactionMonth.year_month === yearMonth)?.amount || 0;
        if ( category.is_income ) {
          return Math.abs(activity) > Math.abs(budget) ? activity : budget;
        } else {
          return Math.abs(activity) > Math.abs(budget) ? -activity : budget;
        }
      })
      .reduce((total, curr) => total + curr, 0);

    const rolloverBudget = categoryBudgetMonths
      .filter(budgetMonth => budgetMonth.year_month < currentMonth)
      .reduce((total, curr) => total + curr.amount, 0);
    const rolloverActivity = category.transactions_by_month
      .filter(transactionMonth => transactionMonth.year_month < currentMonth)
      .reduce((total, curr) => total + curr.amount, 0);
    const totalRollover = category.is_income ? rolloverBudget - rolloverActivity : rolloverBudget + rolloverActivity;


    const budgetWithRollover = category.is_rollover ? parseFloat((currentMonthBudget + totalRollover).toFixed(2)) : currentMonthBudget;
    const remaining = category.is_income ? budgetWithRollover - currentMonthActivity : budgetWithRollover + currentMonthActivity;

    const transactionMonthsForAverage = category.transactions_by_month.filter(transactionMonth => transactionMonth.year_month < currentMonth && transactionMonth.year_month >= moment(currentMonth).subtract(6, 'months').format("YYYY-MM"));
    
    return {
      ...category,
      currentMonth: {
        activity: currentMonthActivity,
        budget: currentMonthBudget,
        budgetWithRollover,
        scheduledTransactions: currentMonthScheduledTransactions,
        remaining
      },
      lastMonthBudget: categoryBudgetMonths.find(budgetMonth => budgetMonth.year_month === moment(currentMonth).subtract(1, 'month').format("YYYY-MM"))?.amount || 0,
      averageActivity: (category.is_income ? 1 : -1) * (transactionMonthsForAverage.reduce((total, curr) => total + curr.amount, 0) / Array.from( new Set(transactionMonthsForAverage.map(tm => tm.year_month)) ).length || 0),
      previousRollover: {
        budget: rolloverBudget,
        activity: rolloverActivity,
        actuals: monthlyActuals,
        rollover: totalRollover
      }
    }
  });

  useEffect(() => {
    window.analytics.page("Plan");
  }, []);

  const getAdjacentSibling = (elem, direction) => {
    if ( direction === "down" ) {
      let sibling = elem.nextElementSibling;
      while ( sibling ) {
        if ( sibling.matches('.category')) return sibling;
        sibling = sibling.nextElementSibling;
      }
    } else {
      let sibling = elem.previousElementSibling;
      while ( sibling ) {
        if ( sibling.matches('.category')) return sibling;
        sibling = sibling.previousElementSibling;
      }
    }
  };

  const getAdjacentCategoryGroups = (elem, direction) => {
    if ( direction === "down" ) {
      let sibling = elem.nextElementSibling;
      while ( sibling ) {
        if ( sibling.querySelectorAll('.category').length > 0 ) return sibling;
        sibling = sibling.nextElementSibling;
      }
    } else {
      let sibling = elem.previousElementSibling;
      while ( sibling ) {
        if ( sibling.querySelectorAll('.category').length > 0 ) return sibling;
        sibling = sibling.previousElementSibling;
      }
    }
  }

  const categoryListRef = useRef();
  useHotKeys('down', () => {
    const openPopover = document.querySelector('.chakra-popover__content[data-is-open=true]');
    if ( !disableHotKeys && !openPopover ) {
      let nextCategoryElement = null;
      const currentRow = categoryListRef.current?.querySelector(".list-item[data-highlighted=true]");
      if ( currentRow ) {
        let nextCategory = getAdjacentSibling(currentRow, "down");
        if ( nextCategory ) {
          nextCategoryElement = nextCategory;
        } else {
          const currentCategoryGroup = currentRow.closest(".category-group");
          const nextCategoryGroup = getAdjacentCategoryGroups(currentCategoryGroup, "down");
          if ( nextCategoryGroup ) {
            nextCategoryElement = nextCategoryGroup.querySelector('.category')
          } else {
            nextCategoryElement = categoryListRef.current?.querySelector(".category");
          }
        }
      } else {
        nextCategoryElement = categoryListRef.current?.querySelector(".category");
      }

      currentRow?.setAttribute('data-highlighted', false);

      if ( nextCategoryElement ) {
        nextCategoryElement.setAttribute('data-highlighted', true);
        setFocusCategoryId(nextCategoryElement.id);
        setTargetCategoryId(null);
      }
    }
  }, [ disableHotKeys, categoryListRef ]);

  useHotKeys('up', () => {
    const openPopover = document.querySelector('.chakra-popover__content[data-is-open=true]');
    if ( !disableHotKeys && !openPopover ) {
      let previousCategoryElement = null;
      const currentRow = categoryListRef.current?.querySelector(".list-item[data-highlighted=true]");
      if ( currentRow ) {
        let previousSibling = getAdjacentSibling(currentRow, "up");
        if ( previousSibling ) {
          previousCategoryElement = previousSibling;
        } else {
          const currentCategoryGroup = currentRow.closest(".category-group");
          const previousCategoryGroup = getAdjacentCategoryGroups(currentCategoryGroup, "up");
          if ( previousCategoryGroup ) {
            const previousCategoryGroupCategories = previousCategoryGroup.querySelectorAll('.category');
            const lastCategory = previousCategoryGroupCategories[previousCategoryGroupCategories.length - 1];
            previousCategoryElement = lastCategory;
          } else {
            const allCategories = categoryListRef.current?.querySelectorAll('.category');
            if ( allCategories.length > 0 ) {
              previousCategoryElement = allCategories[allCategories.length - 1];
            }
          }
        }
      } else {
        previousCategoryElement = categoryListRef.current?.querySelector(".category");
      }

      currentRow?.setAttribute('data-highlighted', false);

      if ( previousCategoryElement ) {
        previousCategoryElement.setAttribute('data-highlighted', true);
        setFocusCategoryId(previousCategoryElement.id);
        setTargetCategoryId(null);
      }
    }
  }, [ disableHotKeys, categoryListRef ]);

  useHotKeys('enter', e => {
    const openPopover = document.querySelector('.chakra-popover__content[data-is-open=true]');
    if ( !disableHotKeys && !openPopover ) {
      const categoryNameButton = categoryListRef.current?.querySelector(".list-item[data-highlighted=true]")?.querySelector('button');
      categoryNameButton?.click();
  
      e.preventDefault();
      e.stopPropagation();
    }
  }, [ disableHotKeys, categoryListRef ]);

  const targetCategory = calculatedCategories.find(category => category.id === targetCategoryId);
  const focusCategory = calculatedCategories.find(category => category.id === focusCategoryId);
  const targetCategoryGroup = categoryGroups.find(categoryGroup => categoryGroup.id === targetCategoryGroupId );

  return (
    <Page>
      <PlanQuickActions 
        budgetId = { budgetId }
        categories = { calculatedCategories }
        targetCategory = { targetCategory || focusCategory }
        currentMonth = { currentMonth }
        categoryListRef = { categoryListRef }
      />
      <PlanAside hasData = { hasData } isLoading = { isLoading } currentMonth = { currentMonth } categories = { calculatedCategories } accountsData = { cashAccounts } />
      <Main
        asideButtonLabel = "Budget Stats"
        hasAside = { true }
        asideShortcutKey = "s"
        isLoading = { !hasData }
      >
        <Header title = "Budget"><AddCategoryGroup setTargetCategoryGroup = { setTargetCategoryGroupId } /></Header>
        <PlanHeader currentMonth = { currentMonth } changeMonth = { changeMonth } />

        <Box ref = { categoryListRef }>
          <CategoryGroupList
            setTargetCategoryGroup = { setTargetCategoryGroupId }
            setTargetCategory = { categoryId => { setFocusCategoryId(null); setTargetCategoryId(categoryId);} }
            categoryGroups = { categoryGroups }
            categories = { calculatedCategories }
            currency = { budget?.default_currency }
            currentMonth = { currentMonth }
            isLoading = { isLoading }
          />
        </Box>

        <CategoryGroupDrawer
          isOpen = { !!targetCategoryGroup }
          onClose = { () => setTargetCategoryGroupId(null) }
          setTargetCategory = { categoryId => { setFocusCategoryId(null); setTargetCategoryId(categoryId);} }
          categoryGroup = { targetCategoryGroup }
          categories = { categories.filter(category => category.category_group_id === targetCategoryGroupId)}
        />

        <CategoryDrawer
          isOpen = { !!targetCategory }
          onClose = { () => setTargetCategoryId(null) }
          category = { targetCategory }
          currentMonth = { currentMonth }
          isLoading = { isLoading }
        />
      </Main>
    </Page>
  )
};

export default Plan;
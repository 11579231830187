import {
  Box,
  Fade,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  VStack
} from "@chakra-ui/react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { AiOutlineRocket } from "react-icons/ai";
import { BiCalendarWeek } from "react-icons/bi";
import { HiOutlineClipboardCheck } from "react-icons/hi";

import { TimezoneSelect } from "components/Select";
import useToast from "hooks/useToast";
import { auth } from "utils/nhost";

const GET_NOTIFICATION_SETTINGS = gql`
  query GetNotificationSettings($user_id: uuid!) {
    user: users_by_pk(id: $user_id) {
      id
      timezone
      subscribed_product_updates
      subscribed_quick_review_reminder
      subscribed_weekly_snapshot
      account {
        email
      }
    }
  }
`;

const UPDATE_PRODUCT_UPDATES_SUBSCRIPTION = gql`
  mutation UpdateProductUpdatesSubscription($user_id: uuid!, $subscribed_product_updates: Boolean!) {
    user: update_users_by_pk(pk_columns: { id: $user_id }, _set: { subscribed_product_updates: $subscribed_product_updates }) {
      id
      subscribed_product_updates
    }
  }
`;

const UPDATE_QUICK_REVIEW_REMINDER_SUBSCRIPTION = gql`
  mutation UpdateQuickReviewReminderSubscription($user_id: uuid!, $subscribed_quick_review_reminder: Boolean!) {
    user: update_users_by_pk(pk_columns: { id: $user_id }, _set: { subscribed_quick_review_reminder: $subscribed_quick_review_reminder }) {
      id
      subscribed_quick_review_reminder
    }
  }
`;

const UPDATE_WEEKLY_SNAPSHOT_SUBSCRIPTION = gql`
  mutation UpdateWeeklySnapshotSubscription($user_id: uuid!, $subscribed_weekly_snapshot: Boolean!) {
    user: update_users_by_pk(pk_columns: { id: $user_id }, _set: { subscribed_weekly_snapshot: $subscribed_weekly_snapshot }) {
      id
      subscribed_weekly_snapshot
    }
  }
`; 

const UPDATE_TIMEZONE = gql`
  mutation UpdateTimezone($user_id: uuid!, $timezone: String!) {
    user: update_users_by_pk(pk_columns: { id: $user_id }, _set: { timezone: $timezone }) {
      id
      timezone
    }
  }
`;

const meRoleContext = {
  context: {
    headers: {
      "x-hasura-role": "me",
    },
  }
}


const Notifications = ({ onClose, isOpen }) => {
  const renderToast = useToast();
  const userId = auth.user()?.id;
  const [ updateProductUpdatesSubscription ] = useMutation(UPDATE_PRODUCT_UPDATES_SUBSCRIPTION, {
    ...meRoleContext
  });
  const [ updateQuickReviewReminderSubscription ] = useMutation(UPDATE_QUICK_REVIEW_REMINDER_SUBSCRIPTION, {
    ...meRoleContext
  });
  const [ updateWeeklySnapshotSubscription ] = useMutation(UPDATE_WEEKLY_SNAPSHOT_SUBSCRIPTION);
  const [ updateTimezone ] = useMutation(UPDATE_TIMEZONE, {
    ...meRoleContext
  });
  const { data } = useQuery(GET_NOTIFICATION_SETTINGS, {
    variables: {
      user_id: userId
    },
    skip: !isOpen && !userId,
    ...meRoleContext
  });

  const { timezone, subscribed_product_updates, subscribed_quick_review_reminder, subscribed_weekly_snapshot } = data?.user || {};
  const onUpdateProductUpdatesSubscription = () => {
    updateProductUpdatesSubscription({
      variables: {
        user_id: userId,
        subscribed_product_updates: !subscribed_product_updates
      }
    })
    .then(response => {
      renderToast("Notification Updated");
      window.analytics.track("Notification Updated", {
        subscribed: response.data.user.subscribed_product_updates,
        notification: 'product_updates'
      });
      window.analytics.identify(userId, {
        subscribed_product_updates: response.data.user.subscribed_product_updates
      });
    });
  };

  const onUpdateQuickReviewReminderSubscription = () => {
    updateQuickReviewReminderSubscription({
      variables: {
        user_id: userId,
        subscribed_quick_review_reminder: !subscribed_quick_review_reminder
      }
    })
    .then(response => {
      renderToast("Notification Updated");
      window.analytics.track("Notification Updated", {
        subscribed: response.data.user.subscribed_quick_review_reminder,
        notification: 'quick_review_reminder'
      });
      window.analytics.identify(userId, {
        subscribed_quick_review_reminder: response.data.user.subscribed_quick_review_reminder
      });
    });
  };

  const onUpdateWeeklySnapshotSubscription = () => {
    updateWeeklySnapshotSubscription({
      variables: {
        user_id: userId,
        subscribed_weekly_snapshot: !subscribed_weekly_snapshot
      }
    })
    .then(response => {
      renderToast("Notification Updated");
      window.analytics.track("Notification Updated", {
        subscribed: response.data.user.subscribed_weekly_snapshot,
        notification: 'weekly_snapshot'
      });
      window.analytics.identify(userId, {
        subscribed_weekly_snapshot: response.data.user.subscribed_weekly_snapshot
      });
    });
  }

  const onTimezoneChange = item => {
    const { value } = item;
    updateTimezone({
      variables: {
        user_id: userId,
        timezone: value
      }
    })
    .then(response => {
      renderToast("Timezone Updated");
      window.analytics.track("Timezone Updated");
      window.analytics.identify(userId, {
        timezone: response.data.user.timezone
      });
    });
  }

  return (
    <Modal isOpen = { isOpen } onClose = { onClose } size = "xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Notifications</ModalHeader>
        <ModalCloseButton />
        <Fade in = { !!data }>
          <ModalBody>
            <VStack width = "full" spacing = "4">
              <HStack width = "full" alignItems = "flex-start">
                <Icon pt = "1" as = { HiOutlineClipboardCheck } w = { 6 } h = { 6 } />
                <Box fontSize = "sm" opacity = "0.9">
                  <Text fontWeight = "bold">Quick Review Reminder</Text>
                  <Text>Receive a daily reminder to complete your quick review so that you don't lose your streak.</Text>
                </Box>
                <Switch 
                  colorScheme = "primary"
                  isChecked = { subscribed_quick_review_reminder }
                  onChange = { onUpdateQuickReviewReminderSubscription }
                ></Switch>
              </HStack>

              <HStack width = "full" alignItems = "flex-start">
                <Icon pt = "1" as = { BiCalendarWeek } w = { 6 } h = { 6 } />
                <Box fontSize = "sm" opacity = "0.9">
                  <Text fontWeight = "bold">Weekly Budget Snapshot</Text>
                  <Text>Get a list of upcoming transactions, net worth changes, portfolio updates, and status of your budget at the beginning of the month.</Text>
                </Box>
                <Switch 
                  colorScheme = "primary"
                  isChecked = { subscribed_weekly_snapshot }
                  onChange = { onUpdateWeeklySnapshotSubscription }
                ></Switch>
              </HStack>

              <HStack width = "full" alignItems = "flex-start">
                <Icon pt = "1" as = { AiOutlineRocket} w = { 6 } h = { 6 } />
                <Box fontSize = "sm" opacity = "0.9">
                  <Text fontWeight = "bold">Product Updates</Text>
                  <Text>Receive updates on new features, improvements, and fixes within the app.</Text>
                </Box>
                <Switch 
                  colorScheme = "primary"
                  isChecked = { subscribed_product_updates }
                  onChange = { onUpdateProductUpdatesSubscription }
                ></Switch>
              </HStack>
            </VStack>
          </ModalBody>
          <ModalFooter mt = "2">
            <FormControl display = "flex" mt = "1">
              <FormLabel mb = "0" fontSize = "sm">Timezone </FormLabel>
              <TimezoneSelect
                value = {{ label: timezone, value: timezone }}
                onChange = { onTimezoneChange }
              />
            </FormControl>
          </ModalFooter>
        </Fade>
      </ModalContent>
    </Modal>
  )
};

export default Notifications;
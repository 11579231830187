import { HiPlus } from 'react-icons/hi';
import mousetrap from "mousetrap";

import { HeaderButton } from "components/Page";

const AddTransaction = () => {
  const onClick = () => {
    mousetrap.trigger('t', 'click');
  }

  return (
    <HeaderButton
      buttonLabel = "Add Transaction"
      LeftIcon = { HiPlus }
      onClick = { onClick }
    />
  )
};

export default AddTransaction;
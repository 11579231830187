import gql from "graphql-tag";

export default gql`
  fragment AllAccountFields on categories {
    __typename
    id
    name
    current_balance
    available_balance
    subtype
    type
    currency
    sync_start_date
  }
`;
import { AiOutlineCalendar } from "react-icons/ai";
import moment from "moment-timezone";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  Box,
  HStack,
  Text
} from "@chakra-ui/react";

import FieldGroup from "components/FieldGroup";
import getDefaultBudget from "utils/getDefaultBudget";
import { formatCurrency } from "utils/format";

const GET_UPCOMING_TRANSACTIONS = gql`
  query GetUpcomingTransactions($budget_id: uuid!, $date: String!) {
    scheduled_transactions(where: {account: {budget_id: {_eq: $budget_id}, is_hidden: {_eq: false}}, next_date: {_lte: $date}}) {
      payee {
        name
      }
      transfer_account {
        name
      }
      amount
      currency
      next_date
      id
    }
  }
`;

const DateSection = ({ date, scheduledTransactions }) => (
  <Box mt = "2">
    <Text fontWeight = "medium">{ moment(date).format("MMM Do") }</Text>
    <Box>
      { scheduledTransactions.map(st => (
        <HStack key = { st.id } justifyContent = "space-between" my = "1">
          <Text isTruncated>{ st.payee?.name || st.transfer_account?.name }</Text>
          <Text ml = "4">{ formatCurrency(st.amount, st.currency) }</Text>
        </HStack>
      ))}
    </Box>
  </Box>
)

const UpcomingTransactions = () => {
  const budgetId = getDefaultBudget();
  const upToDate = moment().add(7, 'days').format("YYYY-MM-DD");
  const { data } = useQuery(GET_UPCOMING_TRANSACTIONS, {
    variables: {
      budget_id: budgetId,
      date: upToDate
    }
  });

  const scheduledTransactions = data?.scheduled_transactions || [];
  const nextDates = Array.from(new Set(scheduledTransactions.map(st => st.next_date)))
    .sort((n1, n2) => n1 > n2 ? 1 : -1 );
  
  return (
    <FieldGroup title = "Upcoming Transactions" Icon = { AiOutlineCalendar } titleHref = "/budget/scheduled-transactions">
      { scheduledTransactions.length === 0 ? <Text py = "2" px = "2" fontSize = "sm">No upcoming transactions</Text> : (
        <Box fontSize = "sm" >
          { nextDates.map(nextDate => <DateSection key = { nextDate } date = { nextDate } scheduledTransactions = { scheduledTransactions.filter(st => st.next_date === nextDate) } /> )}
        </Box>
      )}
    </FieldGroup>
  )
};

export default UpcomingTransactions;
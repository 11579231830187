import { usePlaidLink } from 'react-plaid-link';

const Plaid = ({ linkToken, onSuccess, onExit }) => {
  const plaidConfig = {
    token: linkToken,
    onSuccess,
    onExit
  };

  const { open, ready } = usePlaidLink(plaidConfig);

  ready && open();

  return <div></div>
};

export default Plaid;
import {
  Box,
  Heading,
  Text,
  useColorModeValue as mode,
  useTheme
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { Bar } from 'react-chartjs-2';
import ReactDOM from "react-dom";

import { GET_ACCOUNT_HISTORY } from "./gql";
import { formatCurrency } from "utils/format";
import getDefaultBudget from "utils/getDefaultBudget";


const NetWorth = ({ selectedAccounts, monthEnds, yearMonths }) => {
  const theme = useTheme();
  const budgetId = getDefaultBudget();
  const { data } = useQuery(GET_ACCOUNT_HISTORY, { variables: { budget_id: budgetId }});

  const budget = data?.budget;
  const accounts = budget?.accounts.filter(account => selectedAccounts.includes(account.id));

  const accountsWithBalanceHistory = accounts?.map(account => {
    //const { current_balance, sync_start_date, transactions_by_month } = account;
    const { current_balance, transactions_by_month } = account;
    //const earliestTransactionDate = moment(account.transactions_aggregate.aggregate.min.authorized_at || undefined);
    //const earliestBalanceHistory = moment.min([earliestTransactionDate, moment(sync_start_date || undefined)]);
    const transactionsByMonth = yearMonths.map(yearMonth => transactions_by_month.find(tbm => tbm.year_month === yearMonth)?.amount || 0);
    const cumulativeSum = ( sum => value => {
      return ['credit', 'loan'].includes(account.type) ? sum += value : sum -= value
    })(current_balance)
    const balanceHistory = [current_balance].concat(transactionsByMonth.map(cumulativeSum));

    return {
      ...account,
      balanceHistory: monthEnds
        .map((monthEnd, index) => ({ monthEnd, balance: balanceHistory[index] }))
        //.filter(balanceHistory => balanceHistory.monthEnd >= earliestBalanceHistory.format("YYYY-MM-DD") )
    }
  }) || [];

  const netWorthHistory = monthEnds
    .map(monthEnd => {
      const activeAccounts = accountsWithBalanceHistory
        .filter(account => account.balanceHistory.map(bh => bh.monthEnd).includes(monthEnd));

      const assetAccounts = activeAccounts
        .filter(account => ['depository', 'investment'].includes(account.type))
        .map(account => ({
          id: account.id,
          name: account.name,
          balance: account.balanceHistory.find(bh => bh.monthEnd === monthEnd).balance
        }));

      const liabilityAccounts = activeAccounts
        .filter(account => ['loan', 'credit'].includes(account.type))
        .map(account => ({
          id: account.id,
          name: account.name,
          balance: account.balanceHistory.find(bh => bh.monthEnd === monthEnd).balance
        }));

      const totalAssets = assetAccounts.reduce((total, account) => total + account.balance, 0);
      const totalLiabilities = liabilityAccounts.reduce((total, account) => total + account.balance, 0);
      const netWorth = totalAssets - totalLiabilities;
      return {
        monthEnd,
        assetAccounts,
        liabilityAccounts,
        totalAssets,
        totalLiabilities,
        netWorth
      }
    }).reverse();

  const chartFontColor = mode(theme.colors.gray[800], theme.colors.whiteAlpha[900]);
  const labels = netWorthHistory.map(history => moment(history.monthEnd));
  const chartData = {
    labels,
    datasets: [
      {
        type: 'bar',
        label: "Assets",
        backgroundColor: theme.colors.secondary[400],
        hoverBackgroundColor: theme.colors.secondary[400],
        data: netWorthHistory.map(history => history.totalAssets)
      }, {
        type: 'bar',
        label: "Liabilities",
        backgroundColor: theme.colors.tertiary[400],
        hoverBackgroundColor: theme.colors.tertiary[400],
        data: netWorthHistory.map(history => -history.totalLiabilities)
      }, {
        type: 'line',
        label: "Net Worth",
        backgroundColor: theme.colors.brand[400],
        hoverBackgroundColor: theme.colors.brand[400],
        borderColor: theme.colors.brand[400],
        borderWidth: 2,
        data: netWorthHistory.map(history => history.netWorth),
        fill: false
      }
    ]
  };

  const tooltipBackgroundColor = mode("white", theme.colors.gray[700]);
  const tooltipShadow = mode(theme.shadows.light.sm, theme.shadows.dark.sm)

  const chartOptions = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        labels: {
          color: chartFontColor
        }
      },
      tooltip: {
        enabled: false,
        position: 'average',
        external: context => {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<div></div>';
              document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          var tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
              tooltipEl.classList.add('no-transform');
          }

          // Set Text
          if (tooltipModel.body) {
            const { dataPoints, title } = context.tooltip;
            const DataPoint = ({ dataPoint, ...props }) => (
              <Box display = "flex" width = "full" justifyContent = "space-between" { ...props }>
                <Box display = "flex" alignItems = "center">
                  <div style = {{ boxShadow: tooltipShadow, marginRight: "4px", borderRadius: "100%", width: "12px", height: "12px", backgroundColor: dataPoint.dataset.backgroundColor}}></div>
                  <Text mr = "12">{ dataPoint.dataset.label }</Text>
                </Box>
                <Text textAlign = "right">{ formatCurrency(dataPoint.raw) }</Text>
              </Box>
            )
            const ToolTipBody = () => (
              <Box backgroundColor = { tooltipBackgroundColor } p = "8">
                <Heading fontWeight = "bold" as = "h6">{ moment(parseInt(title[0])).format("MMM YYYY") }</Heading>
                <Box>
                  { dataPoints.slice(0, 2).map((data, index) => <DataPoint key = { index } dataPoint = { data } /> ) }
                  <DataPoint dataPoint = { dataPoints[2] } borderTop = "1px solid" mt = "4" fontWeight = "medium" />
                </Box>
              </Box>
            )

            ReactDOM.render(<ToolTipBody />, tooltipEl);

            var position = context.chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.backgroundColor = tooltipBackgroundColor;
            tooltipEl.style.boxShadow = tooltipShadow;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = Math.min(position.left + window.pageXOffset + tooltipModel.caretX, window.innerWidth - 250) + 'px';
            tooltipEl.style.top = Math.min(position.top + window.pageYOffset + tooltipModel.caretY, window.innerHeight - 200) + 'px';
            tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
            tooltipEl.style.pointerEvents = 'none';
        }
        }
      }
    },
    scales: {
      x: {
        title: {
          color: chartFontColor
        },
        ticks: {
          color: chartFontColor,
          callback: (value, index, values) => {
            return labels[index].format("MMM")
          }
        }
      },
      y: {
        title: {
          color: chartFontColor
        },
        ticks: {
          color: chartFontColor,
        }
      }
    }
  }

  return (
    <Box shadow = { mode("light.sm", "dark.sm")} py = "2" px = "4">
      <Heading fontSize = "2xl" mb = "2">Net Worth</Heading>
      <Box>
        <Bar data = { chartData } options = { chartOptions } style = {{ maxHeight: "400px", padding: "10px 5px"}}/>
      </Box>
    </Box>
  )
};

export default NetWorth;
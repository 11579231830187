import { useCallback, useMemo } from "react";
import {
  Box,
  Divider,
  FormLabel,
  HStack,
  Td,
  Th,
  Text,
  Textarea,
  Tr,
  useColorModeValue as mode
} from "@chakra-ui/react";
import moment from "moment-timezone";

import CurrencyInputField from "components/CurrencyInputField";
import DateField from "components/DateField";
import { PayeeSelect, CategorySelect, CurrencySelect } from "components/Select";
import Table from "components/Table";
import { needsCategory } from "utils/calculations";

const TdProps = {
  py: 1,
  px: 4,
  whiteSpace: "nowrap",
  isTruncated: true,
  fontSize: { base: "sm", sm: "unset" }
};

const ThProps = {
  scope: "col",
  px: 4,
  fontSize: "sm"
}

const BodyRow = ({ row }) => {
  const renderCell = headerName => row.cells.find(cell => cell.column.Header === headerName).render('Cell');
  const getCellProps = headerName => row.cells.find(cell => cell.column.Header === headerName).getCellProps();

  return (
    <Tr
      { ...row.getRowProps() }
      id = { `transaction-${row.original.index}` }
      tabIndex = { row.index }
    >
      <Td
        { ...getCellProps('Date') }
        { ...TdProps }
      >{ renderCell("Date") }</Td>

      <Td
        { ...getCellProps('Payee') }
        { ...TdProps } 
      >{ renderCell("Payee") }</Td>

      <Td
        { ...getCellProps('Amount') }
        { ...TdProps } 
      >{ renderCell('Amount') }</Td>

      <Td
        { ...getCellProps('Currency') }
        { ...TdProps } 
      >{ renderCell('Currency') }</Td>

      <Td
        { ...getCellProps('Category') }
        { ...TdProps } 
      >{ renderCell('Category') }</Td>

      <Td
        { ...getCellProps('Notes') }
        { ...TdProps }
      >{ renderCell('Notes') }</Td>
    </Tr>
  )
};

const HeaderRow = ({ headerGroup }) => {
  const renderHeader = headerName => headerGroup.headers.find(column => column.Header === headerName).render('Header');
  const getHeaderProps = headerName => headerGroup.headers.find(column => column.Header === headerName).getHeaderProps();
  
  return (
    <Tr { ...headerGroup.getHeaderGroupProps() }>
      <Th
        { ...getHeaderProps('Date') }
        { ...ThProps }
        width = "32"
      >{ renderHeader('Date') }</Th>

      <Th
        { ...getHeaderProps('Payee') }
        { ...ThProps }
        width = "52"
      >{ renderHeader('Payee') }</Th>

      <Th
        { ...getHeaderProps('Amount') }
        { ...ThProps }
        width = "28"
      >{ renderHeader('Amount') }</Th>

      <Th
        { ...getHeaderProps('Currency') }
        { ...ThProps }
        width = "32"
      >{ renderHeader('Currency') }</Th>

      <Th
        { ...getHeaderProps('Category') }
        { ...ThProps }
        width = "52"
      >{ renderHeader('Category') }</Th>

      <Th
        { ...getHeaderProps('Notes') }
        { ...ThProps }
        width = "60"
      >{ renderHeader('Notes') }</Th>
    </Tr>
  )
}

const Review = ({ account, setAccount, isOpen, uploadData = [], setUploadData }) => {
  const onRowUpdate = useCallback((rowIndex, property, newValue ) => {
    setUploadData(prev => prev.map(row => {
      const newRow = row.index === rowIndex ? ({
        ...row,
        [ property ]: newValue
      }) : row;

      const disableCategory = account && !needsCategory(account?.type, newRow.payee?.type);
      if ( disableCategory ) {
        newRow.category = undefined;
        newRow.disableCategory = true;
      } else {
        newRow.disableCategory = false;
      };

      return newRow;
    }))
  }, [ account, setUploadData ]);

  const columns = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      id: 'date',
      Cell: props => (
        <DateField 
          selected = { props.value } 
          filterDate = { date => moment(date).isBefore(moment(), 'date')} 
          onChange = { newDate => onRowUpdate(props.row.original.index, 'date', newDate) } 
        />
      )
    },
    {
      Header: 'Payee',
      accessor: 'payee',
      id: 'payee',
      Cell: props => (
        <HStack spacing = "0">
          <PayeeSelect 
            isCreatable = { true } 
            onChange = { item => onRowUpdate(props.row.original.index, 'payee', item) }
            value = { props.value } 
            shouldLoadData = { isOpen } 
            transferDirection = { props.row.original.amount < 0 ? "outgoing" : "incoming" } 
            filterFunction = { acc => acc.id !== account?.id }
          />
          { props.value.__isNew__ ? (
            <Box 
              px = "2" 
              py = "1" 
              bg = { mode("gray.100", "gray.600") } 
              height = "full"
            >
              <Text variant = "helper">New</Text>
            </Box> 
          ) : null }
        </HStack>
      )
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      id: 'amount',
      Cell: props => (
        <CurrencyInputField 
          value = { props.value } 
          onSubmit = { newAmount => onRowUpdate(props.row.original.index, 'amount', newAmount) }
        />
      )
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      id: 'currency',
      Cell: props => (
        <CurrencySelect 
          fieldDisplay = "value" 
          value = { props.value } 
          onChange = { item => onRowUpdate(props.row.original.index, 'currency', item) }
        />
      )
    },
    {
      Header: 'Category',
      accessor: 'category',
      id: 'category',
      Cell: props => (
        <CategorySelect 
          isCreatable = { true } 
          onChange = { item => onRowUpdate(props.row.original.index, 'category', item) } 
          value = { props.value } 
          shouldLoadData = { isOpen } 
          isDisabled = { props.row.original.disableCategory }
        />
      )
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      id: 'notes',
      Cell: props => (
        <Textarea 
          value = { props.value } 
          onBlur = { e => onRowUpdate(props.row.original.index, 'notes', e.target.value) }
          rows = { 1 }
        />
      )
    },

  ], [ account, isOpen, onRowUpdate ]);

  const data = useMemo(() => uploadData || [], [ uploadData ]);

  return (
    <Box mt = "4" experimental_spaceY = "2">
      <Box>
        <Text>Review your new transactions below.</Text>
      </Box>
      <Box display = {{ base: "block", md: "flex" }} justifyContent = "space-between">
        <FormLabel width = {{ base: "full", md: "50%"}}>Account: { account?.label }</FormLabel>
      </Box>
      <Divider my = "4" />
      <Box>
        <Table 
          columns = { columns }
          data = { data }
          BodyRow = { BodyRow }
          HeaderRow = { HeaderRow }
          allowHorizontalOverflow = { true }
        />
      </Box>
    </Box>
  )
};

export default Review;
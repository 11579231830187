import gql from "graphql-tag";

const GET_QUICK_REVIEW_DETAILS = gql`
  query QuickReviewDetails($budget_id: uuid!, $today: String!) {
    uncategorized_transactions: transactions_aggregate(where: {needs_category: {_eq: true}, is_deleted: { _eq: false }, category_id: {_is_null: true}, account: {budget_id: {_eq: $budget_id}, is_hidden: {_eq: false}}}) {
      aggregate {
        count
      }
    }
    overdue_scheduled_transactions: scheduled_transactions_aggregate(where: {account: {budget_id: {_eq: $budget_id}, is_hidden: {_eq: false}}, next_date: {_lte: $today}}) {
      aggregate {
        count
      }
    }
  }
`;

export {
  GET_QUICK_REVIEW_DETAILS
}
import { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import { useMutation, useSubscription } from "@apollo/client";
import gql from "graphql-tag";

import { PopoutSelect } from "components/Select";
import useToast from "hooks/useToast";

const GET_CATEGORY_GROUPS = gql`
  subscription GetCategoryGroups {
    category_groups(order_by: { name: asc }) {
      id
      label: name
      value: id
    }
  }
`;

const UPDATE_CATEGORY_CATEGORY_GROUP = gql`
  mutation UpdateCategoryCategoryGroup($id: uuid!, $category_group_id: uuid!) {
    update_categories_by_pk(
      pk_columns: { id: $id },
      _set: { category_group_id: $category_group_id }
    ) {
      id
      category_group_id
    }
  }
`;

const CategoryGroup = ({ category }) => {
  const renderToast = useToast();
  const { data, loading } = useSubscription(GET_CATEGORY_GROUPS);
  const [ updateCategoryCategoryGroup ] = useMutation(UPDATE_CATEGORY_CATEGORY_GROUP);
  const originalCategoryGroup = category.category_group;
  const [ categoryGroup, setCategoryGroup ] = useState(originalCategoryGroup);

  const categoryGroupOptions = data?.category_groups || [];

  useEffect(() => {
    setCategoryGroup(originalCategoryGroup);
  }, [ originalCategoryGroup ]);

  const onCategoryGroupChange = async item => {
    const { value: categoryGroupId } = item;

    if ( categoryGroupId !== category.category_group_id ) {
      setCategoryGroup(item);

      await updateCategoryCategoryGroup({
        variables: {
          id: category.id,
          category_group_id: categoryGroupId
        }
      })
      
      renderToast("Category Group Updated");
      window.analytics.track("Category Updated", {
        field: "category_group"
      })
    }
  }

  return (
    <FormControl>
      <FormLabel>Category Group</FormLabel>
      <PopoutSelect
        buttonLabel = { category.is_income ? "Income" : "Select Category Group" }
        isDisabled = { category.is_income }
        tooltipText = "Select Category Group"
        onChange = { onCategoryGroupChange }
        options = { categoryGroupOptions }
        value = { categoryGroup }
        isLoading = { loading }
      />
    </FormControl>
  )
};

export default CategoryGroup;
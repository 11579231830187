import {
  VStack
} from "@chakra-ui/react";

import Account from "./Account";
import Amount from "./Amount";
import Category from "./Category";
import Notes from "./Notes";
import Payee from "./Payee";
import DateSection from "./Date";
import NextDate from "./NextDate";
import ScheduledTransactionActions from "./ScheduledTransactionActions";

const ScheduledTransaction = ({ scheduledTransaction, onClose }) => {
  return (
    <VStack spacing = "8" width = "full">
      <NextDate scheduledTransaction = { scheduledTransaction } />
      <Payee scheduledTransaction = { scheduledTransaction } />
      <Amount scheduledTransaction = { scheduledTransaction } />
      <Category scheduledTransaction = { scheduledTransaction } />
      <Account scheduledTransaction = { scheduledTransaction } />
      <DateSection scheduledTransaction = { scheduledTransaction } />
      <Notes scheduledTransaction = { scheduledTransaction } />
      <ScheduledTransactionActions scheduledTransaction = { scheduledTransaction } onClose = { onClose } />
    </VStack>
  )
};

export default ScheduledTransaction;
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

import LoadingSpinner from "components/LoadingSpinner";
import API from "utils/api";

const CannyAuth = () => {
  const querySearch = useLocation().search;

  useEffect(() => {
    const urlQuery = new URLSearchParams(querySearch);
    const queryParams = Object.fromEntries(urlQuery); 
    const { companyID, redirect } = queryParams;
    if ( companyID && redirect ) {
      const api = new API();
      api.getCannyToken()
      .then(response => {
        const { token } = response;
        const redirectURL = `https://canny.io/api/redirects/sso?companyID=${companyID}&ssoToken=${token}&redirect=${redirect}`;
        window.location.assign(redirectURL);
      })
    }
    console.log(queryParams);
  }, [ querySearch ]);

  return <LoadingSpinner />;
};

export default CannyAuth;
const currencyData = require('currency-codes/data');
const ct = require('countries-and-timezones');

const accountTypesAndSubtypes = [
  { label: "Depository", value: "depository", subtypes: [
    { label: "Checking", value: "checking" },
    { label: "Savings", value: "savings" },
    { label: "Health Savings Account (HSA)", value: "hsa" },
    { label: "Certificate of Deposit (CD)", value: "cd" },
    { label: "Money Market", value: "money market" },
    { label: "PayPal", value: "paypal" },
    { label: "Prepaid", value: "prepaid" },
    { label: "Cash Management", value: "cash management" },
    { label: "Electronic Benefit Transfer (EBT)", value: "ebt" },
    { label: "Cash", value: "cash" },
    { label: "Other Depository", value: "other"}
  ]}, 
  { label: "Credit", value: "credit", subtypes: [
    { label: "Credit Card", value: "credit card" },
    { label: "PayPal Credit", value: "paypal" },
    { label: "Other Credit", value: "other" }
  ]},
  { label: "Loan", value: "loan", subtypes: [
    { label: "Auto Loan", value: "auto" },
    { label: "Business Loan", value: "business" },
    { label: "Commercial Loan", value: "commercial" },
    { label: "Construction Loan", value: "construction" },
    { label: "Consumer Loan", value: "consumer" },
    { label: "Home Equity Loan", value: "home equity" },
    { label: "Loan", value: "loan" },
    { label: "Mortgage", value: "mortgage" },
    { label: "Overdraft", value: "overdraft" },
    { label: "Line of Credit", value: "line of credit" },
    { label: "Student Loan", value: "student" },
    { label: "Other Loan", value: "other" }
  ]},
  { label: "Investment", value: "investment", subtypes: [
    { label: "529", value: "529"},
    { label: "401(a)", value: "401a"},
    { label: "401(k)", value: "401k"},
    { label: "403(b)", value: "403b"},
    { label: "457(b)", value: "457b"},
    { label: "Brokerage", value: "brokerage"},
    { label: "Cash Individual Savings Account (ISA)", value: "cash isa"},
    { label: "Crypto", value: "crypto"},
    { label: "Education Savings Account (ESA)", value: "education savings account"},
    { label: "Fixed Annuity", value: "fixed annuity"},
    { label: "Guaranteed Investment Certificate (GIC)", value: "gic"},
    { label: "Health Reimbursement Arrangement (HRA)", value: "health reimbursement arrangement"},
    { label: "Health Savings Account (HSA)", value: "hsa"},
    { label: "Traditional Individual Retirement Account (IRA)", value: "ira"},
    { label: "Individual Savings Account (ISA)", value: "isa"},
    { label: "Keogh", value: "keogh"},
    { label: "Life Income Fund (LIF)", value: "lif"},
    { label: "Locked-in Retirement Account (LIRA)", value: "lira"},
    { label: "Locked-in Retirement Income Fund (LRIF)", value: "lrif"},
    { label: "Locked-in Retirement Savings Plan (LRSP)", value: "lrsp"},
    { label: "Mutual Fund", value: "mutual fund"},
    { label: "Other Non-Taxable Brokerage Account", value: "non-taxable brokerage account"},
    { label: "Pension", value: "pension"},
    { label: "Prescribed Registered Retirement Income Fund (PRIF)", value: "prif"},
    { label: "Profit Sharing Plan", value: "profit sharing plan"},
    { label: "Qualifying Share Account (QSHR)", value: "qshr"},
    { label: "Registered Disability Savings Plan (RDSP)", value: "rdsp"},
    { label: "Registered Education Savings Plan (RESP)", value: "resp"},
    { label: "Other Retirement Account", value: "retirement"},
    { label: "Restricted Life Income Fund (RLIF)", value: "rlif"},
    { label: "Roth IRA", value: "roth"},
    { label: "Roth 401(k)", value: "roth 401k"},
    { label: "Registered Retirement Income Fund (RRIF)", value: "rrif"},
    { label: "Registered Retirement Savings Plan (RRSP)", value: "rrsp"},
    { label: "Salary Reduction Simplified Employee Pension Plan (SARSEP)", value: "sarsep"},
    { label: "Simplified Employee Pension (SEP) IRA", value: "sep ira"},
    { label: "Savings Incentive Match Plan for Employees (SIMPLE) IRA", value: "simple ira"},
    { label: "Self-Invested Personal Pension (SIPP)", value: "sipp"},
    { label: "Stock Plan", value: "stock plan"},
    { label: "Tax-Free Savings Account (TFSA)", value: "tfsa"},
    { label: "Trust", value: "trust"},
    { label: "Uniform Gift to Minors Account (UGMA)", value: "ugma"},
    { label: "Uniform Transfers to Minors Acccount (UTMA)", value: "utma"},
    { label: "Variable Annuity", value: "variable annuity"},
    { label: "Other Investment", value: "other" }
  ]}
];

const activityTypeOptions = [
  { label: "Buy", value: "buy" },
  { label: "Sell", value: "sell" },
  { label: "Cancel", value: "cancel" },
  { label: "Cash Movement", value: "cash" },
  { label: "Fee", value: "fee" },
  { label: "Position Movement", value: "transfer" }
];

const timezones = ct.getAllTimezones();
const timezoneOptions = Object.keys(timezones).map(timezone => ({
  label: timezone,
  value: timezone
}));

const currencyOptions = currencyData.map(data => ({
  label: `${data.currency} (${data.code})`,
  value: data.code
}));

const frequencyOptions = [
  { label: "Once", value: "once" },
  { label: "Weekly", value: "weekly" },
  { label: "Biweekly", value: "biweekly" },
  { label: "1st and 15th", value: "1st&15th" },
  { label: "Monthly", value: "monthly" },
  { label: "End of Month", value: "endOfMonth" },
  { label: "Every 3 Months", value: "3Months" },
  { label: "Yearly", value: "yearly" }
];

export { 
  accountTypesAndSubtypes,
  activityTypeOptions,
  currencyOptions,
  frequencyOptions,
  timezoneOptions
}
import axios from "axios";

import { auth } from "utils/nhost";

class API {
  constructor(){
    this.baseUrl = window.location.origin + "/.netlify/functions"
  }

  _get = (endpoint, params) => axios
    .get(this.baseUrl + endpoint, { params })
    .then(resp => resp.data);

   _post = (endpoint, body = {} ) => axios
    .post(this.baseUrl + endpoint, body)
    .then(resp => resp.data);
  
  _delete = (endpoint, params) => axios
    .delete(this.baseUrl + endpoint, { params })
    .then(resp => resp.data);
  
  generateAccessToken = () => this._get('/user/access_token', { user: auth.user() });

  getCannyToken = () => this._get('/canny/sso-token', { user: auth.user() });

  getPlaidLinkToken = async ( accessToken = null ) => {
    return this._get("/plaid/link-token", { user: auth.user(), accessToken });
  }

  getPlaidAccessToken = async publicToken => {
    return this._get('/plaid/access-token', { user: auth.user(), publicToken });
  }

  getPlaidInstitution = async institutionId => {
    return this._get('/plaid/institution', { user: auth.user(), institutionId })
  }

  revokePlaidAccessToken = async accessToken => {
    return this._delete('/plaid/access-token', { user: auth.user(), accessToken })
  }

  syncPlaidHistory = async (accountId, startDate, endDate) => {
    return this._post('/plaid/sync-history', { user: auth.user(), accountId, startDate, endDate });
  }
};

export default API;
import { 
  AiOutlinePieChart,
  AiOutlineStock
} from "react-icons/ai";
import { BiWallet } from 'react-icons/bi';
import {
  Box,
  HStack,
  Icon,
  Link,
  Text,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";

import Logo from "components/Logo";

const NavLink = ({ label, icon, isActive, subLinks }) => (
  <Box width = "full" px = "4">
    <HStack spacing = "0">
      <Icon w = { 5 } h = { 5 } as = { icon } color={ isActive ? 'primary.400' : 'unset'} />
      <Text
        px="3"
        fontSize="sm"
        fontWeight="medium"
        textTransform="uppercase"
        letterSpacing="widest"
        mb="3"
        opacity = "0.8"
      >
        { label }
      </Text>
    </HStack>
    <VStack spacing="1" alignItems = "flex-start" py = "2">
      { subLinks.map(subLink => <SubNavLink key = { subLink.href } isActive = { subLink.isActive } label = { subLink.label } href = { subLink.href }/> ) }
    </VStack>
  </Box>
);

const SubNavLink = ({ isActive, label, href, ...props }) => (
  <Link
    display="block"
    py="2"
    px="3"
    borderRadius="sm"
    transition="all 0.3s"
    fontWeight="medium"
    fontSize="sm"
    userSelect="none"
    aria-current={isActive ? 'page' : undefined}
    borderLeft = "2px"
    borderColor = "transparent"
    color = { mode("inherit", "whiteAlpha.700") }
    _hover={{
      bg: mode("gray.100", "gray.800"),
      borderColor: "primary.400",
      color: mode("inherit", "whiteAlpha.800")
    }}
    _activeLink={{
      bg: mode("gray.100", "gray.800"),
      borderColor: "primary.400",
      color: mode("inherit", "whiteAlpha.800")
    }}
    href = { href }
    width = "full"
    {...props}
  >
    <HStack spacing="4">
      <Text as="span">{label}</Text>
    </HStack>
  </Link>
)

const NavItems = () => {
  const pathnameParts = window.location.pathname.split('/').filter(part => part.length > 0);
  const [ mainPage, subPage ] = pathnameParts;

  const navLinks = [
    { 
      label: "Insights", Icon: AiOutlinePieChart, isActive: mainPage === "insights", subLinks: [
        { href: "/insights/trends", label: "Trends", isActive: subPage === "trends" },
        //{ href: "/insights/financial-independence", label: "Financial Independence", isActive: subPage === "financial-independence" }
      ]
    },
    { 
      label: "Budget", Icon: BiWallet, isActive: mainPage === "budget", subLinks: [
        { href: "/budget/plan", label: "Plan", isActive: subPage === "plan" },
        { href: "/budget/transactions", label: "Transactions", isActive: subPage === "transactions" && mainPage === "budget" },
        { href: "/budget/accounts", label: "Accounts", isActive: subPage === "accounts" },
        { href: "/budget/scheduled-transactions", label: "Scheduled Transactions", isActive: subPage === "scheduled_transactions" },
      ]
    },
    { 
      label: "Invest", Icon: AiOutlineStock, isActive: mainPage === "invest", subLinks: [
        { href: "/invest/portfolio", label: "Portfolio", isActive: subPage === "portfolio" },
        { href: "/invest/activity", label: "Activity", isActive: subPage === "activity"},
        { href: "/invest/research", label: "Research", isActive: subPage === "research" }
      ]
    }
  ];

  return (
    <VStack
      height = "full"
      overflowY = "scroll"
      alignItems = "flex-start"
      spacing = "4"
    >
      <Box
        as = "a"
        href = "/"
        px = "6"
      >
        <Logo size = "lg" height = "14" />
      </Box>
      { navLinks.map(navLink => <NavLink key = { navLink.label } label = { navLink.label } isActive = { navLink.isActive } subLinks = { navLink.subLinks } icon = { navLink.Icon } /> )}
    </VStack>
  )
};

export default NavItems;
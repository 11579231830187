 import {
  Text,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";

import SpendingProgressBar from "components/SpendingProgressBar";
import StatCard from "components/StatCard";
import { getDatesBetween } from "utils/calculations";
import { formatCurrency } from "utils/format";
import getDefaultBudget from "utils/getDefaultBudget";

const GET_BUDGET = gql`
  query GetBudget($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      default_currency
    }
  }
`;

const GET_BUDGETED_EXPENSES = gql`
  query GetBudgetedExpenses($budget_id: uuid!, $current_month: String!) {
    budgeted_expenses: budget_months_aggregate(where: { amount: { _gt: 0 }, category: {budget_id: {_eq: $budget_id}, is_system: {_eq: false}, is_income: {_eq: false}}, year_month: {_eq: $current_month}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

const GET_ACTUAL_EXPENSES = gql`
  query GetActualExpenses($budget_id: uuid!, $current_month: String!) {
    actual_expenses: transactions_by_category_month_aggregate(where: {year_month: {_eq: $current_month}, category: {budget_id: {_eq: $budget_id}, is_income: {_eq: false}, is_system: {_eq: false}}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

const GET_UPCOMING_SCHEDULED_TRANSACTIONS = gql`
  query GetUpcomingScheduledTransactions($budget_id: uuid!, $start_of_month: String!, $end_of_month: String! ) {
    scheduled_transactions(where: {_and: {next_date: {_gte: $start_of_month, _lte: $end_of_month}, account: {is_hidden: {_eq: false}, budget_id: {_eq: $budget_id}}}}) {
      amount
      frequency
      next_date
      end_after_date
      start_date
      needs_category
      category_id
      category {
        is_income
      }
    }
  }
`;

const SpendingPower = () => {
  const budgetId = getDefaultBudget();
  const currentMonth = moment().format("YYYY-MM");
  const { data: budgetedExpensesData } = useQuery(GET_BUDGETED_EXPENSES, {
    variables: {
      budget_id: budgetId,
      current_month: currentMonth
    }
  });

  const { data: actualExpensesData } = useQuery(GET_ACTUAL_EXPENSES, {
    variables: {
      budget_id: budgetId,
      current_month: currentMonth
    }
  });

  const { data: budgetData } = useQuery(GET_BUDGET, {
    variables: {
      budget_id: budgetId
    }
  });

  const { data: scheduledTransactionsData } = useQuery(GET_UPCOMING_SCHEDULED_TRANSACTIONS, {
    variables: {
      budget_id: budgetId,
      start_of_month: moment().startOf('month').format("YYYY-MM-DD"),
      end_of_month: moment().endOf('month').format("YYYY-MM-DD")
    }
  });

  const afterDate = moment().startOf('month').format("YYYY-MM-DD");
  const beforeDate = moment().endOf('month').format("YYYY-MM-DD");

  const totalScheduledTransactions = (scheduledTransactionsData?.scheduled_transactions || [])
  .filter(transaction => transaction.needs_category && ( !transaction.category_id || !transaction.category.is_income ))
  .map(transaction => {
    const upcomingDates = getDatesBetween({ 
      startDate: transaction.next_date, 
      endDate: transaction.end_after_date, 
      frequency: transaction.frequency, 
      afterDate,
      beforeDate
    });

    return upcomingDates.length * transaction.amount
  })
  .reduce((total, curr) => total + curr, 0)

  const totalBudgetedExpenses = budgetedExpensesData?.budgeted_expenses.aggregate.sum.amount;
  const totalActualExpenses = actualExpensesData?.actual_expenses.aggregate.sum.amount || 0;
  const spendingPower = totalBudgetedExpenses + totalActualExpenses + totalScheduledTransactions;
  const currency = budgetData?.budget.default_currency;

  const isLoading = !budgetedExpensesData || !actualExpensesData || !budgetData || !scheduledTransactionsData;

  return (
    <StatCard
      label = "Spending Power"
      onClick = { () => window.location.assign("/budget/plan") }
      isLoading = { isLoading }
    >
      <VStack spacing = "2" width = "full">
        <Text 
          as = "span"
          color = { mode('gray.800', 'white') }
          fontSize = "3xl"
          fontWeight = "semibold"
          lineHeight = "1"
        >
          { formatCurrency(spendingPower, currency) }
        </Text>
        <SpendingProgressBar 
          budgeted = { totalBudgetedExpenses }
          actual = { totalActualExpenses }
          scheduled = { totalScheduledTransactions }
          isIncome = { false }
        />
      </VStack>
    </StatCard>
  )
};

export default SpendingPower;
import {
  Button,
  ButtonGroup,
  HStack,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { BsChevronDoubleLeft, BsChevronLeft, BsChevronRight, BsChevronDoubleRight } from 'react-icons/bs';

import useHotKeys from "hooks/useHotKeys";

const PaginationComponent = ({ canNextPage, canPreviousPage, gotoPage, nextPage, pageCount, previousPage }) => {
  const paginationButtonProps = [
    { onClick: () => gotoPage(0), isDisabled: !canPreviousPage, srText: "First Page", Icon: BsChevronDoubleLeft },
    { onClick: () => previousPage(), isDisabled: !canPreviousPage, srText: "Previous Page", Icon: BsChevronLeft },
    { onClick: () => nextPage(), isDisabled: !canNextPage, srText: "Next Page", Icon: BsChevronRight },
    { onClick: () => gotoPage(pageCount -1), isDisabled: !canNextPage, srText: "Last Page", Icon: BsChevronDoubleRight },
  ];

  useHotKeys('left', () => {
    if ( canPreviousPage ) {
      previousPage();
    }
  }, [ canPreviousPage, previousPage ] );

  useHotKeys('right', () => {
    if ( canNextPage ) {
      nextPage();
    }
  }, [ canNextPage, nextPage ]);

  useHotKeys('shift+left', () => {
    if ( canPreviousPage ) {
      gotoPage(0);
    }
  }, [ canPreviousPage, gotoPage ]);

  useHotKeys('shift+right', () => {
    if ( canNextPage ) {
      gotoPage(pageCount - 1)
    }
  }, [ canNextPage, pageCount ])

  return (
    <HStack 
      width = "full" 
      justifyContent = "center" 
      py = "2"
      rounded = "sm"
      shadow = { mode("light.sm", "dark.sm") }
    >
      <ButtonGroup as = "nav" display = "inline-flex">
        { paginationButtonProps.map((prop, idx) => (
          <Button
            key = { idx }
            onClick = { prop.onClick}
            isDisabled = { prop.isDisabled }
            colorScheme = "gray"
            variant = "link"
            p = "2"
            fontSize = "2xl"
            fontWeight = "medium"
            _disabled = {{
              opacity: "0.3"
            }}
          >
            <Text srOnly>{ prop.srText }</Text>
            { prop.Icon() }
          </Button>
        ))}
      </ButtonGroup>
    </HStack>
  )
};

export default PaginationComponent;
import {
  Td,
  Text,
  Tr
} from "@chakra-ui/react";

const TdProps = {
  px: { md: "4" },
  py: { base: "1", md: "2" },
  whiteSpace: "nowrap",
  isTruncated: true
}

const BodyRow = ({ row, onRowClick }) => {
  const renderCell = headerName => row.cells.find(cell => cell.column.Header === headerName).render('Cell');
  const getCellProps = headerName => row.cells.find(cell => cell.column.Header === headerName).getCellProps();

  return (
    <Tr
      { ...row.getRowProps() }
      onClick = { () => onRowClick(row.original.id) }
      id = { row.original.id }
      opacity = { row.original.is_pending ? "0.6" : "1" }
      tabIndex = { row.index }
      onMouseEnter = { () => document.getElementById(row.original.id).focus()}
    >
      <Td
        { ...getCellProps('Date') }
        { ...TdProps }
        px = { { base: "2", sm: "4" } }
        fontSize = {{ base: "sm", md: "unset" }}
      >{ renderCell("Date") }</Td>

      <Td
        { ...getCellProps('Payee') }
        { ...TdProps }
        display = {{ base: "none", md: "table-cell" }} 
      >{ renderCell("Payee") }</Td>

      <Td
        { ...getCellProps('Account') }
        { ...TdProps }
        display = {{ base: "none", md: "table-cell" }} 
      >{ renderCell('Account') }</Td>

      <Td
        { ...getCellProps('Category') }
        { ...TdProps }
        display = {{ base: "none", md: "table-cell" }} 
      >{ renderCell('Category') }</Td>

      <Td
        { ...TdProps }
        display = {{ base: "table-cell", md: "none" }}
        fontSize = "sm"
      >
        <Text isTruncated fontWeight = "medium">{ renderCell('Payee') }</Text>
        <Text isTruncated fontWeight = "light">{ renderCell('Account') }</Text>
        <Text isTruncated fontWeight = "light">{ renderCell('Category') }</Text>
      </Td>

      <Td
        { ...getCellProps('Amount') }
        { ...TdProps }
        px = {{ base: "2", sm: "4" }}
        fontSize = {{ base: "sm", md: "unset" }}
      >{ renderCell('Amount') }</Td>
    </Tr>
  );
}

export default BodyRow;
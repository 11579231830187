import { AiOutlineBank } from "react-icons/ai";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import FieldGroup from "components/FieldGroup";
import { formatCurrency } from "utils/format";
import getDefaultBudget from "utils/getDefaultBudget";

const GET_BUDGET_ACCOUNTS = gql`
  query GetBudgetAccounts($budget_id: uuid!) {
    budget: budgets_by_pk(id: $budget_id) {
      id
      default_currency
      accounts(where: { is_hidden: { _eq: false }}, order_by: { name: asc }) {
        id
        name
        type
        subtype
        current_balance
      }
    }
  }
`;

const TypeSection = ({ label, currency, accounts = [] }) => {
  const shouldBeNegative = ['Credit', 'Loans'].includes(label);
  const totalBalance = accounts.reduce((total, account) => total + account.current_balance, 0);


  return (
    <AccordionItem border = "none">
      <Text fontSize = "sm">
        <AccordionButton fontSize = "sm" px = "0">
          <HStack
            justifyContent = "space-between"
            width = "full"
          >
            <HStack
              mr = "2"
              justifyContent = "space-between"
              width = "full"
            >
              <Text>{ label }</Text>
              <Text>{ formatCurrency(shouldBeNegative ? -totalBalance : totalBalance, currency) }</Text>
            </HStack>
            <AccordionIcon />
          </HStack>
        </AccordionButton>
      </Text>
      <AccordionPanel px = "2">
        { accounts.length > 0 ? accounts.map(account => (
          <HStack
            key = { account.id }
            justifyContent = "space-between"
          >
            <Text isTruncated>{ account.name }</Text>
            <Text>{ formatCurrency(shouldBeNegative ? -account.current_balance : account.current_balance, currency ) }</Text>
          </HStack>
        )) : <Text>No accounts</Text> }
      </AccordionPanel>
    </AccordionItem>
  );
}

const Accounts = () => {
  const budgetId = getDefaultBudget();
  const { data } = useQuery(GET_BUDGET_ACCOUNTS, { variables: { budget_id: budgetId }});
  const budget = data?.budget;
  const accounts = budget?.accounts || []; 

  const cashAccounts = accounts.filter(account => account.type === "depository" );
  const investmentAccounts = accounts.filter(account => account.type === "investment");
  const creditAccounts = accounts.filter(account => account.type === "credit");
  const loanAccounts = accounts.filter(account => account.type === "loan");

  const defaultCurrency = budget?.default_currency;
  const totalAssets = accounts
    .filter(account => ['depository', 'investment'].includes(account.type))
    .reduce((total, account) => total + account.current_balance, 0);

  const totalLiabilities = accounts
    .filter(account => !['depository', 'investment'].includes(account.type))
    .reduce((total, account) => total + account.current_balance, 0);
  const netWorth = totalAssets - totalLiabilities;

  return (
    <FieldGroup title = "Accounts" Icon = { AiOutlineBank } titleHref = "/budget/accounts">
      <Box
        py = "2"
      >
        <Accordion
          width = "full"
          allowMultiple
          fontSize = "sm"
        >
          <TypeSection label = "Cash" accounts = { cashAccounts } current = { defaultCurrency } />
          <TypeSection label = "Investments" accounts = { investmentAccounts } current = { defaultCurrency } />
          <TypeSection label = "Credit" accounts = { creditAccounts } current = { defaultCurrency } />
          <TypeSection label = "Loans" accounts = { loanAccounts } current = { defaultCurrency } />
        </Accordion>
        <HStack fontSize = "sm" justifyContent = "space-between" my = "2" fontWeight = "medium">
          <Text>Net Worth</Text>
          <Text>{ formatCurrency(netWorth, defaultCurrency) }</Text>
        </HStack>
      </Box>
    </FieldGroup>
  )
};

export default Accounts;
import { useContext, useEffect, useState } from "react";
import {
  useRegisterActions
} from "kbar";
import { useMutation } from "@apollo/client";
import _ from "lodash";

import { categoryActions, planActions } from "./actions";
import StartFresh from "./StartFresh";
import {DELETE_BUDGET_MONTH, GET_BUDGET_MONTHS, UPSERT_BUDGET_MONTH, UPSERT_BUDGET_MONTHS } from "../gql";
import QuickActionsContext from "QuickActions/QuickActionsContext";
import useHotKeys from "hooks/useHotKeys";
import useToast from "hooks/useToast";

const PlanQuickActions = ({ categories, currentMonth, targetCategory, budgetId }) => {
  const renderToast = useToast();
  const { closeQuickActions, setHeader } = useContext(QuickActionsContext);
  const [ activeAction, setActiveAction ] = useState("");
  const [ deleteBudgetMonthMutation ] = useMutation(DELETE_BUDGET_MONTH, {
    update: (cache, { data: { delete_budget_months_by_pk }}) => {
      if ( delete_budget_months_by_pk ) {
        const { category_id, year_month } = delete_budget_months_by_pk
        const data = cache.readQuery({ query: GET_BUDGET_MONTHS, variables: { budget_id: budgetId }});
        cache.writeQuery({ 
          query: GET_BUDGET_MONTHS,
          variables: { budget_id: budgetId },
          data: {
            budget_months: [ ...data.budget_months.filter(budgetMonth => !(budgetMonth.category_id === category_id && budgetMonth.year_month === year_month))]
          }
        })
      }
    }
  })
  const [ upsertBudgetMonthMutation ] = useMutation(UPSERT_BUDGET_MONTH, {
    update: (cache, { data: { insert_budget_months_one }}) => {
      const data = cache.readQuery({ query: GET_BUDGET_MONTHS, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_BUDGET_MONTHS,
        variables: { budget_id: budgetId },
        data: {
          budget_months: _.uniqWith([insert_budget_months_one], data.budget_months, (bm1, bm2) => bm1.category_id === bm2.category_id && bm1.year_month === bm2.year_month)
        }
      }) 
    }
  });
  const [ upsertBudgetMonthsMutation ] = useMutation(UPSERT_BUDGET_MONTHS, {
    update: (cache, { data: { insert_budget_months: { returning }}}) => {
      const data = cache.readQuery({ query: GET_BUDGET_MONTHS, variables: { budget_id: budgetId }});
      cache.writeQuery({
        query: GET_BUDGET_MONTHS,
        variables: { budget_id: budgetId },
        data: {
          budget_months: _.uniqWith(returning, data.budget_months, (bm1, bm2) => bm1.category_id === bm2.category_id && bm1.year_month === bm2.year_month)
        }
      })
    }
  });

  useEffect(() => {
    if ( targetCategory ) {
      setHeader(`Category: ${targetCategory.name}`)
    } else {
      setHeader("Plan");
    }
  }, [ targetCategory, setHeader ]);

  const _setActiveAction = ( action, keyboard = false ) => {
    closeQuickActions();
    window.analytics.track("Quick Action Opened", {
      action,
      keyboard
    });
    
    setActiveAction(action);
  };

  const onClose = (track = true) => {
    if ( track ) {
      window.analytics.track("Quick Action Closed");
    }
    setActiveAction(null);
  };

  useHotKeys("option+l", () => {
    quickBudgetFunction("plan_quick_budget_last_month", true);
  });

  useHotKeys("option+l", () => {
    quickBudgetFunction("plan_quick_budget_last_month", false);
  }, [], true, 'click');

  useHotKeys("option+a", () => {
    quickBudgetFunction("plan_quick_budget_average_spending", true);
  });

  useHotKeys("option+a", () => {
    quickBudgetFunction("plan_quick_budget_average_spending", false);
  }, [], true, 'click');

  useHotKeys("option+t", () => {
    quickBudgetFunction("plan_quick_budget_true_up", true);
  });

  useHotKeys("option+t", () => {
    quickBudgetFunction("plan_quick_budget_true_up", false);
  }, [], true, 'click');

  useHotKeys("option+shift+l", () => {
    quickBudgetFunction("category_quick_budget_last_month", true);
  });

  useHotKeys("option+shift+l", () => {
    quickBudgetFunction("category_quick_budget_last_month", false);
  }, [], true, 'click');

  useHotKeys("option+shift+a", () => {
    quickBudgetFunction("category_quick_budget_average_spending", true);
  });

  useHotKeys("option+shift+a", () => {
    quickBudgetFunction("category_quick_budget_average_spending", false);
  }, [], true, 'click');

  useHotKeys("option+shift+t", () => {
    quickBudgetFunction("category_quick_budget_true_up", true);
  });

  useHotKeys("option+shift+t", () => {
    quickBudgetFunction("category_quick_budget_true_up", false);
  }, [], true, 'click');

  const quickBudgetFunction = async (command, keyboard = false) => {
    onClose(false);
    const type = command.split("_")[0];

    let method = null;
    switch ( type ) {
      case "plan":
        if ( categories.length === 0 ) {
          break;
        }

        let objects = null;
        const props = { budget_id: budgetId, year_month: currentMonth };
        switch ( command ) {
          case "plan_quick_budget_last_month":
            objects = categories.map(category => ({ 
              category_id: category.id, 
              amount: Math.round(category.lastMonthBudget * 100) / 100, 
              ...props
            }));
            method = "last_month"
            break;
          case "plan_quick_budget_average_spending":
            objects = categories.map(category => ({ 
              category_id: category.id, 
              amount: Math.round(category.averageActivity * 100) / 100, 
              ...props
            }));
            method = "average_spending";
            break;
          case "plan_quick_budget_true_up":
            objects = categories.map(category => ({ 
              category_id: category.id, 
              amount: Math.round((category.currentMonth.budget - category.currentMonth.remaining + ( category.is_income ? category.currentMonth.scheduledTransactions : -category.currentMonth.scheduledTransactions)) * 100) / 100,  
              ...props
            }))
            method = "true_up";
            break;
          default:
        }
        const budgetMonthsToDelete = objects.filter(object => object.amount === 0);
        const budgetMonthsToUpsert = objects.filter(object => object.amount !== 0);
          
        await budgetMonthsToDelete.map(budgetMonth => {
          return deleteBudgetMonthMutation({
            variables: {
              category_id: budgetMonth.category_id,
              year_month: budgetMonth.year_month,
              budget_id: budgetMonth.budget_id
            }
          })
        });
        if ( budgetMonthsToUpsert.length > 0 ) {
          await upsertBudgetMonthsMutation({
            variables: { objects: budgetMonthsToUpsert }
          })
        }
        
        renderToast("Plan Budget Updated");
        window.analytics.track("Plan Budget Updated", {
          method,
          keyboard
        })

        break;
      case "category":
        if ( !targetCategory ) {
          break;
        }

        let amount = null;
        switch ( command ) {
          case "category_quick_budget_last_month":
            amount = targetCategory.lastMonthBudget;
            method = "last_month"
            break;
          case "category_quick_budget_average_spending":
            amount = targetCategory.averageActivity;
            method = "average_spending";
            break;
          case "category_quick_budget_true_up":
            amount = targetCategory.currentMonth.budget - targetCategory.currentMonth.remaining + ( targetCategory.is_income ? targetCategory.currentMonth.scheduledTransactions : -targetCategory.currentMonth.scheduledTransactions);
            method = "true_up";
            break;
          default:
        }

        if ( amount === 0 ) {
          // Delete budget month
          deleteBudgetMonthMutation({
            variables: {
              category_id: targetCategory.id,
              year_month: currentMonth,
              budget_id: budgetId
            }
          })
          .then(() => {
            renderToast("Category Budget Updated");
            window.analytics.track("Category Budget Updated", {
              method,
              keyboard
            })
          })
        } else {
          // Upsert budget month
          upsertBudgetMonthMutation({
            variables: {
              budget_month: {
                category_id: targetCategory.id,
                year_month: currentMonth,
                budget_id: budgetId,
                amount: Math.round(amount * 100) / 100
              }
            }
          })
          .then(() => {
            renderToast("Category Budget Updated");
            window.analytics.track("Category Budget Updated", {
              method,
              keyboard
            })
          })
        }
        break;
      default:
    }
  };

  const actions = ( targetCategory ? categoryActions : []).concat(planActions).map(action => ({
    ...action,
    perform: action.is_quick_budget_function 
      ? () => quickBudgetFunction(action.command)
      : ( action.command ? () => _setActiveAction(action.command) : action.perform )
  }));

  useRegisterActions(actions, [ actions ]);

  return (
    <div>
      <StartFresh onClose = { onClose } isOpen = { activeAction === "start_fresh" } />
    </div>
  );
};

export default PlanQuickActions;
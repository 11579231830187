const axios = require("axios");

const isSandboxMode = process.env.REACT_APP_IEX_VERSION === "sandbox";
const publicToken = process.env.REACT_APP_IEX_PUBLIC_TOKEN;

const client = axios.create({
  baseURL: isSandboxMode ? "https://sandbox.iexapis.com/stable" : "https://cloud.iexapis.com/v1",
});

const _get = (endpoint, params = {}) => 
  client.get(endpoint, {
    params: {
      token: publicToken,
      ...params
    }
  })
  .then(resp => resp.data)

const search = searchText => _get(`/search/${searchText}`);

const batch = ({ symbol, symbols, types, params = {} }) => {
  return _get(`/stock/${symbol || "market"}/batch?types=${types.join(",")}${symbols ? `&symbols=${symbols.join(',')}` : ""}`, params);
}

const getPriceHistory = ({ symbol, range }) => _get(`/stock/${symbol}/chart/${range}`);

const iex = {
  batch,
  getPriceHistory,
  isSandboxMode,
  search
}

export default iex;
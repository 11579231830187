import {
  Stack
} from "@chakra-ui/react";

import Accounts from "./Accounts";
import UpcomingTransactions from "./UpcomingTransactions";
import { Aside } from "components/Page";

const HomeAside = () => (
  <Aside>
    <Stack 
      direction = "column" 
      spacing = "8" 
      pt = "6" 
      px = "2"
    >
      <Accounts />
      <UpcomingTransactions />
    </Stack>
  </Aside>
);

export default HomeAside;
import gql from "graphql-tag";

import { ALL_TRANSACTION_FIELDS } from "./fragments";

const DELETE_TRANSACTION = gql`
  mutation DeleteTransaction($transaction_id: uuid!) {
    delete_transactions_by_pk (
      id: $transaction_id 
    ) {
      id
    }
  }
`;

const GET_TRANSACTIONS = gql`
  ${ALL_TRANSACTION_FIELDS}
  query GetTransactions($budget_id: uuid!) {
    transactions(where: {account: {is_hidden: {_eq: false}, budget_id: {_eq: $budget_id}}, is_deleted: {_eq: false}}, order_by: { authorized_at: desc, id: desc }) {
      ...AllTransactionFields
    }
  }
`;

const INSERT_TRANSACTION = gql`
  ${ALL_TRANSACTION_FIELDS}
  mutation InsertTransaction($transaction: transactions_insert_input!) {
    insert_transactions_one(object: $transaction) {
      ...AllTransactionFields
    }
  }
`;

const INSERT_TRANSACTIONS = gql`
  ${ALL_TRANSACTION_FIELDS}
  mutation UploadTransactions($transactions: [transactions_insert_input!]!) {
    insert_transactions(objects: $transactions) {
      returning {
        ...AllTransactionFields
      }
    }
  }
`;

const TOGGLE_IS_PENDING = gql`
  mutation UpdateIsPending($transaction_id: uuid!, $today: String!) {
    transaction: update_transactions_by_pk(
      pk_columns: { id: $transaction_id },
      _set: { is_pending: false, posted_at: $today }
    ) {
      id
      is_pending
      __typename
    }
  }
`;

export {
  DELETE_TRANSACTION,
  GET_TRANSACTIONS,
  INSERT_TRANSACTION,
  INSERT_TRANSACTIONS,
  TOGGLE_IS_PENDING
};
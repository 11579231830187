import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  useColorModeValue as mode
} from "@chakra-ui/react";

import ShortcutTooltip from "components/ShortcutTooltip";
import TransactionDetails from "components/TransactionDetails";
import useHotKeys from "hooks/useHotKeys";
import { needsCategory } from "utils/calculations";

const CategorizeTransactions = ({ goToScreen, transactions, updateTransaction }) => {
  const [ index, setIndex ] = useState(0);

  const onClickBack = () => {
    if ( index === 0 ) {
      goToScreen("previous")
    } else {
      setIndex(prevIndex => prevIndex - 1);
    }
  };

  const onClickNext = () => {
    if ( index === transactions.length - 1 ) {
      goToScreen("next");
    } else {
      setIndex(prevIndex => prevIndex + 1)
    }
  };

  const transaction = useMemo(() => transactions[index], [ index, transactions ]);
  // Need to calculate needs_category on client since db calculation doesn't happen in cache
  const _transaction = { 
    ...transaction,
    needs_category: needsCategory(transaction.account.type, transaction.transfer_account?.type)
  }
  const canNext = _transaction.category || !_transaction.needs_category;

  useHotKeys('left', onClickBack, [ index ]);
  useHotKeys('right', () => { if ( canNext ) { onClickNext() }}, [ transactions, index ]);

  return (
    <Box 
      shadow = {{ base: "none", md: mode("light.xl", "dark.xl")}} 
      px = {{ base: "4", md: "8" }} py = "8"
      bg = { mode("white", "gray.800") }
    >
      <Heading fontSize = "2xl" mb = "16">Categorize Transaction</Heading>
      <TransactionDetails transaction = { _transaction } onUpdate = { updateTransaction } />
      <HStack justifyContent = "space-between" mt = "4">
        <ShortcutTooltip label = "Back" keys = {['left']}>
          <Button 
            colorScheme = "gray"
            onClick = { onClickBack }
            size = "sm"
            variant = "ghost"
          >Back</Button>
        </ShortcutTooltip>

        <ShortcutTooltip label = "Next" keys = {['right']}>
          <Button
            colorScheme = "primary"
            onClick = { onClickNext }
            isDisabled = { !canNext }
            size = "sm"
          >Next</Button>
        </ShortcutTooltip>
      </HStack>
    </Box>
  )
};

export default CategorizeTransactions;
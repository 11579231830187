const Container = {
  variants: {
    aside: props => ({
      display: "block",
      flex:  "1",
      width: "var(--aside-width)",
      right: 0,
      py: 5,
      px: 3,
      position: "fixed"
    }),
    page: props => ({
      display: "flex",
      height: "100vh",
      bg: props.colorMode === "light" ? "gray.100" : "gray.900",
      overflow: "hidden",
      sx: {
        '--sidebar-width': '20rem'
      },
      maxW: "unset",
      padding: "unset"
    })
  }
};

export default Container;
import {
  Flex,
  useColorModeValue as mode
} from "@chakra-ui/react";

import Aside from "./Aside";
import Header from "./Header";
import HeaderButton from "./HeaderButton";
import Main from "./Main";
import Navigation from "./Navigation";
import PageContext from "./PageContext";
import useMobileAsideState from "./useMobileAsideState";
import useHotKeys from "hooks/useHotKeys";

const Page = ({ children, asideShortcutKey, ...props }) => {
  const navBreakpoint = "lg";
  const asideBreakpoint = "2xl";

  const { isMobile: isAsideMobile, isOpen: isAsideOpen, toggle: toggleAside, off: closeAside  } = useMobileAsideState(asideBreakpoint);
  const { isMobile: isNavMobile, isOpen: isNavOpen, toggle: toggleNav, off: closeNav } = useMobileAsideState(navBreakpoint);

  useHotKeys('m', () => {
    if ( !isNavOpen ) {
      closeAside();
    }
    toggleNav();
  }, [ isNavOpen ]);

  useHotKeys(asideShortcutKey, () => {
    if ( !isAsideOpen ) {
      closeNav();
    }
    toggleAside();
  }, [ isAsideOpen ], !!asideShortcutKey);

  return (
    <PageContext.Provider
      value = {{
        isAsideOpen ,
        toggleAside,
        closeAside,
        isNavOpen,
        toggleNav,
        closeNav,
        navBreakpoint,
        asideBreakpoint,
      }}
    >
      <Flex
        height = "100vh"
        bg = { mode("gray.100", "gray.900") }
        overflow = "hidden"
        sx = {{
          '--aside-width': '20rem',
          '--nav-width': '18rem',
          '--display-aside': isAsideOpen || !isAsideMobile ? 'block' : 'none',
          '--display-nav': isNavOpen || !isNavMobile ? 'flex' : 'none'
        }}
        { ...props }
      >
        <Navigation />
        { children }
      </Flex>
    </PageContext.Provider>
  );
}
export {
  Aside,
  Header,
  HeaderButton,
  Main,
  Page
};